import { map, Observable } from 'rxjs';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Appointment, AppointmentDetail } from '@config/types';
import { AppointmentLists } from '@core/state/appointments.state';

export interface NewDashCardStructure {
  title: string;
  key: string;
  rows: number;
  cols?: number;
  appointments: AppointmentDetail[] | {name: string}[];
}


@Component({
  selector: 'app-appointments',
  templateUrl: './appointment-layout.component.html',
  styleUrls: ['./appointment-layout.component.scss'],
})
export class NewDashDesignComponent implements OnChanges {
  @Input() appointmentData: AppointmentLists;
  @Output() appointmentSelected = new EventEmitter<Appointment>();

  cardContent;
  columns = 3;
  rowHeight = '100vh';
  cardsBase: NewDashCardStructure[] = [
    {
      title: 'To Do',
      key: 'todo',
      rows: 1,
      appointments: null,
    },
    {
      title: 'In Progress',
      key: 'inProgress',
      rows: 1,
      appointments: null,
    },
    { title: 'Needs Review',
      key: 'needsReview',
      rows: 1,
      appointments: null,
    },
  ];
  cards$: Observable<NewDashCardStructure[]>;

  constructor(private breakpointObserver: BreakpointObserver) {
  }

  ngOnChanges(): void {
    /** Based on the screen size, switch from standard to one column per row */
    this.cards$ = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
      map(({ matches }) => {
        const cols = matches ? 3 : 1;
        return this.cardsBase.map((c) => ({
          ...c,
          cols,
          appointments: this.appointmentData[c.key] || c.appointments,
        }));
      })
    );
  }

  selectAppointment(appointment: Appointment): void {
    this.appointmentSelected.emit(appointment);
  }
}
