import { Observable, of, switchMap, take } from 'rxjs';

import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
} from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import { Logout } from '@core/state/user.actions';
import { UserState } from '@core/state/user.state';
import { Select, Store } from '@ngxs/store';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    @Select(UserState.isLoggedIn) isLoggedIn$: Observable<boolean>;

    constructor( private router: Router ) { }

    canActivate(): Observable<boolean> {
        return this.isLoggedIn$.pipe(
        take(1),
        switchMap( isLoggedIn => {
            // health-history/:epmsPID/:appointment as sent by the HA only flow from PERC
            const regEx = new RegExp(`${APP_ROUTES.ha}/[0-9]+/[0-9]+`);
            if (!isLoggedIn && !regEx.test(this.router.routerState.snapshot.url)) {
                this.router.navigateByUrl(APP_ROUTES.logout);
                return of(false);
            }
            return of(true);
        }));
    }
}
