import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { AppConfigService } from 'src/app/app.config';

import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { AppointmentsState } from '@core/state/appointments.state';
import { DashboardModule } from '@dashboard/dashboard.module';
import { environment } from '@environments/environment';
import { NgIdleModule } from '@ng-idle/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';

import { LookupState } from './core/state/lookup.state';
import { UserState } from './core/state/user.state';

const appInitializerFn = (appConfig: AppConfigService) => {
  return () => {
    return appConfig.getEnvironmentConfig();
  };
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    DashboardModule,
    HttpClientModule,
    CoreModule,
    NgIdleModule.forRoot(),
    NgxsModule.forRoot([UserState, AppointmentsState, LookupState], {
      developmentMode: !environment.production,
      selectorOptions: {
        suppressErrors: false,
        injectContainerState: false,
      }
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      // these keys are stashed in localstorage to survive refreshes
      key: [
        'user',
        'appointments.selectedAppointmentLookupIds',
        'patient.keys',
        'lookup.medicalReportType'
      ]
    }),
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      deps: [AppConfigService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;
    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
}
