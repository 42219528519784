import { BehaviorSubject, first, map, Observable, share } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import {
    FacilityAndRoleInternal, FacilityWithRole, FacilityWithRoleResponse, LoginResponse
} from '@config/types';
import { StorageService } from './storage.service';
import { UserRole, UserService } from './user.service';

export enum AUTH_STATES {
  UNAUTHORIZED, // logged out state
  AUTHORIZED, // login token issued
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get userID(): string {
    return this.storageService.getItem('user');
  }

  get lastName(): string {
    return this.storageService.getItem('lastName');
  }

  get firstName(): string {
    return this.storageService.getItem('firstName');
  }

  private isLoginSubject$ = new BehaviorSubject<boolean>(this.hasToken());

  private _facilityAndRoles$: BehaviorSubject<FacilityAndRoleInternal[]> =
    new BehaviorSubject(null);
  public facilityAndRoles$: Observable<FacilityAndRoleInternal[]> =
    this._facilityAndRoles$.asObservable();

  private _refreshTokenInProgress = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    public userService: UserService,
    private config: AppConfigService,
    private storageService: StorageService
  ) {
    // this.restoreState();
  }

  restoreState(): void {
    console.log(
      'isUserDataCurrentlySet?',
      this.userService.isUserDataCurrentlySet()
    );
    if (!this.userService.isUserDataCurrentlySet()) {
      const userId = this.storageService.getItem('user');
      const firstName = this.storageService.getItem('firstName');
      const lastName = this.storageService.getItem('lastName');
      const token = this.storageService.getItem('token');
      if (token && userId && lastName && firstName) {
        this.userService.setUserData({
          userId,
          firstName,
          lastName,
        });
      }
    }
    this.commonStateBuildingTasks();
  }

  login(username: string, password: string): void {
    this.http
      .post(this.config.ENDPOINTS.login, {
        username,
        password,
      })
      .subscribe((response: LoginResponse) => {
        if (response.data) {
          this.userService.setUserData({
            userId: `${response.data.user.adminid}`,
            firstName: `${response.data.user.firstName}`,
            lastName: `${response.data.user.lastName}`,
          });
          this.isLoginSubject$.next(true);
          this.commonStateBuildingTasks();
          this.userService
            .getUserRole()
            .pipe(first())
            .subscribe((result) => {
              if (result !== UserRole.HealthAssessmentOnly) {
                this.userService.clearHAData();
                this.navigateToDashboard();
              } else {
                this.navigateToHealthAssessment();
              }
            });
        }
      });
  }

  commonStateBuildingTasks(): void {
    this.setFacilityAndRolesInternalArr();
  }

  navigateToHealthAssessment() {
    this.router.navigateByUrl(`${APP_ROUTES.ha}`);
  }

  setFacilityAndRolesInternalArr(): void {
    // this.userId will have a value only after login success.
    if (!this.isFacilityAndRolesSet()) {
      this.http
        .get<FacilityWithRoleResponse>(
          this.config.ENDPOINTS.allowedFacilitiesAndRoles,
          {
            params: { userID: `${this.userID}` },
          }
        )
        .pipe(
          first(),
          map(
            (obj) =>
              obj.data.map((arr) => {
                return {
                  id: arr.facility.id.toString(),
                  name: arr.facility.name,
                  isCr: arr.isCR,
                  isMd: arr.isMD,
                  isMa: arr.isMA,
                };
              }) as FacilityAndRoleInternal[]
          )
        )
        .subscribe((roles) => {
          console.log('received Facility and roles', roles);
          this._facilityAndRoles$.next(roles);
        });
    }
  }

  navigateToDashboard() {
    this.facilityAndRoles().subscribe(
      (data) => {
        if (data.length > 0) {
          this.viewPatientAppointments('isMD');
        } else {
          this.viewPatientAppointments();
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getRefreshTokenInProgress(): boolean {
    return this._refreshTokenInProgress;
  }

  setRefreshTokenInProgress(value: boolean): void {
    this._refreshTokenInProgress = value;
  }

  refreshToken(): Observable<any> {
    const body: any = {
      refreshToken: this.storageService.getItem('refreshToken'),
      userID: +this.userID,
    };
    const url: string = this.config.ENDPOINTS.refreshToken;
    return this.http.post(url, body) as Observable<any>;
  }

  logout(): void {
    this.isLoginSubject$.next(false);
    this.storageService.removeAll();
    localStorage.clear();
    const logoutEvent = new Event('logoutEvent');
    document.dispatchEvent(logoutEvent);
    this.router.navigateByUrl('/');
  }

  viewPatientAppointments(isMD?): void {
    if (isMD && this.isUserLoggedIn()) {
      // router outlet - need to change this routing in dashboard module ticket
      this.router.navigateByUrl(
        `${APP_ROUTES.dashboard}`
      );
    } else if (this.isUserLoggedIn()) {
      this.router.navigateByUrl(`${APP_ROUTES.dashboard}`);
    }
  }

  private hasToken(): boolean {
    return !!this.storageService.getItem('token');
  }

  isUserLoggedIn(): Observable<boolean> {
    return this.isLoginSubject$.asObservable();
  }

  isUserCR(options: {
    userID: string;
    epmsPID: string;
    reportID: string;
  }): Observable<object> {
    return this.http.get(this.config.ENDPOINTS.isUserCR, {
      params: options,
    });
  }

  isUserMD(options: { userID: string; epmsPID: string; reportID: string }) {
    return this.http.get(this.config.ENDPOINTS.isUserMD, {
      params: options,
    });
  }

  isUserHAOnly(): boolean {
    const userRole = this.storageService.getItem('userRole');
    return userRole && userRole === UserRole.HealthAssessmentOnly;
  }

  // is facilityAndRolesSet?
  isFacilityAndRolesSet(): boolean {
    console.log('isFacilityAndRolesSet', this._facilityAndRoles$.getValue());
    return !!this._facilityAndRoles$.getValue();
  }

  facilityAndRoles(): Observable<FacilityWithRole[]> {
    return this.http
      .get<FacilityWithRoleResponse>(
        this.config.ENDPOINTS.allowedFacilitiesAndRoles,
        {
          params: { userID: `${this.userID}` },
        }
      )
      .pipe(
        first(),
        share(),
        map((response) => {
          return response.data.filter((arr) => arr.isMD);
        })
      );
  }
}
