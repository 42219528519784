
import { Subject } from 'rxjs';

import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { LabOrderState, LabRequisitionOrder, ReqDetailsControls } from '@config/types';
import { LabsService } from '@patient/services/labs.service';

import { CytologyDetailsComponent } from '../cytology-details/cytology-details.component';
import { ExamInfoComponent } from '../exam-info/exam-info.component';
import { PapOrderComponent } from '../pap-order/pap-order.component';
import { ReqDetailsComponent } from '../req-details/req-details.component';

@Component({
  selector: 'app-pap-request',
  templateUrl: './pap-request.component.html',
  styleUrls: ['./pap-request.component.scss']
})
export class PapRequestComponent implements OnInit {

  @ViewChild(ExamInfoComponent) examInfo: ExamInfoComponent;
  @ViewChild(ReqDetailsComponent) reqDetails: ReqDetailsComponent;
  @ViewChild(CytologyDetailsComponent) cytDetails: CytologyDetailsComponent;
  @ViewChild(PapOrderComponent) papOrder: PapOrderComponent;

  isInvalidForm = true;
  papRequest: LabRequisitionOrder;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private location: Location,
    private labsService: LabsService
  ) {}

  ngOnInit(): void {
    this.papRequest = {
      displayOrderDate: null,
      eheCytologyTests: '',
      ehePreferredPanels: '',
      ehePreferredTests: '',
      id: null,
      labCorpCollectionDate: null,
      labCorpPatientNotes: null,
      labCorpResultsDate: null,
      labRequisition: {
        displayCollectionDate: '',
        displayCollectionTime: '',
        displayType: '2',
        encounterID: null,
        isFasting: '',
        labType: '2',
        requisitionID: null,
        type: 2,
      },
      nonStandardTests: '',
      orderDate: '',
      orderID: null,
      otherTests: '',
      physician: {
        adminid: 0,
      },
      cytologyDetails: {
        lmpMenoDate: '',
        formattedLmpMenoDate: '',
        cytSource: '',
        cytCollection: '',
        cytHistory: '',
        cytPrevCondition: '',
        cytPrevTreatment: '',
        cytPrevTreatmentComments: '',
      },
      resultsReceivedDate: null,
      status: 0,
      statusModifiedDate: null,
      statusName: '',
    };
    const labOrder = <LabOrderState>history.state;
    if(labOrder.orderID) {
      this.papRequest = labOrder.labRequisitionData;
    }
  }

  navigateToRequests(){
    this.location.back();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(){
    this.reqDetails.form.get('fasting').clearValidators();
    this.reqDetails.form.get('fasting').updateValueAndValidity();
    this.examInfo.form.valueChanges.subscribe(res => {
      this.papRequest.physician.adminid = res.physician;
      this.isInvalidForm = this.checkFormValidity();
    });
    this.reqDetails.form.valueChanges.subscribe((res: ReqDetailsControls) => {
      this.papRequest.labRequisition.displayCollectionDate =
        res.dateCollected instanceof Date
        ? res.dateCollected.toLocaleDateString()
        : res.dateCollected;
      this.papRequest.labRequisition.displayCollectionTime =
        res.timeCollected instanceof Date
        ? res.timeCollected.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit', hour12: false})
        : res.timeCollected;
      this.isInvalidForm = this.checkFormValidity();
    });
    this.cytDetails.form.valueChanges.subscribe(res => {
      this.papRequest.cytologyDetails.formattedLmpMenoDate =
        res.menodate instanceof Date
        ? res.menodate.toLocaleDateString()
        : res.menodate;
      this.papRequest.cytologyDetails.cytSource = res.cytSource;
      this.papRequest.cytologyDetails.cytCollection = res.cytCollection;
      this.papRequest.cytologyDetails.cytHistory = res.cytOtherPatientInfo.toString();
      this.papRequest.cytologyDetails.cytPrevCondition = res.cytPreviousCondition.toString();
      this.papRequest.cytologyDetails.cytPrevTreatment = res.cytPreviousTreatment.toString();
      this.isInvalidForm = this.checkFormValidity();
   })
   this.papOrder.form.valueChanges.subscribe(res => {
     this.papRequest.eheCytologyTests = res.papProcedure;
   })
  }

  checkFormValidity(): boolean{
    return (this.examInfo.form.invalid || this.cytDetails.form.invalid || this.reqDetails.form.invalid)
  }

  createPapOrder(){
    return this.labsService.createPapOrder(
      this.papRequest
    ).subscribe((res) => {
      this.navigateToRequests()
    });
  }

}
