import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LabOrderState, LookupOption } from '@config/types';

@Component({
  selector: 'app-cytology-details',
  templateUrl: './cytology-details.component.html',
  styleUrls: ['./cytology-details.component.scss']
})
export class CytologyDetailsComponent implements OnInit {

  form: FormGroup;
  labOrderState: LabOrderState;
  cytSourceOptions: LookupOption[];
  cytCollectionOptions: LookupOption[];
  cytOtherPatientinfoOptions: LookupOption[];
  cytPreviousConditionOptions: LookupOption[];
  cytPreviousTreatmentOptions: LookupOption[];

  constructor(private fb: FormBuilder, private router: Router) {
    this.form = this.fb.group({
      menodate: ['', Validators.required],
      cytSource: ['', Validators.required],
      cytCollection: ['', Validators.required],
      cytOtherPatientInfo: ['', Validators.required],
      cytPreviousCondition: ['', Validators.required],
      cytPreviousTreatment: ['', Validators.required],
    });
    this.labOrderState = <LabOrderState>history.state ?? <LabOrderState>(
      this.router.getCurrentNavigation().extras.state
    );
    this.cytSourceOptions = this.labOrderState.cytSourceOptions;
    this.cytCollectionOptions = this.labOrderState.cytCollectionOptions;
    this.cytOtherPatientinfoOptions = this.labOrderState.cytOtherPatientinfoOptions;
    this.cytPreviousConditionOptions = this.labOrderState.cytPreviousConditionOptions;
    this.cytPreviousTreatmentOptions = this.labOrderState.cytPreviousTreatmentOptions;
  }

  ngOnInit(): void {
    if (this.labOrderState.labRequisitionData)
      this.form.patchValue({
        menodate: this.labOrderState.labRequisitionData.cytologyDetails.formattedLmpMenoDate.length > 0 ? new Date(this.labOrderState.labRequisitionData.cytologyDetails.formattedLmpMenoDate): null,
        cytSource: this.labOrderState.labRequisitionData.cytologyDetails.cytSource.length > 0 ?  this.labOrderState.labRequisitionData.cytologyDetails.cytSource: null,
        cytCollection: this.labOrderState.labRequisitionData.cytologyDetails.cytCollection.length > 0 ?  this.labOrderState.labRequisitionData.cytologyDetails.cytCollection: null,
        cytOtherPatientInfo: this.labOrderState.labRequisitionData.cytologyDetails.cytHistory.length > 0 ?  this.labOrderState.labRequisitionData.cytologyDetails.cytHistory.split(','): null,
        cytPreviousCondition: this.labOrderState.labRequisitionData.cytologyDetails.cytPrevCondition.length > 0 ?  this.labOrderState.labRequisitionData.cytologyDetails.cytPrevCondition.split(','): null,
        cytPreviousTreatment: this.labOrderState.labRequisitionData.cytologyDetails.cytPrevTreatment.length > 0 ?  this.labOrderState.labRequisitionData.cytologyDetails.cytPrevTreatment.split(','): null,
      });
    }

}
