import { map, Observable, shareReplay } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EkgResultsDTO, GetEkgResultsResponse, GetHearingResultsResponse, GetRiskScoresResultsResponse,
    GetVisionResultsResponse, GetVitalsResultsResponse, HearingResultsDTO, LookupOption,
    LookupOptionResponse, RiskScoresResultsDTO, SetEkgResultsResponse, SetHearingResultsResponse,
    SetRiskScoresResultsResponse, SetVisionResultsResponse, SetVitalsResultsResponse,
    VisionResultsDTO, VitalsResultsDTO
} from '@config/types';
import { HttpContextHelperService } from '@core/services/http-context-helper.service';

@Injectable()
export class ScreeningsService {
  constructor(private http: HttpClient, private config: AppConfigService, private httpContextHelperService: HttpContextHelperService) {}

  postVision(formValues: VisionResultsDTO): Observable<SetVisionResultsResponse> {
    return this.http.post<SetVisionResultsResponse>(
      this.config.ENDPOINTS.vision,
      formValues,
      this.httpContextHelperService.setParamTokensInHttpContext({
        body: ['reportID'],
        queryParams: ['userID', 'epmsPID']
      })
    );
  }

  getVision(): Observable<GetVisionResultsResponse> {
    return this.http.get<GetVisionResultsResponse>(
      this.config.ENDPOINTS.vision,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  postHearing(formValues: HearingResultsDTO): Observable<SetHearingResultsResponse> {
    return this.http.post<SetHearingResultsResponse>(
      this.config.ENDPOINTS.hearing,
      formValues,
      this.httpContextHelperService.setParamTokensInHttpContext({
        body: ['reportID'],
        queryParams: ['userID', 'epmsPID']
      })
    );
  }

  getHearing(): Observable<GetHearingResultsResponse> {
    return this.http.get<GetHearingResultsResponse>(
      this.config.ENDPOINTS.hearing,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  postVitals(formValues: VitalsResultsDTO): Observable<SetVitalsResultsResponse> {
    return this.http.post<SetVitalsResultsResponse>(
      this.config.ENDPOINTS.vitals,
      formValues,
      this.httpContextHelperService.setParamTokensInHttpContext({
        body: ['reportID'],
        queryParams: ['userID', 'epmsPID']
      })
    );
  }

  getVitals(): Observable<GetVitalsResultsResponse> {
    return this.http.get<GetVitalsResultsResponse>(
      this.config.ENDPOINTS.vitals,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  postEKG(formValues: EkgResultsDTO): Observable<SetEkgResultsResponse> {
    return this.http.post<SetEkgResultsResponse>(
      this.config.ENDPOINTS.ekg,
      formValues,
      this.httpContextHelperService.setParamTokensInHttpContext({
        body: ['reportID'],
        queryParams: ['userID', 'epmsPID']
      })
    );
  }

  getEKG(): Observable<GetEkgResultsResponse> {
    return this.http.get<GetEkgResultsResponse>(
      this.config.ENDPOINTS.ekg,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  postRisk(formValues: RiskScoresResultsDTO): Observable<SetRiskScoresResultsResponse> {
    return this.http.post<SetRiskScoresResultsResponse>(
      this.config.ENDPOINTS.riskScores,
      formValues,
      this.httpContextHelperService.setParamTokensInHttpContext({
        body: ['reportID'],
        queryParams: ['userID', 'epmsPID']
      })
    );
  }

  getRisk(): Observable<GetRiskScoresResultsResponse> {
    return this.http.get<GetRiskScoresResultsResponse>(
      this.config.ENDPOINTS.riskScores,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  getBpPositionOptions(): Observable<LookupOption[]> {
    return this.http.get<LookupOptionResponse>(this.config.ENDPOINTS.providerLookupBpPosition).pipe(
      map((response) => response.data),
      shareReplay()
    );
  }
  getV02Options(): Observable<LookupOption[]> {
    return this.http.get<LookupOptionResponse>(this.config.ENDPOINTS.providerLookupVo2Options).pipe(map((response) => response.data));
  }
}
