import { merge, Observable, Subject, takeUntil } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VitalsResultResponse } from '@config/types';
import { ScreeningsService } from '@patient/services/screenings.service';
import { AppUtils } from '@shared/utils/app.utils';

@Component({
  selector: 'app-vitals-form',
  templateUrl: './vitals-form.component.html',
  styleUrls: ['./vitals-form.component.scss']
})
export class VitalsFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;

  // No Lookup API yet
  sideOptions = ['Left', 'Right'];
  positionOptions$ = this.screeningsService.getBpPositionOptions();
  VO2Options$ = this.screeningsService.getV02Options();

  private bmiChanges$: Observable<number>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private screeningsService: ScreeningsService,
  ) { }

  ngOnInit(): void {
    const height = this.form.get('height');
    const weight = this.form.get('weight');
    const bmi = this.form.get('bmi');

    this.bmiChanges$ = merge(
      height.valueChanges,
      weight.valueChanges
    );

    this.fetchForm();

    this.bmiChanges$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (height.value && weight.value) {
        bmi.patchValue(AppUtils.calculateBMI(weight.value, height.value));
      } else {
        bmi.patchValue(null);
      }
    });

  }

  saveForm(): Promise<boolean> {
    const formData = this.form.getRawValue();
    return new Promise<boolean>(resolve => {
      this.screeningsService.postVitals(formData)
        .pipe(
          takeUntil(this.unsubscribe$),
        )
        .subscribe((response: VitalsResultResponse) => {
          if (response) {
            this.form.markAsPristine();
            resolve(true);
          } else {
            resolve(false);
          }
          AppUtils.DEBUG && console.log('Response', response);
        });
    });
  }

  fetchForm(): void {
    this.screeningsService
      .getVitals()
      .subscribe((response: VitalsResultResponse) => {
        if (response.data) {
          this.form.patchValue(response.data);
          AppUtils.DEBUG && console.log('Vitals', response.data);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
