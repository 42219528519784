import {
    AppointmentFacility, Facility, LatestReportStatus, ObjectiveField, Patient
} from '@config/types';

export type AppointmentTypes = 'todo' | 'inProgress' | 'needsReview';

export enum AppointmentTypesEnum {
  toDo = 'todo',
  inProgress = 'inProgress',
  needsReview = 'needsReview'
}
export interface ReportDTO {
  id: number;
  examId: number;
  examDateStr: string;
  reportTypeId: number;
  physician: number;
  isDeleted: boolean;
  facility: number;
  objectives: ObjectiveField[];
  assessmentPlans: ObjectiveField[];
  latestReportStatus: LatestReportStatus | null;
  // appointmentFacility: Facility;
  procedureID: number;
  procedureName: string;
}


// consolidated Appointment Detail
export interface AppointmentDetail {
  appointmentID: number;
  appointmentDate: string;
  apptDateTime: string;
  patient: Patient;
  exam: ReportDTO | null;
  procedureID: number;
  procedureName: string;
  type: AppointmentTypes;
  // optional information below only features in PatientAppointmentDTO
  duration: number | null;
  eventType: number | null;
  appointmentFacility: AppointmentFacility;
  primaryProcedureID: number | null;
  providerID: number | null;
  status: number | null;
}

// contents of todo appointments
// PatientAppointmentDTO
export interface PatientAppointmentDTO {
  appointmentDate: string; // NOT USED
  appointmentFacility: Facility;
  appointmentID: number;
  apptDateTime: string;
  duration: number; // not in ExamReviewDTO
  eventType: number; // not in ExamReviewDTO
  patient: Patient;
  primaryProcedureID: number; // not in ExamReviewDTO
  procedureID: number;
  procedureName: string;
  providerID: number;  // not in ExamReviewDTO
  status: number;  // not in ExamReviewDTO
}

// contents of inProgress & needsReview appointments
export interface ExamReviewDTO {
  appointmentDate: string;  // NOT USED
  appointmentFacility: Facility;
  appointmentID: number;
  apptDateTime: string;
  patientDTO: Patient;
  reportDTO: ReportDTO;
  procedureID: number;
  procedureName: string;
}
export interface PristineAppointmentsResponse {
  data: PatientAppointmentDTO[];
}
export interface TouchedAppointmentsResponse {
  data: ExamReviewDTO[];
}

export interface ExamReviewDTOResponse {
  data: ExamReviewDTO;
}

export interface AppointmentDetailResponse {
  data: AppointmentDetail;
}
