import { filter, map, Observable, switchMap, throwError, withLatestFrom } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    AppointmentDetail, Exam, ExamResponse, ExamReviewDTO, ExamReviewDTOResponse, IParamsEpmsAndReport, LookupOption, NoShowResponse,
    PatientAppointmentDTO, PristineAppointmentsResponse, ServerErrorMessage,
    TouchedAppointmentsResponse, User
} from '@config/types';
import { CoreLookupService } from '@core/services/core-lookup.service';
import { GetToDoAppointments } from '@core/state/appointments.actions';
import { UserState } from '@core/state/user.state';
import { Select, Store } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

import { ErrorDialogService } from './error-dialog.service';

export type AppointmentsUriKey =
  | 'appointmentsToDo'
  | 'appointmentsInProgress'
  | 'appointmentsNeedsReview';
export interface GetAppointmentParams {
  facilityID: string;
  appointmentDate?: string;
}
export interface GetCommonAppointmentsParams {
  facilityID: string;
}
export interface GetToDoAppointmentsParams extends GetCommonAppointmentsParams {
  appointmentDate: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppointmentsService {
  @Select(UserState.getUser) user$: Observable<User>;

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private coreLookupService: CoreLookupService,
    private errorDialogService: ErrorDialogService,
    private store: Store,
  ) {}

  getToDoAppointments(
    params: GetToDoAppointmentsParams
  ): Observable<PatientAppointmentDTO[]> {
    return this.genericGetAppoinments(params, 'appointmentsToDo').pipe(
      map(r => r.data as PatientAppointmentDTO[])
    );
  }

  getInProgressAppointments(
    params: GetCommonAppointmentsParams
  ): Observable<ExamReviewDTO[]> {
    const sanitizedParams = {facilityID: params.facilityID};
    return this.genericGetAppoinments(sanitizedParams, 'appointmentsInProgress').pipe(
      map(r => r.data as ExamReviewDTO[])
    );
  }

  getNeedsReviewAppointments(
    params: GetCommonAppointmentsParams
  ): Observable<ExamReviewDTO[]> {
    const sanitizedParams = {facilityID: params.facilityID};
    return this.genericGetAppoinments(sanitizedParams, 'appointmentsNeedsReview').pipe(
      map(r => r.data as ExamReviewDTO[])
    );
  }

  genericGetAppoinments(
    params: GetAppointmentParams,
    uriKey: AppointmentsUriKey
  ): Observable<PristineAppointmentsResponse | TouchedAppointmentsResponse> {
    const constructedParams: GetAppointmentParams = {
      facilityID: params.facilityID
    };
    if (params.appointmentDate) {
      constructedParams.appointmentDate = AppUtils.getFormattedDate(params.appointmentDate);
    }
    return this?.user$.pipe(
      filter(user => !!user),
      switchMap((user) => {
        return this.http.get<PristineAppointmentsResponse | TouchedAppointmentsResponse>(this.config.ENDPOINTS[uriKey], {
          params: {
            ...constructedParams,
            userID: user?.adminid?.toString()
          }
        });
      })
    );
  }

  // userID, epmsPID, reportID
  getAppointmentDetails({ epmsPID, reportID }: IParamsEpmsAndReport): Observable<ExamReviewDTO> {
    return this?.user$.pipe(
      filter((user) => !!user),
      switchMap((user) =>
        this.http.get<ExamReviewDTOResponse>(this.config.ENDPOINTS.getAppointmentDetails, {
          params: {
            epmsPID: epmsPID.toString(),
            reportID: reportID.toString(),
            userID: user?.adminid?.toString()
          }
        }).pipe(
          map( response => response.data)
        )
      )
    );
  }

  createOrGetExam(appointment: AppointmentDetail): Observable<Exam> {
    const body = { notes: '' };
    return this.coreLookupService.getMedicalReportTypeId({ procedureId: appointment.procedureID }).pipe(
      withLatestFrom(this.user$),
      switchMap(([reportTypeData, user]: [[LookupOption], User]) => {
        console.log('createOrGetExam', appointment);
        const reportTypeID = reportTypeData[0].id;
        return this.http.post<ExamResponse>(this.config.ENDPOINTS.createOrGetExam, body, {
          params: {
            appointmentID: appointment.appointmentID.toString(),
            procedureID: appointment.procedureID.toString(),
            reportTypeID: reportTypeID.toString(),
            epmsPID: appointment?.patient?.epmsPID.toString(),
            userID: user?.adminid.toString()
          }
        });
      }),
      map(response => response.data)
    );
  }

  submitNoShow(appointment: AppointmentDetail): void {
    // not setting params in the context since appointment id not present in url
    let httpParams;
    this.user$.subscribe((user: User) => {
      httpParams = new HttpParams({
        fromObject: {
          apptID: appointment.appointmentID.toString(),
          procedureID: appointment.procedureID.toString(),
          userID: user.adminid.toString(),
          epmsPID: appointment.patient.epmsPID.toString()
        }
      });
    });

    this.http
      .post<NoShowResponse>(
        this.config.ENDPOINTS.appointmentsNoShow,
        {},
        {
          params: httpParams
        }
      )
      .subscribe((response: NoShowResponse) => {
        AppUtils.DEBUG && console.log('no show submitted', response);
        if (response.data && response.data.noShowAllowed) {
          const params: GetToDoAppointmentsParams = {
            appointmentDate: appointment.apptDateTime,
            facilityID: appointment.appointmentFacility.id.toString()
          };
          return this.store.dispatch(new GetToDoAppointments(params));
        } else if (response.data && !response.data.noShowAllowed && response.message) {
          this.errorDialogService.openDialog(
            {
              reason: response.message,
              status: 0
            } as ServerErrorMessage,
            this.config.ENDPOINTS.appointmentsNoShow,
            httpParams
          );
        }
      }, this.handleAppointmentError);
  }

private handleAppointmentError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(
        'An error occurred for genericGetAppoinments()',
        error.error
      );
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('An error occurred; please try again later.');
  }
}

