import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calculateAge',
})

/**
 *
 * @param dateString  Param for date string to be calculated.
 *
 * @return    The calculated age
 *
 * Accepted string formats
 *   - 'mm/dd/yyyy'
 *   - 'mm/dd/yy'
 */
export class CalculateAgePipe implements PipeTransform {
  transform(dateString: string) {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
