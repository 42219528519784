import { Component, Input } from '@angular/core';

export interface SkeletonCard {
  cols: number;
  rows: number;
  class?: string;
}

@Component({
  selector: 'app-skeleton-card',
  templateUrl: './skeleton-card.component.html',
  styleUrls: ['./skeleton-card.component.scss'],
})
export class SkeletonCardComponent {
  @Input() layoutConfig: SkeletonCard[];
  @Input() listRowHeight: string | number = '25px';
  @Input() listCols = 2;

  defaultConfig: SkeletonCard[] = [
    { cols: 1, rows: 1, class: 'card-animation'},
    { cols: 1, rows: 1 },
    { cols: 1, rows: 1, class: 'card-animation' },
    { cols: 1, rows: 1, class: 'card-animation' },
    { cols: 1, rows: 1, class: 'card-animation' },
    { cols: 1, rows: 1, class: 'card-animation' },
  ];

  constructor() {
    this.layoutConfig = this.layoutConfig ?? this.defaultConfig;
  }
}
