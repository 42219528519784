import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { StorageService } from './storage.service';
import { AppUtils } from '@shared/utils/app.utils';

export interface IUserData{
    firstName: string;
    lastName: string;
    userId: string;
}

export interface IPatientReportInfo{
    apptID: string;
    epmsPID: string;
    reportID?: string;
}

export type userRole = 'MD' | 'MA' | 'CR' | 'HAOnly';

export enum UserRole {
  Provider = 'MD',
  MedicalAssistant = 'MA',
  ClinicalReview = 'CR',
  HealthAssessmentOnly = 'HAOnly'
}

@Injectable({
    providedIn: 'root',
})
export class UserService {

    private userData: BehaviorSubject<IUserData> = new BehaviorSubject<IUserData>(null);
    private userRole: BehaviorSubject<userRole> = new BehaviorSubject<userRole>(null);
    private examData: BehaviorSubject<IPatientReportInfo> = new BehaviorSubject<IPatientReportInfo>({epmsPID: '', apptID: '', reportID: ''});

    constructor(private storageService: StorageService) {

    }

    getUserRole(): Observable<userRole>{
        return this.userRole as Observable<userRole>;
    }

    getUserData(): Observable<IUserData>{
        return this.userData as Observable<IUserData>;
    }

    setUserData(userData: IUserData): void{
        this.userData.next(userData);
    }

    setUserRole(role: UserRole): void{
        if (role === UserRole.HealthAssessmentOnly) {
            this.storageService.setItem('userRole', role);
        } else {
            this.storageService.removeItem('userRole');
        }
        this.userRole.next(role);
    }

    setPatientReportInfo(examData: IPatientReportInfo){
        if (this.storageService.getItem('userRole') === UserRole.HealthAssessmentOnly){
            // WE are storing epmsPID and apptID in session storage because we will lose the reference
            // once when we refresh the page from healthhistory tab from PERC workflow
            this.storageService.setItem('epmsPID', examData.epmsPID);
            this.storageService.setItem('apptID', examData.apptID);
        }else{
            // Do not store epmsPID/apptID for normal provider workflow. if it s there clear it out
           this.clearHAData();
        }
        this.storageService.setItem('reportID', examData.reportID);
        this.examData.next(examData);
        AppUtils.DEBUG && console.log('Exam data from set patient', examData);
    }

    // Remove storage related to HA
    clearHAData(): void {
        if (this.storageService.getItem('epmsPID')) {
          this.storageService.removeItem('epmsPID');
        }
        if (this.storageService.getItem('apptID')) {
          this.storageService.removeItem('apptID');
        }
      }

    getPatientReportInfo(): Observable<IPatientReportInfo>{
        this.tempGetPatientReportInfo();
        return this.examData as Observable<IPatientReportInfo>;
    }

    isUserDataCurrentlySet(): boolean {
        return !!this.userData.getValue();
    }

    tempGetPatientReportInfo() {
        const examInfo  = this.examData.value;
        AppUtils.DEBUG && console.log('tempGetPatientReportInfo', examInfo);
        return examInfo;
    }

}
