import { Observable, Subject, switchMap, takeUntil, tap } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { SUB_PROCEDURE_MAPPING } from '@config/app.constant';
import { APP_ROUTES } from '@config/application-routes';
import { AppointmentDetail } from '@config/types';
import { AppointmentsControllerService } from '@core/services/appointments-controller.service';
import { AuthService } from '@core/services/auth.service';
import { PatientExamService } from '@patient/services/patient-exam.service';
import { HaNavigationService, HealthAssesmentTab } from '@ha/services/ha-navigation.service';

@Component({
  selector: 'app-patient-chart',
  templateUrl: 'patient-chart.component.html',
})
export class PatientChartComponent implements OnInit, OnDestroy {
  childRoutes: Route[] = [];
  activeLink: ActivatedRouteSnapshot;
  appointment$: Observable<AppointmentDetail>;

  private unsubscribe$ = new Subject<void>();

  subProcedureObj: SubProcedureObject;
  isPhysicalExamSubprocedure = false;


  constructor(
    public patientExamService: PatientExamService,
    public route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private appointmentsControllerService: AppointmentsControllerService,
    private haNavigationService: HaNavigationService,
  ) {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.activeLink = this.route.snapshot.firstChild;
    });
  }

  ngOnInit() {
    this.appointment$ = this.route.paramMap.pipe(
      takeUntil(this.unsubscribe$),
      switchMap( paramMap => {
        console.log('in header looking for paramMap', paramMap);
        const appointmentID = parseInt(paramMap.get('appointmentID'), 10);
        return this.appointmentsControllerService.getAppointment(appointmentID);
      })
    ).pipe(
      tap(appointment => console.log('have appointment', appointment))
    );

    const currentUrl = this.router.url;
    if (currentUrl.includes('sub-procedure/')) {
      this.isPhysicalExamSubprocedure = false;
      this.appointment$.pipe(takeUntil(this.unsubscribe$)).subscribe(appointment => {
        const route = SUB_PROCEDURE_MAPPING.get(appointment.procedureID);
        this.subProcedureObj = {
          name: appointment.procedureName,
          routeLink: `sub-procedure/${route}`
        };
      });
    } else {
      this.isPhysicalExamSubprocedure = true;
    }
    this.buildRoutes();
  }

  buildRoutes() {
    this.childRoutes = this.route.snapshot.routeConfig.children
      .filter(item => item && item.data &&
        this.isPhysicalExamSubprocedure ? item.data.physicalExamSubProcedureVisibility : item.data.nonPhysicalExamSubProcedureVisibility);
  }


  navigateNext() {
    let destination = this.activeLink?.data.nextRoute;
    if (!this.isPhysicalExamSubprocedure && this.router.url.indexOf(APP_ROUTES.documents) !== -1) {
      destination = APP_ROUTES.review;
    }
    if (this.router.url.includes(APP_ROUTES.ha)) {
      let haTab: HealthAssesmentTab = this.haNavigationService.getHealthAssesmentTab();
      if (haTab) {
        haTab.selectedTab = (haTab.selectedTab + 1 ) % haTab.tabCount;
        this.haNavigationService.setHealthAssesmentTab(haTab);
        if (haTab.selectedTab) {
          destination = APP_ROUTES.ha;
        }
      }
    }
    this.router.navigate([destination], {
      relativeTo: this.route,
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}

export interface SubProcedureObject {
  name: string;
  routeLink: string;
}
