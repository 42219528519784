import { Injectable } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import { mainPatientRoute } from '@patient/patient-routing.module';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  peRoutes: Routes;
  nonPeRoutes: Routes;

  constructor(
    private router: Router,
  ) {
    this.peRoutes = mainPatientRoute.children.filter((r) => r.data.physicalExamSubProcedureVisibility);
    this.nonPeRoutes = mainPatientRoute.children.filter((r) => r.data.nonPhysicalExamSubProcedureVisibility);
  }

  goToDashboard(): void {
    this.router.navigateByUrl(APP_ROUTES.dashboard);
  }

  isDashboardUrl(url: string): boolean {
    const urlSegments = url.split('/');
    console.log('isDashboardUrl', urlSegments, urlSegments[urlSegments.length - 1]);
    return urlSegments[urlSegments.length - 1] === APP_ROUTES.dashboard;
  }

  isPatientUrl(url: string): boolean {
    const regEx = new RegExp(`${APP_ROUTES.dashboard}/[0-9]+`);
    // return /appointments\/[0-9]+/ig.test(url);
    return regEx.test(url);
  }

  goToPatientChart(appointmentID: number): void {
    this.router.navigate([APP_ROUTES.dashboard, appointmentID]);
  }

  navigateNext(currentSection: string, isPeSection: boolean = true): Route {
    const routeGroup = isPeSection ? this.peRoutes : this.nonPeRoutes;

    const currentIndex = routeGroup.findIndex((childRoute) => {
      return currentSection === childRoute.data.title;
    });
    return routeGroup[currentIndex + 1];
  }

}
