import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EheFormComponent, EheFormComponentTemplate } from '@core/guards/dirty-check.guard';
import { EKGFormComponent } from '@patient/components/ekg-form/ekg-form.component';
import { HearingFormComponent } from '@patient/components/hearing-form/hearing-form.component';
import { VisionFormComponent } from '@patient/components/vision-form/vision-form.component';
import { VitalsFormComponent } from '@patient/components/vitals-form/vitals-form.component';

@Component({
  selector: 'app-screenings',
  templateUrl: './screenings.component.html'
})
export class ScreeningsComponent extends EheFormComponentTemplate implements OnInit, EheFormComponent {

  @ViewChild(VitalsFormComponent) vitals: VitalsFormComponent;
  @ViewChild(VisionFormComponent) vision: VisionFormComponent;
  @ViewChild(HearingFormComponent) hearing: HearingFormComponent;
  @ViewChild(EKGFormComponent) ekg: EKGFormComponent;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    super();
  }

  ngOnInit() {
    this.forms = {
      vitals: this.fb.group({
        id: null,
        height: null,
        weight: null,
        bmi: { value: null, disabled: true },
        bodyFat: null,
        waistCircumference: null,
        bpSystolic: null,
        bpDiastolic: null,
        bpSide: null,
        bpPositionId: null,
        repeatSystolic: null,
        repeatDiastolic: null,
        repeatSide: null,
        repeatPositionId: null,
        pulse: null,
        vo2LookupId: null,
        notes: null,
      }),
      vision: this.fb.group({
        id: null,
        farRightNumeratorU: null,
        farRightDenominatorU: null,
        farLeftNumeratorU: null,
        farLeftDenominatorU: null,
        farBothNumeratorU: null,
        farBothDenominatorU: null,
        nearRightNumeratorU: null,
        nearRightDenominatorU: null,
        nearLeftNumeratorU: null,
        nearLeftDenominatorU: null,
        nearBothNumeratorU: null,
        nearBothDenominatorU: null,
        farRightNumeratorC: null,
        farRightDenominatorC: null,
        farLeftNumeratorC: null,
        farLeftDenominatorC: null,
        farBothNumeratorC: null,
        farBothDenominatorC: null,
        nearRightNumeratorC: null,
        nearRightDenominatorC: null,
        nearLeftNumeratorC: null,
        nearLeftDenominatorC: null,
        nearBothNumeratorC: null,
        nearBothDenominatorC: null,
        colorVisionNumerator: null,
        colorVisionDenominator: null,
        visionColor: null,
        visionDeclined: false
      }),
      hearing: this.fb.group({
        id: null,
        right250hz: null,
        right500hz: null,
        right1khz: null,
        right2khz: null,
        right3khz: null,
        right4khz: null,
        right6khz: null,
        right8khz: null,
        left250hz: null,
        left500hz: null,
        left1khz: null,
        left2khz: null,
        left3khz: null,
        left4khz: null,
        left6khz: null,
        left8khz: null,
        hearingOption: null
      }),
      ekgRiskForm: this.fb.group({
        ekgOption: null
      }),
    };
  }

// Initial Autosave
  // ngAfterViewInit() {

    // console.log("Element List: " + this.childForm.complete);

  //   this.childFormValueChanges = merge(
  //     this.forms.vitals.valueChanges,
  //     this.forms.vision.valueChanges,
  //     this.forms.hearing.valueChanges,
  //   )
  //   this.childFormValueChanges.subscribe((value => {
  //       console.log('childForm Value', value)
  //     }))
    // }

  submitChildData = async () => {
    try {
      await this.saveAll();
      this.router.navigate(['../pe'], { relativeTo: this.route });
    } catch (e) {
      console.log('Error: ', e);
    }
  }

  async saveAll() {
    const vitalsPromise = this.vitals.saveForm();
    const hearingPromise = this.hearing.saveForm();
    const visionPromise = this.vision.saveForm();
    const ekgPromise = this.ekg.saveForm();

    return Promise.all([vitalsPromise, hearingPromise, visionPromise, ekgPromise]);
  }
}
