<h2 class="form-heading">Vitals</h2>
<form [formGroup]="form" class="vitals-form" (ngSubmit)="saveForm()">
    <div class="input-sm">
        <mat-form-field>
            <mat-label>Height (inches)</mat-label>
            <input matInput formControlName="height" appDecimal maxVal="999" decimalPlaces="1" positiveValueOnly="true">
        </mat-form-field>
    </div>
    <div class="input-sm">
        <mat-form-field>
            <mat-label>Weight (pounds)</mat-label>
            <input matInput formControlName="weight" appDecimal maxVal="9999" decimalPlaces="1" positiveValueOnly="true">
        </mat-form-field>
    </div>
    <div class="input-sm">
        <mat-form-field>
            <mat-label>BMI</mat-label>
            <input
                matInput
                formControlName="bmi"
                type="number"
                [attr.disabled]="true"
            >
        </mat-form-field>
        <mat-form-field>
            <mat-label>Body Fat %</mat-label>
            <input matInput formControlName="bodyFat" appDecimal maxVal="999" decimalPlaces="1" positiveValueOnly="true">
        </mat-form-field>
    </div>
    <div class="input-md">
        <mat-form-field>
            <mat-label>Waist Circumference (inches)</mat-label>
            <input matInput formControlName="waistCircumference" appDecimal maxVal="999" decimalPlaces="1" positiveValueOnly="true">
        </mat-form-field>
    </div>

    <p class="custom-label">Blood pressure</p>
    <div class="inputs-inline">
        <div class="vitals-inputs-group">
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="bpSystolic" type="number" appInteger maxVal="999" positiveValueOnly="true">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="bpDiastolic" type="number" appInteger maxVal="999" positiveValueOnly="true">
                </mat-form-field>
            </div>
        </div>
        <div class="input-select-sm">
            <mat-form-field>
                <mat-label>Side</mat-label>
                <mat-select formControlName="bpSide">
                    <mat-option value="null" disabled>Choose a side</mat-option>
                    <mat-option *ngFor="let option of sideOptions" [value]="option[0]">
                        {{ option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="input-select-sm">
          <shared-ui-select
          [typeDropDown]="true"
          label="Position"
          [parent]="form"
          formFieldSize="xs"
          control="bpPositionId"
          [options]="positionOptions$ | async"
        ></shared-ui-select>
        </div>

    </div>

    <p class="custom-label">Repeat blood pressure</p>
    <div class="inputs-inline">

        <div class="vitals-inputs-group">
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="repeatSystolic" appInteger maxVal="999" positiveValueOnly="true">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="repeatDiastolic" appInteger maxVal="999" positiveValueOnly="true">
                </mat-form-field>
            </div>
        </div>
        <div class="input-select-sm">
            <mat-form-field>
                <mat-label>Side</mat-label>
                <mat-select formControlName="repeatSide">
                    <mat-option value="" disabled>Choose a side</mat-option>
                    <mat-option *ngFor="let option of sideOptions" [value]="option[0]">
                        {{ option }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="input-select-sm">
          <shared-ui-select
          [typeDropDown]="true"
          label="Position"
          [parent]="form"
          control="repeatPositionId"
          [options]="positionOptions$ | async"
        ></shared-ui-select>
        </div>
    </div>
    <div class="input-sm">
        <mat-form-field>
            <mat-label>Pulse (bpm)</mat-label>
            <input matInput formControlName="pulse" appInteger maxVal="999" positiveValueOnly="true">
        </mat-form-field>
    </div>
    <div class="input-select-lg">
        <shared-ui-select
        [typeDropDown]="true"
        label="Cardiorespiratory Fitness Level"
        [parent]="form"
        formFieldSize="medium"
        control="vo2LookupId"
        [options]="VO2Options$ | async"
      ></shared-ui-select>
    </div>
    <app-textarea-control
    label="Notes (optional)"
    [control]="this.form.get('notes')"
></app-textarea-control>
</form>
