import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface ModalOptions {
  title: string;
  actionButton: string;
  cancelButton: string;
  modalWidth: string;
  modalHeight: string;
  content: string;
  titleAlignment: string;
  contentAlignment: string;
  actionsAlignment: string;
}
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-verification-modal',
  templateUrl: './verification-modal.component.html',
})
export class VerificationModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalOptions,
    private dialogRef: MatDialogRef<VerificationModalComponent>
  ) {}

  ngOnInit() {
    this.data.actionsAlignment = this.data.actionsAlignment ?? 'end';
    this.data.cancelButton = this.data.cancelButton ?? 'Cancel';
    this.dialogRef.updateSize(this.data.modalWidth ?? '35vw', this.data.modalHeight ?? ''); // Can be overridden via input
  }

  public cancel() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }

  public confirm() {
    this.close(true);
  }
}
