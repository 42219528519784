<ng-container [formGroup]="parent">
  <ng-container *ngIf="hasMultipleOptions; else radioSelection">
    <p *ngIf="prompt">{{ prompt }}</p>
    <mat-form-field [ngClass]="formFieldSize" [fxFlex]="fxFlex" *ngIf="control">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <mat-select #dropdown [required]="required" [multiple]="multipleSelect" [formControlName]="control">
        <mat-select-trigger *ngIf="optionBadges && dropdown.value">{{getSelectedOptionName(dropdown.value)}}</mat-select-trigger>
        <mat-option #optionRef *ngFor="let option of options | sortData:sort:path:order; let i = index" [value]="option.id ? option.id : option.name">
          {{ option.name }}
            <ng-container *ngIf="option.icons">
              <span *ngFor="let icon of option.icons">
                <mat-icon>{{icon}}</mat-icon>
              </span>
            </ng-container>
            <!-- Experimental badge display -->
            <ng-container *ngIf="optionBadges && optionBadges[i]">
              <span [matBadge]="" matBadgePosition="before" matBadgeOverlap="true" matBadgeSize="small">&nbsp;&nbsp;</span>
              <span *ngFor="let config of optionBadges[i]" [matBadge]="config.text" [matBadgeColor]="config.color"
                matBadgePosition="before" matBadgeOverlap="true" matBadgeSize="small">{{'&nbsp;' | repeat:5}}</span>
            </ng-container>

          </mat-option>

      </mat-select>
      <mat-error>{{ label }} is required</mat-error>
    </mat-form-field>
    <ng-content></ng-content>
  </ng-container>

  <ng-template #radioSelection>
    <mat-label *ngIf="prompt">{{ prompt }}</mat-label>
    <mat-radio-group [required]="required" [formControlName]="control">
      <mat-label *ngIf="label">{{ label }}</mat-label>
      <mat-radio-button
        *ngFor="let option of options | sortData:sort:path:order"
        [value]="option.id ? option.id : option.name"
        >{{ option.name }}</mat-radio-button
      >
    </mat-radio-group>
  </ng-template>
</ng-container>
