import { filter, forkJoin, Subject, take, takeUntil } from 'rxjs';

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ActivatedRoute, ActivationStart, NavigationEnd, Router, RouterOutlet
} from '@angular/router';
import {
    FastingState, LabOrderState, LabRequisitionOrder, LabRequisitionOrdersResponse
} from '@config/types';
import { LabsService } from '@patient/services/labs.service';

import { RequisitionOrders } from '../lab-results/labs-form.config';

@Component({
  selector: 'app-lab-requisitions',
  templateUrl: './lab-requisitions.component.html',
  styleUrls: ['./lab-requisitions.component.scss']
})
export class LabRequisitionsComponent implements OnInit, OnDestroy {

  requisitionsEmpty = false; // to be filled after getRequisitions api
  requestCreated = false;
  private unsubscribe$ = new Subject<void>();
  private labRequisitionsArr: LabRequisitionOrder[];
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  reqDetailsColumns = ['displayOrderDate', 'displayType', 'requisitionID', 'statusName', 'actions'];
  reqOrdersData;

  labOrderOptions$ = forkJoin({
    standardPanelOptions: this.labService.getStandardPanelOptions().pipe(take(1)),
    additionalTestsOptions: this.labService.getCommonAdditionalTestsOptions().pipe(take(1)),
    labOrderOptions: this.labService.getLabOrderOptions().pipe(take(1)),
    cytSourceOptions: this.labService.getCytSourceOptions().pipe(take(1)),
    cytCollectionOptions: this.labService.getCytCollectionOptions().pipe(take(1)),
    cytOtherPatientinfoOptions: this.labService.getCytOtherPatientinfoOptions().pipe(take(1)),
    cytPreviousConditionOptions: this.labService.getCytPreviousConditionOptions().pipe(take(1)),
    cytPreviousTreatmentOptions: this.labService.getCytPreviousTreatmentOptions().pipe(take(1))
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private labService: LabsService,
  ) {}

  ngOnInit(): void {
    const navigationEndEvents = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    const activationStartEvents = this.router.events.pipe(
      filter((event) => event instanceof ActivationStart)
    );

    // Added to resolve the error 'Cannot activate an already activated outlet'
    // as per the solution given in
    // https://stackoverflow.com/questions/55617772/angular-7-multiple-outlets-error-cannot-activate-an-already-activated-outle
    activationStartEvents.pipe(takeUntil(this.unsubscribe$)).subscribe((event: ActivationStart) => {
     // primary is the default name given by Angular if no specific outlet name is given for the route
      if (event.snapshot.outlet === 'primary' && this.outlet) {
        this.outlet.deactivate();
      }
    });

    navigationEndEvents.pipe(takeUntil(this.unsubscribe$)).subscribe((event: NavigationEnd) => {
      if (event.url.includes('labRequest') || event.url.includes('papRequest')) {
        this.requestCreated = true;
      } else {
        // refresh lab requisitions after creating a new lab requisition
        this.getLabRequisitions();
        this.requestCreated = false;
      }
    });
    this.getLabRequisitions();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getLabRequisitions(): void {
    this.labService.getAllRequisitions().subscribe((res: LabRequisitionOrdersResponse) => {
      const { data } = res;
      this.labRequisitionsArr = data;
      if (data) {
        this.requisitionsEmpty = false;
        const reqOrders: RequisitionOrders[] = [];
        data.forEach((reqData: LabRequisitionOrder) => {
          reqOrders.push({
            displayOrderDate: reqData.labRequisition.displayCollectionDate,
            displayType: reqData.labRequisition.displayType,
            requisitionID: reqData.labRequisition.requisitionID,
            statusName: reqData.statusName
          });
        });
        this.reqOrdersData = reqOrders;
      } else {
        this.requisitionsEmpty = true;
      }
    });
  }

  /**
   * method to create a new lab/pap order
   * @param url - name - pap/laborder
   */
  createRequisition(url: string) {
    if (url) {

      this.labOrderOptions$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        const labOrderState: LabOrderState = {
          examInfo: {
            selectedPhysician: -1
          },
          reqDetails: {
            dateCollected: '',
            timeCollected: '',
            fasting : FastingState.UNKNOWN
          },
          standardPanelid: '',
          commonAdditionalTests: '',
          cytologyPanels: '',
          standardLabPanelOptions: res.standardPanelOptions,
          commonAdditonalTestsOptions: res.additionalTestsOptions,
          labOrderOptions: res.labOrderOptions,
          orderID:null,
          labRequisitionData:null,
          cytSourceOptions: res.cytSourceOptions,
          cytCollectionOptions: res.cytCollectionOptions,
          cytOtherPatientinfoOptions: res.cytOtherPatientinfoOptions,
          cytPreviousConditionOptions: res.cytPreviousConditionOptions,
          cytPreviousTreatmentOptions: res.cytPreviousTreatmentOptions
        };
        this.requestCreated = true;
        this.router.navigate([url], { relativeTo: this.route, state: labOrderState });
      });
    }
  }

  /**
   * Method to route the view to lab/pap order for a specific req id in the table
   * @param reqId - requisition id needed apis for viewing lab/pap order
   */
  viewReqorder(reqId: number){
    const requistionItem = this.labRequisitionsArr.find((reqOrder) => reqOrder.labRequisition.requisitionID === reqId);
    const url = requistionItem.labRequisition.type === 1 ? 'labRequest' : 'papRequest';


    this.labOrderOptions$.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      // Data required for the components in the laborder page for a specific reqId
    // this data is passed as state object through router navigation
    const labOrderState: LabOrderState = {
      examInfo: {
        selectedPhysician: requistionItem.physician.adminid
      },
      reqDetails: {
        dateCollected: requistionItem.labRequisition.displayCollectionDate,
        timeCollected: requistionItem.labRequisition.displayCollectionTime,
        fasting : this.getFastingType(requistionItem.labRequisition.isFasting)
      },
      standardPanelid: requistionItem.ehePreferredPanels,
      commonAdditionalTests: requistionItem.ehePreferredTests,
      cytologyPanels: requistionItem.eheCytologyTests,
      standardLabPanelOptions: res.standardPanelOptions,
      commonAdditonalTestsOptions: res.additionalTestsOptions,
      labOrderOptions: res.labOrderOptions,
      orderID: requistionItem.orderID,
      labRequisitionData:requistionItem,
      cytSourceOptions: res.cytSourceOptions,
      cytCollectionOptions: res.cytCollectionOptions,
      cytOtherPatientinfoOptions: res.cytOtherPatientinfoOptions,
      cytPreviousConditionOptions: res.cytPreviousConditionOptions,
      cytPreviousTreatmentOptions: res.cytPreviousTreatmentOptions
    };
    this.requestCreated = true;
    this.router.navigate([url, reqId], { relativeTo: this.route, state: labOrderState });
    });
  }

  getFastingType(type: string){
    switch (type.toLowerCase()){
      case 'y':
          return FastingState.YES;
      case 'n':
        return FastingState.NO;
      case 'u':
        return FastingState.UNKNOWN;
    }
  }

  /**
   * Method to render the PDF to print lab/pap order for a specific req id
   * @param reqId - requisition id needed to fetch req details
   */
  printReqorder(reqId: number): void {
    const requistionItem = this.labRequisitionsArr.find((reqOrder) => reqOrder.labRequisition.requisitionID === reqId);
    this.labService.printLabOrder(requistionItem.id).subscribe( data => {
      const url= window.URL.createObjectURL(data);
      window.open(url);
    }, err => {
      console.log(err);
    })
  }
}
