import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { AppUtils } from '@shared/utils/app.utils';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private showLoader = new BehaviorSubject<boolean>(false);
  public isLoading$: Observable<boolean> = this.showLoader.asObservable();

  constructor() {
    AppUtils.DEBUG && console.log('Loader constructor');
  }

  show() {
    AppUtils.DEBUG && console.log('Show loader');
    this.showLoader.next(true);
  }

  hide() {
    AppUtils.DEBUG && console.log('Hide loader');
    this.showLoader.next(false);
  }
}
