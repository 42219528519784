import { catchError, Observable, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OverrideMacroDTO, PatientPlanResponse, SignOffResponse } from '@config/types';
import { HttpContextHelperService } from '@core/services/http-context-helper.service';

@Injectable()
export class ReviewService {
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private httpContextHelperService: HttpContextHelperService
  ) {}

  getMedicalReport(): Observable<PatientPlanResponse> {
    return this.http.get<PatientPlanResponse>(
      this.config.ENDPOINTS.getMedicalReport,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  updateMedicalReport(
    data: OverrideMacroDTO
  ): Observable<OverrideMacroDTO> {
    return this.http.post<OverrideMacroDTO>(
      this.config.ENDPOINTS.updateReviewMacro,
      data,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID']
      })
    );
  }

  mdSignOff(): Observable<SignOffResponse> {
    return this.genericSignOffRequest(this.config.ENDPOINTS.mdSignoff);
  }

  crSignoff(): Observable<SignOffResponse> {
    return this.genericSignOffRequest(this.config.ENDPOINTS.crSignoff);
  }

  needsReview(): Observable<SignOffResponse> {
    return this.genericSignOffRequest(this.config.ENDPOINTS.reportNeedsReview);
  }

  genericSignOffRequest(url: string): Observable<SignOffResponse> {
    return this.http.post<SignOffResponse>(
      url,
      {},
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    )
    .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error(
        'An error occurred for POST "user/medical-report/assessment-plan/override-macro"',
        error.error
      );
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    return throwError('Something bad happened; please try again later.');
  }
}
