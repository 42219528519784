import { first, map, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { IMMUNIZATION_OTHER_ID } from '@config/app.constant';
import {
    AppointmentDetail, CreateReferrals, ImmunizationLogsResponse, InternalImmunizationLog,
    LookupOption, ObjectiveField, SubProcedurePostData, User
} from '@config/types';
import { EheFormComponent, EheFormComponentTemplate } from '@core/guards/dirty-check.guard';
import { UpdateAppointmentExam } from '@core/state/appointments.actions';
import { UserState } from '@core/state/user.state';
import { Select, Store } from '@ngxs/store';
import { SpecialtyService } from '@patient/components/add-specialty/specialty.service';
import { CreateService } from '@patient/services/create.service';
import { PatientExamService } from '@patient/services/patient-exam.service';
import { AppUtils } from '@shared/utils/app.utils';
import { CreatePageFormControls, FormUtils } from '@shared/utils/form.utils';

import {
    biometricsHeadings, configCreateTabFields, configSectionSettings, createFieldBySection,
    labsHeadings, SectionKey
} from '../create-config';

export interface SpecialtyObject {
  isStat: boolean;
  name?: string;
  otherSpeciality: string;
  reason: string;
  specialtyID: number;
}
@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class CreateComponent
  extends EheFormComponentTemplate
  implements OnInit, OnDestroy, EheFormComponent
{
  @Select(UserState.getUser) user$: Observable<User>;
  appointment$: Observable<AppointmentDetail>;
  appointmentID: number;
  createFieldBySection = createFieldBySection;

  fieldsByGroup = {
    biometrics: {},
    labs: {},
    recommended: {},
  };

  today = new Date();
  biometricsSections = [];
  labsSections = [];
  recommendedSections = [];
  biometricsHeadings = biometricsHeadings;
  labsHeadings = labsHeadings;
  lookupImmunizations: LookupOption[];
  lookupCardiorespiratoryFitnessLevels: LookupOption[];
  IMMUNIZATION_OTHER_ID = IMMUNIZATION_OTHER_ID;

  private action$: Subject<string> = new Subject<string>();

  get immunizationsCtrl(): FormArray {
    return this.forms.immunizationLogs.get('data') as FormArray;
  }

  // all comment fields
  controls: { [key: string]: FormControl } = [
    'biometricsOtherValue',
    'labsOtherValue',
    'screeningsOtherValue',
    'recommendedScreeningsOtherValue',
    'recommendedOther2Value',
    'recommendedVaccinesOtherValue',
    'physicianValue',
    'summaryValue',
    'pcpReason',
    'referralOptionalComments',
  ].reduce((z, key) => ({ ...z, [key]: new FormControl('') }), {});

  ngZone: any;
  autosize: any;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private createService: CreateService,
    private examService: PatientExamService,
    private fb: FormBuilder,
    private specialtyService: SpecialtyService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    super();
    this.appointment$ = this.route.parent.data.pipe(map(data => data.appointment));
    this.appointment$.pipe(first()).subscribe( appointment => this.appointmentID = appointment.appointmentID);
  }

  ngOnInit(): void {
    this.forms = {
      biometrics: FormUtils.createFormGroup(
        this.fb,
        CreatePageFormControls.biometrics
      ),
      labs: FormUtils.createFormGroup(this.fb, CreatePageFormControls.labs),
      screenings: FormUtils.createFormGroup(
        this.fb,
        CreatePageFormControls.screenings
      ),
      vaccines: FormUtils.createFormGroup(
        this.fb,
        CreatePageFormControls.vaccines
      ),
      immunity: FormUtils.createFormGroup(
        this.fb,
        CreatePageFormControls.immunity
      ),
      recommended: this.fb.group({
        papOption: null,
        mammoOption: null,
        corCalScanOption: null,
        colonoscopyOption: null,
        fitOption: null,
        vascStudyOption: null,
        lungCTOption: null,
        abdominalUltrasoundOption: null,
        dexaOption: null,
        recommendedScreeningsOtherValue:
          this.controls.recommendedScreeningsOtherValue,
        noPapDescription: false,
        noMammoDescription: false,
        corCalScanNoDescription: false,
        colonoscopyNoDescription: false,
        fitNoDescription: false,
        vascStudyNoDescription: false,
        lungCTNoDescription: false,
        abdominalUltrasoundNoDescription: false,
        dexaNoDescription: false,
        referrals: this.fb.group({
          healthMentorshipReferral: false,
          primaryCareReferral: false,
          pcpReason: this.controls.pcpReason,
          optionalComments: this.controls.referralOptionalComments,
          generalSpecialties: this.fb.array([]),
          eapSpecialties: this.fb.array([]),
        }),
        hpv: false,
        pneumovax: false,
        prevnar: false,
        shingrix: false,
        tetanus: false,
        tdap: false,
        mmr: false,
        hepA: false,
        hepB: false,
        covid19: false,
        recommendedVaccinesOtherValue:
          this.controls.recommendedVaccinesOtherValue,
        ascvd: false,
        statin: false,
        riskFactors2: false,
        LDL: false,
        triglycerides: false,
        hscrp: false,
        peripheralVascular: false,
        family2: false,
        a1c2: false,
        hypertension2: false,
        LDL2: false,
        smoker2: false,
        family3: false,
        smoker3: false,
        smokerHistory: false,
        specialist1: '',
        specialist1Reason: '',
        specialist2: '',
        specialist2Reason: '',
        specialist3: '',
        specialist3Reason: '',
      }),
      immunizationLogs: this.fb.group({
        data: this.fb.array([this.generateImmunizationLogFormGroup()]),
      }),
      summary: FormUtils.createFormGroup(
        this.fb,
        CreatePageFormControls.summary
      ),
      physician: FormUtils.createFormGroup(
        this.fb,
        CreatePageFormControls.physician
      ),
    };

    // get all the radio button inputs and assign them to their formGroups
    this.fieldsByGroup = this.createFieldsByFormGroup();
    this.biometricsSections = Object.keys(this.fieldsByGroup.biometrics);
    this.labsSections = Object.keys(this.fieldsByGroup.labs);
    this.recommendedSections = Object.keys(this.fieldsByGroup.recommended);

    this.forms.immunizationLogs.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe();

    // action handlers
    this.action$
      .pipe(
        withLatestFrom(this.appointment$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([action, appointment]) => {
        AppUtils.DEBUG && console.log('action detected', action, appointment);
        switch (action) {
          case 'save':
            const dirtyKeys = Object.keys(this.forms).filter(
              (key) => this.forms[key].dirty
            );
            dirtyKeys.forEach((key, i) => {
              AppUtils.DEBUG &&
                console.log('key', `save${AppUtils.capitalize(key)}Form`);
              setTimeout(
                ((ii) => {
                  return () => {
                    const method = `save${AppUtils.capitalize(key, true)}Form`;
                    this[method]()
                      .subscribe( response => {
                        AppUtils.DEBUG && console.log('response from:', method, response, ii);
                        if (dirtyKeys.length - 1 === ii ) {
                          AppUtils.DEBUG && console.log('last save attempt detected, dispatch update', ii);
                          this.store.dispatch(
                            new UpdateAppointmentExam({appointmentID: this.appointmentID})
                          );
                          this.markAsPristine();
                          this.router.navigate(['../review'], { relativeTo: this.route });
                        }
                      });
                  };
                })(i),
                AppUtils.DELAY_CONSECUTIVE_REQUESTS * i
              );
            });
            break;
        }
      });

    this.appointment$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((appointment) => {
      AppUtils.DEBUG && console.log('appointment update detected', appointment);
      const examID = appointment.exam.id;
      Object.keys(this.createFieldBySection).forEach((section) => {
        this.createFieldBySection[section].reportID = appointment.exam.id;
      });
      // get immunization logs
      setTimeout(() => {
        AppUtils.DEBUG && console.log('examID to be passed to getImmunizationLogs', examID);
        this.createService
          .getImmunizationLogs()
          .subscribe((response) => {
            AppUtils.DEBUG &&
              console.log(
                'prefill immunizationLogs data',
                response,
                this.createService.mapImmunizationLogsToInternal(
                  response.data
                )
              );
            const internalData =
              this.createService.mapImmunizationLogsToInternal(response.data);
            // this.forms.immunizationLogs.controls.data = this.generateImmunizationLogFormArray(internalData.data);
            this.setImmunizationLogFormArray(internalData.data);
          });
      }, AppUtils.DELAY_CONSECUTIVE_REQUESTS);
      // prefill
      this.prefill(appointment.exam.assessmentPlans);
    }),
    this.createService.lookupImmunizations().subscribe((response) => {
      this.lookupImmunizations = response.data;
    });
    this.createService.lookupCardiorespiratoryFitnessLevel().subscribe((response) => {
      this.lookupCardiorespiratoryFitnessLevels = response;
    });
  }

  createFieldsByFormGroup() {
    const fieldIds = Object.keys(configCreateTabFields);
    const grouped = {
      biometrics: {},
      labs: {},
      recommended: {},
    };
    fieldIds.forEach((fieldID) => {
      const field = configCreateTabFields[fieldID];
      if (field.group) {
        const section = field.section;
        if (!grouped[section][field.group]) {
          grouped[section][field.group] = [{ ...field, fieldID }];
        } else {
          grouped[section][field.group].push({ ...field, fieldID });
        }
      }
    });

    Object.keys(grouped).forEach((section) =>
      this.sortChildrenByDisplayOrder(grouped, section)
    );
    return grouped;
  }

  sortChildrenByDisplayOrder(groupedRadioButtons: object, section: string) {
    Object.keys(groupedRadioButtons[section]).forEach((groupName) => {
      this.sortByDisplayOrder(groupedRadioButtons[section][groupName]);
    });
  }

  sortByDisplayOrder(unsortedArray) {
    unsortedArray.sort((a, b) => {
      return a.displayOrder - b.displayOrder;
    });
  }

  ngOnDestroy(): void {
    Object.keys(this.forms).forEach((key) => this.forms[key].reset());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  dirtyStates(): string {
    return Object.keys(this.forms)
      .map((form) => `${form} (${this.forms[form].dirty})`)
      .join('; ');
  }

  saveBiometricsForm(): Observable<SubProcedurePostData> {
    const assessmentPlanFields =
      this.genericProcessFormSectionForSave('biometrics');
    AppUtils.DEBUG && console.log(assessmentPlanFields);
    if(this.forms.biometrics?.value.vO2Option) {
      let cardiorespiratoryFitnessLevel: LookupOption;
      cardiorespiratoryFitnessLevel = this.lookupCardiorespiratoryFitnessLevels.find((arr:LookupOption) => arr.id == this.forms.biometrics.value.vO2Option);
      assessmentPlanFields.push({
        fieldID: cardiorespiratoryFitnessLevel.id,
        fieldName: cardiorespiratoryFitnessLevel.name,
        fieldValue: 'true',
      });
    }
    return this.createService
      .postSection(
        {
          ...configSectionSettings.biometrics,
          assessmentPlanFields,
        },
    );
  }

  saveLabsForm(): Observable<SubProcedurePostData> {
    const assessmentPlanFields = this.genericProcessFormSectionForSave('labs');

    return this.createService
      .postSection(
        {
          ...configSectionSettings.screenings,
          assessmentPlanFields,
        },
      );
  }

  saveVaccinesForm(): Observable<SubProcedurePostData> {
    const assessmentPlanFields =
      this.genericProcessFormSectionForSave('vaccines');

    return this.createService
      .postSection(
        {
          ...configSectionSettings.vaccines,
          assessmentPlanFields,
        },
      );

  }

  saveImmunityForm(): Observable<SubProcedurePostData> {
    const assessmentPlanFields =
      this.genericProcessFormSectionForSave('immunity');

    return this.createService
      .postSection(
        {
          ...configSectionSettings.immunity,
          assessmentPlanFields,
        },
      );
  }

  saveImmunizationLogsForm(): Observable<ImmunizationLogsResponse> {
    AppUtils.DEBUG &&
      console.log(
        'save ImmunizatonLogs form',
        this.forms.immunizationLogs.value
      );

    return this.createService.saveImmunizationLogs(this.forms.immunizationLogs.value);
  }

  saveVaccines2Form(): Observable<SubProcedurePostData> {
    const assessmentPlanFields = this.genericProcessFormSectionForSave(
      'vaccines2',
      'recommended'
    );

    return this.createService.postSection({
      ...configSectionSettings.vaccines2,
      assessmentPlanFields,
    });
  }

  saveSummaryForm(): Observable<SubProcedurePostData> {
    const assessmentPlanFields = this.genericProcessFormSectionForSave('summary');
    return this.createService.postSection({
      ...configSectionSettings.summary,
      assessmentPlanFields,
    });
  }

  savePhysicianForm(): Observable<SubProcedurePostData> {
    const assessmentPlanFields = this.genericProcessFormSectionForSave('physician');
    return this.createService.postSection({
      ...configSectionSettings.physician,
      assessmentPlanFields,
    });
  }

  genericProcessFormSectionForSave(
    section: SectionKey,
    formKey?: string
  ): ObjectiveField[] {
    return createFieldBySection[section].reduce((z, fieldConfig) => {
      const fieldName = fieldConfig.group ?? fieldConfig.field;
      // console.log('genericProcessFormSectionForSave', section, this.forms[section], fieldName, this.forms[section]?.controls[fieldName]);
      const isRadioGroup = !!fieldConfig.group;
      const subForm = this.forms[formKey ?? section];
      const value = subForm.controls[fieldName].value;
      if (!value || (isRadioGroup && value !== fieldConfig.field)) {
        return z;
      }
      let fieldOtherValue = {};
      if (fieldConfig.otherField) {
        fieldOtherValue = {
          fieldOtherValue: value,
        };
      } else if (fieldConfig.dropdownControlName) {
        fieldOtherValue = {
          fieldOtherValue:
            subForm.controls[fieldConfig.dropdownControlName].value,
        };
      }
      const fieldValue = {
        fieldValue: true,
      };
      if (['physicianValue', 'summaryValue'].includes(fieldConfig.field)) {
        fieldValue.fieldValue = value;
      }
      return z.concat({
        fieldID: fieldConfig.id,
        fieldName: fieldConfig.label,
        ...fieldValue,
        ...fieldOtherValue,
      });
    }, []);
  }

  /**
   * @param action string eg. 'save'
   */
  action(action: string): void {
    this.action$.next(action);
  }

  /**
   * Referral section
   */
  getFormReferrals(): void {

    this.createService.getReferrals()
      .subscribe((response) => {
        console.log('Fetched Referrals', response);
        this.patchSpecialtiesArray(response.data);
      });
  }

  patchSpecialtiesArray(data) {
    if (!data) {
      return;
    }

    // add retrieved specialties to referrals form
    const retrievedGeneralSpecialties = data.generalSpecialties;
    const retrievedEAPSpecialties = data.eapSpecialties;
    const generalSpecialityFormArray: FormArray = this.forms.recommended.get(
      'referrals.generalSpecialties'
    ) as FormArray;
    const generalEAPFormArray: FormArray = this.forms.recommended.get(
      'referrals.eapSpecialties'
    ) as FormArray;

    this.specialtyService.importGeneralSpecialtyData(
      generalSpecialityFormArray,
      retrievedGeneralSpecialties
    );

    this.specialtyService.importEAPSpecialtyData(
      generalEAPFormArray,
      retrievedEAPSpecialties,
    );

    this.forms.recommended.get('referrals').patchValue({
      healthMentorshipReferral: data.healthMentorshipReferral,
      primaryCareReferral: data.primaryCareReferral,
      optionalComments: data.optionalComments,
      specialtyReferral: data.specialtyReferral,
      pcpReason: data.pcpReason,
    });
  }

  postFormReferrals(): void {
    const body: CreateReferrals = this.forms.recommended.get('referrals').value;
    body.specialtyReferral =
      body.generalSpecialties.length > 0 && !!body.generalSpecialties[0].name;
    body.eapReferral =
      body.eapSpecialties.length > 0 && !!body.eapSpecialties[0].name;
    if (!body.eapReferral) {
      body.eapSpecialties = [];
    }

    body.generalSpecialties =
      this.specialtyService.exportGeneralSpecialtyData(
        body.generalSpecialties
      );
    this.specialtyService
      .exportEAPSpecialties(body.eapSpecialties)
      .then((res) => {
        body.eapSpecialties = res as any;
        this.createService
          .postReferrals(
            {
              ...body,
            },
          )
          .subscribe((response) => console.log(response));
      });
  }

  /**
   * We cycle through the list of assessments (ObjectiveField type)
   * which is returned within the Exam object.  We iterate over each
   * entry, looking for its config entries which will direct us to
   * the name of the form and field relevant for activating the
   * checkbox to reflect the prefill state
   *
   * @param assessments array of assessments to process
   * @output assesment
   *  { fieldID: 172,
   *    fieldName: "Physician",
   *    fieldValue: "- WT",
   *    fieldOtherValue: null
   *   }
   */
  prefill(assessments: ObjectiveField[]): void {
    this.getFormReferrals();

    assessments.forEach((assessment) => {
      if (assessment.fieldID in configCreateTabFields) {
        const config = configCreateTabFields[assessment.fieldID];
        const control = this.forms[config.form].get(config.field);

        // check if field has a group (for radio buttons)
        if (config.group) {
          this.forms[config.section].get(config.group).setValue(config.field);
          if (assessment.fieldOtherValue && config.dropdownControlName) {
            this.forms[config.form]
              .get(config.dropdownControlName)
              .setValue(assessment.fieldOtherValue);
          }
        } else if (control) {
          // other input types
          // check if control input has a text field
          if (assessment.fieldOtherValue) {
            AppUtils.DEBUG &&
              console.log('Field Other', assessment.fieldOtherValue);
            if (config.otherField) {
              this.forms[config.form]
                .get(config.otherField)
                .setValue(assessment.fieldOtherValue);
            } else {
              AppUtils.DEBUG &&
                console.log(
                  'Need to add other field config for ',
                  assessment.fieldID,
                  assessment.fieldName,
                  assessment.fieldOtherValue
                );
            }
          } else {
            control.setValue(assessment.fieldValue);
          }
        } else {
          console.log(
            'no control for ',
            config.field,
            '; was this field removed by clinical request?'
          );
        }
      } else {
          const cardiorespiratoryFitnessLevelIDs = [181, 176, 34, 217, 218, 219];
          if (cardiorespiratoryFitnessLevelIDs.includes(assessment.fieldID)) {
            this.forms.biometrics.get('vO2Option').setValue(assessment.fieldID);
          } else {
            AppUtils.DEBUG &&
              console.log(
                'Need to add config for ',
                assessment.fieldID,
                assessment.fieldName
              );
          }
      }
    });
  }

  addImmunization(): void {
    const formArray = this.forms.immunizationLogs.get('data') as FormArray;
    formArray.push(this.generateImmunizationLogFormGroup());
    this.forms.immunizationLogs.markAsDirty();
  }

  generateImmunizationLogFormGroup(data?: InternalImmunizationLog): FormGroup {
    return this.fb.group({
      immunizationLogID: data?.immunizationLogID ?? null,
      comments: data?.comments ?? null,
      dateGiven: data?.dateGiven ?? null,
      dateUnknown: data?.dateUnknown ?? false,
      administeredOrReported: data?.administeredOrReported ?? null,
      otherValue: data?.otherValue ?? null
    });
  }

  generateImmunizationLogFormArray(
    data: InternalImmunizationLog[] = []
  ): FormArray {
    return this.fb.array(
      data.map((row) => this.generateImmunizationLogFormGroup(row))
    );
  }

  handleImmunizationDate(event: MatDatepickerInputEvent<Date>, i: number) {
    if(event.value) {
      this.immunizationsCtrl.controls[i].get('dateUnknown').setValue(false);
    } else {
      this.immunizationsCtrl.controls[i].get('dateUnknown').setValue(true);
    }
  }

  handleImmunizationDateUnknown(event, i: number) {
    if(event.value) {
      this.immunizationsCtrl.controls[i].get('dateGiven').reset();
    }
  }

  setImmunizationLogFormArray(data: InternalImmunizationLog[] = []): void {
    const formArray = this.forms.immunizationLogs.get('data') as FormArray;
    formArray.clear();
    data.forEach((row) =>
      formArray.push(this.generateImmunizationLogFormGroup(row))
    );
    if (formArray.length < 1) {
      formArray.push(this.generateImmunizationLogFormGroup());
    }
  }

  reset(): void {
    this.reset();
  }
}
