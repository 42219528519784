<div class="grid-container">
  <mat-grid-list [cols]="columns" [rowHeight]="rowHeight">
    <ng-container *ngIf="cards$ | async as cards">
      <mat-grid-tile
        *ngFor="let card of cards; let i = index"
        [colspan]="card.cols"
        [rowspan]="card.rows"
        class="grid-tile"
      >
        <mat-card class="appointment-card">
          <mat-card-header>
            <mat-card-title> {{ card.title }} </mat-card-title>
          </mat-card-header>
          <mat-card-content
            class="appointment-card-content"
            *ngIf="card.appointments; else skeletonCards"
          >
            <ng-container *ngFor="let appointment of card.appointments">
              <app-appointment-card
                (click)="selectAppointment(appointment)"
                [data]="appointment"
              >
              </app-appointment-card>
            </ng-container>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </ng-container>
  </mat-grid-list>
</div>
<ng-template #skeletonCards>
  <div style="padding: 16px">
    <app-skeleton-card></app-skeleton-card>
  </div>
  <div style="padding: 16px">
    <app-skeleton-card></app-skeleton-card>
  </div>
  <div style="padding: 16px">
    <app-skeleton-card></app-skeleton-card>
  </div>
  <div style="padding: 16px">
    <app-skeleton-card></app-skeleton-card>
  </div>
</ng-template>
