import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import { Logout } from '@core/state/user.actions';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-logout',
  template: ''
})
export class LogoutComponent {
  constructor(
    public router: Router,
    private store: Store,
  ) {
    this.store.dispatch(new Logout()).subscribe({
      next: _ => this.router.navigateByUrl(APP_ROUTES.login)
    });
  }
}