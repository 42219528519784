import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClinicalNotes, ClinicalNotesResponse } from '@config/types';
import { HttpContextHelperService } from '@core/services/http-context-helper.service';

@Injectable()
export class ClinicalNotesService {
  constructor(
    private config: AppConfigService,
    private http: HttpClient,
    private httpContextHelperService: HttpContextHelperService,
  ) {}

  getClinicalNotes(): Observable<ClinicalNotesResponse> {
    return this.http.get<ClinicalNotesResponse>(
      this.config.ENDPOINTS.clinicalNotes,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  postClinicalNotes(body: string): Observable<ClinicalNotesResponse> {
    return this.http.post<ClinicalNotesResponse>(
      this.config.ENDPOINTS.clinicalNotes,
      body,
      {
        ...this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID', 'epmsPID', 'reportID']
        }),
        headers: new HttpHeaders({
          'Content-Type': 'text/plain'
        }),
      }
    );
  }
}
