import { NgModule } from '@angular/core';
import { Route, RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import { AuthGuard } from '@core/guards/auth.guard';
import { DirtyCheckGuard } from '@core/guards/dirty-check.guard';
import {
    AddSpecialtyItemComponent
} from '@patient/components/add-specialty-item/add-specialty-item.component';
import { AddSpecialtyComponent } from '@patient/components/add-specialty/add-specialty.component';
import { EKGFormComponent } from '@patient/components/ekg-form/ekg-form.component';
import { HearingFormComponent } from '@patient/components/hearing-form/hearing-form.component';
import { PatientChartComponent } from '@patient/components/patient-chart/patient-chart.component';
import { VisionFormComponent } from '@patient/components/vision-form/vision-form.component';
import { VitalsFormComponent } from '@patient/components/vitals-form/vitals-form.component';

import { ClinicalNotesComponent } from './components/clinical-notes/clinical-notes.component';
import { CreateComponent } from './pages/create/create/create.component';
import { CytologyDetailsComponent } from './pages/labs/cytology-details/cytology-details.component';
import { ExamInfoComponent } from './pages/labs/exam-info/exam-info.component';
import { LabOrderComponent } from './pages/labs/lab-order/lab-order.component';
import { LabRequestComponent } from './pages/labs/lab-request/lab-request.component';
import { LabRequisitionsComponent } from './pages/labs/lab-requisitions/lab-requisitions.component';
import { LabResultsComponent } from './pages/labs/lab-results/labs-results.component';
import { LabsComponent } from './pages/labs/labs.component';
import { PapOrderComponent } from './pages/labs/pap-order/pap-order.component';
import { PapRequestComponent } from './pages/labs/pap-request/pap-request.component';
import { ReqDetailsComponent } from './pages/labs/req-details/req-details.component';
import { PEComponent } from './pages/pe/pe.component';
import { MacroDialogComponent } from './pages/review/macro-dialog.component';
import { ReviewComponent } from './pages/review/review.component';
import { ScreeningsComponent } from './pages/screenings/screenings.component';
import { AppointmentResolver } from './services/appointment.resolver';

export const mainPatientRoute: Route = {
    path: ':appointmentID',
    canActivate: [AuthGuard],
    component: PatientChartComponent,
    resolve: {
      appointment: AppointmentResolver
    },
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: APP_ROUTES.ha,
        loadChildren: () => import('@ha/health-assessment.module').then(m => m.HealthAssessmentModule),
        data: {
          title: 'Health Assessment',
          notes: true,
          nextRoute: APP_ROUTES.documents,
          physicalExamSubProcedureVisibility: true,
          nonPhysicalExamSubProcedureVisibility: false
        },
      },
      {
        path: APP_ROUTES.subProcedure,
        loadChildren: () => import('./pages/sub-procedure/sub-procedure.module').then(m => m.SubProcedureModule),
        data: {
          title: 'Sub Procedure',
          notes: true,
          physicalExamSubProcedureVisibility: false,
          nonPhysicalExamSubProcedureVisibility: true
        },
      },
      {
        path: APP_ROUTES.documents,
        loadChildren: () => import('@documents/documents.module').then(m => m.DocumentsModule),
        data: {
          title: 'Documents',
          notes: true,
          nextRoute: APP_ROUTES.screenings,
          physicalExamSubProcedureVisibility: true,
          nonPhysicalExamSubProcedureVisibility: true
        },
      },
      {
        path: APP_ROUTES.screenings,
        component: ScreeningsComponent,
        canDeactivate: [DirtyCheckGuard],
        data: {
          title: 'Screenings',
          notes: true,
          physicalExamSubProcedureVisibility: true,
          nonPhysicalExamSubProcedureVisibility: false
        },
      },
      {
        path: APP_ROUTES.labs,
        component: LabsComponent,
        children: [
          {path: 'labRequest/:requestID', component: LabRequestComponent},
          {path: 'labRequest', component: LabRequestComponent},
          {path: 'papRequest/:requestID', component: PapRequestComponent},
          {path: 'papRequest', component: PapRequestComponent}
        ],
        data: {
          title: 'Labs',
          notes: true,
          nextRoute: APP_ROUTES.create,
          physicalExamSubProcedureVisibility: true,
          nonPhysicalExamSubProcedureVisibility: false
        },
      },
      {
        path: APP_ROUTES.pe,
        component: PEComponent,
        canDeactivate: [DirtyCheckGuard],
        data: {
          title: 'Physical Exam',
          notes: true,
          physicalExamSubProcedureVisibility: true,
          nonPhysicalExamSubProcedureVisibility: false
        },
      },
      {
        path: APP_ROUTES.create,
        canDeactivate: [DirtyCheckGuard],
        component: CreateComponent,
        data: {
          title: 'Create',
          notes: true,
          physicalExamSubProcedureVisibility: true,
          nonPhysicalExamSubProcedureVisibility: false
        },
      },
      {
        path: APP_ROUTES.review,
        component: ReviewComponent,
        data: {
          title: 'Review',
          notes: false,
          physicalExamSubProcedureVisibility: true,
          nonPhysicalExamSubProcedureVisibility: true
        },
      },
    ],
  };

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('@dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  mainPatientRoute
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatientRoutingModule {
  // static property added so declarations array in feature module can just be declared once as components are built out
  static components = [
    AddSpecialtyComponent,
    AddSpecialtyItemComponent,
    ClinicalNotesComponent,
    CreateComponent,
    CytologyDetailsComponent,
    EKGFormComponent,
    ExamInfoComponent,
    HearingFormComponent,
    HearingFormComponent,
    LabOrderComponent,
    LabRequestComponent,
    LabRequisitionsComponent,
    LabResultsComponent,
    LabsComponent,
    LabsComponent,
    MacroDialogComponent,
    PapOrderComponent,
    PapRequestComponent,
    PatientChartComponent,
    PEComponent,
    ReqDetailsComponent,
    ReviewComponent,
    ScreeningsComponent,
    VisionFormComponent,
    VitalsFormComponent,
  ];
}
