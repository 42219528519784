<div class="appointment-card-wrapper">
  <mat-card id="apt-card">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div>
        <p mat-mat-card-title class="bold">{{ patient | formatName }}</p>
      </div>
      <div>
        <span class="bold">Keycode: </span>
        {{ patient?.patientID }}
      </div>
    </div>
    <div class="row justify-content__between">
      <div>{{ patient?.gender | gender }}</div>
      <div>
        <span class="bold">Appointment Date: </span>
        {{ data?.apptDateTime | date: "shortDate" }}
      </div>
    </div>
    <div class="row justify-content__between">
      <div><span class="bold">DOB: </span>{{ patient?.dob }}</div>
      <div><span class="bold">Procedure: </span>{{ data?.procedureName }}</div>
    </div>
  </mat-card>
</div>
