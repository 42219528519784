import { LookupOption } from './provider';

export interface LabRequisitionOrdersResponse{
    data: LabRequisitionOrder[];
    message: string;
}

export interface LabRequisitionOrderResponse{
  data: LabRequisitionOrder;
  message: string;
}

export interface PhysicianListResponse{
    data: Physician[];
    message: string;
}

export interface LabRequisitionOrder {
  id: number;
  orderID: number;
  resultsReceivedDate: string;
  status: number;
  statusName: string;
  ehePreferredPanels: string;
  ehePreferredTests: string;
  otherTests: string;
  nonStandardTests: string;
  eheCytologyTests: string;
  orderDate: string;
  displayOrderDate: string;
  statusModifiedDate: string;
  labCorpPatientNotes: string;
  labCorpResultsDate: string;
  labCorpCollectionDate: string;
  physician: Physician;
  labRequisition: LabRequisition;
  cytologyDetails: CytologyDetails;
}

export interface LabRequisition {
    requisitionID:         number;
    isFasting:             string;
    encounterID:           number;
    collectionDate?:        CollectionDate;
    displayCollectionDate: string;
    displayCollectionTime: string;
    labType:               string;
    type:                  number;
    displayType?:           string;
}

export interface CollectionDate {
  date: number;
  day: number;
  hours: number;
  minutes: number;
  month: number;
  nanos: number;
  seconds: number;
  time: number;
  timezoneOffset: number;
  year: number;
}

export interface Physician {
  adminid: number;
  username?: string;
  firstName?: string;
  lastName?: string;
  workLocation?: number;
  npiNumber?: number;
  name?: string;
}

export enum FastingState {
  YES = 'y',
  NO = 'n',
  UNKNOWN = 'u',
}

// Interface for route state object passed from lab requisitions to lab order/pap order component.
export interface LabOrderState {
  examInfo: {
    selectedPhysician: number;
  };
  reqDetails: ReqDetailsControls;
  standardPanelid: string;
  commonAdditionalTests: string;
  cytologyPanels: string;
  standardLabPanelOptions: LookupOption[];
  commonAdditonalTestsOptions: LookupOption[];
  labOrderOptions: LookupOption[];
  orderID ?: number;
  labRequisitionData: LabRequisitionOrder;
  cytSourceOptions: LookupOption[];
  cytCollectionOptions: LookupOption[];
  cytOtherPatientinfoOptions: LookupOption[];
  cytPreviousConditionOptions: LookupOption[];
  cytPreviousTreatmentOptions: LookupOption[];
}

export interface ReqDetailsControls{
  dateCollected: string | Date;
  timeCollected: string | Date;
  fasting: string | FastingState;
}

export interface CytologyDetails {
  lmpMenoDate: string;
  formattedLmpMenoDate: string;
  cytSource: string;
  cytCollection: string;
  cytHistory: string;
  cytPrevCondition: string;
  cytPrevTreatment: string;
  cytPrevTreatmentComments: string;
}

export interface NonStandardPanel {
  ehePreferred: number;
  id: number;
  labCorpPanelNumber?: number;
  labCorpTestName?: string;
  name?: string;
  referenceRange?: string;
  specimenRequirement?: string;
  specimenStability?: string;
  testName?: string;
  toolTip?: string;
}

