
export const EmptyPatient = {
    address: null,
    address2: null,
    cellPhone: null,
    city: null,
    clientNumber: null,
    country: null,
    dob: null,
    email: null,
    epmsPID: null,
    eveningPhone: null,
    firstName: null,
    gender: null,
    lastName: null,
    otherPhone: null,
    patientID: null,
    phone: null,
    state: null,
    zip: null,
};

export type GenderTypes = 'M' | 'F' | 'U' | Gender;

export enum Gender {
    male = 'Male',
    female = 'Female',
    nonBinary = 'Non-Binary',
}
export interface Patient {
  address: string;
  address2?: string;
  cellPhone?: string | null;
  city: string;
  clientNumber: number;
  country?: null;
  dob: string;
  email: string;
  epmsPID: number;
  eveningPhone?: string | null;
  firstName: string;
  gender: GenderTypes;
  lastName: string;
  otherPhone?: string | null;
  patientID?: string;
  phone?: string | null;
  state: string;
  zip: string;
}

export interface SpecialtyInternal {
    name: string;
    otherSpeciality: string;
    isStat: boolean;
}
export interface SpecialtyLookup {
    id: number;
    name: string;
    displayOrder: number;
}

export interface SpecialtyResponse {
    data: SpecialtyLookup[];
}

export interface Specialty {
    id: number;
    name: string;
    otherSpeciality?: string | null;
    isStat?: boolean | null;
    displayOrder: number;
}
