export interface ExamPrefillData {
    objectives: ObjectiveField[];
    assessmentPlans: ObjectiveField[];
}

// ReportDTO
export interface ExamStatic {
    epmsPID?: number;
    examDateStr?: string;
    examId: number;
    facility: number;
    id: number; // long
    latestReportStatus?: LatestReportStatus;
    physician?: any;
    reportTypeId?: number;
}

export interface LatestReportStatus {
    crsendBackToMDAllowedForCurrentStatus: boolean;
    mdlabsSignOffAllowedForCurrentStatus: boolean;
    mdsignOffAllowedForCurrentStatus: boolean;
    crsignOffAllowedForCurrentStatus: boolean;
    mdlabsSignOffAllowedForReport: boolean;
    mdsignOffAllowedForReport: boolean;
    crsignOffAllowedForReport: boolean;
    crsendBackToMDAllowedForReport: boolean;
}

export interface Exam extends ExamStatic, ExamPrefillData {
}

export const EmptyExam: Exam = {
    id: null,
    examId: null,
    reportTypeId: null,
    physician: null,
    facility: null,
    objectives: [],
    assessmentPlans: [],
};
export interface ObjectiveField {
    fieldID: number;
    fieldName: string;
    fieldValue: string;
    fieldOtherValue?: string;
}

export interface ExamResponse {
    data: Exam;
}

export interface ClinicalNotes {
    id: number; // long
    medicalReportID: number; // long
    notes: string;
}

export interface ClinicalNotesResponse {
  data: ClinicalNotes;
  message: string;
}

export interface CreateReferrals {
  eapReferral?: boolean;
  eapSpecialties: any[];
  generalSpecialties: any[];
  healthMentorshipReferral: false;
  optionalComments: string;
  pcpReason: string;
  primaryCareReferral: false;
  reportID: number;
  specialtyReferral?: boolean;
}

export interface CreateReferralsResponse {
  data: CreateReferrals;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface PostReferrals extends CreateReferrals {}

export interface LabsResponse {
    data: Labs;
    message: string;
}

export interface Labs {
    [resultGroup: string]: LabsResultGroup[];
}

export interface LabsResultGroup {
    loincResultCode: string;
    resultCode: string;
    description: string;
    resultsDate: string;
    observationValue: string;
    units: string;
    referenceRange: string;
    abnormalFlag: string;
    comments: string;
    panelID: string;
    labLocationCode: string;
    status: string;
    inRange: boolean;
    glossary: string;
}

export interface MedicalReportMetadataResponse {
    data: MedicalReportMetadataSection[];
}

export interface MedicalReportMetadataSection extends MedicalReportMetadataSubsection {
    subSections: MedicalReportMetadataSubsection[];
}

export interface MedicalReportMetadataSubsection {
    id: number;
    name: string;
    displayName: string;
    displayOrder: number;
}

export interface MedicalReportSectionResponse {
    data: MedicalReportSection[];
}

export interface MedicalReportSection {
    id: number;
    sectionId: number;
    subSectionId: number;
    name: string;
    displayName: string;
    additionalDisplayName: string;
    displayOrder: number;
    hasOtherText: boolean;
    isTextSelection: boolean;
    dataType: string;
    subFields: MedicalReportSection[];
}

export interface MedicalReportObjectiveDataResponse {
  data: MedicalReportObjectiveData;
}
export interface MedicalReportObjectiveData {
    reportID: number;
    sectionID: number;
    subSectionID: number;
    objectiveFields: ObjectiveField[];
}

export interface ObjectiveField {
    fieldID: number;
    fieldName: string;
    fieldValue: string;
    fieldOtherValue?: string;
}

/**
 * POST object for provider/user/medical-report/assessment-plan/override-macro
 */
export interface OverrideMacroDTO {
    fieldID: number;
    overrideMacroNotes: string;
    reportID: number;
}

export interface PatientPlanResponse {
    data: PatientPlan[];
}

export interface PatientPlan {
    mainSection: string;
    sectionID: number;
    sections: PatientPlanSection[];
}

export interface PatientPlanSection {
    fields: PatientPlanField[];
    sectionID: number;
    sectionName: string;
    sectionNotes: string;
}

export interface PatientPlanField {
    fieldName: string;
    fieldID: number;
    notes: string;
    canEditMacro: boolean;
}

export interface ImmunizationResponse {
    data: {
        immunizationLogs: ImmunizationLog[];
        reportID: number;
    };
    message: string;
}
export interface ImmunizationLog {
    administered: boolean;
    comments: string;
    dateGivenStr: string;
    dateUnknown: boolean;
    immunizationLogID: number;
    otherValue: string;
    reported: boolean;
}

export interface ParamsWithReportID {
    userID: string;
    epmsPID: string;
    reportID: string;
}

export interface LookupOption {
    displayOrder?: number;
    id: number;
    name?: string;
}

export interface LookupOptionResponse {
    data: LookupOption[];
    message: string;
}

export interface InternalImmunizationLogs {
    data: InternalImmunizationLog[];
}

export interface ExternalImmunizationLogs {
    immunizationLogs: ImmunizationLog[];
    reportID: number;
}

export interface ImmunizationLogsResponse {
    data: ExternalImmunizationLogs;
    message: string;
}

export interface ImmunizationLog {
    administered: boolean;
    immunizationLogID: number;
    reported: boolean;
}

export interface InternalImmunizationLog {
    administeredOrReported: string;
    comments: string
    dateGiven: Date | null;
    dateUnknown: boolean;
    immunizationLogID: number;
    otherValue: string;
}

export const ImmunizationStatuses = {
    administered: 'administered',
    reported: 'reported'
};

export interface SignOffResponse {
    data: LatestReportStatus;
    message: string;
}
