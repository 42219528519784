import { map, Observable, take } from 'rxjs';

import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { VerificationModalComponent } from './verification-modal.component';

@Injectable()
export class VerificationModalService {
  constructor(private dialog: MatDialog) {}

  dialogRef: MatDialogRef<VerificationModalComponent>;

  public open(options) {
    this.dialogRef = this.dialog.open(VerificationModalComponent, {
      data: {
        title: options.title,
        actionButton: options.actionButton,
        cancelButton: options.cancelButton,
        modalWidth: options.modalWidth,
        modalHeight: options.modalHeight,
        content: options.content,
        titleAlignment: options.titleAlignment,
        contentAlignment: options.contentAlignment,
        actionsAlignment: options.actionsAlignment,
      },
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }
}
