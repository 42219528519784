import { first, map, Observable, Subject, switchMap, withLatestFrom } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HAS_ABNORMAL_LABS, NO_ABNORMAL_LABS } from '@config/app.constant';
import {
    AppointmentDetail, FacilityWithRoleDTO, LabRequisition, Labs, ObjectiveField
} from '@config/types';
import { EheFormComponentTemplate } from '@core/guards/dirty-check.guard';
import { UpdateAppointmentExam } from '@core/state/appointments.actions';
import { UserState } from '@core/state/user.state';
import { Select, Store } from '@ngxs/store';
import { LabsService } from '@patient/services/labs.service';
import { PEService } from '@patient/services/pe.service';

import { LabConfig } from './labs-form.config';

@Component({
  selector: 'app-labs-results',
  templateUrl: './labs-results.component.html',
})
export class LabResultsComponent
  extends EheFormComponentTemplate
  implements OnInit, OnDestroy
{
  @Select(UserState.getRolesForSelectedFacility) roles$: Observable<FacilityWithRoleDTO>;
  appointment$: Observable<AppointmentDetail>;

  headers = [
    'Result Name',
    'In Range',
    'Out Of Range',
    'Reference',
    'Flag',
    'Units',
    'Status',
  ];
  labs: Labs;
  loading = true;
  fields: { [key: string]: FormControl };
  labConfig: LabConfig;
  form: FormGroup;

  objectiveFields: ObjectiveField;
  abnormalRes: ObjectiveField;
  isUserMD: boolean;
  statusMDLabsSignOffAllowed: boolean;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private labsService: LabsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private store: Store,
    public peService: PEService
  ) {
    super();
    this.appointment$ = this.route.parent.data.pipe(map(data => data.appointment));
  }

  ngOnInit(): void {
    this.buildForm();

    this.appointment$
      .pipe(
        first(),
        withLatestFrom(this.roles$)
      )
      .subscribe(([appointment, roles]: [AppointmentDetail, FacilityWithRoleDTO]) => {
        if (appointment) {
          this.isUserMD = roles.isMD;

          if (appointment.exam.latestReportStatus?.mdlabsSignOffAllowedForCurrentStatus) {
            this.statusMDLabsSignOffAllowed =
              appointment.exam.latestReportStatus.mdlabsSignOffAllowedForCurrentStatus;
          }
          this.fetchLabResults();
          this.fetchAbnormalLabs();
        }
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchAbnormalLabs(): void {
    this.labsService.getAbnormalLabs().subscribe((response) => {
      if (response.data?.objectiveFields?.length > 0 ) {
        this.abnormalRes = response.data.objectiveFields[0];
        if (this.abnormalRes.fieldID === NO_ABNORMAL_LABS) {
          this.fields.abnormalLabs.setValue('noAbnormalLabs');
        } else if (this.abnormalRes.fieldID === HAS_ABNORMAL_LABS) {
          this.fields.abnormalLabs.setValue('hasAbnormalLabs');
          this.fields.abnormalLabsDescription.setValue(
            this.abnormalRes.fieldOtherValue
          );
        }
      }
    });
  }

  fetchLabResults(): void {
    this.labsService.getLabs().subscribe(
      (response) => {
        if (response.data) {
          this.labs = response.data;
        }
        else if (response.message === 'no encounters') {
          this.statusMDLabsSignOffAllowed = false;
        }
        this.loading = false;
      },
      () => (this.loading = false)
    );
  }

  fastingDisplayName(lab: LabRequisition) {
    if (lab.isFasting) {
      if (lab.isFasting.toLowerCase() === 'n') {
        return 'No';
      }
      if (lab.isFasting.toLowerCase() === 'y') {
        return 'Yes';
      }
    }
    return 'Unknown';
  }

  labSignOff(): void {
    this.labsService.labsSignOff().subscribe((response) => {
      this.statusMDLabsSignOffAllowed = response.data.mdlabsSignOffAllowedForCurrentStatus;
      this.appointment$.subscribe(appointment => {
        this.store.dispatch(
          new UpdateAppointmentExam({appointmentID: appointment.appointmentID})
        )
      });
    });
  }

  private buildForm(): void {
    this.fields = {
      abnormalLabsDescription: this.fb.control(null),
      abnormalLabs: this.fb.control(null),
    };
    this.form = this.fb.group({
      abnormalLabsDescription: this.fields.abnormalLabsDescription,
      abnormalLabs: this.fields.abnormalLabs,
    });
  }

  saveForm() {
    if (this.fields.abnormalLabs.value == 'hasAbnormalLabs') {
      this.objectiveFields = {
        fieldID: HAS_ABNORMAL_LABS,
        fieldName: 'Abnormal Labs',
        fieldValue: 'true',
        fieldOtherValue: this.fields.abnormalLabsDescription.value,
      };
    } else if (this.fields.abnormalLabs.value == 'noAbnormalLabs') {
      this.objectiveFields = {
        fieldID: NO_ABNORMAL_LABS,
        fieldName: 'Abnormal Labs - None',
        fieldValue: 'true',
      };
    }

    this.appointment$.pipe(
      switchMap( appointment => {
        return this.labsService
          .postAbnormalLabs(
            {
              objectiveFields: [this.objectiveFields],
              sectionID: 2,
              reportID: appointment.exam.id,
              subSectionID: 13,
            }
          );
      })
    )
    .subscribe((response) => console.log(response));
  }
}
