<div class="exam-container">
  <label class="section-header">Exam Info</label>
  <div class="divider">
    <mat-divider></mat-divider>
 </div>

  <form class="exam-form-wrapper column" [formGroup]="form">
    <shared-ui-input
      [parent]="form"
      control="procedureName"
      label="Type"
    ></shared-ui-input>
    <shared-ui-input
      [parent]="form"
      control="facilityName"
      label="Facility"
    ></shared-ui-input>
    <shared-ui-input
      [parent]="form"
      control="apptDate"
      label="Date"
    ></shared-ui-input>
    <shared-ui-select
      label="Physician"
      [parent]="form"
      control="physician"
      [typeDropDown]="true"
      [options]="physicianList"
      required
    >
    </shared-ui-select>
  </form>
</div>
