import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { Gender, GenderTypes, Patient } from '@config/types';

@Injectable({ providedIn: 'root' })
export class PatientService {
  private _patient$: BehaviorSubject<Patient> = new BehaviorSubject(null);
  public patient$ = this._patient$.asObservable();

  private _gender$: BehaviorSubject<Gender> = new BehaviorSubject(null);
  public gender$ = this._gender$.asObservable();

  updatePatient(patient: Patient) {
    this._patient$.next(patient);
    this._gender$.next(this.formatGenderStrings(patient.gender));
  }

  private formatGenderStrings(input: GenderTypes) {
    let gender: Gender;
    switch (input) {
      case 'M':
        gender = Gender.male;
        break;

      case 'F':
        gender = Gender.female;
        break;

      case 'U':
        gender = Gender.nonBinary;
        break;
      default:
        gender = Gender.nonBinary;
    }
    return gender;
  }
}
