<div class="sticky-header">
  <nav id="chart-nav" mat-tab-nav-bar>
    <a mat-tab-link *ngFor="let link of childRoutes" [routerLink]="link.data?.title === 'Sub Procedure' ? subProcedureObj?.routeLink : link.path" routerLinkActive #rla="routerLinkActive"
      [active]="rla.isActive">
      {{link.data?.title === "Sub Procedure" ? subProcedureObj?.name : link.data?.title}}
    </a>
  </nav>
  <header>
    <mat-toolbar class="patient-info" *ngIf="appointment$ | async as appointment">
      <mat-toolbar-row>
        <span class="flex-spacer"></span>
        <div class="info-column">
          <span><b>KeyCode:</b> {{appointment?.patient.patientID}}</span>
          <span><b>Phone:</b> {{appointment?.patient | patientPhone}}</span>
        </div>
        <div class="info-column">
          <span><b>DOB:</b> {{appointment?.patient.dob}}</span>
          <span><b>Age:</b> {{appointment?.patient.dob | calculateAge}}</span>
        </div>
        <div class="info-column">
          <span><b>Sex:</b> {{appointment?.patient.gender | gender}}</span>
          <span><b></b> {{appointment?.patient | formatName}}</span>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </header>
</div>

<mat-divider></mat-divider>
<div class="dashboard-content">
  <div *ngIf="activeLink?.data?.notes" class="column">
    <app-clinical-notes></app-clinical-notes>
  </div>
  <div class="column">
    <router-outlet></router-outlet>
    <div *ngIf="activeLink?.data?.nextRoute">
      <button (click)="navigateNext()" mat-flat-button class="primary">Continue</button>
    </div>
  </div>
</div>
