<div class="create-wrapper">
    <h2>Assessment</h2>

    <h3>Biometrics</h3>
    <form [formGroup]="forms.biometrics">
        <div *ngIf="biometricsSections.length > 0">
            <div *ngFor="let section of biometricsSections" class="create-section">
                <p class="bold radio-button-heading">{{ biometricsHeadings[section] }}</p>
                <mat-radio-group [formControlName]="section">
                    <span *ngFor="let option of fieldsByGroup.biometrics[section]">
                        <mat-radio-button [value]="option.field">
                            {{ option.displayText }}
                        </mat-radio-button>
                    </span>
                </mat-radio-group>
            </div>
            <shared-ui-select
              label="Cardiorespiratory Fitness Level"
              [parent]="forms.biometrics"
              control="vO2Option"
              sort="true"
              [options]="lookupCardiorespiratoryFitnessLevels"
            >
            </shared-ui-select>
            <section class="create-wrapper-top">
                <div class="input-75">
                    <app-textarea-control label="Optional comment on biometrics"
                        [control]="forms.biometrics.controls.biometricsOtherValue">
                    </app-textarea-control>
                </div>
            </section>
        </div>
    </form>

    <h3>Labs</h3>
    <form [formGroup]="forms.labs">
        <div *ngIf="labsSections.length > 0">
            <div *ngFor="let section of labsSections" class="create-section">
                <p class="bold radio-button-heading">{{ labsHeadings[section] }}</p>
                <mat-radio-group [formControlName]="section">
                    <span *ngFor="let option of fieldsByGroup.labs[section]">
                        <mat-radio-button [value]="option.field">
                            {{ option.displayText }}
                        </mat-radio-button>
                    </span>
                </mat-radio-group>
            </div>
        </div>
        <section class="create-wrapper-top">
            <div class="input-75">
                <app-textarea-control label="Optional comment on labs" [control]="forms.labs.controls.labsOtherValue">
                </app-textarea-control>
            </div>
        </section>
    </form>

    <h3>Screenings</h3>
    <form [formGroup]="forms.screenings">
        <section class="input-checkbox">
            <mat-checkbox formControlName="ascvdHigh">ASCVD High (≥ 5%)</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="breastCancerHigh">Breast Cancer Risk Assessment High (≥ 1.66%)</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="skinCancer">Skin Cancer Prevention</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="oralHealth">Oral Health</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="ekgAbnormal">EKG Abnormal</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="visionDeficit">Vision Deficit (≥ 20/40)</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="hearingDeficit">Hearing Deficit (≥ 30)</mat-checkbox>
        </section>
        <section class="create-wrapper-top">
            <div class="input-75">
                <app-textarea-control label="Optional comment on screenings" [control]="forms.screenings.controls.screeningsOtherValue">
                </app-textarea-control>
            </div>
        </section>
    </form>

    <h3>Flu Vaccination</h3>
    <form [formGroup]="forms.vaccines">
        <section class="input-checkbox">
            <mat-checkbox formControlName="fluGiven">Flu Vaccine Given</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="fluDeclined">Flu Vaccine Declined</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="fluUpToDate">Flu Vaccine Up-To-Date</mat-checkbox>
        </section>
    </form>
    <h3>Confirmed Immunity</h3>

    <form [formGroup]="forms.immunity">
        <section class="input-checkbox">
            <mat-checkbox formControlName="mmr">MMR</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="hepA">Hepatitis A</mat-checkbox>
        </section>
        <section class="input-checkbox">
            <mat-checkbox formControlName="hepB">Hepatitis B</mat-checkbox>
        </section>
    </form>

    <h3>Immunization Log</h3>
    <form [formGroup]="forms.immunizationLogs" id="immunizationLogs">
        <ng-container formArrayName="data">
            <div class="immunization-row" *ngFor="let control of immunizationsCtrl.controls; let i = index" [formGroup]="control">
                <div fxLayout="row wrap" fxLayoutAlign="space-between start" class="immunization-details">
                    <div fxFlex="55">
                        <div fxFlex="60" fxLayout="column" class="immunization-name-wrapper">
                            <shared-ui-select
                                [parent]="control"
                                control="immunizationLogID"
                                [fxFlex]="'1 1 100%'"
                                [options]="lookupImmunizations"
                                sort="true"
                                class="immunization-select"
                            >
                            </shared-ui-select>
                            <div class="immunization-other" *ngIf="control.value.immunizationLogID === IMMUNIZATION_OTHER_ID">
                                <app-textarea-control
                                    label="Please Explain Other Immunization"
                                    [control]="control.get('otherValue')"
                                ></app-textarea-control>
                            </div>
                            <section class="immunization-source" [ngStyle]="{'visibility': control.get('immunizationLogID').value ? 'visible' : 'hidden'}">
                                Source
                                <mat-radio-group formControlName="administeredOrReported" layout="row">
                                    <mat-radio-button value="administered">Administered</mat-radio-button>
                                    <mat-radio-button value="reported">Reported</mat-radio-button>
                                </mat-radio-group>
                            </section>
                        </div>
                        <div fxFlex="35" fxFlexOffset="5" fxLayout="column" class="immunization-date-wrapper">
                            <mat-form-field class="immunization-date">
                                <mat-label>Date Given</mat-label>
                                <input
                                    matInput
                                    formControlName="dateGiven"
                                    [matDatepicker]="picker"
                                    [max]="today"
                                    (dateChange)="handleImmunizationDate($event, i)"
                                />
                                <mat-datepicker #picker></mat-datepicker>
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-error *ngIf="control.get('dateGiven').hasError('matDatepickerMax')">Future dates are not allowed</mat-error>
                                <mat-error *ngIf="control.get('dateGiven').hasError('matDatepickerFilter')">Date is invalid</mat-error>
                            </mat-form-field>
                            <section class="input-checkbox">
                                <mat-checkbox
                                    formControlName="dateUnknown"
                                    (change)="handleImmunizationDateUnknown($event, i)"
                                    class="input-checkbox immunization-date-unknown"
                                >Date Unknown</mat-checkbox>
                            </section>
                        </div>
                    </div>
                </div>
                <section fxLayout="row" class="immunization-comments padding-left__0 margin-top__10">
                    <app-textarea-control fxFlexFill label="Optional comment on immunization" [control]="control.get('comments')">
                    </app-textarea-control>
                </section>
            </div>
        </ng-container>
        <button type="button" mat-button (click)="addImmunization()">
            + Add Another Immunization
        </button>
    </form>

    <h2>Recommended Follow-up</h2>

    <div class="recommended-followup">
        <form [formGroup]="forms.recommended">

            <h3>EHE Covered Ancillaries</h3>
            <ng-container *ngFor="let section of recommendedSections">
                <div class="create-section">
                    <p class="bold radio-button-heading">{{ recommendedSections[section] }}</p>
                    <mat-radio-group [formControlName]="section">
                        <span *ngFor="let option of fieldsByGroup.recommended[section]">
                            <mat-radio-button #radioField [value]="option.field">
                                {{ option.displayText }}
                            </mat-radio-button>
                            <div class="option-description" *ngIf="option.description">{{option.description}}</div>
                            <ng-container *ngIf="radioField.checked">
                                <div class="checkbox-list" [ngSwitch]="option.field">
                                    <div *ngSwitchCase="'lungCT'">
                                        <ng-container *ngTemplateOutlet="lungCT"></ng-container>
                                    </div>
                                    <div *ngSwitchCase="'vascStudy'">
                                        <ng-container *ngTemplateOutlet="peripheralVascularStudy"></ng-container>
                                    </div>
                                    <div *ngSwitchCase="'corCalScan'">
                                        <ng-container *ngTemplateOutlet="coronoryScan"></ng-container>
                                    </div>
                                </div>
                                <div *ngIf="option.dropdownOptions" class="input-select-lg dropdown-indent">
                                    <mat-form-field>
                                        <mat-select [formControlName]="option.dropdownControlName">
                                            <mat-option></mat-option>
                                            <mat-option [value]="option.dropdownOptions[0]">
                                                Patient up to date
                                            </mat-option>
                                            <mat-option [value]="option.dropdownOptions[1]">
                                                Patient declined
                                            </mat-option>
                                            <mat-option [value]="option.dropdownOptions[2]">
                                                Patient sees specialist
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                        </span>
                    </mat-radio-group>
                </div>
                <ng-template #coronoryScan>
                    <section class="checkbox-indent">
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="ascvd">ASCVD Risk Borderline (5-7.4%)</mat-checkbox>
                        </section>
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="statin">Not currently on a statin</mat-checkbox>
                        </section>
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="riskFactors2">At least one risk factor</mat-checkbox>
                        </section>
                        <section class="checkbox-indent">
                            <section class="input-checkbox">
                                <mat-checkbox formControlName="LDL">LDL > 160</mat-checkbox>
                            </section>
                            <section class="input-checkbox">
                                <mat-checkbox formControlName="triglycerides">Triglycerides > 175</mat-checkbox>
                            </section>
                            <section class="input-checkbox">
                                <mat-checkbox formControlName="hscrp">hs-CRP > 2.0</mat-checkbox>
                            </section>
                            <section class="input-checkbox">
                                <mat-checkbox formControlName="peripheralVascular">Peripheral Vascular Disease (ABI <
                                        0.9)</mat-checkbox>
                            </section>
                            <section class="align-top input-checkbox">
                                <mat-checkbox formControlName="family2">Family History of Premature Coronary Artery
                                    Disease
                                    <br />(1st degree male relative < 55 y/o or 1st degree female relative < 65
                                        y/o)</mat-checkbox>
                            </section>
                        </section>
                    </section>
                </ng-template>

                <ng-template #peripheralVascularStudy>
                    <section class="checkbox-indent">
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="a1c2">Hemoglobin A1C > 6.4</mat-checkbox>
                        </section>
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="hypertension2">Hypertension (SBP > 130, DBP > 80)
                            </mat-checkbox>
                        </section>
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="LDL2">LDL > 160</mat-checkbox>
                        </section>
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="smoker2">Current smoker or has quit within the past 15 years
                            </mat-checkbox>
                        </section>
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="family3">Family History of Peripheral Artery Disease
                            </mat-checkbox>
                        </section>
                    </section>
                </ng-template>

                <ng-template #lungCT>
                    <section class="checkbox-indent">
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="smokerHistory">30-pack-year smoking history</mat-checkbox>
                        </section>
                        <section class="input-checkbox">
                            <mat-checkbox formControlName="smoker3">Smoker within the past 15 years</mat-checkbox>
                        </section>
                    </section>
                </ng-template>
                <mat-divider></mat-divider>
            </ng-container>

            <section class="create-wrapper-top">
                <div class="input-75">
                    <app-textarea-control label="Optional comment on recommended screenings"
                        [control]="forms.recommended.controls.recommendedScreeningsOtherValue"></app-textarea-control>
                </div>
            </section>

            <h3>Referrals</h3>
            <div formGroupName="referrals">
                <section class="input-checkbox">
                    <mat-checkbox formControlName="healthMentorshipReferral">Health Mentorship Referral</mat-checkbox>
                </section>
                <section class="input-checkbox">
                    <mat-checkbox formControlName="primaryCareReferral">Primary Care Referral</mat-checkbox>
                </section>
                <section class="create-wrapper-top comment-indent-left">
                    <div class="input-75">
                        <app-textarea-control *ngIf="forms.recommended.get('referrals.primaryCareReferral').value"
                            label="Reason for Referral (optional)" [control]="controls.pcpReason">
                        </app-textarea-control>
                    </div>
                </section>
                <p>Specialist Referral</p>
                <app-add-specialty [formGroup]="forms.recommended.get('referrals')" formArrayName="generalSpecialties"
                    type="specialtyDropdown"></app-add-specialty>
                <section class="create-wrapper-top">
                    <div class="input-75">
                        <app-textarea-control label="Optional comment on referrals recommended"
                            [control]="controls.referralOptionalComments"></app-textarea-control>
                    </div>
                </section>
                <app-add-specialty
                    [formGroup]="forms.recommended.get('referrals')"
                    formArrayName="eapSpecialties"
                    type="eapDropdown">
                    <p header>Employer Assisted Program Referral (EAP)</p>
                </app-add-specialty>
            </div>

            <h4 class="create-subheader">VACCINATIONS</h4>
            <section class="input-checkbox">
                <mat-checkbox formControlName="hpv">HPV</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="pneumovax">Pneumonia (Pneumovax)</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="prevnar">Pneumonia (Prevar)</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="shingrix">Shingles (Shingrix)</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="tetanus">Tetanus/Diptheria</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="tdap">Tdap</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="mmr">Measles, Mumps, Rubella</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="hepA">Hepatitis A</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="hepB">Hepatitis B</mat-checkbox>
            </section>
            <section class="input-checkbox">
                <mat-checkbox formControlName="covid19">Covid-19</mat-checkbox>
            </section>
            <section class="create-wrapper-top">
                <div class="input-75">
                    <app-textarea-control label="Optional comment on vaccinations"
                        [control]="forms.recommended.controls.recommendedVaccinesOtherValue"></app-textarea-control>
                </div>
            </section>
        </form>
    </div>

    <form [formGroup]="forms.physician">
        <h3>Physician</h3>
        <section>
            <div class="input-75">
                <app-textarea-control [control]="forms.physician.controls.physicianValue"></app-textarea-control>
            </div>
        </section>
    </form>

    <h3>Summary Statement to Patient</h3>

    <form [formGroup]="forms.summary">
        <section>
            <div class="input-75">
                <app-textarea-control
                    label="In a couple of sentences, please indicate the most important action items for this patient."
                    [control]="forms.summary.controls.summaryValue"></app-textarea-control>
            </div>
        </section>
    </form>

    <button mat-flat-button class="primary save-button" (click)="action('save')">Save and Continue</button>

</div>