import { Component, Input, OnInit } from '@angular/core';
import { AppointmentDetail, Patient, ReportDTO } from '@config/types';

@Component({
  selector: 'app-appointment-card',
  templateUrl: './appointment-card.component.html',
  styleUrls: ['./appointment-card.component.scss'],
})
export class AppointmentCardComponent implements OnInit {
  @Input() data: AppointmentDetail;
  patient: Patient;
  exam: ReportDTO;

  ngOnInit(): void {
    this.patient = this.data.patient;
    this.exam = this.data.exam;
  }
}
