// Note: All have '/base/' as the prefix and then the microservice prefix , configured in appConfig
export const ENDPOINTS = {
  auth: {
    prefix: '/base/',
    routes: {
      login: 'auth/user/login',
      loginWithAuth0: 'auth/user/login-auth0',
      refreshToken: 'auth/user/refresh-token',
    },
  },
  scheduling: {
    prefix: '/base/scheduling/',
    routes: {
      appointments: 'appointments',
      schedulingHealth: 'health',
    },
  },
  healthHistory: {
    prefix: '/base/health-assessment/user/',
    routes: {
      allergies: 'medical-history/allergies',
      eat: 'life-style/how-you-eat',
      emergencyContact: 'general-info/emergency-contact',
      familyHistory: 'medical-history/family-history',
      healthAssesmentMarkComplete: 'mark-complete',
      healthAssessment: 'health-assessment',
      healthAssessmentIsCompleted: 'is-completed',
      healthGoals: 'life-style/health-goals',
      livingWill: 'general-info/living-will',
      markAsReviewDone: 'mark-review-done',
      medicalConditions: 'medical-history/past-present-conditions',
      medicalSpecialists: 'general-info/medical-specialists',
      medications: 'medical-history/medications',
      menHealth: 'medical-history/men-health',
      move: 'life-style/how-you-move',
      personalInfo: 'general-info/personal-info',
      primaryCare: 'general-info/primary-care',
      safety: 'life-style/safety',
      screenings: 'medical-history/screenings',
      sleep: 'life-style/how-you-sleep',
      substanceAbuse: 'life-style/substance-abuse',
      surgicalHistory: 'medical-history/surgical-history',
      think: 'life-style/how-you-think',
      vaccinations: 'medical-history/vaccinations',
      womenHealth: 'medical-history/women-health',
      workLife: 'life-style/work-life',
    },
  },
  healthHistoryLookup: {
    prefix: '/base/health-assessment/lookup/user/',
    routes: {
      haLookupAlcoholDrinkingFrequency: 'alcohol-drinking-frequency-options',
      haLookupAlcoholDrinksMorethanSix: 'alcohol-drinks-more-than-six-options',
      haLookupAlcoholDrinksPerDay: 'alcohol-drinks-per-day-options',
      haLookupBotheredByProblems: 'bothered-by-problems-options',
      haLookupCharacterizationDescribeRating: 'characterization-describe-rating-options',
      haLookupConsiderMyselfRating: 'consider-myself-rating-options',
      haLookupDiagnosis: 'diagnosis-options',
      haLookupDifficultyWithSleeping: 'difficulty-with-sleeping-options',
      haLookupEducationLevel: 'education-level-list',
      haLookupEthnicBackground: 'ethnic-background-list',
      haLookupExerciseType: 'exercise-type-options',
      haLookupFeelaboutWeight: 'feel-about-weight-options',
      haLookupGenderIdentityList: 'gender-identity-list',
      haLookupHealthGoalsPlan: 'health-goals-plan-options',
      haLookupLimitExercise: 'limit-exercise-options',
      haLookupMaritalStatus: 'marital-status-list',
      haLookupMedicalConditions: 'medical-conditions-options',
      haLookupMenHealthConcern: 'men-health-concern-options',
      haLookupMinutesOfTransport: 'minutes-of-transport-options',
      haLookupModeOfTransport: 'mode-of-transport-options',
      haLookupMonthlyNightsAwayfromHome: 'monthly-nights-away-from-home-options',
      haLookupPainDiscomfortArea: 'pain-discomfort-area-options',
      haLookupPreferredPronouns: 'preferred-pronouns-list',
      haLookupPreferredTitle: 'preferred-title-list',
      haLookupPresentHealth: 'present-health-options',
      haLookupQuestions: 'sub-sections/health-assessment-questions-answers',
      haLookupRelative: 'relative-options',
      haLookupSafety: 'safety-options',
      haLookupSatisfiedWithSleep: 'satisfied-with-sleep-options',
      haLookupSections: 'sections',
      haLookupSubSections: 'sub-sections',
      haLookupThinkReaction: 'think-reaction-options',
      haLookupWeeklyHoursOfWorking: 'weekly-hours-of-working-options',
      haLookupWomenHealthConcern: 'women-health-concern-options',
      haLookupHoursMissedFromWork: 'hours-missed-from-work-options',
      haLookupHealthProblemsAffect: 'health-problems-affect-options'

    },
  },
  provider: {
    prefix: '/base/provider/user/',
    routes: {
      allowedFacilitiesAndRoles: 'allowed-facilities-with-roles',
      appointmentsInProgress: 'exams/in-progress',
      appointmentsNeedsReview: 'exams/needs-review',
      appointmentsNoShow: 'exams/mark-no-show',
      appointmentsToDo: 'exams/todo',
      assessmentPlan: 'medical-report/assessment-plan/section',
      clinicalNotes: 'medical-report/physician-notes',
      createOrGetExam: 'medical-report/exam-report',
      createSections: 'medical-report/assessment-plan/section',
      crSignoff: 'medical-report/cr-sign-off',
      deleteDocument: 'medical-report/documents/delete',
      downloadDocument: 'medical-report/documents/download',
      downloadHistoryDocument: 'medical-report/documents/history/content',
      ekg: 'ekg',
      getAppointmentDetails: 'medical-report/report-details',
      getDocumentList: 'medical-report/documents/all',
      getHistoryDocumentList: 'medical-report/documents/all-history',
      getMedicalReport: 'medical-report/view-plan/review',
      getPatientExam: 'medical-report/exam',
      hearing: 'hearing',
      immunizations: 'medical-report/immunizations',
      isUserCR: 'medical-report/can-display-review',
      isUserMD: 'medical-report/can-display-md-signoff',
      labsSignOff: 'medical-report/labs-sign-off',
      mdSignoff: 'medical-report/md-sign-off',
      medicalReportSection: 'medical-report/objective/section',
      referralSection: 'referrals',
      reportNeedsReview: 'medical-report/cr-sent-back-to-md',
      riskScores: 'risk-scores',
      updateReviewMacro: 'medical-report/assessment-plan/override-macro',
      uploadDocument: 'medical-report/documents/upload',
      vision: 'vision',
      vitals: 'vitals',
    },
  },
  providerLookup: {
    prefix: '/base/provider/lookup/',
    routes: {
      providerLookupAssessmentFields: 'section/assessment-plan-fields',
      providerLookupAssessmentSections: 'medical-report-type/assessment-plan/sections',
      providerLookupBpPosition: 'bp-position',
      providerLookupDocumentType: 'document-type',
      providerLookupEAPSpecialities: 'eap-specialty',
      providerLookupImmunizations: 'immunization',
      providerLookupMedicalReport: 'medical-report-type',
      providerLookupmedicalReportSectionMetadata: 'section/objective-fields',
      providerLookupObjectiveSections: 'medical-report-type/objective/sections',
      providerLookupReferralSpecialities: 'specialty',
      providerLookupVo2Options: 'vo2',
    },
  },
  labs: {
    prefix: '/base/labs/user/',
    routes: {
      labResults: 'results',
      physiciansList: 'physician-list',
      requisitions: 'laborders',
      createLabOrder: 'laborder',
      createPapOrder: 'paporder',
      lookupStandardPanelOptions: 'ehe-preferred-panels',
      lookupCommonAdditionalTests: 'common-additional-tests',
      lookupNonStandardPanelOptions: 'non-standard-panels',
      lookupLabOrders: 'cytology-panels',
      lookupCytSourceOptions: 'cyt-source',
      lookupCytCollectionOptions: 'cyt-collection',
      lookupCytOtherPatientinfoOptions: 'cyt-other-patientinfo',
      lookupCytPreviousConditionOptions: 'cyt-previous-condition',
      lookupCytPreviousTreatmentOptions: 'cyt-previous-treatment',
      printOrder:'print-order'
    },
  },
};
