import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MedicalReportSection } from '@config/types';

@Component({
  selector: 'app-pe-form',
  templateUrl: './pe-form.component.html',
  styleUrls: ['./pe-form.component.scss']
})
export class PeFormComponent {

  @Input() parent: FormGroup;
  @Input() options: MedicalReportSection[];
  // supplied in order of No Significant Findings, Findings, Comment, Not Done

  shouldDisplayTextarea(option): boolean {
    return option.hasOtherText && option.displayName === 'additionalComment';
  }

}
