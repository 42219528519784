import { filter, first, Observable, Subject, take, takeUntil, tap, withLatestFrom } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import { AuthToken } from '@config/types';
import {
    ConfirmationDialogComponent
} from '@core/components/confirmation-dialog/confirmation-dialog.component';
import { ExtendLoginSession, Logout, RefreshLoginToken } from '@core/state/user.actions';
import { UserState } from '@core/state/user.state';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Select, Store } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

@Component({
  selector: 'app-timeout',
  template: ''
})
export class TimeoutComponent implements OnDestroy, OnInit {
  @Select(UserState.isLoggedIn) isLoggedIn$: Observable<boolean>;
  @Select(UserState.getAuth) auth$: Observable<AuthToken>;
  @Select(UserState.getExpiration) timestamp$: Observable<number>;

  private unsubscribe$ = new Subject<void>();
  private timeoutToken: ReturnType<typeof setTimeout>;
  private timeoutDialogRef;

  constructor(
    private idle: Idle,
    private dialog: MatDialog,
    public router: Router,
    private store: Store,
  ) { }

  // Note: Whenever we update our Angular versionion, this will be a dependency that breaks
  setupAutoLogout(): void {
    const idleTime = 900; // 15 minutes
    this.idle.setIdle(idleTime);
    this.idle.setTimeout(idleTime);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      // open the timeout dialog when the user has been idle for 15 mins
      if(!this.timeoutDialogRef){
        AppUtils.DEBUG && console.log('User is idle! Opening timeout dialog.');
        this.showConfirmationPopup();
      }
    });

    this.idle.onTimeout.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
        // force logout if the user has not responded to the timeout popup after another 15 mins
        AppUtils.DEBUG && console.log('No action taken on timeout dialog. Logging out!');
        this.timeoutDialogRef.close();
    });

    // if you refresh and are logged in, immediately start watching
    this.isLoggedIn$.pipe(
      takeUntil(this.unsubscribe$),
      take(1),
      filter( isLoggedIn => isLoggedIn)
    ).subscribe( _ => {
      this.idle.watch();
    });

    // listen to login/logout changes (later to be stored somewhere in the app)
    document.addEventListener('loginEvent', () => this.idle.watch());
    document.addEventListener('logoutEvent', () => this.idle.stop());
  }

  showConfirmationPopup(): void {
    const message =
      'Your session is about to expire. Do you want to stay logged in?';
    const title = 'Expiring Session';

    this.timeoutDialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        buttonText: {
          ok: 'Yes',
          cancel: 'No',
        },
      },
    });

    this.timeoutDialogRef.afterClosed().pipe(
      takeUntil(this.unsubscribe$),
      first(),
      withLatestFrom(this.auth$),
    ).subscribe(([confirmed, authToken]) => {
      if (confirmed) {
        this.store.dispatch(new ExtendLoginSession({
          auth: authToken.idToken,
          refreshToken: authToken.refreshToken
        })).pipe(
          takeUntil(this.unsubscribe$),
        ).subscribe( _ => {
          this.idle.watch();
        });
      } else {
        if (this.timeoutToken) {
          clearTimeout(this.timeoutToken);
          this.timeoutToken = null;
        }
        this.idle.stop();
        this.router.navigateByUrl(APP_ROUTES.logout);
      }

      this.timeoutDialogRef = undefined;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
     //  console.log('RefreshToken: App Component constructor');
     this.timestamp$.pipe(
      takeUntil(this.unsubscribe$),
      //  tap((v) => console.log('RefreshToken: Timestamp value seen:', v)),
      filter((v) => !!v), // non null
      tap((expiration) => {
        this.setupAutoLogout();

        console.log('RefreshToken: timestamp stream change detected', expiration);
        if (this.timeoutToken) {
          clearTimeout(this.timeoutToken);
          this.timeoutToken = null;
        }

        // ref: https://stackoverflow.com/questions/45802988/typescript-use-correct-version-of-settimeout-node-vs-window

        this.timeoutToken = setTimeout(() => {
        //  console.log('RefreshToken: Refresh timeout triggered');

          this.store.dispatch(new RefreshLoginToken());
          this.timeoutToken = null; // reset timeoutToken
        }, Math.max(0, expiration - Date.now() - 1000) as number);
      //  }, 8000); // for test purposes do 8 seconds from now
      //  console.log('RefreshToken: set Timeout launched with token', this.timeoutToken);
      })
    ).subscribe();
  }

}
