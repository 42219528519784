import { Subject, takeUntil } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { EkgResultsResponse } from '@config/types';
import { ScreeningsService } from '@patient/services/screenings.service';
import { AppUtils } from '@shared/utils/app.utils';

@Component({
  selector: 'app-ekg-form',
  templateUrl: './ekg-form.component.html',
})
export class EKGFormComponent implements OnInit, OnDestroy {
  ekgBody;

  @Input() form: FormGroup;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private screeningsService: ScreeningsService,
  ) { }

  ngOnInit(): void {
    this.fetchForm();
  }

  saveForm(): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.screeningsService.postEKG({
        id: this.ekgBody ? this.ekgBody.id : null,
        ekgUploaded: this.form.value.ekgOption === 'ekgUploaded',
        ekgDeclined: this.form.value.ekgOption === 'ekgDeclined',
      }).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe((response: EkgResultsResponse) => {
        if (response) {
          this.form.markAsPristine();
          resolve(true);
        } else {
          resolve(false);
        }
        AppUtils.DEBUG && console.log('Response', response);
      });
    });
  }

  fetchForm(): void {
    this.screeningsService.getEKG().pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((response: EkgResultsResponse) => {
      if (response.data) {
        this.ekgBody = {
          ...response.data,
          ekgOption: response.data.ekgDeclined ? 'ekgDeclined' : 'ekgUploaded',
        };
        this.form.patchValue(this.ekgBody);
        AppUtils.DEBUG && console.log('EKG', response.data);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
