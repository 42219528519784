<div mat-dialog-content align="center">
  <textarea
    cdkTextareaAutosize
    [cdkAutosizeMinRows]="5"
    style="width: 100%; font-family: inherit; height: 200px; line-height: 20px"
    [(ngModel)]="data.overrideMacroNotes"
  ></textarea>
</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button (click)="onNoClick()">Cancel</button>
  <button
    mat-raised-button
    [mat-dialog-close]="data"
    color="primary"
    cdkFocusInitial
  >
    Update Macro
  </button>
</div>
