import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { FastingState, LabOrderState } from '@config/types';

@Component({
  selector: 'app-req-details',
  templateUrl: './req-details.component.html',
  styleUrls: ['./req-details.component.scss'],
})
export class ReqDetailsComponent implements OnInit {
  @Input() isLabReq? = false;

  form: FormGroup;

  private labOrderState: LabOrderState;

  constructor(private fb: FormBuilder, private router: Router) {
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) { return; }})

    this.form = this.fb.group({
      dateCollected: [null, Validators.required],
      timeCollected: [null, Validators.required],
      fasting: [null, Validators.required],
    });
    if(this.router.getCurrentNavigation()) {
      this.labOrderState = <LabOrderState>(
        this.router.getCurrentNavigation().extras.state
      );
    }
  }

  ngOnInit(): void {
    //Getting data passed through router state
    const {reqDetails} = this.labOrderState ?? (<LabOrderState>history.state);
    if (reqDetails) {
      let fastingOption =
        <FastingState>reqDetails.fasting === FastingState.UNKNOWN
          ? null
          : <FastingState>reqDetails.fasting === FastingState.YES
          ? 'Yes'
          : 'No';
      this.form.patchValue({
        dateCollected: new Date(reqDetails.dateCollected),
        timeCollected: reqDetails.timeCollected.toString().length > 0 ? this.to12Hour(reqDetails.timeCollected): null,
        fasting: fastingOption,
      });
    }
  }

  to12Hour(time: string | Date): string {
    if(time instanceof Date) {
      return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })
    }

    const timeArray = time.split(':');
    let hours = +timeArray[0];
    const minutes = timeArray[1];
    const AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
     
    return hours + ':' + minutes + ' ' + AmOrPm;
  }
}
