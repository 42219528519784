<div *ngIf="conditionalDisplay" [formGroup]="parent">
  <p class="bold">{{prompt}} <span *ngIf="required">&nbsp;*</span></p>
  <mat-form-field [ngClass]="formFieldSize" [hideRequiredMarker]="!this.label">
    <mat-label>{{ label }}</mat-label>
    <div [ngSwitch]="inputType">
      <div [ngClass]="{'input-datepicker': inputType === 'datePicker' || 'timePicker'}" *ngSwitchCase="'datePicker'"
        class="row flex-center">
        <input matInput [formControlName]="control" [matDatepicker]="picker" [required]="required">
        <mat-datepicker #picker></mat-datepicker>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      </div>
      <div *ngSwitchCase="'timePicker'" class="row flex-center">
        <input matInput [formControlName]="control" [required]="required">
      </div>
      <ng-container *ngSwitchDefault>
        <input [type]="inputType" [placeholder]="placeholder" [required]="required" [type]="inputType" matInput
          [formControlName]="control" />
      </ng-container>
    </div>
    <mat-error *ngIf="inputType === 'timePicker' && this.parent.get(this.control).errors?.pattern">h:mm am | pm</mat-error>
  </mat-form-field>
</div>
