import { Subject, takeUntil } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HearingResultsResponse } from '@config/types';
import { ScreeningsService } from '@patient/services/screenings.service';

@Component({
  selector: 'app-hearing-form',
  templateUrl: './hearing-form.component.html'
})
export class HearingFormComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private screeningsService: ScreeningsService,
  ) { }

  ngOnInit(): void {
    this.fetchForm();
  }

  fetchForm(): void {
    this.screeningsService.getHearing().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      (response: HearingResultsResponse) => {
        if (response.data) {
          const hearingBody = {
            ...response.data,
            hearingOption: response.data.hearingDeclined ? 'hearingDeclined' : 'hearingUploaded',
          };
          this.form.patchValue(hearingBody);
        }
      });
    }

  saveForm(): Promise<boolean> {
    const postBody = {
      ...this.form.value,
      hearingDeclined: this.form.value.hearingOption === 'hearingDeclined',
      hearingUploaded: this.form.value.hearingOption === 'hearingUploaded',
      hearingOption: undefined
    };

    return new Promise<boolean>( resolve => {
      this.screeningsService.postHearing(postBody).pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe((response: HearingResultsResponse) => {
        if (response) {
          this.form.markAsPristine();
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
