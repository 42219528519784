import { IParamsRUE, IParamsUserAndEpms, IWithMessage } from '@config/types';

export interface ReferralsResponse extends IWithMessage {
  data: ReferralsDTO;
}

// GET /provider/user/referrals
export interface GetReferralsRequestParams extends IParamsRUE {}
export interface GetReferralsResponse extends ReferralsResponse {}
// POST /provider/user/referrals
export interface SetReferralsRequestParams extends IParamsUserAndEpms {}
export interface SetReferralsRequestBody extends ReferralsDTO {}
export interface SetReferralsResponse extends ReferralsResponse {}

export interface ReferralsDTO {
  eapReferral: boolean;
  eapSpecialties: ReferralsSpecialtyDTO[];
  generalSpecialties: ReferralsSpecialtyDTO[];
  healthMentorshipReferral: boolean;
  optionalComments: string;
  pcpReason: string;
  primaryCareReferral: boolean;
  reportID: number;
  specialtyReferral: boolean;
}

export interface ReferralsSpecialtyDTO {
  isStat: boolean;
  otherSpecialty: string;
  reason: string;
  specialtyID: number;
}
