import { map, Observable, tap } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LookupOption } from '@config/types';
import { AppUtils } from '@shared/utils/app.utils';

export interface GetMedicalReportTypeIdResponse {
  data: [LookupOption]; // returns ONE LookupOption in an array `\_("/)_/'
}

@Injectable()
export class CoreLookupService {
  constructor(
    private config: AppConfigService,
    private http: HttpClient
  ) {}

  getMedicalReportTypeId(params: {procedureId: number}): Observable<[LookupOption]> {
    console.log('getMedicalReportType', params, this.http, this.config.ENDPOINTS.providerLookupMedicalReport);
    return this.http.get<GetMedicalReportTypeIdResponse>(this.config.ENDPOINTS.providerLookupMedicalReport, {
      params: {
        procedureId: params.procedureId.toString(),
      },
    }).pipe(
      tap(result => AppUtils.DEBUG && console.log('heard back from getMedicalReprtyType', result)),
      map(result => result.data)
    );
  }
}


// Get ReportTypeID from ProcedureID
// do we already have this ReportTypeID?

// yes -> okay lets use that

// no -> fetch it and add to cache


