import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

import { GetFacilityPermissions, Login, Logout } from '../state/user.actions';
import { UserStateModel } from '../state/user.state';
import { APP_ROUTES } from '@config/application-routes';

@Injectable({
  providedIn: 'root',
})
export class UserControllerService {
  constructor(
    private store: Store,
    private router: Router,
  ) {}

  login(params: {
    username: string,
    password: string,
  }, redirectRoute?: string): void {
    this.store.dispatch(new Login(params)).subscribe((newState: {user: UserStateModel}) => {
      AppUtils.DEBUG && console.log('login Success', newState, newState.user);
      this.store.dispatch(new GetFacilityPermissions({
        userId: newState?.user?.user.adminid
      })).subscribe(o => {
        AppUtils.DEBUG && console.log('dispatch facilities complete', o);
        this.router.navigate([redirectRoute]);
      });
    });
  }

  logout(): void {
    this.router.navigateByUrl(APP_ROUTES.logout);
  }
}
