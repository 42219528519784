import { map, Observable, startWith } from 'rxjs';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-specialty-item',
  templateUrl: './add-specialty-item.component.html'
})
export class AddSpecialtyItemComponent implements OnInit {
  @Input() specialtyOptions;
  @Input() specialty;
  @Input() type: string; // EAP or regular specialties dropdown

  filteredOptions: Observable<any>;

  constructor() { }

  ngOnInit(): void {
    this.filteredOptions = this.specialty.controls.name.valueChanges
      .pipe(
        startWith(''),
        map((value: any) => this._filter(value))
      );
  }

  // for autocomplete
  private _filter(value: any): any {
    const filterValue = value.name ? value.name.toLowerCase() : value.toLowerCase();

    if (filterValue) {
      return this.specialtyOptions.filter(option => {
        // for EAP specialties, we ignore the client name when filtering specialty options
        if (this.type === 'eapDropdown') {
          const specialtyNameRegex = /(?<= -).*/g;
          const specialtyNamePart = option.name.match(specialtyNameRegex)[0].trim();

          if (specialtyNamePart.toLowerCase().indexOf(filterValue) === 0) {
            return option;
          }
        } else {
          if (option.name.toLowerCase().indexOf(filterValue) === 0) {
            return option;
          }
        }
      });
    } else {
      return this.specialtyOptions;
    }
  }
}
