import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { AppUtils } from '@shared/utils/app.utils';

export interface SuffixIconConfig {
  icon: string;
  onIconClick?: Function | null;
}

@Component({
  selector: 'shared-ui-input',
  templateUrl: './ui-input-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class SharedUiInputComponent implements OnInit {
  @Input() conditionalDisplay?: boolean;
  @Input() control: string;
  @Input() formFieldSize = 'small';
  @Input() hint?: string;
  @Input() inputType = 'text';
  @Input() label?: string;
  @Input() parent?: FormGroup;
  @Input() placeholder?: string;
  @Input() prompt?: string;
  @Input() required = false;
  @Input() width?: string;
  @Input() suffixIconConfig?: SuffixIconConfig;

  constructor() {
    AppUtils.DEBUG && console.log('CONTROL Constructor', this.control);
  }

  ngOnInit(): void {
    this.conditionalDisplay = this.conditionalDisplay ?? true;
    AppUtils.DEBUG && console.log('Control', this.control);

    if (this.control && this.inputType === 'timePicker') {
      this.parent.get(this.control).addValidators(
        Validators.pattern(/^(10|11|12|[1-9]):[0-5][0-9] ([AaPp][Mm])$/))
    }
  }

  getErrorMessage(): string {
    const errors = this.parent.get(this.control);
    AppUtils.DEBUG && console.log('Errors', errors);
    if (this.control) {
      AppUtils.DEBUG && console.log('Errors Inside', errors);
      return this.parent.get(this.control).hasError('required') ?
        `${(this.label ?? this.prompt)} is REQUIRED` : 'IDK';
      }
    }
}
