import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppointmentDetail, AppointmentTypes, Patient } from '@config/types';

@Component({
  selector: 'app-appointment-select-dialog',
  templateUrl: './appointment-select-dialog.component.html',
  styleUrls: ['./appointment-select-dialog.component.scss'],
})
export class AppointmentSelectDialogComponent {
  isNoShowEnabled: boolean;
  title = 'Confirm Patient';
  patient: Patient;

  constructor(@Inject(MAT_DIALOG_DATA) public appointment: AppointmentDetail) {
    this.patient = appointment.patient;
    this.verifyNoShow(appointment.type, appointment.apptDateTime);
  }

  verifyNoShow(appointmentType: AppointmentTypes, apptDateTimeString: string): void {
    const now: Date = new Date();
    const today: Date = new Date();
    const apptDateTime: Date = new Date(apptDateTimeString);
    const apptDate: Date = new Date(apptDateTime.getTime());

    today.setHours(0, 0, 0, 0);
    apptDate.setHours(0, 0, 0, 0);

    this.isNoShowEnabled =
    appointmentType.toLowerCase() === 'todo' &&
      today.getTime() === apptDate.getTime() &&
      now > apptDateTime;
  }
}
