<mat-toolbar class="header" color="primary">
  <mat-toolbar-row>
      <img src="assets/images/ehe-logo-ko.svg" class="logo" alt="ehe logo" />
      <span class="flex-spacer"></span>
      <ng-container *ngIf="user$ | async as user">
        <span>{{user | formatName: true}}</span>
        <button *ngIf="patientView"  mat-button (click)="viewPatientAppointments()">Select Patient</button>
        <button type="button" disableRipple mat-button (click)="logout()">Logout</button>
      </ng-container>
  </mat-toolbar-row>
</mat-toolbar>

    <!-- Facilty / appointment date form-->
    <div  *ngIf="dashboardView" class="conditional-display">
      <ng-content ></ng-content>
    </div>
