import { filter, first, map, merge, Observable, switchMap, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { GetReportTypeId } from '@core/state/lookup.actions';
import { LookupState, ProcedureLookupObject } from '@core/state/lookup.state';
import { Select, Store } from '@ngxs/store';

@Injectable()
export class CoreLookupControllerService {
  @Select(LookupState.getReportTypeIdDictionary)
  reportTypeIdDictionary$: Observable<ProcedureLookupObject>;

  constructor(
    private store: Store,
  ) {}

  getMedicalReportTypeId(procedureId: number): Observable<number> {
    console.log('CoreLookupControllerSerivce.getMedicalReportTypeId', procedureId);

    // return this.reportTypeIdDictionary$.pipe(
    //   tap( data => console.log('data', data)),
    //   filter( lookup => lookup && procedureId in lookup),
    //   map( lookup => lookup[procedureId]),
    //   tap( result => {
    //     console.log('found from procedureId', result);
    //   })
    // );

    return merge(
      // if we already have the ReportTypeId for this ProcedureId,
      // return that from the LookupState
      this.reportTypeIdDictionary$.pipe(
        filter( lookup => procedureId in lookup),
        map( lookup => lookup[procedureId]),
        tap( result => {
          console.log('found from procedureId', result);
        })
      ),
      // else we don't have the ReportTypeId for this ProcedureId,
      // we need to retrieve it and add it to the store
      this.reportTypeIdDictionary$.pipe(
        filter( lookup => !(procedureId in lookup)),
        switchMap(_ => {
          return this.store.dispatch(new GetReportTypeId({
            procedureId
          }));
        }),
        tap( result => {
          console.log('back from dispatching GetReportTypeId', result);
        }),
        map(result => result[procedureId])
      )
    ).pipe(
      first()
    );


    // return this.reportTypeIdDictionary$.pipe(
    //   filter( lookup => !(procedureId in lookup)),
    //   switchMap(_ => {
    //   return this.store.dispatch(new GetReportTypeId({
    //     procedureId
    //     }));
    //   }),
    //   tap( result => {
    //     console.log('back from dispatching GetReportTypeId', result);
    //   }),
    //   map(result => result[procedureId])
    // );
  }
}
