import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LabOrderState, LookupOption, NonStandardPanel } from '@config/types';
import { LabsService } from '@patient/services/labs.service';

@Component({
  selector: 'app-lab-order',
  templateUrl: './lab-order.component.html',
  styleUrls: ['./lab-order.component.scss'],
})
export class LabOrderComponent implements OnInit {
  labOrderState: LabOrderState;
  configOptions: LookupOption[];
  manualPanelOptions: LookupOption[] = [];
  nonStandardPanelOptions: NonStandardPanel[];

  form: FormGroup;

  @ViewChild('validationDialog') validationDialog : TemplateRef<any>;

  constructor(private fb: FormBuilder, private router: Router, private labService: LabsService, private dialog: MatDialog) {
    this.labOrderState = <LabOrderState>history.state ?? <LabOrderState>(
      this.router.getCurrentNavigation().extras.state
    );
    this.configOptions = this.labOrderState.commonAdditonalTestsOptions;
    this.form = this.fb.group({
      additionalTests: this.fb.group({
        ...this.configOptions?.reduce(
          (z, config) => ({
            ...z,
            [config.id]: false,
          }),
          {}
        ),
      }),
      standardLabPanels: [null, Validators.required],
      manualPanel: null,
      nonStandardLabTests: this.fb.array([])
    });
  }

  ngOnInit(): void {
    this.labService.getNonStandardPanelOptions().subscribe(
      (nonStandardPanels: NonStandardPanel[]) => {
        this.nonStandardPanelOptions = nonStandardPanels;
        nonStandardPanels.forEach(nonStandardPanel => {
          this.manualPanelOptions.push({
            "id": nonStandardPanel.labCorpPanelNumber,
            "name": nonStandardPanel.labCorpPanelNumber.toString(),
          });
        });
        if (this.labOrderState.labRequisitionData) {
          this.labOrderState.labRequisitionData.nonStandardTests.split(',').forEach(manualPanelNumber => {
            this.setNonStandardLabTest(Number(manualPanelNumber));
          });
        }
      });
    //Getting data passed through router state
    if (this.labOrderState) {
      this.form.patchValue({
        standardLabPanels: this.labOrderState.standardPanelid?.length > 0 ? this.labOrderState.standardPanelid: null,
        additionalTests: this.getSelectedAdditionalTests(),
      });
    }
  }

  getSelectedAdditionalTests() {
    const selectedAdditionalTests: number[] = this.labOrderState.commonAdditionalTests
      ?.split(',')
      .map((i) => Number(i));
    const additionalTests = this.configOptions?.reduce(
      (z, config) => ({
        ...z,
        [config.id]: selectedAdditionalTests.includes(+config.id),
      }),
      {}
    );
    return additionalTests;
  }

  get nonStandardLabTests() : FormArray {
    return this.form.get("nonStandardLabTests") as FormArray
  }

  addManualPanel() {
    let message = null;
    if(!this.form.value.manualPanel) {
      message = 'Please select a panel number to add';
    }
    else if(this.form.value.nonStandardLabTests.find(
      (item: NonStandardPanel) => item.labCorpPanelNumber === this.form.value.manualPanel))  {
      message = 'Selected  panel number already added';
    }
    else {
      this.setNonStandardLabTest(this.form.value.manualPanel);
    }
    if(message) {
      this.dialog.open (this.validationDialog, {
        data: { message: message }
      });
    }
  }

  removeManualPanel(index : number): void{
    this.nonStandardLabTests.removeAt(index);
  }

  setNonStandardLabTest(manualPanelNumber: number): void {
    let nonStandardPanel = this.nonStandardPanelOptions.find((item: NonStandardPanel) => item.labCorpPanelNumber == manualPanelNumber );
    if (nonStandardPanel) {
      this.nonStandardLabTests.push(
        this.fb.group({
          labCorpPanelNumber: [nonStandardPanel.labCorpPanelNumber],
          labCorpTestName:[nonStandardPanel.labCorpTestName]
        })
      );
    }
  }

}
