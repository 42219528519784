import { filter, Observable } from 'rxjs';

import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import { User } from '@config/types';
import { NavigationService } from '@core/services/navigation.service';
import { UserControllerService } from '@core/services/user-controller.service';
import { UserState } from '@core/state/user.state';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent {
  date = new Date();
  appRoutes = APP_ROUTES;
  dashboardView = true;
  patientView = false;

  @Select(UserState.getUser) user$: Observable<User>;

  constructor(
    public navigationService: NavigationService,
    private router: Router,
    private userControllerSerivice: UserControllerService,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((response: NavigationEnd) => {
        if (this.navigationService.isDashboardUrl(response.url)) {
          this.dashboardView = true;
          this.patientView = false;
        } else if (this.navigationService.isPatientUrl(response.url)) {
          this.dashboardView = false;
          this.patientView = true;
        }
      });
  }

  logout() {
    this.userControllerSerivice.logout();
  }

  viewPatientAppointments() {
    this.navigationService.goToDashboard();
  }
}
