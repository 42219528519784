import { Injectable } from '@angular/core';

/*
    Wrapper service over cookieService for storing objects. Do not store larger key/values
*/

@Injectable()
export class StorageService {
  constructor() {}

  setItem(key: string, value: string){
      sessionStorage.setItem(key, value);
  }

  getItem(key): string{
     return sessionStorage.getItem(key);
  }

  removeItem(key): void{
      if (this.getItem(key)) {
        sessionStorage.removeItem(key);
      }
  }

  removeAll(): void{
      sessionStorage.clear();
  }
}
