import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RadioButtonSetService } from './radio-button-set.service';

export const YES_VALUE = 'Yes';
export const NO_VALUE = 'No';

@Component({
  selector: 'app-ui-radio-button-set',
  templateUrl: './radio-button-set.component.html',
  styleUrls: ['./radio-button-set.component.scss'],
})
export class RadioButtonSetComponent implements OnInit {
  // options is either a 2D array (Array of Arrays) that contain
  // Strings and/or Numbers or a flat Array of Strings or Numbers
  options: Array<(string | number | boolean)[]> | (string | number | boolean)[];

  @Input() type: string;        // input the type from parent
  @Input() parent: FormGroup;
  @Input() control: string;
  @Input() labels: boolean;
  @Input() label: string;
  @Input() line = false;
  // allow for custom options to be supplied via input binding
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('options') inputOptions: InputOptions[];
  @Input() fullWidth = false;
  @Input() align: string;
  @Input() padding: string;
  highValue = 10;
  value = 1;

  constructor(
    private rbs: RadioButtonSetService,
  ) { }

  ngOnInit() {
    this.labels = this.labels !== undefined;
    this.align = this.align || 'left';
    this.padding = this.padding || '';
    this.load();
  }

  output(value: any, i: number) {
    return Array.isArray(value) ? value[i] : value;
  }

  load(): void {
    if (this.inputOptions) {
      // convert HHOptions for radio-button-set ingestion
      this.options = this.inputOptions.map((c: InputOptions) => [c.id, c.name]);
    } else {
      // grab data from our rbs service's load method using type input
      this.options = this.rbs.load(this.type);
    }
  }
}

interface InputOptions {
  id: string;
  name: string;
}
