import { ObjectiveField } from './';

export interface ReportTypeInfo {
    data: ReportType[];
}

export interface ReportType {
    id: string;
    name: string;
    displayOrder: number;
}

export interface ReportTypeSectionInfo {
    data: ReportTypeSection[];
}

export interface ReportTypeSection {
    id: number;
    displayName: string;
    name: string;
    displayOrder: number;
    subSections: ReportTypeSubSection[]
}

export interface ReportTypeSubSection {
    id: number;
    displayName: string;
    name: string;
    displayOrder: number;
}

export interface ReportTypeFieldsInfo {
    data: ReportTypeFields[];
}

export interface ReportTypeFields {
    id: number;
    sectionId: number;
    subSectionId: number;
    name: string;
    displayName: string;
    additionalDisplayName: string;
    displayOrder: number;
    hasOtherText: boolean;
    isTextSelection: boolean;
    dataType: boolean;
    subFields: ReportTypeFields[];
}

export class SubProcedurePostData {
    assessmentPlanFields: ObjectiveField[];
    reportID: string;
    sectionID: string;
    subSectionID: string;
}
