<h2 class="form-heading">Vision</h2>
<form [formGroup]="form" class="visionForm" (ngSubmit)="saveForm()">
    <p class="form-subheading">Without Correction</p>
    <mat-divider></mat-divider>
    <div class="vision-inner">
        <p class="form-subheading">Near vision</p>
        <div class="vision-inputs-group">
            <p class="custom-label">Right</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="nearRightNumeratorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="nearRightDenominatorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
        <div class="vision-inputs-group">
            <p class="custom-label">Left</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="nearLeftNumeratorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="nearLeftDenominatorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
        <div class="vision-inputs-group">
            <p class="custom-label">Both</p>
            <div>
                <div class="input-xs">
                    <mat-form-field>
                        <input matInput formControlName="nearBothNumeratorU" appInteger minVal="-999" maxVal="999">
                    </mat-form-field>
                    <span class="fraction-bar">/</span>
                    <mat-form-field>
                        <input matInput formControlName="nearBothDenominatorU" appInteger minVal="-999" maxVal="999">
                    </mat-form-field>
                </div>
            </div>
        </div>

        <p class="form-subheading">Far vision</p>
        <div class="vision-inputs-group">
            <p class="custom-label">Right</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="farRightNumeratorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="farRightDenominatorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
        <div class="vision-inputs-group">
            <p class="custom-label">Left</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="farLeftNumeratorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="farLeftDenominatorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
        <div class="vision-inputs-group">
            <p class="custom-label">Both</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="farBothNumeratorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="farBothDenominatorU" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
    </div>

    <p class="form-subheading">With Correction</p>
    <mat-divider></mat-divider>
    <div class="vision-inner">
        <p class="form-subheading">Near vision</p>
        <div class="vision-inputs-group">
            <p class="custom-label">Right</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="nearRightNumeratorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="nearRightDenominatorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
        <div class="vision-inputs-group">
            <p class="custom-label">Left</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="nearLeftNumeratorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="nearLeftDenominatorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
        <div class="vision-inputs-group">
            <p class="custom-label">Both</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="nearBothNumeratorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="nearBothDenominatorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>

        <p class="form-subheading">Far vision</p>
        <div class="vision-inputs-group">
            <p class="custom-label">Right</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="farRightNumeratorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="farRightDenominatorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
        <div class="vision-inputs-group">
            <p class="custom-label">Left</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="farLeftNumeratorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="farLeftDenominatorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
        <div class="vision-inputs-group">
            <p class="custom-label">Both</p>
            <div class="input-xs">
                <mat-form-field>
                    <input matInput formControlName="farBothNumeratorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
                <span class="fraction-bar">/</span>
                <mat-form-field>
                    <input matInput formControlName="farBothDenominatorC" appInteger minVal="-999" maxVal="999">
                </mat-form-field>
            </div>
        </div>
    </div>
    <div>
      <div>&nbsp;</div>
      <mat-divider></mat-divider>
      <div class="vision-inner">
        <p class="form-subheading">Color Vision</p>
        <div class="input-xs">
            <mat-form-field>
                <input matInput formControlName="colorVisionNumerator" appInteger minVal="-9" maxVal="99" positiveValueOnly="true">
            </mat-form-field>
            <span class="fraction-bar">/</span>
            <mat-form-field>
                <input matInput formControlName="colorVisionDenominator" appInteger minVal="-9" maxVal="99" positiveValueOnly="true">
            </mat-form-field>
        </div>
      </div>
    </div>
    <div>
        <mat-radio-group aria-label="Select an option" formControlName="visionDeclined" color="primary">
            <mat-radio-button [value]="false">Vision Done / Reviewed</mat-radio-button>
            <mat-radio-button [value]="true">Vision Not Done / Declined</mat-radio-button>
        </mat-radio-group>
    </div>
</form>
