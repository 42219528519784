<form [formGroup]="clinicalNotesForm">
  <div class="notes-wrapper">
    <label class="uppercase">Clinical Notes</label>
    <mat-form-field>
      <span class="formfield-header">These notes will never be shared with the patient</span>
      <textarea id="clinical-notes" formControlName="notes" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        placeholder="Type here" matInput></textarea>
      <mat-hint align="end">{{saveIndicator}} <mat-icon *ngIf="showMark">done</mat-icon>
      </mat-hint>
    </mat-form-field>
  </div>
</form>