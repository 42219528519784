<div class="input-select-lg" [formGroup]="specialty">
    <div class="input-autocomplete">
        <mat-form-field>
            <mat-label>Specialty</mat-label>
            <input 
                type="text" 
                aria-label="Specialty" 
                matInput 
                [matAutocomplete]="auto"
                formControlName="name"
            >
            <mat-autocomplete panelWidth="350" autoActiveFirstOption #auto="matAutocomplete">
                <mat-option 
                    *ngFor="let option of filteredOptions | async" 
                    [value]="option.name"
                >
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>
