import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@config/types';
import { CoreLookupService } from '@core/services/core-lookup.service';
import { UpdateAppointmentExam } from '@core/state/appointments.actions';
import { UserState } from '@core/state/user.state';
import { Select, Store } from '@ngxs/store';

export interface GetExamParams {
    appointmentID: string;
    facilityID: string;
    epmsPID: string;
    userID: string;
    procedureID: number;
}

// Deprecated as of 11/21, use PatientService Instead, via PatientControllerService
@Injectable()
export class PatientExamService {
    @Select(UserState.getUser) user$: Observable<User>;

    // private _prefillData$: BehaviorSubject<ExamPrefillData> = new BehaviorSubject<ExamPrefillData>(null);
    // private _appointment$: Observable<Appointment>;

    constructor(
        private http: HttpClient,
        private config: AppConfigService,
        private store: Store,
        // private storageService: StorageService
        private coreLookupService: CoreLookupService,
        private route: ActivatedRoute,
    ) {

    }

  /**
   * getExam was previously named "getReport"
   *
   * Send a POST call to check for exam prior to opening patient chart
   * Moved from patient chart service w/ resolver to being used in general state
   *
   * @param appointmentID   Available in appointments$
   * @param facilityID  Available in appointments$.appointmentFacility.id
   * @param epmsPID   Todo capture
   * @returns ExamResponse
   */
    // createOrGetExam(appointment: AppointmentDetail): Observable<Exam> {
    //     const body = { notes: '' };
    //     return this.coreLookupService.getMedicalReportTypeId({procedureId: appointment.procedureID})
    //       .pipe(
    //         withLatestFrom(this.user$),
    //         switchMap(([reportTypeData, user]: [[LookupOption], User]) => {
    //           const reportTypeID = reportTypeData[0].id;
    //           return this.http.post<ExamResponse>(
    //               this.config.ENDPOINTS.createOrGetExam,
    //               body,
    //               {
    //                   params: {
    //                       appointmentID: appointment.appointmentID.toString(),
    //                       procedureID: appointment.procedureID.toString(),
    //                       reportTypeID: reportTypeID.toString(),
    //                       epmsPID: appointment?.patient?.epmsPID.toString(),
    //                       userID: user.adminid.toString(),
    //                   }
    //               }
    //           );
    //         }),
    //         map(response => response.data)
    //       );
    // }

    // setAppointment(appointment$: Observable<Appointment>) {
    //     this._appointment$ = appointment$;
    // }

    refreshExam(appointmentID: number) {
      this.store.dispatch(new UpdateAppointmentExam({
        appointmentID
      }));
    }

    getAppointmentIdFromRoute(): string {
      return this.route.snapshot.paramMap.get('appointmentID');
    }
    // refreshExam() {
    //     // To update prefill data
    //     const appointmentDate = this.storageService.getItem('appointmentDate');
    //     const userID = this.storageService.getItem('user');
    //     this._appointment$.subscribe(appointment => {
    //         this.getExam({
    //             appointmentID: `${appointment.appointmentID}`,
    //             facilityID: `${appointment.appointmentFacility.id}`,
    //             epmsPID: `${appointment.patient.epmsPID}`,
    //             userID,
    //             procedureID: `${appointment.procedureID}`
    //         }).subscribe((response: ExamResponse) => {
    //             const exam = response.data;
    //             this.setPrefillData({
    //                 objectives: exam.objectives,
    //                 assessmentPlans: exam.assessmentPlans
    //             });
    //         });
    //     });
    // }


    // // prefill data
    // getPrefillData(): Observable<ExamPrefillData> {
    //     return this._prefillData$.asObservable().pipe(
    //         tap(prefillData => console.log('prefill: ' + prefillData)),
    //         filter(prefillData => prefillData !== null),
    //         debounceTime(AppUtils.DEBOUNCE_TIME),
    //         tap(prefillData => console.log('in prefillData pipe w/ value', prefillData)), // looks like PE, fires on Create?
    //         distinctUntilChanged((x, y) => x.assessmentPlans.toString() === y.assessmentPlans.toString() &&
    //             x.objectives.toString() === y.objectives.toString()
    //         )
    //     );
    // }

    // setPrefillData(value: ExamPrefillData): void {
    //     this._prefillData$.next(value);
    // }

    // clearPrefillData(): void {
    //     this._prefillData$.next(null);
    // }
}
