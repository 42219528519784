import { HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CUSTOM_BODY_CONTEXT_TOKEN,
  CUSTOM_QUERY_PARAMS_CONTEXT_TOKEN,
  SetParamTokensInHttpContextParams
} from '@config/types';


// https://angular.io/api/common/http/HttpContext
@Injectable()
export class HttpContextHelperService {
  setParamTokensInHttpContext(params: SetParamTokensInHttpContextParams): {context?: HttpContext} {
    const contextTokens = new HttpContext();
    if (Array.isArray(params.body) && params.body.length > 0 ) {
      contextTokens.set(CUSTOM_BODY_CONTEXT_TOKEN, params.body);
    }
    if (Array.isArray(params.queryParams) && params.queryParams.length > 0) {
      contextTokens.set(CUSTOM_QUERY_PARAMS_CONTEXT_TOKEN, params.queryParams);
    }
    const newContext = {
      context: contextTokens
    };
    console.log('newContext', newContext);
    return newContext;
  }
}
