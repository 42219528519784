<div class="pap-request column">
    <mat-toolbar class="header" >
        <mat-toolbar-row>
            <span >Pap Requisition</span> 
            <span class="flex-spacer"></span>
            <button  class="backbtn" mat-raised-button (click)="navigateToRequests()">Back</button>  
        </mat-toolbar-row>
    </mat-toolbar>
    <app-exam-info class="padding-vertical"></app-exam-info>
    <app-req-details class="padding-vertical"></app-req-details>
    <app-cytology-details class="padding-vertical"></app-cytology-details>
    <app-pap-order class="padding-vertical"></app-pap-order>
   
    <button [disabled]="isInvalidForm" mat-raised-button color="primary" (click)="createPapOrder()">Save Order</button>
    <mat-divider></mat-divider>
</div>
