import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

export interface IFormControlsData {
  keyName: string;
  initialValue?: any;
  validators?: [];
  subFormGroup?: {
    formControls: IFormControlsData[];
  };
  formControl?: FormControl;
}

export class FormUtils {

  public static createFormGroup(fb: FormBuilder, formControlsData: IFormControlsData[]): FormGroup {
    if (fb === undefined || formControlsData.length <= 0) { return; }

    const dict = {};
    formControlsData.map(formControlData => {
      const key: string = formControlData.keyName;
      let value: any = [formControlData.initialValue, formControlData.validators];
      value = formControlData.formControl || value;
      if (formControlData.subFormGroup){
        value = FormUtils.createFormGroup(new FormBuilder(), formControlData.subFormGroup.formControls);
      }
      dict[key] = value;
    }
    );
    return fb.group(dict);
  }
}

// Can have form groups created with static form controls
class PageFormControls{

}

// Class for Page specific form groups with form controls
export class CreatePageFormControls extends PageFormControls{

  static recommended: IFormControlsData[] = [
    {keyName: 'papOption', initialValue: null, validators: [], subFormGroup: null},
    {keyName: 'mammoOption', initialValue: null, validators: [], subFormGroup: null},
    {keyName: 'corCalScanOption', initialValue: null, validators: [], subFormGroup: null},
    {keyName: 'colonoscopyOption', initialValue: false, validators: [], subFormGroup: null},
    {keyName: 'fitOption', initialValue: false, validators: [], subFormGroup: null},
    {keyName: 'vascStudyOption', initialValue: false, validators: [], subFormGroup: null},
    {keyName: 'lungCTOption', initialValue: false, validators: [], subFormGroup: null},
    {keyName: 'abdominalUltrasoundOption', initialValue: false, validators: [], subFormGroup: null},
    {keyName: 'dexaOption', initialValue: false, validators: [], subFormGroup: null},
    {keyName: 'otherValue', initialValue: null, validators: null, formControl: new FormControl(''), subFormGroup: null}
  ];

  static biometrics: IFormControlsData[] = [
    {keyName: 'bmiOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'bodyfatOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'waistOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'bpOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'vO2Option', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'biometricsOtherValue', initialValue: '', validators: null, formControl: new FormControl(''), subFormGroup: null}
  ];

  static labs: IFormControlsData[] = [
    {keyName: 'hemoglobinOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'lipidsOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'reactiveProteinOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'psaOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'anemiaOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'lftOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'hepatitisOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'stdOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'papOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'hpvOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'urineOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'labsOption', initialValue: null, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'labsOtherValue', initialValue: '', validators: null, formControl: new FormControl(''), subFormGroup: null}
  ];

  static screenings: IFormControlsData[] = [
    {keyName: 'ascvdHigh', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'breastCancerHigh', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'skinCancer', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'oralHealth', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'ekgAbnormal', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'visionDeficit', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'hearingDeficit', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'screeningsOtherValue', initialValue: '', validators: null, formControl: new FormControl(''), subFormGroup: null}
  ];

  static immunity: IFormControlsData[] = [
    {keyName: 'mmr', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'hepA', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'hepB', initialValue: false, validators: null, formControl: null, subFormGroup: null}
  ];

  static vaccines: IFormControlsData[] = [
    {keyName: 'fluGiven', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'fluDeclined', initialValue: false, validators: null, formControl: null, subFormGroup: null},
    {keyName: 'fluUpToDate', initialValue: false, validators: null, formControl: null, subFormGroup: null}
  ];

  static summary: IFormControlsData[] = [
    {keyName: 'summaryValue', initialValue: '', validators: null, formControl: null, subFormGroup: null}
  ];

  static physician: IFormControlsData[] = [
    {keyName: 'physicianValue', initialValue: '', validators: null, formControl: null, subFormGroup: null}
  ];

}


