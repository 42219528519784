import { IParamsRUE, IParamsUserAndEpms, IWithMessage } from '@config/types';

// ASCI Art: https://patorjk.com/software/taag/#p=display&f=Univers&t=EKG

// 88888888888  88      a8P   ,ad8888ba,
// 88           88    ,88'   d8"'    `"8b
// 88           88  ,88"    d8'
// 88aaaaa      88,d88'     88
// 88"""""      8888"88,    88      88888
// 88           88P   Y8b   Y8,        88
// 88           88     "88,  Y8a.    .a88
// 88888888888  88       Y8b  `"Y88888P"

export interface EkgResultsResponse extends IWithMessage {
  data: EkgResultsDTO;
}

// GET /provider/user/ekg -- getEKGResults
export interface GetEkgResultsRequestParams extends IParamsRUE {}
export interface GetEkgResultsResponse extends EkgResultsResponse {}
// POST /provider/user/ekg -- setEKGResults
export interface SetEkgResultsRequestParams extends IParamsUserAndEpms {}
export interface SetEkgResultsRequestBody extends EkgResultsDTO {}
export interface SetEkgResultsResponse extends EkgResultsResponse {}

// 88        88                                      88
// 88        88                                      ""
// 88        88
// 88aaaaaaaa88   ,adPPYba,  ,adPPYYba,  8b,dPPYba,  88  8b,dPPYba,    ,adPPYb,d8
// 88""""""""88  a8P_____88  ""     `Y8  88P'   "Y8  88  88P'   `"8a  a8"    `Y88
// 88        88  8PP"""""""  ,adPPPPP88  88          88  88       88  8b       88
// 88        88  "8b,   ,aa  88,    ,88  88          88  88       88  "8a,   ,d88
// 88        88   `"Ybbd8"'  `"8bbdP"Y8  88          88  88       88   `"YbbdP"Y8
//                                                                     aa,    ,88
//                                                                      "Y8bbdP"

export interface HearingResultsResponse extends IWithMessage {
  data: HearingResultsDTO;
}

// GET /provider/user/hearing -- getHearingResults
export interface GetHearingResultsRequestParams extends IParamsRUE {}
export interface GetHearingResultsResponse extends HearingResultsResponse {}
// POST /provider/user/hearing -- setHearingResults
export interface SetHearingResultsRequestParams extends IParamsUserAndEpms {}
export interface SetHearingResultsRequestBody extends HearingResultsDTO {}
export interface SetHearingResultsResponse extends HearingResultsResponse {}

// 88888888ba   88             88
// 88      "8b  ""             88
// 88      ,8P                 88
// 88aaaaaa8P'  88  ,adPPYba,  88   ,d8
// 88""""88'    88  I8[    ""  88 ,a8"
// 88    `8b    88   `"Y8ba,   8888[
// 88     `8b   88  aa    ]8I  88`"Yba,
// 88      `8b  88  `"YbbdP"'  88   `Y8a

export interface RiskScoresResponse extends IWithMessage {
  data: RiskScoresResultsDTO;
}

// GET /provider/user/risk-scores -- getRiskScoresResults
export interface GetRiskScoresResultsRequestParams extends IParamsRUE {}
export interface GetRiskScoresResultsResponse extends RiskScoresResponse {}
// POST /provider/user/risk-scores -- setHearingResults
export interface SetRiskScoresResultsRequestParams extends IParamsUserAndEpms {}
export interface SetRiskScoresResutlsRequestBody extends RiskScoresResultsDTO {}
export interface SetRiskScoresResultsResponse extends RiskScoresResponse {}

// 8b           d8  88             88
// `8b         d8'  ""             ""
//  `8b       d8'
//   `8b     d8'    88  ,adPPYba,  88   ,adPPYba,   8b,dPPYba,
//    `8b   d8'     88  I8[    ""  88  a8"     "8a  88P'   `"8a
//     `8b d8'      88   `"Y8ba,   88  8b       d8  88       88
//      `888'       88  aa    ]8I  88  "8a,   ,a8"  88       88
//       `8'        88  `"YbbdP"'  88   `"YbbdP"'   88       88

export interface VisionResultsResponse extends IWithMessage {
  data: VisionResultsDTO;
}

// GET /provider/user/vision -- getVisionResults
export interface GetVisionResultsRequestParams extends IParamsRUE {}
export interface GetVisionResultsResponse extends VisionResultsResponse {}
// POST /provider/user/vision -- setVisionResults
export interface SetVisionResultsRequestParams extends IParamsUserAndEpms {}
export interface SetVisionResultsRequestBody extends VisionResultsDTO {}
export interface SetVisionResultsResponse extends VisionResultsResponse {}

// 8b           d8  88                       88
// `8b         d8'  ""    ,d                 88
//  `8b       d8'         88                 88
//   `8b     d8'    88  MM88MMM  ,adPPYYba,  88  ,adPPYba,
//    `8b   d8'     88    88     ""     `Y8  88  I8[    ""
//     `8b d8'      88    88     ,adPPPPP88  88   `"Y8ba,
//      `888'       88    88,    88,    ,88  88  aa    ]8I
//       `8'        88    "Y888  `"8bbdP"Y8  88  `"YbbdP"'

export interface VitalsResultResponse extends IWithMessage {
  data: VitalsResultsDTO;
}

// GET /provider/user/vitals -- getVitalsResults
export interface GetVitalsResultsRequestParams extends IParamsRUE { }
export interface GetVitalsResultsResponse extends VitalsResultResponse {}
// POST /provider/user/vitals -- setVitalsResults
export interface SetVitalsResultsRequestParams extends IParamsUserAndEpms { }
export interface SetVitalsResultsRequestBody extends VitalsResultResponse {}
export interface SetVitalsResultsResponse extends VitalsResultResponse {}


export interface RiskScoresResponse extends IWithMessage {
  data: RiskScoresResultsDTO;
}
// GET /provider/user/risk-scores -- getRiskScoreResults
export interface GetRiskScoresResultsRequestParams extends IParamsRUE {}
export interface GetRiskScoresResultsResponse extends RiskScoresResponse {}
// POST /provider/user/risk-scores -- setRiskScoreResults
export interface SetRiskScoresResultsRequestParams extends IParamsRUE {}
export interface SetRiskScoresResultsResponse extends RiskScoresResponse {}

// 88b           d88                        88              88
// 888b         d888                        88              88
// 88`8b       d8'88                        88              88
// 88 `8b     d8' 88   ,adPPYba,    ,adPPYb,88   ,adPPYba,  88  ,adPPYba,
// 88  `8b   d8'  88  a8"     "8a  a8"    `Y88  a8P_____88  88  I8[""
// 88   `8b d8'   88  8b       d8  8b       88  8PP"""""""  88   `"Y8ba,
// 88    `888'    88  "8a,   ,a8"  "8a,   ,d88  "8b,   ,aa  88  aa    ]8I
// 88     `8'     88   `"YbbdP"'    `"8bbdP"Y8   `"Ybbd8"'  88  `"YbbdP"'

export interface EkgResultsDTO  {
  ekgDeclined: boolean;
  ekgUploaded: boolean;
  id: number;
  reportID?: number;
}

export interface HearingResultsDTO {
  hearingDeclined: boolean;
  hearingUploaded: boolean;
  id: number;
  left1khz: number;
  left250hz: number;
  left2khz: number;
  left3khz: number;
  left4khz: number;
  left500hz: number;
  left6khz: number;
  left8khz: number;
  reportID?: number;
  right1khz: number;
  right250hz: number;
  right2khz: number;
  right3khz: number;
  right4khz: number;
  right500hz: number;
  right6khz: number;
  right8khz: number;
}

export interface RiskScoresResultsDTO {
  ascvdRisk: number;
  breastCancerRisk: number;
  id: number;
  reportID?: number;
}

export interface VisionResultsDTO {
  colorVisionDenominator: number;
  colorVisionNumerator: number;
  farBothDenominatorC: number;
  farBothDenominatorU: number;
  farBothNumeratorC: number;
  farBothNumeratorU: number;
  farLeftDenominatorC: number;
  farLeftDenominatorU: number;
  farLeftNumeratorC: number;
  farLeftNumeratorU: number;
  farRightDenominatorC: number;
  farRightDenominatorU: number;
  farRightNumeratorC: number;
  farRightNumeratorU: number;
  id: number;
  nearBothDenominatorC: number;
  nearBothDenominatorU: number;
  nearBothNumeratorC: number;
  nearBothNumeratorU: number;
  nearLeftDenominatorC: number;
  nearLeftDenominatorU: number;
  nearLeftNumeratorC: number;
  nearLeftNumeratorU: number;
  nearRightDenominatorC: number;
  nearRightDenominatorU: number;
  nearRightNumeratorC: number;
  nearRightNumeratorU: number;
  reportID?: number;
  visionDeclined: boolean;
}

export interface VitalsResultsDTO {
  bloodPressure: string;
  bloodPressureAbnormal: boolean;
  bmi: number;
  bmiAbnormal: boolean;
  bodyFat: number;
  bpDiastolic: number;
  bpPositionId: number;
  bpSide: string;
  bpSystolic: number;
  height: number;
  id: number;
  notes: string;
  pulse: number;
  repeatDiastolic: number;
  repeatPositionId: number;
  repeatSide: number;
  repeatSystolic: number;
  reportID?: number;
  vo2LookupId: number;
  vo2LookupName: string;
  waistCircumference: number;
  weight: number;
}

export interface RiskScoresResultsDTO {
  ascvdRisk: number;
  breastCancerRisk: number;
  id: number;
  reportID?: number;
}
