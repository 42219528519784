import { Pipe, PipeTransform } from '@angular/core';

export enum SortOrder {
  ASC = 1,
  DESC = -1,
}

/**
 *
 * @usageNotes
 *
 * Sort data properties in ascending or descending fashion
 * Nested properties of the data array are exposed via the path variable
 *
 * @example
 * ```ts
 * export class ChildComponent  {
 *    options: LookupOption[];
 *    path: string[] = ['name'];
 *    order: number = 1;
 *
 *    ngOnInit() {
 *    this.options = this.exampleDataService.getSomeOptions();
 *    }
 * }
 *```
 * ```html
 *
 *  <select [(ngModel)]="model">
 *     <option *ngFor="let option of options | sortData:path:order" [value]="option.id">
 *      {{option.name}}
 *     </option>
 *  </select>
 * ```
 */
@Pipe({
  name: 'sortData',
})
export class SortDataPipe implements PipeTransform {
  /**
   * @param on
   * @param data
   * Can be `LookupOptions`, `Appointments`, `Patient`,' etc.
   * @param path
   * To be defined within the component using the pipe
   * @param order
   * Default is 1(SortOrder.ASC)
   */
  transform(data, on?: boolean, path?: string[], order?: SortOrder) {
    // Check if data is empty since pipe will be executed prior to getting the data
    if (!on || !data || !path || !order) {
      return data;
    }
    return data.sort((a, b) => {
      path.forEach((property) => {
        a = a[property];
        b = b[property];
      });

      return a > b ? order : order * SortOrder.DESC;
    });
  }
}
