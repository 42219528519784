<div class="dashboard-wrapper">
<h2>State Experiments</h2>

<div>
  <mat-form-field>
    <mat-select [formControl]="ctrlProcedureId">
    <mat-label>Procedure ID</mat-label>
      <mat-option *ngFor="let procedureId of procedureIds" [value]="procedureId">
        {{procedureId}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<button (click)="getProcedureId(ctrlProcedureId.value)">Get Result</button>

<div>
  ProcedureId: <strong>{{ctrlProcedureId.value}}</strong>
</div>

<div>
Result: <strong>{{result}}</strong>
</div>

<pre>
  state: {{state$ | async | json}}


  appointments: {{appointments$ | async | json}}
</pre>
</div>
