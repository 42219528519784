import { AuthToken, User } from '@config/types';

export interface AuthorizationDetail {
  user: User;
  token: AuthToken;
}

export interface AuthorizationResponse {
  data: AuthorizationDetail;
}
