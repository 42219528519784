<div class="labs-wrapper">
    <div *ngIf="loading">Loading...</div>
    <div class="labs-info-msg" *ngIf="!labs && !loading">Lab results will appear here when ready.
    </div>
    <div *ngFor="let lab of labs" class="labs-table">
        <div class="lab-info">
            <ul>
                <li>Lab Number: {{lab.labRequisitionDTO.requisitionID}}</li>
                <li>Date: {{lab.labRequisitionDTO.specimenCollectionDate}}</li>
                <li>Time: {{lab.labRequisitionDTO.collectionDate | date: 'shortTime':'GMT'}}</li>
                <li>Fasting: {{fastingDisplayName(lab.labRequisitionDTO)}} </li>
            </ul>
        </div>

        <div class="mat-table" *ngIf="lab.resultGroups.length; else noResults">
            <div class="mat-header-row">
                <div class="mat-header-cell" *ngFor="let header of headers">{{header}}</div>
            </div>
            <div *ngFor="let resultGroup of lab.resultGroups">
                <div class="mat-row"><b>{{resultGroup.panelDescription}}</b></div>
                <div *ngFor="let result of resultGroup.resultsDtoList">
                    <div class="mat-row">
                        <div class="mat-cell">{{ result.description }}</div>
                        <div class="mat-cell" id="inRange">{{ result.inRange ? result.observationValue : '' }}</div>
                        <div class="mat-cell cell-warning" id="outRange">{{ !result.inRange ? result.observationValue :
                            '' }}</div>
                        <div class="mat-cell">{{result.referenceRange}}</div>
                        <div class="mat-cell">{{ result.abnormalFlag}}</div>
                        <div class="mat-cell">{{ result.units}}</div>
                        <div class="mat-cell">{{ result.status}}</div>
                    </div>
                    <div class="mat-row" *ngIf="result.comments">
                        <div class="mat-cell cell-comment" [innerHTML]="result.comments"></div>
                    </div>
                </div>
            </div>
            <div>
                <form [formGroup]="form">
                    <h3>Significant Abnormal Labs</h3>
                        <mat-radio-group formControlName="abnormalLabs">
                          <div class="padding-vertical">
                            <mat-radio-button value="noAbnormalLabs">No abnormal labs</mat-radio-button>
                          </div>
                          <mat-radio-button value="hasAbnormalLabs">Patient has significant abnormal labs requiring immediate attention</mat-radio-button>
                          <div class="input-75">
                            <app-textarea-control
                              label="Please document your interactions with the patient, with dates"
                              [control]="form.get('abnormalLabsDescription')"
                            ></app-textarea-control>
                          </div>
                        </mat-radio-group>
                    <button mat-flat-button class="primary save-button" (click)="saveForm()">Save</button>
                </form>
            </div>
        </div>
        <ng-template #noResults>
            <p>No results found.</p>
        </ng-template>
    </div>
        <button [disabled]="!statusMDLabsSignOffAllowed || !isUserMD"  mat-flat-button class="primary save-button" (click)="labSignOff()">MD Labs Sign-Off</button>
</div>
