import { Pipe, PipeTransform } from '@angular/core';
import { Patient } from '@config/types';

@Pipe({
  name: 'patientPhone',
})
export class PatientPhonePipe implements PipeTransform {
  transform(patient: Patient): string {
    const defaultValue = 'N/A';
    if (!patient) {
      return defaultValue;
    }
    return (
      patient.phone || patient.cellPhone || patient.eveningPhone || defaultValue
    );
  }
}
