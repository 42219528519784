export class AppUtils {
  static DEBUG = false;

  static DEBOUNCE_TIME = 100;
  static DELAY_CONSECUTIVE_REQUESTS = 250;
  /**
   *
   * @param weight  Param for date string to be calculated.
   * @param number  Param for date string to be calculated.
   *
   * @return    The calculated BMI
   *
   * For Imperial
   * Formula: 703 x weight (lbs) / [height (in)]^2
   * When using Imperial measurements, pounds should be divided by inches squared
   * This should then be multiplied by 703 to convert from lbs/inches^2 to kg/m^2.
   * Source: 'https://www.cdc.gov/'
   */
  public static calculateBMI(weight: number, height: number) {
    return ((703 * weight) / Math.pow(height, 2)).toFixed(2);
  }

  /**
   * Convert date string eg. Wed Dec 16 2020 00:00:00 GMT-0500 (Eastern Standard Time)
   * to YYYY-MM-DD format expected by the database
   * @param input Date string
   */
  public static getFormattedDate(date: string): string {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();

    let month = (1 + inputDate.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = inputDate.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return year + '-' + month + '-' + day;
  }

  /**
   * Convert YYYY-MM-DD date to date string eg. Wed Dec 16 2020 00:00:00 GMT-0500 (Eastern Standard Time)
   * setting date in YYYY-MM-DD format will result in parsed date being one day behind
   * because of timezone difference, this parses it resetting the time to 00:00:00
   * and fixes timezone issue
   * @param input string
   */
  public static getFullDate(date: string) {
    const dateArr = date.split('-');
    const [year, month, day] = dateArr;
    const fullDate = new Date(Number(year), Number(month) - 1, Number(day));

    return fullDate;
  }

  public static zeroPad(x: number, padSize: number = 2): string {
    return `${'0'.repeat(padSize)}${x}`.slice(-padSize);
  }

  public static capitalize(input: string, preserveOtherCase = false): string {
    const otherChars = preserveOtherCase
      ? input.substr(1)
      : input.substr(1).toLowerCase();
    return `${input[0].toUpperCase()}${otherChars}`;
  }
}
