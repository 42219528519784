import { forkJoin, tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { AppointmentDetail, AppointmentTypes, ExamReviewDTO, PatientAppointmentDTO } from '@config/types';
import { AppointmentsService } from '@core/services/appointments.service';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';

import {
    ForgetAllAppointments, GetAllAppointments, GetInProgressAppointments,
    GetNeedsReviewAppointments, GetToDoAppointments, RefreshSelectedAppointment, SubmitNoShow,
    UpdateAppointmentExam
} from './appointments.actions';
import { AppUtils } from '@shared/utils/app.utils';

export interface AppointmentLookupObject {
  [id: number]: AppointmentDetail;
}

export interface UpdateAppointmentExamParams {
  appointmentID: number;
}
export interface SelectedAppointmentLookupIds {
  epmsPID: string;
  apptID: number;
  reportID: number;
}

export interface AppointmentLists {
  todo: AppointmentDetail[] | null;
  inProgress: AppointmentDetail[] | null;
  needsReview: AppointmentDetail[] | null;
}
export interface AppointmentsStateModel {
  todo: AppointmentLookupObject | null;
  inProgress: AppointmentLookupObject | null;
  needsReview: AppointmentLookupObject | null;
  isSearchInProgress: boolean;
}

export const EmptyAppointmentsStateModel = {
  todo: null,
  inProgress: null,
  needsReview: null,
  isSearchInProgress: false,
};

@State<AppointmentsStateModel>({
  name: 'appointments',
  defaults: EmptyAppointmentsStateModel,
})
@Injectable()
export class AppointmentsState {
  @Selector([AppointmentsState])
  static getAppointments(state: AppointmentsStateModel): AppointmentLists {
    AppUtils.DEBUG && console.log('getAppointments selector', state);
    return state && ({
      todo: state.todo && Object.values(state.todo),
      inProgress: state.inProgress && Object.values(state.inProgress),
      needsReview: state.needsReview && Object.values(state.needsReview)
    });
  }

  @Selector([AppointmentsState.getAppointments])
  static getTodo(state: AppointmentLists): AppointmentDetail[] {
    return state && state.todo;
  }

  @Selector([AppointmentsState.getAppointments])
  static getInProgress(state: AppointmentLists): AppointmentDetail[] {
    return state && state.inProgress;
  }

  @Selector([AppointmentsState.getAppointments])
  static getNeedsReview(state: AppointmentLists): AppointmentDetail[] {
    return state && state.needsReview;
  }


  @Selector([AppointmentsState])
  static getAll(state: AppointmentsStateModel): AppointmentLookupObject {
    return {...state?.todo, ...state?.inProgress, ...state?.needsReview};
  }

  @Selector([AppointmentsState])
  static isSearchInProgress(state: AppointmentsStateModel): boolean {
    return state.isSearchInProgress;
  }

  static getAppointment(appointmentId: number) {
    return createSelector([AppointmentsState], (state: AppointmentsStateModel) => {
      return AppointmentsState.getAll(state)[appointmentId];
    });
  }

  @Selector([AppointmentsState.getAll])
  static getAppointmentsList(state: AppointmentLookupObject): AppointmentDetail[] {
    return Object.values(state);
  }

  constructor(
    private store: Store,
    private appointmentsService: AppointmentsService,
  ) {}

  @Action(GetToDoAppointments)
  getToDoAppointments(
    ctx: StateContext<AppointmentsStateModel>,
    action: GetToDoAppointments
  ) {
    const type = 'todo';
    return this.appointmentsService.getToDoAppointments(action.payload).pipe(
      tap((result: PatientAppointmentDTO[]) => {
        ctx.patchState({
          [type]: this.normalizePristineAppointments(result, type),
        });
      })
    );
  }

  @Action(GetInProgressAppointments)
  getInProgressAppointments(
    ctx: StateContext<AppointmentsStateModel>,
    action: GetInProgressAppointments
  ) {
    const type = 'inProgress';
    return this.appointmentsService
      .getInProgressAppointments(action.payload)
      .pipe(
        tap((result: ExamReviewDTO[]) => {
          ctx.patchState({
            [type]: this.normalizeTouchedAppointments(result, type),
          });
        })
      );
  }

  @Action(GetNeedsReviewAppointments)
  getNeedsReviewAppointments(
    ctx: StateContext<AppointmentsStateModel>,
    action: GetNeedsReviewAppointments
  ) {
    const type = 'needsReview';
    return this.appointmentsService
      .getNeedsReviewAppointments(action.payload)
      .pipe(
        tap((result: ExamReviewDTO[]) => {
          ctx.patchState({
            [type]: this.normalizeTouchedAppointments(result, type),
          });
        })
      );
  }

  @Action(GetAllAppointments)
  getAllAppointments(
    ctx: StateContext<AppointmentsStateModel>,
    action: GetAllAppointments
  ) {
    ctx.patchState({
      isSearchInProgress: true
    });
    return forkJoin([
      this.appointmentsService.getToDoAppointments(action.payload).pipe(
        tap((result: PatientAppointmentDTO[]) => {
          ctx.patchState({
            todo: this.normalizePristineAppointments(result, 'todo'),
          });
        }),
      ),
      this.appointmentsService.getInProgressAppointments(action.payload).pipe(
        tap((result: ExamReviewDTO[]) => {
          ctx.patchState({
            inProgress: this.normalizeTouchedAppointments(result, 'inProgress')
          });
        }),
      ),
      this.appointmentsService.getNeedsReviewAppointments(action.payload).pipe(
        tap((result: ExamReviewDTO[]) => {
          ctx.patchState({
            needsReview: this.normalizeTouchedAppointments(result, 'needsReview')
          });
        }),
      )
    ]).pipe(
      tap(_ => ctx.patchState({
        isSearchInProgress: false
      }))
    );
  }

  @Action(ForgetAllAppointments)
  forgetAllAppointments(
    ctx: StateContext<AppointmentsStateModel>
  ) {
    const {todo, inProgress, needsReview} = EmptyAppointmentsStateModel;
    return ctx.patchState({
      todo,
      inProgress,
      needsReview
    });
  }

  // @Action(SetSelectedAppointmentLookupIds)
  // setSelectedAppointmentLookupIds(
  //   ctx: StateContext<AppointmentsStateModel>,
  //   action: SetSelectedAppointmentLookupIds
  // ) {
  //   ctx.patchState({selectedAppointmentLookupIds: action.payload});
  // }


  @Action(UpdateAppointmentExam)
  updateAppointmentExam(
    ctx: StateContext<AppointmentsStateModel>,
    action: UpdateAppointmentExam
  ) {
    const state = ctx.getState();
    const appointment = AppointmentsState.getAppointment(action.payload.appointmentID)(state);
    AppUtils.DEBUG && console.log('state examination', state, action.payload.appointmentID, appointment);
    AppUtils.DEBUG && console.log('get appointment from updateAppointmentExam', appointment);
    return this.appointmentsService.createOrGetExam(appointment).pipe(
      tap(exam => {
        const state: AppointmentsStateModel = ctx.getState();
        ctx.patchState({
          [appointment.type]: {
            ...state[appointment.type],
            [appointment.appointmentID]: {
              ...state[appointment.type][appointment.appointmentID],
              exam
            }
          }
        });
      })
    );
  }

  @Action(RefreshSelectedAppointment)
  refreshSelectedAppointment(
    ctx: StateContext<AppointmentsStateModel>,
    action: RefreshSelectedAppointment
  ) {
    const state: AppointmentsStateModel = ctx.getState();
    const appointment: AppointmentDetail = AppointmentsState.getAppointment(action.payload.appointmentID)(state);

    return this.appointmentsService.getAppointmentDetails({
      reportID: appointment.exam.id,
      epmsPID: appointment.patient.epmsPID,
    }).pipe(
      tap(examReviewDTO => {
        const state: AppointmentsStateModel = ctx.getState();
        const appointment: AppointmentDetail = AppointmentsState.getAppointment(action.payload.appointmentID)(state);
        ctx.patchState({
          [appointment.type]: {
            ...state[appointment.type],
            [appointment.appointmentID]: this.normalizeTouchedAppointment(examReviewDTO, appointment.type)
          }
        });
      })
    );
  }

  @Action(SubmitNoShow)
  submitNoShow(
    ctx: StateContext<AppointmentsStateModel>,
    action: SubmitNoShow
  ) {
    const state = ctx.getState();
    const appointment = AppointmentsState.getAppointment(action.payload.appointmentID)(state);
    AppUtils.DEBUG && console.log('state examination', state, action.payload.appointmentID, appointment);
    AppUtils.DEBUG && console.log('get appointment from submitNoShow', appointment);
    return this.appointmentsService.submitNoShow(appointment);
  }

  private normalizePristineAppointments(
    input: PatientAppointmentDTO[],
    type: string
  ): AppointmentLookupObject {
    return input.reduce((z, o) => ({
      ...z,
      [o.appointmentID]: ({
        appointmentFacility: o.appointmentFacility,
        appointmentID: o.appointmentID,
        appointmentDate: o.appointmentDate,
        apptDateTime: o.apptDateTime,
        patient: o.patient,
        exam: null, // NO EXAM for Todo data
        procedureID: o.procedureID,
        procedureName: o.procedureName,
        type,
        duration: o.duration,
        eventType: o.eventType,
        primaryProcedureID: o.primaryProcedureID,
        providerID: o.providerID,
        status: o.status,
      })
    }), {});
  }

  private normalizeTouchedAppointments(
    input: ExamReviewDTO[],
    type: AppointmentTypes
  ): AppointmentLookupObject {
    return input.reduce((z, o) => ({
      ...z,
      [o.appointmentID]: this.normalizeTouchedAppointment(o, type)
    }), {});
  }

  private normalizeTouchedAppointment(
    input: ExamReviewDTO,
    type: AppointmentTypes
  ): AppointmentDetail {
    return {
      appointmentDate: input.appointmentDate,
      appointmentFacility: input.appointmentFacility,
      appointmentID: input.appointmentID,
      apptDateTime: input.apptDateTime,
      patient: input.patientDTO,
      exam: input.reportDTO,
      procedureID: input.procedureID,
      procedureName: input.procedureName,
      type,
      duration: null, // explicitly set as null missing values
      eventType: null,
      primaryProcedureID: null,
      providerID: null,
      status: null
    };
  }

}
