import { map, Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    LabRequisitionOrder, LabRequisitionOrderResponse, LabRequisitionOrdersResponse, LabsResponse,
    LookupOption, LookupOptionResponse, NonStandardPanel, PhysicianListResponse
} from '@config/types';
import { HttpContextHelperService } from '@core/services/http-context-helper.service';

@Injectable()
export class LabsService {
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private httpContextHelperService: HttpContextHelperService
  ) {}

  getLabs(): Observable<LabsResponse> {
    return this.http.get<LabsResponse>(
      this.config.ENDPOINTS.labResults,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'apptID'],
      })
    );
  }

  postAbnormalLabs(data): Observable<any> {
    // to do: Add explicit type for this
    return this.http.post<any>(
      this.config.ENDPOINTS.medicalReportSection,
      data,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID'],
      })
    );
  }

  getAbnormalLabs(): Observable<any> {
    // sectionID and subSectionID are static for abnormal labs
    const sectionID = '2';
    const subSectionID = '13';

    return this.http.get<any>(this.config.ENDPOINTS.medicalReportSection, {
      params: {
        sectionID,
        subSectionID,
      },
      ...this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID'],
      }),
    });
  }

  labsSignOff(): Observable<any> {
    return this.http.post<any>(
      this.config.ENDPOINTS.labsSignOff,
      {},
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID'],
      })
    );
  }

  getPhysiciansList(): Observable<PhysicianListResponse> {
    return this.http.get<PhysicianListResponse>(
      this.config.ENDPOINTS.physiciansList,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'apptID', 'facilityID'],
      })
    );
  }

  getAllRequisitions(): Observable<LabRequisitionOrdersResponse> {
    return this.http.get<LabRequisitionOrdersResponse>(
      this.config.ENDPOINTS.requisitions,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'apptID'],
      })
    );
  }

  getStandardPanelOptions(): Observable<LookupOption[]> {
    return this.http
      .get<LookupOptionResponse>(
        this.config.ENDPOINTS.lookupStandardPanelOptions,
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as LookupOption[]));
  }

  getCommonAdditionalTestsOptions(): Observable<LookupOption[]> {
    return this.http
      .get<LookupOptionResponse>(
        this.config.ENDPOINTS.lookupCommonAdditionalTests,
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as LookupOption[]));
  }

  getLabOrderOptions(): Observable<LookupOption[]> {
    return this.http
      .get<LookupOptionResponse>(
        this.config.ENDPOINTS.lookupLabOrders,
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as LookupOption[]));
  }

  getCytSourceOptions(): Observable<LookupOption[]> {
    return this.http
      .get(
        this.config.ENDPOINTS.lookupCytSourceOptions,
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as LookupOption[])) as Observable<
      LookupOption[]
    >;
  }

  getCytCollectionOptions(): Observable<LookupOption[]> {
    return this.http
      .get(
        this.config.ENDPOINTS.lookupCytCollectionOptions,
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as LookupOption[])) as Observable<
      LookupOption[]
    >;
  }

  getCytOtherPatientinfoOptions(): Observable<LookupOption[]> {
    return this.http
      .get(
        this.config.ENDPOINTS.lookupCytOtherPatientinfoOptions,
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as LookupOption[])) as Observable<
      LookupOption[]
    >;
  }

  getCytPreviousConditionOptions(): Observable<LookupOption[]> {
    return this.http
      .get(
        this.config.ENDPOINTS.lookupCytPreviousConditionOptions,
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as LookupOption[])) as Observable<
      LookupOption[]
    >;
  }

  getCytPreviousTreatmentOptions(): Observable<LookupOption[]> {
    return this.http
      .get(
        this.config.ENDPOINTS.lookupCytPreviousTreatmentOptions,
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as LookupOption[])) as Observable<
      LookupOption[]
    >;
  }

  createLabOrder(data): Observable<LabRequisitionOrderResponse> {
    return this.http.post<LabRequisitionOrderResponse>(
      this.config.ENDPOINTS.createLabOrder,
      data,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['apptID', 'epmsPID', 'facilityID', 'userID'],
      })
    );
  }

  createPapOrder(papOrderData: LabRequisitionOrder) {
    return this.http.post(this.config.ENDPOINTS.createPapOrder, papOrderData,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['apptID', 'epmsPID', 'facilityID', 'userID'],
      })
    ) as Observable<LabRequisitionOrderResponse>;
  }

  printLabOrder(orderID): Observable<Blob> {
    return this.http.get(this.config.ENDPOINTS.printOrder, {
      params: {
        orderID,
      },
      ...this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['apptID', 'epmsPID', 'facilityID', 'userID'],
      }),
      responseType: 'blob',
    });
  }
  
  getNonStandardPanelOptions(): Observable<NonStandardPanel[]> {
    return this.http
      .get(
        this.config.ENDPOINTS.lookupNonStandardPanelOptions, 
        this.httpContextHelperService.setParamTokensInHttpContext({
          queryParams: ['userID'],
        })
      )
      .pipe(map((res: any) => res.data as NonStandardPanel[])) as Observable<
      NonStandardPanel[]
    >;
  }
  
}
