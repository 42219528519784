import { map, Observable, Subject, takeUntil } from 'rxjs';

import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
    AppointmentDetail, FastingState, LabOrderState, LabRequisitionOrder, ReqDetailsControls
} from '@config/types';
import { LabsService } from '@patient/services/labs.service';

import { ExamInfoComponent } from '../exam-info/exam-info.component';
import { LabOrderComponent } from '../lab-order/lab-order.component';
import { ReqDetailsComponent } from '../req-details/req-details.component';

@Component({
  selector: 'app-lab-request',
  templateUrl: './lab-request.component.html',
  styleUrls: ['./lab-request.component.scss']
})
export class LabRequestComponent implements OnInit, OnDestroy, AfterViewInit {
  appointment$: Observable<AppointmentDetail>;

  @ViewChild(ExamInfoComponent) examInfo: ExamInfoComponent;
  @ViewChild(ReqDetailsComponent) reqDetails: ReqDetailsComponent;
  @ViewChild(LabOrderComponent) labOrder: LabOrderComponent;
  labRequest: LabRequisitionOrder;
  isInvalidForm = true;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private location: Location,
    private labsService: LabsService,
    private route: ActivatedRoute,
  ) {
    this.appointment$ = this.route.parent.parent.data.pipe(map(data => data.appointment));

  }

  ngOnInit(): void {
    this.labRequest = {
      displayOrderDate: null,
      eheCytologyTests: '',
      ehePreferredPanels: '',
      ehePreferredTests: '',
      id: null,
      labCorpCollectionDate: null,
      labCorpPatientNotes: null,
      labCorpResultsDate: null,
      labRequisition: {
        displayCollectionDate: '',
        displayCollectionTime: '',
        displayType: '1',
        encounterID: null,
        isFasting: FastingState.UNKNOWN,
        labType: '1',
        requisitionID: null,
        type: 1,
      },
      cytologyDetails: {
        lmpMenoDate: '',
        formattedLmpMenoDate: '',
        cytSource: '',
        cytCollection: '',
        cytHistory: '',
        cytPrevCondition: '',
        cytPrevTreatment: '',
        cytPrevTreatmentComments: '',
      },
      nonStandardTests: '',
      orderDate: '',
      orderID: null,
      otherTests: '',
      physician: {
        adminid: 0,
      },
      resultsReceivedDate: null,
      status: 0,
      statusModifiedDate: null,
      statusName: '',
    };
    const labOrder = <LabOrderState>history.state;
    if (labOrder.orderID) {
      this.labRequest = labOrder.labRequisitionData;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    this.examInfo.form.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      // create interface for examInfoComponent form
      this.labRequest.physician.adminid = res.physician;
      this.isInvalidForm = this.checkFormValidity();
    });
    this.reqDetails.form.valueChanges.subscribe((res: ReqDetailsControls) => {
      this.labRequest.labRequisition.displayCollectionDate =
        res.dateCollected instanceof Date
          ? res.dateCollected.toLocaleDateString()
          : res.dateCollected;
      this.labRequest.labRequisition.displayCollectionTime =
        res.timeCollected instanceof Date
          ? res.timeCollected.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
          : this.to24Hour(res.timeCollected);
      this.labRequest.labRequisition.isFasting =
        res.fasting === 'Yes'
          ? FastingState.YES
          : res.fasting === 'No'
            ? FastingState.NO
            : FastingState.UNKNOWN;
      this.isInvalidForm = this.checkFormValidity();
    });
    this.labOrder.form.valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      this.labRequest.ehePreferredPanels = res.standardLabPanels;
      this.labRequest.ehePreferredTests = Object.keys(res.additionalTests).filter(key => res.additionalTests[key]).toString();
      let selectedNonstandardTests: number[] = [];
      res.nonStandardLabTests.forEach(nonStandardPanel => {
        selectedNonstandardTests.push(nonStandardPanel.labCorpPanelNumber);
      });
      this.labRequest.nonStandardTests = selectedNonstandardTests.toString();
      this.isInvalidForm = this.checkFormValidity();
    });

  }

  navigateToRequests(): void {
    this.location.back();
  }

  checkFormValidity(): boolean {
    return this.examInfo.form.invalid || this.reqDetails.form.invalid || this.labOrder.form.invalid;
  }

  createLabOrder(): void {
    this.labsService.createLabOrder(this.labRequest).subscribe((res) => {
      this.navigateToRequests();
    });
  }

  to24Hour(time: string): string {
    if(time.length > 5) {
      const timeArray = time.split(/[:\s]/);
      // convert hours to a number
      const hours: number = timeArray[2] === 'pm' ? +timeArray[0] + 12 : +timeArray[0];
      const hourString: string = hours < 10 ? '0' + hours : hours.toString();
      const minutes = timeArray[1];

      return hourString + ':' + minutes;
    }
    return time;
  }
}
