<mat-card>
    <mat-card-header>
        <mat-card-title>Labs</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-tab-group class="labs-container" dynamicHeight animationDuration="0ms">
            <mat-tab label="Results">
                <ng-template matTabContent>
                    <app-labs-results></app-labs-results>
                </ng-template>

            </mat-tab>
            <mat-tab label="Requisitions">
                <ng-template matTabContent>
                    <app-lab-requisitions></app-lab-requisitions>
                </ng-template>

            </mat-tab>
        </mat-tab-group>
    </mat-card-content>
</mat-card>