import { IEmpty, IWithMessage } from '../common';

// GET /provider/user/allowed-facilities-with-roles -- getUserFacilitiesWithRoles

export interface GetAllowedFacilitiesWithRolesRequestParams extends IEmpty {}
export interface GetAllowedFacilitiesWithRolesResponse extends IWithMessage {
  data: FacilityWithRoleDTO[];
}

export interface FacilityWithRoleDTO {
  allowedRolesAtFac: number[];
  facility: FacilityDTO;
  isCR: boolean;
  isMA: boolean;
  isMD: boolean;
}

export interface FacilityDTO {
  active: string;
  displayName: string;
  ehefacility: boolean;
  facilityType: number;
  id: number;
  name: string;
  onlyOutSourcedFacility: boolean;
  outsourceType: number;
  primaryFacility: boolean;
  primaryNetworkFacility: boolean;
  primaryType: number;
}
