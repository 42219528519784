<div class="dashboard-wrapper">
  <div [formGroup]="filterGroup" class="filterGroup" *ngIf="facilities">
    <shared-ui-select
      label="Facility"
      [parent]="filterGroup"
      control="facilityID"
      sort="true"
      [options]="facilities"
      [typeDropDown]="true"
    >
    </shared-ui-select>
    <mat-form-field class="mat-form-field-padding">
      <mat-label>Appointment Date</mat-label>
      <input
        matInput
        formControlName="appointmentDate"
        [matDatepicker]="picker"
      />
      <mat-datepicker #picker></mat-datepicker>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    </mat-form-field>
  </div>
  <ng-container *ngIf="appointmentsList$ | async as appointmentsList">
    <app-appointments-todo-only
      *ngIf="isTodoOnlyView"
      (appointmentSelected)="confirmAppointment($event)"
      [appointmentData]="appointmentsList.todo"></app-appointments-todo-only>
    <app-appointments
      *ngIf="!isTodoOnlyView"
      (appointmentSelected)="confirmAppointment($event)"
      [appointmentData]="appointmentsList"></app-appointments>
  </ng-container>
</div>
<ng-template #columnLoading>
  <h3>Loading...</h3>
</ng-template>
