export type SectionKey = 'biometrics' | 'labs' | 'screenings' |
  'vaccines' | 'vaccines2' | 'immunity' | 'recommended' |
  'summary' | 'physician';

export interface CreateField {
  [id: number]: CreateFieldContents;
}

export interface CreateFieldContents {
  form: string;
  section: string;
  field: string;
  label: string;
  displayOrder?: number;
  group?: string;
  displayText?: string;
  description?: string;
  dropdownOptions?: string[];
  dropdownControlName?: string;
  otherField?: string;
}

export const configSectionSettings: ConfigSectionSettings = {
  biometrics: {
    sectionID: 2,
    subSectionID: 6,
  },
  labs: {
    sectionID: 2,
    subSectionID: 7,
  },
  screenings: {
    sectionID: 2,
    subSectionID: 8,
  },
  vaccines: {
    sectionID: 2,
    subSectionID: 9,
  },
  immunity: {
    sectionID: 2,
    subSectionID: 13,
  },
  recommended: {
    sectionID: 3,
    subSectionID: 10,
  },
  vaccines2: {
    sectionID: 3,
    subSectionID: 12,
  },
  summary: {
    sectionID: 4,
    subSectionID: null,
  },
  physician: {
    sectionID: 5,
    subSectionID: null,
  },
};


export const configCreateTabFields: CreateField = {
  28: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'bmiNormal',
    label: 'BMI Normal',
    displayOrder: 1,
    group: 'bmiOption',
    displayText: 'BMI Normal (18.5 – 24.9)'
  },
  29: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'bmiHigh',
    label: 'BMI High',
    displayOrder: 2,
    group: 'bmiOption',
    displayText: 'BMI High (≥ 25)'
  },
  174: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'bodyfatNormal',
    label: 'Body Fat Percentage Normal',
    displayOrder: 3,
    group: 'bodyfatOption',
    displayText: 'Body Fat Percentage Normal',
  },
  30: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'bodyfatHigh',
    label: 'Body Fat Percentage High',
    displayOrder: 4,
    group: 'bodyfatOption',
    displayText: 'Body Fat Percentage High (above average)'
  },
  175: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'waistNormal',
    label: 'Waist Circumference Normal',
    displayOrder: 5,
    group: 'waistOption',
    displayText: 'Waist Circumference Normal'
  },
  31: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'waistHigh',
    label: 'Waist Circumference High',
    displayOrder: 6,
    group: 'waistOption',
    displayText: 'Waist Circumference High (men > 40 in, women > 35 in)'
  },
  32: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'bpNormal',
    label: 'BP Normal',
    displayOrder: 7,
    group: 'bpOption',
    displayText: 'Blood Pressure Normal (120/80)'
  },
  33: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'bpHigh',
    label: 'BP High',
    displayOrder: 8,
    group: 'bpOption',
    displayText: 'Blood Pressure Elevated or Stage 1 (SBP 120-139, DBP 80-89)'
  },
  171: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'bpStage2',
    label: 'Blood Pressure Stage 2 (>= 140/90)',
    displayOrder: 9,
    group: 'bpOption',
    displayText: 'Blood Pressure Stage 2 (≥ 140/90)'
  },
  
  35: {
    form: 'biometrics',
    section: 'biometrics',
    field: 'biometricsOtherValue',
    label: 'Optional Comments',
    otherField: 'biometricsOtherValue'
  },
  36: {
    form: 'labs',
    section: 'labs',
    field: 'a1cNormal',
    label: 'Hemoglobin A1c Normal',
    displayOrder: 1,
    group: 'hemoglobinOption',
    displayText: 'Hemoglobin A1c Normal',
  },
  37: {
    form: 'labs',
    section: 'labs',
    field: 'a1cPrediabetes',
    label: 'Hemoglobin A1c Prediabetes',
    displayOrder: 2,
    group: 'hemoglobinOption',
    displayText: 'Hemoglobin A1c Prediabetes (5.7 - 6.4)',
  },
  106: {
    form: 'labs',
    section: 'labs',
    field: 'a1cDiabetes',
    label: 'Hemoglobin A1c Diabetes',
    displayOrder: 3,
    group: 'hemoglobinOption',
    displayText: 'Hemoglobin A1c Diabetes (> 6.4)',
  },
  107: {
    form: 'labs',
    section: 'labs',
    field: 'a1cUncontrolled',
    label: 'Hemoglobin A1c Uncontrolled Diabetes',
    displayOrder: 4,
    group: 'hemoglobinOption',
    displayText: 'Hemoglobin A1c Uncontrolled Diabetes (> 7.0)',
  },
  38: {
    form: 'labs',
    section: 'labs',
    field: 'lipidsNormal',
    label: 'Lipids Normal',
    displayOrder: 5,
    group: 'lipidsOption',
    displayText: 'Lipid Panel Normal',
  },
  108: {
    form: 'labs',
    section: 'labs',
    field: 'lipidsAbnormal',
    label: 'Lipids Abnormal',
    displayOrder: 6,
    group: 'lipidsOption',
    displayText: 'Lipids Abnormal (any abnormality)',
  },
  177: {
    form: 'labs',
    section: 'labs',
    field: 'reactiveProteinNormal',
    label: 'C-Reactive Protein Normal',
    displayOrder: 7,
    group: 'reactiveProteinOption',
    displayText: 'C-Reactive Protein Normal',
  },
  39: {
    form: 'labs',
    section: 'labs',
    field: 'reactiveProteinHigh',
    label: 'C-Reactive Protein High',
    displayOrder: 8,
    group: 'reactiveProteinOption',
    displayText: 'C-Reactive Protein High (> 3.00)',
  },
  40: {
    form: 'labs',
    section: 'labs',
    field: 'psaNormal',
    label: 'PSA Normal',
    displayOrder: 9,
    group: 'psaOption',
    displayText: 'PSA Normal (≤ 4.0)',
  },
  41: {
    form: 'labs',
    section: 'labs',
    field: 'psaHigh',
    label: 'PSA High',
    displayOrder: 10,
    group: 'psaOption',
    displayText: 'PSA High (> 4.0)'
  },
  109: {
    form: 'labs',
    section: 'labs',
    field: 'psaIncrease',
    label: 'PSA Inapproprite',
    displayOrder: 11,
    group: 'psaOption',
    displayText: 'PSA Significant Increase'
  },
  178: {
    form: 'labs',
    section: 'labs',
    field: 'anemiaNo',
    label: 'Anemia No',
    displayOrder: 12,
    group: 'anemiaOption',
    displayText: 'Anemia Not Found',
  },
  110: {
    form: 'labs',
    section: 'labs',
    field: 'anemiaYes',
    label: 'Anemia Yes',
    displayOrder: 13,
    group: 'anemiaOption',
    displayText: 'Anemia',
  },
  179: {
    form: 'labs',
    section: 'labs',
    field: 'lftElevatedNo',
    label: 'LFTs Elevated No',
    displayOrder: 14,
    group: 'lftOption',
    displayText: 'LFT\'s Not Elevated',
  },
  111: {
    form: 'labs',
    section: 'labs',
    field: 'lftElevatedYes',
    label: 'LFTs Elevated Yes',
    displayOrder: 15,
    group: 'lftOption',
    displayText: 'LFT\'s Elevated',
  },
  42: {
    form: 'labs',
    section: 'labs',
    field: 'hepCNeg',
    label: 'Hepatitis C Negative',
    displayOrder: 16,
    group: 'hepatitisOption',
    displayText: 'Hepatitis C Negative',
  },
  112: {
    form: 'labs',
    section: 'labs',
    field: 'hepCPos',
    label: 'Hepatitis C Positive',
    displayOrder: 17,
    group: 'hepatitisOption',
    displayText: 'Hepatitis C Positive',
  },
  43: {
    form: 'labs',
    section: 'labs',
    field: 'stdNeg',
    label: 'STD Testing Negative',
    displayOrder: 18,
    group: 'stdOption',
    displayText: 'STD Testing Negative',
  },
  113: {
    form: 'labs',
    section: 'labs',
    field: 'stdPos',
    label: 'STD Testing Positive',
    displayOrder: 19,
    group: 'stdOption',
    displayText: 'STD Testing Positive',
  },
  114: {
    form: 'labs',
    section: 'labs',
    field: 'papNormal',
    label: 'Pap Normal',
    displayOrder: 20,
    group: 'papOption',
    displayText: 'Pap Normal',
  },
  115: {
    form: 'labs',
    section: 'labs',
    field: 'papAbnormal',
    label: 'Pap Abnormal',
    displayOrder: 21,
    group: 'papOption',
    displayText: 'Pap Abnormal',
  },
  180: {
    form: 'labs',
    section: 'labs',
    field: 'papNotApplicable',
    label: 'Pap Not Applicable',
    displayOrder: 22,
    group: 'papOption',
    displayText: 'Not Applicable',
  },
  116: {
    form: 'labs',
    section: 'labs',
    field: 'hpvNeg',
    label: 'HPV Negative',
    displayOrder: 23,
    group: 'hpvOption',
    displayText: 'HPV Negative',
  },
  117: {
    form: 'labs',
    section: 'labs',
    field: 'hpvPos',
    label: 'HPV Positive',
    displayOrder: 24,
    group: 'hpvOption',
    displayText: 'HPV Positive',
  },
  118: {
    form: 'labs',
    section: 'labs',
    field: 'urineNormal',
    label: 'Urine Screening Normal',
    displayOrder: 25,
    group: 'urineOption',
    displayText: 'Urine Screening Normal (including non-significant findings)',
  },
  119: {
    form: 'labs',
    section: 'labs',
    field: 'urineAbnormal',
    label: 'Urine Screening Abnormal',
    displayOrder: 26,
    group: 'urineOption',
    displayText: 'Urine Screening Abnormal',
  },
  45: {
    form: 'labs',
    section: 'labs',
    field: 'nonsigLab',
    label: 'Non-Significant Lab Findings',
    displayOrder: 27,
    group: 'labsOption',
    displayText: 'Non-significant Lab Findings',
  },
  44: {
    form: 'labs',
    section: 'labs',
    field: 'followup',
    label: 'Follow-Up Lab Findings',
    displayOrder: 28,
    group: 'labsOption',
    displayText: 'Follow-up Lab Findings',
  },
  46: {
    form: 'labs',
    section: 'labs',
    field: 'labsOtherValue',
    label: 'Optional Comments',
    otherField: 'labsOtherValue'
  },
  47: {
    form: 'screenings',
    section: 'screenings',
    field: 'ascvdHigh',
    label: 'ASCVD High',
  },
  48: {
    form: 'screenings',
    section: 'screenings',
    field: 'breastCancerHigh',
    label: 'Breast Cancer Risk Assessment High',
  },
  53: {
    form: 'screenings',
    section: 'screenings',
    field: 'oralHealth',
    label: 'Oral Health',
  },
  52: {
    form: 'screenings',
    section: 'screenings',
    field: 'skinCancer',
    label: 'Skin Cancer Prevention',
  },
  49: {
    form: 'screenings',
    section: 'screenings',
    field: 'ekgAbnormal',
    label: 'EKG Abnormal',
  },
  50: {
    form: 'screenings',
    section: 'screenings',
    field: 'visionDeficit',
    label: 'Vision Deficit',
  },
  51: {
    form: 'screenings',
    section: 'screenings',
    field: 'hearingDeficit',
    label: 'Hearing Deficit',
  },
  54: {
    form: 'screenings',
    section: 'screenings',
    field: 'screeningsOtherValue',
    label: 'Optional Comments',
    otherField: 'screeningsOtherValue',
  },
  55: {
    form: 'vaccines',
    section: 'vaccines',
    field: 'fluGiven',
    label: 'Flu Vaccination Given',
  },
  56: {
    form: 'vaccines',
    section: 'vaccines',
    field: 'fluDeclined',
    label: 'Flu Vaccination Declined',
  },
  120: {
    form: 'vaccines',
    section: 'vaccines',
    field: 'fluUpToDate',
    label: 'Flu Vaccination Up-To-Date',
  },
  121: {
    form: 'immunity',
    section: 'immunity',
    field: 'mmr',
    label: 'MMR',
  },
  122: {
    form: 'immunity',
    section: 'immunity',
    field: 'hepA',
    label: 'Hepatitis A',
  },
  123: {
    form: 'immunity',
    section: 'immunity',
    field: 'hepB',
    label: 'Hepatitis B',
  },
  57: {
    form: 'recommended',
    section: 'recommended',
    field: 'pap',
    label: 'Pap',
    group: 'papOption',
    displayText: 'Pap Ordered',
    description: 'Recommended every 3 years for women ages 21-29 and every 5 years for women ages 30-65'
  },
  124: {
    form: 'recommended',
    section: 'recommended',
    field: 'noPap',
    label: 'Pap Not Ordered',
    group: 'papOption',
    displayText: 'Pap Not Ordered',
    dropdownOptions: [
      '125', '126', '127'
    ],
    dropdownControlName: 'noPapDescription'
  },
  58: {
    form: 'recommended',
    section: 'recommended',
    field: 'mammo',
    label: 'Mammogram',
    group: 'mammoOption',
    displayText: 'Mammogram Ordered',
    description: 'Recommended annually for women ages 40 and older'
  },
  128: {
    form: 'recommended',
    section: 'recommended',
    field: 'noMammo',
    label: 'Mammogram Not Ordered',
    group: 'mammoOption',
    displayText: 'Mammogram Not Ordered',
    dropdownOptions: [
      '1', '2', '3'
    ],
    dropdownControlName: 'noMammoDescription'
  },
  // 59: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'cst',
  //   label: 'CST',
  // },
  // 132: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'cstNo',
  //   label: 'CST Not Ordered',
  //   otherField: 'cstNoDescription',
  // },
  61: {
    form: 'recommended',
    section: 'recommended',
    field: 'colonoscopy',
    label: 'Colonoscopy',
    group: 'colonoscopyOption',
    displayText: 'Colonoscopy Ordered',
    description: 'Recommended for people ages 50 and older, or 45 and older if African American (or earlier depending on clinical indication)'
  },
  140: {
    form: 'recommended',
    section: 'recommended',
    field: 'colonoscopyNo',
    label: 'Colonoscopy Not Ordered',
    group: 'colonoscopyOption',
    displayText: 'Colonoscopy Not Ordered',
    dropdownOptions: [
      '1', '2', '3'
    ],
    dropdownControlName: 'colonoscopyNoDescription'

  },
  144: {
    form: 'recommended',
    section: 'recommended',
    field: 'fit',
    label: 'FIT Test',
    group: 'fitOption',
    displayText: 'FIT Test Ordered',
    description: 'Recommended for average risk patients for whom colonoscopy is not ordered'
  },
  145: {
    form: 'recommended',
    section: 'recommended',
    field: 'fitNo',
    label: 'FIT Test Not Ordered',
    group: 'fitOption',
    displayText: 'FIT Test Not Ordered',
    dropdownOptions: [
      '1', '2', '3'
    ],
    dropdownControlName: 'fitNoDescription'
  },
  62: {
    form: 'recommended',
    section: 'recommended',
    field: 'vascStudy',
    label: 'Peripheral Vascular Study',
    group: 'vascStudyOption',
    displayText: 'Peripheral Vascular Study Ordered',
    description: 'Recommended annually for people ages 50 and older who meet at least one of the following criteria:'
  },
  85: {
    form: 'recommended',
    section: 'recommended',
    field: 'peripheralVascular',
    label: 'Peripheral Vascular Disease',
  },
  149: {
    form: 'recommended',
    section: 'recommended',
    field: 'peripheralVascularNo',
    label: 'Peripheral Vascular Study Not Ordered',
    group: 'vascStudyOption',
    displayText: 'Peripheral Vascular Study Not Ordered',
    dropdownOptions: [
      '1', '2', '3'
    ],
    dropdownControlName: 'vascStudyNoDescription'
  },
  63: {
    form: 'recommended',
    section: 'recommended',
    field: 'lungCT',
    label: 'Lung CT',
    group: 'lungCTOption',
    displayText: 'Lung CT Ordered',
    description: 'Recommended for people ages 55-80 who meet all the following criteria:'
  },
  153: {
    form: 'recommended',
    section: 'recommended',
    field: 'lungCTNo',
    label: 'Lung CT Not Ordered',
    group: 'lungCTOption',
    displayText: 'Lung CT Not Ordered',
    dropdownOptions: [
      '1', '2', '3'
    ],
    dropdownControlName: 'lungCTNoDescription'
  },
  64: {
    form: 'recommended',
    section: 'recommended',
    field: 'abdominalUltrasound',
    label: 'Abdominal Aorta Ultrasound',
    group: 'abdominalUltrasoundOption',
    displayText: 'Abdominal Aortal Ultrasound Ordered',
    description: 'Recommended for people ages 65 and older'
  },
  157: {
    form: 'recommended',
    section: 'recommended',
    field: 'abdominalUltrasoundNo',
    label: 'Abdominal Aorta Ultrasound Not Ordered',
    group: 'abdominalUltrasoundOption',
    displayText: 'Abdominal Aortal Ultrasound Not Ordered',
    dropdownOptions: [
      '1', '2', '3'
    ],
    dropdownControlName: 'abdominalUltrasoundNoDescription'
  },
  65: {
    form: 'recommended',
    section: 'recommended',
    field: 'dexa',
    label: 'DEXA Scan',
    group: 'dexaOption',
    displayText: 'Bone Density(DEXA) Scan Ordered',
    description: 'Recommended for people ages 65 and older'
  },
  161: {
    form: 'recommended',
    section: 'recommended',
    field: 'dexaNo',
    label: 'DEXA Scan Not Ordered',
    group: 'dexaOption',
    displayText: 'Bone Density(DEXA) scan Not Ordered',
    dropdownOptions: [
      '1', '2', '3'
    ],
    dropdownControlName: 'dexaNoDescription'
  },
  // 67: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'a1c',
  //   label: 'Hemoglobin A1c',
  // },
  // 68: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'smoker',
  //   label: 'Smoker within the past 15 years',
  // },
  // 69: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'riskFactors',
  //   label: 'Risk factor',
  // },
  // 70: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'obesity',
  //   label: 'Obesity',
  // },
  // 71: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'hypertension',
  //   label: 'Hypertension',
  // },
  // 72: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'dyslipidemia',
  //   label: 'Dyslipidemia',
  // },
  // 73: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'kidney',
  //   label: 'Kidney Disease',
  // },
  // 74: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'inflammatory',
  //   label: 'Chronic Inflammatory Disease',
  // },
  // 75: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'vascular',
  //   label: 'Peripheral Vascular Disease',
  // },
  // 76: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'apnea',
  //   label: 'Sleep Apnea',
  // },
  // 77: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'asian',
  //   label: 'South Asian Race',
  // },
  // 78: {
  //   form: 'recommended',
  //   section: 'recommended',
  //   field: 'family',
  //   label: 'Family History Artery Disease',
  // },
  60: {
    form: 'recommended',
    section: 'recommended',
    field: 'corCalScan',
    label: 'Coronary Calcium Scan',
    group: 'corCalScanOption',
    displayText: 'Coronary Calcium Scan Ordered',
    description: 'Recommended for people ages 40 and older who meet all the following criteria:'
  },
  79: {
    form: 'recommended',
    section: 'recommended',
    field: 'ascvd',
    label: 'ASCVD',
  },
  80: {
    form: 'recommended',
    section: 'recommended',
    field: 'statin',
    label: 'No Statin',
  },
  81: {
    form: 'recommended',
    section: 'recommended',
    field: 'riskFactors2',
    label: 'Risk factor',
  },
  82: {
    form: 'recommended',
    section: 'recommended',
    field: 'LDL',
    label: 'LDL',
  },
  83: {
    form: 'recommended',
    section: 'recommended',
    field: 'triglycerides',
    label: 'Triglycerides',
  },
  84: {
    form: 'recommended',
    section: 'recommended',
    field: 'hscrp',
    label: 'hs-CRP',
  },
  86: {
    form: 'recommended',
    section: 'recommended',
    field: 'family2',
    label: 'Family History Artery Disease',
  },
  136: {
    form: 'recommended',
    section: 'recommended',
    field: 'corCalScanNo',
    label: 'Coronary Calcium Scan Not Ordered',
    group: 'corCalScanOption',
    displayText: 'Coronary Calcium Scan Not Ordered',
    dropdownOptions: [
      '1', '2', '3'
    ],
    dropdownControlName: 'corCalScanNoDescription'
  },
  87: {
    form: 'recommended',
    section: 'recommended',
    field: 'a1c2',
    label: 'Hemoglobin A1c',
  },
  165: {
    form: 'recommended',
    section: 'recommended',
    field: 'hypertension2',
    label: 'Hypertension',
  },
  88: {
    form: 'recommended',
    section: 'recommended',
    field: 'smoker2',
    label: 'Smoker',
  },
  166: {
    form: 'recommended',
    section: 'recommended',
    field: 'LDL2',
    label: 'LDL',
  },
  167: {
    form: 'recommended',
    section: 'recommended',
    field: 'family3',
    label: 'Family History of Peripheral Artery Disease',
  },
  89: {
    form: 'recommended',
    section: 'recommended',
    field: 'smokerHistory',
    label: 'Smoking history',
  },
  90: {
    form: 'recommended',
    section: 'recommended',
    field: 'smoker3',
    label: 'Smoker',
  },
  66: {
    form: 'recommended',
    section: 'recommended',
    field: 'recommendedScreeningsOtherValue',
    label: 'Optional Comments',
    otherField: 'recommendedScreeningsOtherValue',
  },
  98: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'hpv',
    label: 'HPV',
  },
  99: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'pneumovax',
    label: 'Pneumovax',
  },
  100: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'prevnar',
    label: 'Prevnar',
  },
  101: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'shingrix',
    label: 'Shingrix',
  },
  102: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'tetanus',
    label: 'Tetanus/Diptheria',
  },
  103: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'tdap',
    label: 'Tdap',
  },
  168: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'mmr',
    label: 'Measles, Mumps, Rubells (MMR)',
  },
  169: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'hepA',
    label: 'Hepatitis A',
  },
  170: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'hepB',
    label: 'Hepatitis B',
  },
  104: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'recommendedVaccinesOtherValue',
    label: 'Optional Comments',
    otherField: 'recommendedVaccinesOtherValue',
  },
  182: {
    form: 'recommended',
    section: 'vaccines2',
    field: 'covid19',
    label: 'Covid-19',
  },
  105: {
    form: 'summary',
    section: 'summary',
    field: 'summaryValue',
    label: 'Summary Statement to Patient',
  },
  172: {
    form: 'physician',
    section: 'physician',
    field: 'physicianValue',
    label: 'Physician',
  },
};

export const createFieldBySection: CreateFieldBySection = Object.keys(configCreateTabFields).reduce((z, id) => {
  const configObj = configCreateTabFields[id];
  const thisSectionArr = z[configObj.section] ?? [];
  return {
    ...z,
    [configObj.section]: [].concat(thisSectionArr, {id, ...configObj})
  };
}, {}) as CreateFieldBySection;

// If rendering headings dynamically in the template,
// add a new object or create change this
// to handle multiple sections
export const biometricsHeadings = {
  bmiOption: 'BMI',
  bodyfatOption: 'Body Fat Percentage',
  waistOption: 'Waist Circumference',
  bpOption: 'Blood Pressure',
};

export const labsHeadings = {
  hemoglobinOption: 'Hemoglobin A1c',
  lipidsOption: 'Lipid Panel',
  reactiveProteinOption: 'C-Reactive Protein',
  psaOption: 'PSA',
  anemiaOption: 'Anemia',
  lftOption: 'LFT\'s Elevated',
  hepatitisOption: 'Hepatitis C',
  stdOption: 'STD Testing',
  papOption: 'Pap',
  hpvOption: 'HPV',
  urineOption: 'Urine Screening',
  labsOption: 'Lab Findings',
};

export type CreateFieldBySection = {
  [section in SectionKey]: CreateFieldWithId[];
};

export interface CreateFieldWithId extends CreateFieldContents {
  id: number;
}

export type ConfigSectionSettings = {
  [section in SectionKey]: {
    reportID?: number;
    sectionID: number;
    subSectionID: number;
  }
};
