import { Subject, takeUntil } from 'rxjs';

import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Specialty } from '@config/types';
import {
    VerificationModalService
} from '@shared/components/verification-modal/verification-modal.service';

import { SpecialtyService } from './specialty.service';

@Component({
  selector: 'app-add-specialty',
  templateUrl: './add-specialty.component.html',
})
export class AddSpecialtyComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() formArrayName: string;
  @Input() type: string; // EAP or regular specialties dropdown

  formArray: FormArray;
  specialtyOptions: Specialty[];
  specialtyEAPOptions = [];

  private unsubscribe = new Subject<void>();

  constructor(
    private specialtyService: SpecialtyService,
    private verificationModalService: VerificationModalService
  ) { }

  ngOnInit(): void {
    this.specialtyService.specialtyOptions$.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe((value => this.specialtyOptions = value as Specialty[]));
    // subscribe to clientNumber id if component is EAP dropdown
    if (this.type === 'eapDropdown') {
      this.getEAPSpecialties();
    }

    this.formArray = this.formGroup.get(this.formArrayName) as FormArray;
  }

  // if there are no saved specialist referrals, display an empty input to the user
  ngAfterViewInit() {
    setTimeout(() => {
      if (this.formArray && !this.formArray.controls.length) {
        this.addSpecialty();
      }
    }, 1000);
  }

  getEAPSpecialties() {
    this.specialtyService.getEAPSpecialties().subscribe((response: any) => {
      if (response.data) {
        this.specialtyEAPOptions = response.data;
      }
    });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  addSpecialty(specialty?: Specialty): void {
    return this.specialtyService.addSpecialty(this.formArray, specialty);
  }

  removeSpecialty(index: number): void {
    return this.specialtyService.removeSpecialty(this.formArray, index);
  }

  openStatDialog(parent: FormGroup, child: string) {
    const statCtrl = parent.get(child);
    if (statCtrl.value) {
      const statDialogOptions = {
        title: 'Mark Referral as STAT?',
        actionButton: 'Mark as STAT',
        content: 'Please confirm that you would like to make this referral STAT',
      };

      this.verificationModalService.open(statDialogOptions);
      this.verificationModalService
        .confirmed()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((confirmed) => {
          if (!confirmed) {
            statCtrl.patchValue(false);
          }
          this.unsubscribe.complete();
        });
    }
  }
}
