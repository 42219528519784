import { FormatOptionBadgesPipe } from './pipes/format-option-badges.pipe';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DynamicFormModule } from '@shared/dynamic-form/dynamic-form.module';
import { SharedModule } from '@shared/shared.module';

import { DashboardRoutingModule } from './dashboard-routing.module';

@NgModule({
  declarations: [
    DashboardRoutingModule.components,
    FormatOptionBadgesPipe,
  ],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    DynamicFormModule,
    LayoutModule,
    ReactiveFormsModule,
    SharedModule,
  ],
})
export class DashboardModule { }
