<div class="lab-order-container">
    <label class="section-header">Lab Order</label>
    <mat-divider></mat-divider>

    <form [formGroup]="form" class="column mt-1">
        <mat-label class>EHE Standard Lab Panels *</mat-label>
        <mat-radio-group required class="grid-2cols" formControlName="standardLabPanels">
            <mat-radio-button value="234509">Male/Female profile (234509) [CMP (14), Lipid Panel, CBC WITH Diff/Platelets, UA,
                Hemoglobin A1C]</mat-radio-button>
            <mat-radio-button value="240452">Male/Female profile (240452) [CMP (14), Lipid Panel, CBC WITH Diff/Platelets, UA,
                Hemoglobin A1C, <b>+ TSH</b>]</mat-radio-button>
        </mat-radio-group>
        <mat-label>Common Additional Tests</mat-label>
        <div class="grid-2cols">
            <div *ngFor="let option of configOptions | sortData:true:['name']:1" class="align-top"
                formGroupName="additionalTests">
                <mat-checkbox [formControlName]="option.id">
                    {{option.name}}
                </mat-checkbox>
            </div>
        </div>
        <mat-label>Non-standard Lab Tests</mat-label>
        <div formArrayName="nonStandardLabTests" *ngFor="let pannel of nonStandardLabTests.controls; let i = index">
          <div [formGroupName]="i">
            <label>{{pannel.value.labCorpTestName}}</label>
            <button mat-icon-button color="warn" (click)="removeManualPanel(i)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </div>
        <mat-label>Manual Panel Number Addition</mat-label>
        <div class="input-select-lg">
          <shared-ui-select
            label="Manual Panel Number"
            [parent]="form"
            control="manualPanel"
            sort="true"
            [options]="manualPanelOptions"
            [typeDropDown]="true">
            <button mat-raised-button color="primary" (click)="addManualPanel()">Add</button>
          </shared-ui-select>
       </div>
    </form>
    
</div>

<ng-template #validationDialog let-data>
  <mat-dialog-content>
    {{data.message}}
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" matDialogClose="yes" tabindex="1">OK</button>
  </mat-dialog-actions>
</ng-template>
