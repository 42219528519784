import { MedicalReportSection } from '@config/types';

export type YesNoType = 'Yes' | 'No';
export type FrequencyType = 'Not at all' | 'Several days' | 'More than half the days' | 'Nearly every day';
export type MedicalConditionsType = 'hbp' | 'onHbpMeds' | 'onBetaBlocker' | 'highCholesterol' | 'onStatins' | 'diabetes' | 'onDiabetesMeds';

export const ABNORMAL_VALUE = 'Findings';

export interface SnapshotFormConfig {
  ethnicBackground: FormConfigDetail;
  medicalConditions: FormConfigDetail;
  allergies: FormConfigDetail;
  alcoholMisuse: FormConfigDetail;
  currentSmoker: FormConfigDetail;
  stds: FormConfigDetail;
  gad2phq2: FormConfigDetail;
  abnormalLabs: FormConfigDetail;
}

export interface FormConfigOption {
  id: number;
  name: string;
  displayName: string;
}

export interface FormConfigContainer {
  [key: string]: FormConfigOption;
}

export interface FormConfigDetail {
  // type: FormType;
  name: string;
  displayName?: string;
  id: number;
  questions?: {
    [key: string]: FormConfigOption | FormConfigContainer;
  };
  options?: FormConfigOption[];
  validators?: string[];
}


export const patientSnapshotConfig: SnapshotFormConfig = {
   ethnicBackground: {
      name: 'Snapshot - Ethnic Background',
      displayName: 'Ethnic Background',
      id: 6,
      options: [
        {
          id: 10,
          name: 'Ethnic Background - American Indian or Alaskan Native',
          displayName: 'American Indian or Alaskan Native'
        },
        {
          id: 11,
          name: 'Ethnic Background - Asian',
          displayName: 'Asian'
        },
        {
          id: 12,
          name: 'Ethnic Background - Black or African American',
          displayName: 'Black or African American'
        },
        {
          id: 13,
          name: 'Ethnic Background - Hispanic',
          displayName: 'Hispanic'
        },
        {
          id: 14,
          name: 'Ethnic Background - Native Hawaiian or other Pacific Islander',
          displayName: 'Native Hawaiian or other Pacific Islander'
        },
        {
          id: 15,
          name: 'Ethnic Background - Two or more ethnicities',
          displayName: 'Two or more ethnicities'
        },
        {
          id: 16,
          name: 'Ethnic Background - White',
          displayName: 'White'
        },
        {
          id: 17,
          name: 'Ethnic Background - Other',
          displayName: 'Other'
        }
      ],
    },
    medicalConditions: {
      id: 7,
      name: 'Snapshot - Medical Conditions',
      displayName: 'Medical Conditions',
      questions: {
        hbp: {
          id: 18,
          name: 'Medical Conditions - High BP',
          displayName: 'High Blood Pressure'
        },
        onHbpMeds: {
          id: 19,
          name: 'High BP - on BP medication(s)',
          displayName: 'on blood pressure medication(s)'
        },
        onBetaBlocker: {
          id: 20,
          name: 'High BP - on beta blocker',
          displayName: 'on beta blocker'
        },
        highCholesterol: {
          id: 21,
          name: 'Medical Conditions - High Cholesterol',
          displayName: 'High Cholesterol'
        },
        onStatins: {
          id: 22,
          name: 'High Cholesterol - on statin(s)',
          displayName: 'on statin(s)'
        },
        diabetes: {
          id: 23,
          name: 'Medical Conditions - Diabetes',
          displayName: 'diabetes'
        },
        onDiabetesMeds: {
          id: 24,
          name: 'Diabetes - on diabetes medication(s)',
          displayName: 'on diabetes medication(s)'
        },
        noConditions: {
          id: 57,
          name: 'Medical Conditions - None',
          displayName: 'None of the above'
        }
      },
    },
    allergies: {
      id: 8,
      name: 'Medication Allergies',
      questions: {
        medicalAllergies: {
          id: 25,
          name: 'Medication Allergies',
          displayName: 'Medication Allergies'
        }
      }
    },
    alcoholMisuse: {
      id: 9,
      name: 'Alcohol Misuse',
      questions: {
        alcoholMisuse: {
          Yes: {
            id: 26,
            name: 'Alcohol Misuse - Yes',
            displayName: 'Yes'
          },
          No: {
            id: 27,
            name: 'Alcohol Misuse - No',
            displayName: 'No'
          }
        }
      }
    },
    currentSmoker: {
      id: 10,
      name: 'Current Smoker',
      questions: {
        currentSmoker: {
          Yes: {
            id: 28,
            name: 'Current Smoker - Yes',
            displayName: 'Yes'
          },
          No: {
            id: 29,
            name: 'Current Smoker - No',
            displayName: 'No'
          }
        }
      }
    },
    stds: {
      id: 11,
      name: 'Sexually Transmitted Diseases',
      questions: {
        highRiskStds: {
          Yes: {
            id: 30,
            name: 'High-Risk for STDs - Yes',
            displayName: 'Yes'
          },
          No: {
            id: 31,
            name: 'High-Risk for STDs - No',
            displayName: 'No'
          },
        },
        stdTestingDeclined: {
          id: 32,
          name: 'STD testing declined',
          displayName: 'STD testing declined'
        }
      }
    },
    gad2phq2: {
      id: 12,
      name: 'GAD/PHQ2',
      questions: {
        healthAssessmentAnswered: {
          id: 33,
          name: 'GAD2/PHQ2 - answered on HA',
          displayName: 'Patient answered all four questions on the health assessment (skip below)',
        },
        anxious: {
          39: {
            id: 39,
            name: 'Feeling nervous - Not at all',
            displayName: 'Not at all',
          },
          40: {
            id: 40,
            name: 'Feeling nervous - Several days',
            displayName: 'Several days',
          },
          41: {
            id: 41,
            name: 'Feeling nervous - More than half of the days',
            displayName: 'More than half of the days',
          },
          42: {
            id: 42,
            name: 'Feeling nervous - Nearly everyday',
            displayName: 'Nearly every day'
          },
        },
        worried: {
          43: {
            id: 43,
            name: 'Worrying - Not at all',
            displayName: 'Not at all'
          },
          44: {
            id: 44,
            name: 'Worrying - Several days',
            displayName: 'Several days'
          },
          45: {
            id: 45,
            name: 'Worrying - More than half of the days',
            displayName: 'More than half of the days'
          },
          46: {
            id: 46,
            name: 'Worrying - Nearly everyday',
            displayName: 'Nearly every day'
          }
        },
        interest: {
          47: {
            id: 47,
            name: 'Little interest - Not at all',
            displayName: 'Not at all'
          },
          48: {
            id: 48,
            name: 'Little interest - Several days',
            displayName: 'Several days'
          },
          49: {
            id: 49,
            name: 'Little interest - More than half of the days',
            displayName: 'More than half of the days'
          },
          50: {
            id: 50,
            name: 'Little interest - Nearly everyday',
            displayName: 'Nearly every day'
          },
        },
        depressed: {
          51: {
            id: 51,
            name: 'Feeling down - Not at all',
            displayName: 'Not at all'
          },
          52: {
            id: 52,
            name: 'Feeling down - Several days',
            displayName: 'Several days'
          },
          53: {
            id: 53,
            name: 'Feeling down - More than half of the days',
            displayName: 'More than half of the days'
          },
          54: {
            id: 54,
            name: 'Feeling down - Nearly everyday',
            displayName: 'Nearly every day'
          },
        },
      }
    },
    abnormalLabs: {
      id: 13,
      name: 'Abnormal Labs',
      questions: {
        hasAbnormalLabs: {
          id: 55,
          name: 'Abnormal Labs',
          displayName: 'Patient has significant abnormal labs requiring immediate notification'
        },
        noAbnormalLabs: {
          id: 56,
          name: 'Abnormal Labs - None',
          displayName: 'No abnormal labs'
        }
      }
    }
};

export interface PeConfig {
  [key: string]: MedicalReportSection[];
}

export const peConfig: PeConfig = {
  General: [
    {
      id: 58,
      sectionId: 3,
      subSectionId: 14,
      name: 'General Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 59,
      sectionId: 3,
      subSectionId: 14,
      name: 'General Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 103,
      sectionId: 3,
      subSectionId: 14,
      name: 'General Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 60,
      sectionId: 3,
      subSectionId: 14,
      name: 'General Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Skin: [
    {
      id: 61,
      sectionId: 3,
      subSectionId: 15,
      name: 'Skin Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 62,
      sectionId: 3,
      subSectionId: 15,
      name: 'Skin Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 104,
      sectionId: 3,
      subSectionId: 14,
      name: 'Skin Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 63,
      sectionId: 3,
      subSectionId: 15,
      name: 'Skin Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  HEENT: [
    {
      id: 64,
      sectionId: 3,
      subSectionId: 16,
      name: 'Heent Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 65,
      sectionId: 3,
      subSectionId: 16,
      name: 'Heent Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 105,
      sectionId: 3,
      subSectionId: 14,
      name: 'Heent Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 66,
      sectionId: 3,
      subSectionId: 16,
      name: 'Heent Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Neck: [
     {
      id: 67,
      sectionId: 3,
      subSectionId: 17,
      name: 'Neck Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 68,
      sectionId: 3,
      subSectionId: 17,
      name: 'Neck Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 106,
      sectionId: 3,
      subSectionId: 14,
      name: 'Neck Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 69,
      sectionId: 3,
      subSectionId: 17,
      name: 'Neck Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Heart: [
    {
      id: 70,
      sectionId: 3,
      subSectionId: 18,
      name: 'Heart Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 71,
      sectionId: 3,
      subSectionId: 18,
      name: 'Heart Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 107,
      sectionId: 3,
      subSectionId: 14,
      name: 'Heart Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 72,
      sectionId: 3,
      subSectionId: 18,
      name: 'Heart Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Lungs: [
    {
      id: 73,
      sectionId: 3,
      subSectionId: 19,
      name: 'Lungs Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 74,
      sectionId: 3,
      subSectionId: 19,
      name: 'Lungs Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 108,
      sectionId: 3,
      subSectionId: 14,
      name: 'Lungs Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 75,
      sectionId: 3,
      subSectionId: 19,
      name: 'Lungs Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Abdomen: [
    {
      id: 76,
      sectionId: 3,
      subSectionId: 20,
      name: 'Abdomen Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 77,
      sectionId: 3,
      subSectionId: 20,
      name: 'Abdomen Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 109,
      sectionId: 3,
      subSectionId: 14,
      name: 'Abdomen Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 78,
      sectionId: 3,
      subSectionId: 20,
      name: 'Abdomen Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  'Female - Breasts': [
    {
      id: 79,
      sectionId: 3,
      subSectionId: 21,
      name: 'Female-Breasts Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: [],
    },
    {
      id: 80,
      sectionId: 3,
      subSectionId: 21,
      name: 'Female-Breasts Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: [],
    },
    {
      id: 110,
      sectionId: 3,
      subSectionId: 14,
      name: 'Female-Breasts Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: [],
    },
    {
      id: 81,
      sectionId: 3,
      subSectionId: 21,
      name: 'Female-Breasts Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  'Female - Pelvis': [
    {
      id: 82,
      sectionId: 3,
      subSectionId: 22,
      name: 'Female-Pelvis Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 83,
      sectionId: 3,
      subSectionId: 22,
      name: 'Female-Pelvis Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 111,
      sectionId: 3,
      subSectionId: 14,
      name: 'Female-Pelvis Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 84,
      sectionId: 3,
      subSectionId: 22,
      name: 'Female-Pelvis Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  'Male - Testes': [
    {
      id: 85,
      sectionId: 3,
      subSectionId: 23,
      name: 'Male-Testes Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 86,
      sectionId: 3,
      subSectionId: 23,
      name: 'Male-Testes Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 112,
      sectionId: 3,
      subSectionId: 14,
      name: 'Male-Testes Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 87,
      sectionId: 3,
      subSectionId: 23,
      name: 'Male-Testes Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  'Male - Rectum': [
    {
      id: 88,
      sectionId: 3,
      subSectionId: 24,
      name: 'Male-Rectum Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 89,
      sectionId: 3,
      subSectionId: 24,
      name: 'Male-Rectum Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 113,
      sectionId: 3,
      subSectionId: 14,
      name: 'Male-Rectum Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 90,
      sectionId: 3,
      subSectionId: 24,
      name: 'Male-Rectum Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Extremities: [
    {
      id: 91,
      sectionId: 3,
      subSectionId: 25,
      name: 'Extremeties Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 92,
      sectionId: 3,
      subSectionId: 25,
      name: 'Extremeties Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 114,
      sectionId: 3,
      subSectionId: 14,
      name: 'Extremeties Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 93,
      sectionId: 3,
      subSectionId: 25,
      name: 'Extremeties Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Musculoskeletal: [
    {
      id: 94,
      sectionId: 3,
      subSectionId: 26,
      name: 'Musculoskeletal Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 95,
      sectionId: 3,
      subSectionId: 26,
      name: 'Musculoskeletal Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 115,
      sectionId: 3,
      subSectionId: 14,
      name: 'Musculoskeletal Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 96,
      sectionId: 3,
      subSectionId: 26,
      name: 'Musculoskeletal Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Neurologic: [
    {
      id: 97,
      sectionId: 3,
      subSectionId: 27,
      name: 'Neurologic Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 98,
      sectionId: 3,
      subSectionId: 27,
      name: 'Neurologic Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 116,
      sectionId: 3,
      subSectionId: 14,
      name: 'Neurologic Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 99,
      sectionId: 3,
      subSectionId: 27,
      name: 'Neurologic Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ],
  Psychiatric: [
    {
      id: 100,
      sectionId: 3,
      subSectionId: 28,
      name: 'Psychiatric Normal',
      displayName: 'No Significant Findings',
      additionalDisplayName: null,
      displayOrder: 1,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 101,
      sectionId: 3,
      subSectionId: 28,
      name: 'Psychiatric Abnormal',
      displayName: 'Findings',
      additionalDisplayName: null,
      displayOrder: 2,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
    {
      id: 117,
      sectionId: 3,
      subSectionId: 14,
      name: 'Psychiatric Comments',
      displayName: 'additionalComment',
      additionalDisplayName: null,
      displayOrder: 3,
      hasOtherText: true,
      isTextSelection: false,
      dataType: 'String',
      subFields: []
    },
    {
      id: 102,
      sectionId: 3,
      subSectionId: 28,
      name: 'Psychiatric Not done',
      displayName: 'Not done',
      additionalDisplayName: null,
      displayOrder: 4,
      hasOtherText: false,
      isTextSelection: false,
      dataType: 'Boolean',
      subFields: []
    },
  ]
};

export const FREQUENCIES = [
  'Not at all',
  'Several days',
  'More than half of the days',
  'Nearly every day'
];

export const FREQUENCIES_MAP = {
  anxious: Object.keys(patientSnapshotConfig.gad2phq2.questions.anxious)
    .map(key => ({
      id: parseInt(key, 10),
      name: patientSnapshotConfig.gad2phq2.questions.anxious[key].name,
      displayName: patientSnapshotConfig.gad2phq2.questions.anxious[key].displayName
    })),
  worried: Object.keys(patientSnapshotConfig.gad2phq2.questions.worried)
    .map(key => ({
      id: parseInt(key, 10),
      name: patientSnapshotConfig.gad2phq2.questions.worried[key].name,
      displayName: patientSnapshotConfig.gad2phq2.questions.worried[key].displayName
    })),
  interest: Object.keys(patientSnapshotConfig.gad2phq2.questions.interest)
    .map(key => ({
      id: parseInt(key, 10),
      name: patientSnapshotConfig.gad2phq2.questions.interest[key].name,
      displayName: patientSnapshotConfig.gad2phq2.questions.interest[key].displayName
    })),
  depressed: Object.keys(patientSnapshotConfig.gad2phq2.questions.depressed)
    .map(key => ({
      id: parseInt(key, 10),
      name: patientSnapshotConfig.gad2phq2.questions.depressed[key].name,
      displayName: patientSnapshotConfig.gad2phq2.questions.depressed[key].displayName
    })),
};

export const PE_SECTIONS = [
  'General',
  'Skin',
  'HEENT',
  'Neck',
  'Heart',
  'Lungs',
  'Abdomen',
  'Female - Breasts',
  'Female - Pelvis',
  'Male - Testes',
  'Male - Rectum',
  'Extremities',
  'Musculoskeletal',
  'Neurologic',
  'Psychiatric',
];

export interface PatientSnapshotData {
  ethnicBackground: number;
  medicalConditions: {
    hbp: boolean;
    onHbpMeds: boolean;
    onBetaBlocker: boolean;
    highCholesterol: boolean;
    onStatins: boolean;
    diabetes: boolean;
    onDiabetesMeds: boolean;
    noConditions: boolean;
  };
  allergies: string;
  alcoholMisuse: YesNoType;
  currentSmoker: YesNoType;
  stds: {
    highRiskStds: YesNoType;
    stdTestingDeclined: boolean;
  };
  gad2phq2: {
    healthAssessmentAnswered: boolean;
    anxious: FrequencyType;
    worried: FrequencyType;
    interest: FrequencyType;
    depressed: FrequencyType;
  };
  abnormalLabs: {
    hasAbnormalLabs: boolean;
    abnormalLabsDescription: string;
    noAbnormalLabs: boolean;
  };
}

export interface PeData {
  'General': object;
  'Skin': object;
  'HEENT': object;
  'Neck': object;
  'Heart': object;
  'Lungs': object;
  'Abdomen': object;
  'Female - Breasts': object;
  'Female - Pelvis': object;
  'Male - Testes': object;
  'Male - Rectum': object;
  'Extremities': object;
  'Musculoskeletal': object;
  'Neurologic': object;
  'Psychiatric': object;
}

