import { filter, Observable, Subject, takeUntil } from 'rxjs';

import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TITLE } from '@config/app.constant';
import { UserState } from '@core/state/user.state';
import { environment } from '@environments/environment';
import { Select } from '@ngxs/store';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  title = TITLE;
  gTag = 'G-D3E22YCZ6X';
  @Select(UserState.isLoggedIn) isLoggedIn$: Observable<boolean>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public router: Router,
  ) {
    const navigationEndEvents = router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    );
    navigationEndEvents.pipe(takeUntil(this.unsubscribe$)).subscribe((event: NavigationEnd) => {
      if (environment.production) {
        this.gTag = 'G-WBE8M0WSNV';
      }
      gtag('config', this.gTag, {
        page_path: event.urlAfterRedirects,
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
