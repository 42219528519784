import { AppointmentSearchTerms, HaOnlyData } from './user.state';

export class Login {
  static readonly type = '[User] Login';
  constructor( public payload: {
    username: string;
    password: string;
    landingUri?: string;
  }) {}
}

export class Auth0Login {
  static readonly type = '[User] Auth0 Login';
  constructor( public payload: {
    token: string
  }) {}
}

export class RefreshLoginToken {
  static readonly type = '[User] Refresh Login Token';
}

export class Logout {
  static readonly type = '[User] Logout';
}

export class ExtendLoginSession {
  static readonly type = '[User] Extend Login Session';
  constructor( public payload: {
    auth: string;
    refreshToken: string;
  }) {}
}

export class GetFacilityPermissions {
  static readonly type = '[User] Get Facility Permissions';
  constructor( public payload: {
    userId: number,
  }) {}
}

export class SetAppointmentSearchTerms {
  static readonly type = '[User] Set Appointment Search Terms';
  constructor( public payload: AppointmentSearchTerms) {}
}

export class ClearAppointmentSearchTerms {
  static readonly type = '[User] Clear Appointment Search Terms';
}

export class SetHaOnly {
  static readonly type = '[User] Set HA Only';
  constructor( public payload: HaOnlyData) {}
}

export class ClearHaOnly {
  static readonly type = '[User] Clear HA Only';
}

// export class LoginAttempt {
//   static readonly type = '[Auth] Login Attempt';
//   constructor(
//     public username: string,
//     public password: string,
//   ) {}
// }

// export class LoginFail {
//   static readonly type = '[Auth] Login Fail';
// }

// export class LoginSuccess {
//   static readonly type = '[Auth] Login Success';
//   constructor(
//     public authorizationDetails: AuthorizationDetail,
//   ) {}
// }

