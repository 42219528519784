import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-status-dialog',
  templateUrl: './status-dialog.component.html',
  styleUrls: ['./status-dialog.component.scss'],
})
export class StatusDialogComponent {
  message = '';
  confirmButtonText = 'Yes';
  title = 'Info';
  role = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<StatusDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      this.title = data.title || this.title;
      this.role = data.role || this.role;
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }
}
