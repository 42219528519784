import { IWithMessage } from '@config/types';

export interface AllHistory {
  documentID: number;
  documentTypeID: number;
  epmsPID: number;
  fileName: number;
  mimeType: number;
  providerBased: boolean;
  reportID: number;
  year: number;
}

export interface AllHistoryResponse extends IWithMessage {
  data: AllHistory;
}

export interface AllHistoryListResponse extends IWithMessage {
  data: AllHistory[];
}

export interface DateDetails {
  date: number;
  day: number;
  hours: number;
  minutes: number;
  month: number;
  nanos: number;
  seconds: number;
  time: number;
  timezoneOffset: number;
  year: number;
}

export interface DocumentType {
  description: string;
  id: number;
  multipleUploadsAllowed: boolean;
  name: string;
  shareWithPatient: boolean;
  signatureRequired: boolean;
}

export interface DocumentTypeResponse extends IWithMessage {
  data: DocumentType;
}

export interface DocumentMetadata {
  base64: string;
  createdBy: number;
  createdOn: DateDetails;
  displayName: string;
  documentType: DocumentType;
  fileName: string;
  fileType: string;
  id: number;
  reportID: number;
  updatedBy: number;
  updatedByFirstName: string;
  updatedByLastName: string;
  updatedOn: DateDetails;
}

export interface DocumentMetadataResponse extends IWithMessage {
  data: DocumentMetadata;
}

export interface DocumentMetadataListResponse extends IWithMessage {
  data: DocumentMetadata[];
}
