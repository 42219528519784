import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '@config/types';

@Pipe({
  name: 'gender',
})

/**
 *
 * Converts the single character M/F value API sends for gender
 *
 * @remarks
 *
 * Currently we are receiving M/F/U(Non-binary) from the Patient object
 * This will convert and display the full string values within the `PatientCard` on the dashboards
 *
 */
export class GenderStringPipe implements PipeTransform {
  transform(value: string): any {
    // protect against null values; return empty string rather
    // than "N/A", which may be offensive if viewed as someone's gender
    if (!value) {
      return '';
    }
    const lowerCaseValue = value.toLowerCase();
    const male = 'm';
    const female = 'f';
    const nonBinary = 'u';

    switch (lowerCaseValue || null) {
      case female:
        return Gender.female;
      case male:
        return Gender.male;
      case nonBinary:
        return Gender.nonBinary;
      default:
        return value;
    }
  }
}
