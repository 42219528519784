import { Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { CoreLookupControllerService } from '@core/services/core-lookup-controller.service';
import { AppointmentLists, AppointmentsState } from '@core/state/appointments.state';
import { LookupState, LookupStateModel, ProcedureLookupObject } from '@core/state/lookup.state';
import { Select } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.scss']
})
export class StateComponent implements OnInit {

  @Select(LookupState.getReportTypeIdDictionary)
  lookups$: Observable<ProcedureLookupObject>;

  @Select(LookupState)
  state$: Observable<LookupStateModel>;

  @Select(AppointmentsState)
  appointments$: Observable<AppointmentLists>;

  procedureIds = [1, 8, 32, 5, 9];
  result: string;

  ctrlProcedureId: FormControl = new FormControl();

  constructor(
    public coreLookupControllerService: CoreLookupControllerService,
  ) { }

  ngOnInit(): void {
    this.lookups$.subscribe(lookup => {
      AppUtils.DEBUG && console.log('lookup', lookup);
    });
  }

  getProcedureId(procedureId): void {
    AppUtils.DEBUG &&  console.log('Procedure Id selected', procedureId);
    this.coreLookupControllerService.getMedicalReportTypeId(procedureId).subscribe(result => {
      AppUtils.DEBUG &&  console.log('result', result);
    });
  }


}
