import { Observable, Subject, switchMap, takeUntil } from 'rxjs';

import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AppointmentDetail, LabOrderState, LookupOption, PhysicianListResponse
} from '@config/types';
import { AppointmentsControllerService } from '@core/services/appointments-controller.service';
import { LabsService } from '@patient/services/labs.service';

@Component({
  selector: 'app-exam-info',
  templateUrl: './exam-info.component.html',
  styleUrls: ['./exam-info.component.scss'],
  providers: [DatePipe],
})
export class ExamInfoComponent implements OnInit, OnDestroy {
  appointment$: Observable<AppointmentDetail>;
  appointmentID: number;

  physicianList: LookupOption[] = [];
  labOrderState: LabOrderState;

  form: FormGroup = this.fb.group({
    procedureName: { value: null, disabled: true },
    facilityName: { value: null, disabled: true },
    apptDate: { value: null, disabled: true },
    physician: [null, Validators.required],
  });

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private labService: LabsService,
    private appointmentsControllerService: AppointmentsControllerService,
  ) {
    this.appointment$ = this.route.parent.parent.paramMap.pipe(
      takeUntil(this.unsubscribe$),
      switchMap( paramMap => {
        this.appointmentID = parseInt(paramMap.get('appointmentID'), 10);
        return this.appointmentsControllerService.getAppointment(this.appointmentID);
      })
    );
    if (this.router.getCurrentNavigation()) {
      this.labOrderState = (
        this.router.getCurrentNavigation().extras.state
      ) as LabOrderState;
    }
  }

  ngOnInit(): void {
    this.appointment$
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((appointment: AppointmentDetail) => {
          const { procedureName, appointmentFacility } = appointment;
          this.form.patchValue({
            procedureName,
            facilityName: appointmentFacility.name,
            apptDate: this.datePipe.transform(
              appointment.apptDateTime,
              'dd/MM/yyyy'
            ),
          });
          return this.labService.getPhysiciansList();
        })
      )
      .subscribe((res: PhysicianListResponse) => {
        res.data.forEach((physician) => {
          this.physicianList.push({
            id: physician.adminid,
            name: physician.name,
          });
        });

        // Getting data passed through router state
        this.labOrderState = this.labOrderState ?? history.state as LabOrderState;
        const selectedPhysicianID =
          this.labOrderState.examInfo?.selectedPhysician ?? (history.state as LabOrderState).examInfo?.selectedPhysician;
        if (selectedPhysicianID) {
          const physicianIndex = this.physicianList.findIndex(
            (physician: LookupOption) => physician.id === selectedPhysicianID
          );

          if (physicianIndex >= 0){
            this.form.patchValue({
              physician: selectedPhysicianID,
            });
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
