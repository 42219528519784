import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface HealthAssesmentTab {
    selectedTab: number,
    tabCount: number,
}

@Injectable({ providedIn: 'root' })
export class HaNavigationService {
  private _haTab$ : BehaviorSubject<HealthAssesmentTab> = new BehaviorSubject(null);
  public haTab$ = this._haTab$.asObservable();

  setHealthAssesmentTab(haTab: HealthAssesmentTab) {
    this._haTab$.next(haTab);
  }

  getHealthAssesmentTab(): HealthAssesmentTab {
    return this._haTab$.getValue();
  }

}
