<div class="review-wrapper">
  <div *ngIf="loading">Loading...</div>
  <div *ngIf="!medicalReport && !loading">Review data not available.</div>
  <ng-container *ngIf="medicalReport.length !== 0">
    <ng-container *ngFor="let primarySection of medicalReport">
      <div
        class="section-inner"
        [ngClass]="{
          'lightblue-background': primarySection.sectionID === followupSectionId
        }"
      >
        <h2>{{ primarySection.mainSection }}</h2>
        <ng-container *ngIf="primarySection.sections">
          <div
            *ngFor="let section of primarySection.sections"
            class="review-section"
          >
            <h4 *ngIf="section.sectionName" class="form-heading">
              {{ section.sectionName }}
            </h4>
            <p *ngIf="section.sectionNotes" class="indent-left">
              {{ section.sectionNotes }}
            </p>
            <div *ngFor="let field of section.fields" class="indent-left">
              <p class="review-field-name">{{ field.fieldName }}</p>
              <div
                class="row justify-content__between pointer"
                (mouseenter)="
                  mouseEnter({ showEdit: true, showField: field.fieldID })
                "
                (mouseleave)="mouseLeave()"
              >
              <ng-container *ngIf="showField === field.fieldID; else staticNotes">
                <p class="review-notes">{{ field.notes }}</p>
                <button
                *ngIf="field.canEditMacro"
                mat-icon-button
                [matTooltip]="'Update' + ' ' + field.fieldName"
                (click)="openMacroDialog(field)"
              >
                <span class="flex-spacer">
                  <mat-icon>edit</mat-icon>
                </span>
              </button>
              </ng-container>
              <!-- <ng-container *ngIf="showField; else staticNotes"></ng-container> -->

              <ng-template #staticNotes><p class="review-notes">{{ field.notes }}</p></ng-template>
                <div style="height: 40px">
                  <ng-container
                    *ngIf="
                      showEdit &&
                      field.canEditMacro &&
                      field.fieldID === showField
                    "
                  >

                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
  <div class="section-inner" *ngIf="status">
    <span>
      <p>
        By submitting this report, I attest that I have completed the history,
        physical examination, assessment, and plan for this patient.
      </p>
      </span>
    <span
      matTooltip="Labs review needed"
      [matTooltipDisabled]="!status.mdlabsSignOffAllowedForCurrentStatus || !rolesForSelectedFacility?.isMD"
      matTooltipShowDelay="500"
    >
      <button
        [disabled]="!status.mdsignOffAllowedForCurrentStatus || !rolesForSelectedFacility?.isMD"
        mat-flat-button
        class="primary"
        (click)="openDialog('MD')"
      >
        MD Signoff<span></span></button
    ></span>
    <button
      [disabled]="!status.crsignOffAllowedForCurrentStatus || !rolesForSelectedFacility?.isCR"
      mat-flat-button
      class="primary"
      (click)="openDialog('CR')"
    >
      CR Signoff
    </button>
    <span matTooltip="Send report back to physician for review."
      [matTooltipDisabled]="!status.crsendBackToMDAllowedForCurrentStatus || !rolesForSelectedFacility?.isCR">
      <button [disabled]="!status.crsendBackToMDAllowedForCurrentStatus || !rolesForSelectedFacility?.isCR" mat-flat-button
        class="primary" (click)="openDialog('CR', 'needsReview')">
        Needs Review
      </button>
    </span>
  </div>
</div>
