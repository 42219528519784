<section [formGroup]="parent">
  <ng-content></ng-content>
  <mat-radio-group formControlName="determination">
    <ng-container *ngFor="let option of options">
      <div>
        <mat-radio-button *ngIf="!shouldDisplayTextarea(option)" [value]="option.id">
          {{option.displayName}}
        </mat-radio-button>
        <app-textarea-control *ngIf="shouldDisplayTextarea(option)" [control]="parent.get('note')"></app-textarea-control>
      </div>
    </ng-container>
  </mat-radio-group>
</section>