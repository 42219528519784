import {
    debounceTime, filter, first, Observable, pairwise, Subject, takeUntil, withLatestFrom
} from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentDetail, Facility, FacilityWithRoleDTO, User } from '@config/types';
import { AppointmentsControllerService } from '@core/services/appointments-controller.service';
import { AppointmentLists, AppointmentsState } from '@core/state/appointments.state';
import { SetAppointmentSearchTerms } from '@core/state/user.actions';
import { AppointmentSearchTerms, FacilityWithRoleInternal, UserState } from '@core/state/user.state';
import { Select, Store } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

interface AppointmentFilterValues {
  facilityID: string;
  appointmentDate: string;
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  // @Select(UserState.getUser) user$: Observable<User>;
  @Select(UserState.getAppointmentSearchTerms) searchTerms$: Observable<AppointmentSearchTerms>;
  @Select(UserState.getAssignedFacilitiesList) facilities$: Observable<Facility[]>;
  facilities: Facility[];
  @Select(UserState.getFacilityPermissions) facilityPermissions$: Observable<FacilityWithRoleInternal>;
  facilityPermissions: FacilityWithRoleInternal;
  isTodoOnlyView: boolean;

  @Select(AppointmentsState.getAppointments)
  appointmentsList$: Observable<AppointmentLists>;
  isFirstSearchComplete = false;
  debug = AppUtils.DEBUG;
  filterGroup: FormGroup;
  ctrlAppointmentID = new FormControl();

  private unsubscribe$ = new Subject<void>();

  constructor(private appointmentsControllerService: AppointmentsControllerService, private matDialog: MatDialog, private store: Store) {}

  ngOnInit(): void {
    this.filterGroup = new FormGroup({
      facilityID: new FormControl(),
      appointmentDate: new FormControl()
    });

    this.facilities$.pipe(
      takeUntil(this.unsubscribe$),
      withLatestFrom(this.facilityPermissions$)
    ).subscribe(([facilities, facilityPermissions]) => {
      this.facilities = facilities;
      this.facilityPermissions = facilityPermissions;
    });

    this.searchTerms$
      .pipe(
        takeUntil(this.unsubscribe$),
        pairwise(),
        withLatestFrom(this.facilities$),
        filter(([[_, searchTerms], facilities]) => !!facilities && !!searchTerms.facilityID)
      )
      .subscribe(([[prevSearchTerms, searchTerms], facilities]) => {
        this.debug && console.log('date selected', searchTerms.appointmentDate);
        const params = {
          appointmentDate: searchTerms.appointmentDate,
          facilityID: (searchTerms.facilityID || facilities[0].id).toString()
        };
        if (!this.isFirstSearchComplete || prevSearchTerms?.facilityID !== searchTerms.facilityID) {
          this.appointmentsControllerService.getAllAppointments(params);
        } else {
          this.appointmentsControllerService.getToDoAppoinments(params);
        }
        this.isFirstSearchComplete = true;
      });

    this.filterGroup.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((value: AppointmentFilterValues) => /^\d{4}-\d{1,2}-\d{1,2}$/.test(AppUtils.getFormattedDate(value.appointmentDate))),
        debounceTime(AppUtils.DEBOUNCE_TIME)
      )
      .subscribe((searchTerms) => {
        const facilityID = parseInt(searchTerms.facilityID, 10);
        this.isTodoOnlyView = this.isNonMdRole(facilityID);
        this.store.dispatch(
          new SetAppointmentSearchTerms({
            appointmentDate: searchTerms.appointmentDate,
            facilityID
          })
        );
      });

    // set default search
    this.facilities$.pipe(first(), withLatestFrom(this.searchTerms$)).subscribe(([facilities, searchTerms]) => {
      const facilityID = searchTerms?.facilityID ?? facilities[0]?.id;
      const appointmentDate = searchTerms?.appointmentDate ?? new Date();
      this.filterGroup.setValue(
        {
          facilityID,
          appointmentDate
        },
        { emitEvent: false }
      );
      this.isTodoOnlyView = this.isNonMdRole(facilityID);
      this.store.dispatch(new SetAppointmentSearchTerms(this.filterGroup.value));
    });
  }

  confirmAppointment(appointment: AppointmentDetail): void {
    this.appointmentsControllerService.openDialog({
      appointment,
      matDialog: this.matDialog
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isCrOnlyRole(selectedFacilityID: number): boolean {
    const facilitySettings: FacilityWithRoleDTO = this.facilityPermissions[selectedFacilityID];
    this.debug && console.log('isCrOnlyRole', selectedFacilityID, facilitySettings, !(facilitySettings.isMA || facilitySettings.isMD));
    return !(facilitySettings.isMA || facilitySettings.isMD);
  }

  isNonMdRole(selectedFacilityID: number): boolean {
      const facilitySettings: FacilityWithRoleDTO = this.facilityPermissions[selectedFacilityID];
      this.debug && console.log('isNonMdRole', selectedFacilityID, facilitySettings, !facilitySettings.isMD);
      return !facilitySettings.isMD;
  }
}
