import { IEmpty, IParamsUserAndEpms, IWithMessage, ObjectiveField } from '@config/types';

export interface MedicalReportMacrosOverride {
  fieldID: number;
  overrideMacroNotes: string;
  reportID: number;
}

export interface MedicalReportAssessmentPlanSection {
  assessmentPlanFields: ObjectiveField[];
  reportID: number;
  sectionID: number;
  subSectionID: number;
}

// POST /provider/user/medical-report/assessment-plan/override-macro
export interface MedicalReportAssessmentPlanOverrideMacro extends IParamsUserAndEpms {
  overrideMacroDto: MedicalReportMacrosOverride;
}
export interface MedicalReportPostAssessmentPlanOverrideMacroResponse extends IWithMessage {
  data: MedicalReportMacrosOverride;
}

// GET /provider/user/medical-report/assessment-plan/section
export interface MedicalReportGetAssessmentPlanSectionResponse extends IWithMessage {
  data: MedicalReportAssessmentPlanSection;
}

// POST /provider/user/medical-report/assessment-plan/section
export interface MedicalReportPostAssessmentPlanSection extends IParamsUserAndEpms {
  reqMedicalReportSectionDto: MedicalReportAssessmentPlanSection;
}
export interface MedicalReportPostAssessmentPlanSectionResponse extends IWithMessage {
  data: MedicalReportAssessmentPlanSection;
}

// POST /provider/user/medical-report/cr-sent-back-to-md
export interface MedicalReportPostCrSentBackToMd extends IEmpty {}


