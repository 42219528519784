import { EmptyPatient, Patient } from './patient';
import { Exam } from './provider';

export const EmptyAppointmentFacility: AppointmentFacility = {
    active: null,
    address: null,
    address2: null,
    city: null,
    displayName: null,
    ehefacility: null,
    facilityType: null,
    id: null,
    name: null,
    onlyOutSourcedFacility: null,
    outsourceType: null,
    primaryFacility: null,
    primaryNetworkFacility: null,
    primaryType: null,
    state: null,
    zip: null,
};

export const EmptyAppointment: Appointment = {
    appointmentFacility: EmptyAppointmentFacility,
    appointmentID: null,
    apptDateTime: null,
    duration: null,
    eventType: null,
    patient: EmptyPatient,
    primaryProcedureID: null,
    procedureID: null,
    procedureName: null,
    providerID: null,
    status: null,
    type: null,
};

export interface Appointment {
    appointmentDate?: string;
    appointmentFacility: AppointmentFacility;
    appointmentID: number;
    apptDateTime?: string | null;
    duration?: string | null;
    eventType?: string | null;
    exam?: Exam;
    patient: Patient;
    primaryProcedureID?: number | null;
    procedureID: number;
    procedureName: string | null;
    providerID?: number | null;
    status?: string | null;
    type: string | null;
}

export interface Facility {
    active: string;
    displayName: string;
    ehefacility: boolean;
    facilityType: number;
    id: number;
    name: string;
    onlyOutSourcedFacility: boolean;
    outsourceType: number;
    primaryFacility: boolean;
    primaryNetworkFacility: boolean;
    primaryType: number;
}

// Custom Object for stream
export interface FacilityAndRoleInternal {
    id: string;
    name: string;
    isCr: boolean;
    isMd: boolean;
    isMa: boolean;
}

//   Object from API
export interface FacilityWithRole {
    allowedRolesAtFac: number[];
    facility: Facility;
    isCR: boolean;
    isMA: boolean;
    isMD: boolean;
}

export interface FacilityWithRoleResponse {
    data: FacilityWithRole[];
}

export interface AppointmentFacility {
    id: number;
    name: string;
    displayName: string;
    facilityType: number;
    primaryType: number;
    outsourceType: number;
    active: string;
    address?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    ehefacility?: boolean;
    onlyOutSourcedFacility?: boolean;
    primaryNetworkFacility?: boolean;
    primaryFacility?: boolean;
}

export interface AppointmentResponse {
    data: {
        patientAppointments: Appointment[];
    };
}

export interface NoShowResponse {
    data: {
        noShowAllowed: boolean;
    };
    message: string;
}