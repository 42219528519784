export const TITLE = 'EHE Connect';

export const SUB_PROCEDURE_TITLE = {
    physicalExam: 'Physical Exam',
    peripheralVascularABI: 'Peripheral Vascular(ABI)',
    mammogram: 'Mammogram',
    vascularAorta: 'Vascular Aorta',
    coronaryCalciumScan: 'Coronary Calcium CT',
    lungCt: 'Lung CT',
    dexa: 'DEXA',
    breastUltrasound: 'Breast Ultrasound',
};

export const SUB_PROCEDURE_MAPPING: Map<number, string> = new Map<number, string>([
    // [1, 'physical-exam'],
    [5, 'coronary-calcium-scan'],
    [6, 'lung-CT'],
    [7, 'dexa'],
    [8, 'mammogram'],
    [9, 'vascular-aorta'],
    [32, 'peripheral-vascular-ABI'],
    [134217728, 'breast-ultrasound']
]);

/**
 * Constants for Mammogram Section Name
 */
export const MammogramSectionName = {
    BiRadCategory: 'Bi-Rad Category',
    Findings: 'Mammogram Findings',
    Comments: 'Optional Comment on Mammogram'
};

/**
 * Constant is created so that We can get the Id, sectionId, subsection Id
 */
export const MammogramDisplayName = 'Mammogram';

/**
 * Constant for Immunization section for "other" id from PP-1315
 */
export const IMMUNIZATION_OTHER_ID = 32;

export const NO_ABNORMAL_LABS = 56;
export const HAS_ABNORMAL_LABS = 55;


