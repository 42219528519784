import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorDialogService } from '@core/services/error-dialog.service';
import { Select, Store } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

import { AuthService } from '../services/auth.service';
import { StorageService } from '../services/storage.service';
import { UserState } from '../state/user.state';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  @Select(UserState.getToken) token$: Observable<string>;
  token: string;

  @Select(UserState.getUser) usse$: Observable<string>;
  user: string;

  constructor(
    public errorDialogService: ErrorDialogService,
    public config: AppConfigService,
    private authService: AuthService,
    private storageService: StorageService,
    private store: Store,
  ) {
    this.token$.subscribe( token => this.token = token);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const loginUrl = this.config.ENDPOINTS.login || this.config.ENDPOINTS.loginWithAuth0; // To guard from expired, cached token

    request = this.setTokenToHeader(this.token, request);
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          AppUtils.DEBUG &&
            console.log(
              'event--->>>',
              event,
              'loggedIn',
              this.storageService.getItem('loggedIn')
            );
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        const errorLevel = Math.floor(error.status / 100);
        if (errorLevel === 5 || request.url === loginUrl) {
          console.warn(`
          Server Error
          Reason: ${
            error && error.error && error.error.message
              ? error.error.message
              : ''
          }
          Status: ${error.status}
          `);
        }
        return throwError(error);
      })
    );
  }

  setTokenToHeader(token: string, request: HttpRequest<any>): HttpRequest<any> {
    const state = this.store.selectSnapshot(UserState.getAuth);
    const loginUrl: string = this.config.ENDPOINTS.login || this.config.ENDPOINTS.loginWithAuth0; // To guard from expired, cached token
    if (state?.accessToken && request.url !== loginUrl) {
      return request.clone({
        headers: request.headers.set('X-auth-token', state.accessToken),
      });
    }
    return request;
  }
}
