<div *ngIf="requestCreated; else showRequisitions">
    <router-outlet></router-outlet>
</div>

<ng-template #showRequisitions>
    <div class="requisitions-container column flex-center">
        <div class="requistion-links">
            <a class="link-text" (click)="createRequisition('labRequest')">+ Lab Requisition</a>
            <a class="link-text" (click)="createRequisition('papRequest')">+ Pap Requisition</a>
        </div>

        <div *ngIf="requisitionsEmpty; else requisitionsList" class="requisition-info">
            There are no requisitions created for this patient. Click above to create one
        </div>
        <ng-template #requisitionsList>
            <!-- Table of lab/pap requests created -->
            <table mat-table [dataSource]="reqOrdersData" class="mat-elevation-z8">
                <ng-container matColumnDef="displayOrderDate">
                  <th mat-header-cell *matHeaderCellDef> Date Added </th>
                  <td mat-cell *matCellDef="let reqOrder"> {{reqOrder.displayOrderDate}} </td>
                </ng-container>
              
                <ng-container matColumnDef="displayType">
                  <th mat-header-cell *matHeaderCellDef> Type </th>
                  <td mat-cell *matCellDef="let reqOrder"> {{reqOrder.displayType}} </td>
                </ng-container>
              
                <ng-container matColumnDef="requisitionID">
                  <th mat-header-cell *matHeaderCellDef> Req.Number </th>
                  <td mat-cell *matCellDef="let reqOrder"> {{reqOrder.requisitionID}} </td>
                </ng-container>
              
                <ng-container matColumnDef="statusName">
                  <th mat-header-cell *matHeaderCellDef> Status </th>
                  <td mat-cell *matCellDef="let reqOrder"> {{reqOrder.statusName}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let reqOrder">
                      <button mat-icon-button [matMenuTriggerFor]="recordOptions" #menuTrigger>
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #recordOptions="matMenu" xPosition="before">
                        <button mat-menu-item class="row" (click)="viewReqorder(reqOrder.requisitionID)" >
                          <mat-icon>remove_red_eye</mat-icon>
                          <span>View Order</span>
                        </button>
                        <button mat-menu-item class="row" (click)="printReqorder(reqOrder.requisitionID)">
                          <mat-icon>print</mat-icon>
                          <span>Print Order</span>
                        </button>
                      </mat-menu>
                    </td>
                  </ng-container>
              
                <tr mat-header-row *matHeaderRowDef="reqDetailsColumns; sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: reqDetailsColumns;"></tr>
              </table>
        </ng-template>
    </div>
</ng-template>