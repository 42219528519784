import { Pipe, PipeTransform } from "@angular/core";
import { BadgeConfig, FacilityWithRoleDTO } from "@config/types";
import { FacilityWithRoleInternal } from "@core/state/user.state";

@Pipe({
  name: 'formatOptionBadges'
})
export class FormatOptionBadgesPipe implements PipeTransform {
  private static badgeConfig: BadgeConfig[] = [
    { text: 'MD', color: 'primary' },
    { text: 'MA', color: 'accent' },
    { text: 'CR', color: 'warn' }
  ];

  transform(facilities: FacilityWithRoleInternal): BadgeConfig[][] {
    const data = Object.keys(facilities).reduce( (z, id) => [...z, this.getRelevantBadges(facilities[id])], []);
    // console.log('Badges pipe', data);
    return data;
  }

  private getRelevantBadges(facility: FacilityWithRoleDTO): BadgeConfig[] {
    const data = FormatOptionBadgesPipe.badgeConfig.reduce( (z, v) => facility['is' + v.text] ? [...z, v] : z, []);
    // console.log('getRelevantBadges', data);
    return data;
  }
}
