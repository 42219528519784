<div class="cytology-details-container column">
    <label class="section-header">Cytology Details</label>
    <div class="divider">
        <mat-divider></mat-divider>
    </div>

    <form class="cytology-form-wrapper column" [formGroup]="form">
        <shared-ui-input formFieldSize="small" [parent]="form" inputType="datePicker" control="menodate"
            label="Last Meno Date"></shared-ui-input>
        
        <shared-ui-select label="CYT Source" [parent]="form" control="cytSource" [typeDropDown]="true" [options]="cytSourceOptions"></shared-ui-select>
        <shared-ui-select label="CYT Collection" [parent]="form" control="cytCollection" [typeDropDown]="true" [options]="cytCollectionOptions" ></shared-ui-select>
        <shared-ui-select multipleSelect label="CYT Other Patient Info" [parent]="form" control="cytOtherPatientInfo" [typeDropDown]="true" [options]="cytOtherPatientinfoOptions" ></shared-ui-select>
        <shared-ui-select multipleSelect label="CYT Previous Condition" [parent]="form" control="cytPreviousCondition" [typeDropDown]="true" [options]="cytPreviousConditionOptions" ></shared-ui-select>
        <shared-ui-select multipleSelect label="CYT Previous Treatment" [parent]="form" control="cytPreviousTreatment" [typeDropDown]="true" [options]="cytPreviousTreatmentOptions" ></shared-ui-select>
    </form>
</div>