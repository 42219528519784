import { filter, first, map, Observable, race, share, switchMap, take, withLatestFrom } from 'rxjs';

import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SUB_PROCEDURE_MAPPING } from '@config/app.constant';
import { APP_ROUTES } from '@config/application-routes';
import { AppointmentDetail } from '@config/types';
import {
  ConfirmationDialogComponent
} from '@core/components/confirmation-dialog/confirmation-dialog.component';
import { CoreLookupControllerService } from '@core/services/core-lookup-controller.service';
import {
    ForgetAllAppointments, GetToDoAppointments, SubmitNoShow
} from '@core/state/appointments.actions';
import { AppointmentLookupObject, AppointmentsState } from '@core/state/appointments.state';
import { AppointmentSearchTerms, UserState } from '@core/state/user.state';
import {
  AppointmentSelectDialogComponent
} from '@dashboard/components/appointment-select-dialog/appointment-select-dialog.component';
import { Select, Store } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

import {
  GetAllAppointments,
  UpdateAppointmentExam,
  RefreshSelectedAppointment,
} from '../state/appointments.actions';
import { PatientService } from './patient.service';

export interface OpenDialogArguments {
  appointment: AppointmentDetail;
  matDialog: MatDialog;
}

@Injectable()
export class AppointmentsControllerService {
  @Select(AppointmentsState.getAll) allAppointments$: Observable<AppointmentLookupObject>;
  @Select(AppointmentsState.isSearchInProgress) isSearchInProgress$: Observable<boolean>;
  @Select(UserState.getAppointmentSearchTerms) appointmentSearchTerms$: Observable<AppointmentSearchTerms>;

  constructor(
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private patientService: PatientService
  ) {}

  getToDoAppoinments(params: { facilityID: string, appointmentDate: string }): Observable<AppointmentLookupObject> {
    AppUtils.DEBUG && console.log('getToDoAppoinments() Appointments controller params', params);
    return this.store.dispatch(
      new GetToDoAppointments(params)
    );
  }

  getAllAppointments(params: { facilityID: string, appointmentDate: string }): Observable<AppointmentLookupObject> {
    return this.store.dispatch(
      new GetAllAppointments(params)
    ).pipe(share());
  }

  getAppointment(appointmentID: number): Observable<AppointmentDetail> {
    return this.store.select(AppointmentsState.getAppointment(appointmentID));
  }

  refreshAppointment(appointmentID: number): Observable<AppointmentDetail> {
    return this.store.dispatch(
      new RefreshSelectedAppointment({appointmentID})
    )
  }

  createAppointmentReportIfNecessary(appointmentID: number): Observable<AppointmentDetail> {
    AppUtils.DEBUG && console.log('inside createAppointmentReportIfNecessary', appointmentID);
    const appointment$ = this.getAppointmentFetchIfNecessary(appointmentID);
    return race([
      appointment$.pipe(
        filter(appointment => !appointment.exam || !appointment.exam.latestReportStatus),
        switchMap(appointment => {
          return this.store.dispatch(
            new UpdateAppointmentExam({appointmentID: appointment.appointmentID})
          );
        }),
        switchMap( () => {
          return this.store.select(AppointmentsState.getAppointment(appointmentID));
        }),
        take(1)
      ),
      appointment$.pipe(
        filter(appointment => !!appointment.exam && !!appointment.exam.latestReportStatus),
        take(1)
      )
    ]).pipe(take(1));
  }

  getAppointmentFetchIfNecessary(appointmentID: number): Observable<AppointmentDetail> {
    return race([
      this.allAppointments$.pipe(
        withLatestFrom(this.isSearchInProgress$),
        filter(([appointments, isSearchInProgress]) => !isSearchInProgress && (!appointments || !appointments[appointmentID])),
      ).pipe(
        switchMap(() => this.appointmentSearchTerms$),
        switchMap( searchTerms => {
          const facilityID = searchTerms.facilityID.toString();
          const appointmentDate = searchTerms.appointmentDate;
          return this.getAllAppointments({
            facilityID,
            appointmentDate
          });
        })
      ),
      // Or, use our existing appointments data if we have it available
      this.allAppointments$.pipe(
        filter((appointments) => !!appointments[appointmentID]),
      )
    ]).pipe(
      // switch context to the appointment we're concerned about
      map(appointments => {
        const appointment = appointments[appointmentID];
        if (!appointment) {
          throw new Error(`Unable to load appointment time ${appointments}`);
        }
        return appointment;
      })
    );
  }

  getAppointmentFromRoute(route: ActivatedRoute = this.route): Observable<AppointmentDetail> {
    AppUtils.DEBUG && console.log('getAppointmentFromRoute', route, route.snapshot.paramMap);
    const appointmentID = route.snapshot.paramMap.get('appointmentID') ||
      route.parent?.snapshot.paramMap.get('appointmentID') ||
      route.parent?.parent?.snapshot.paramMap.get('appointmentID') ||
      this.getAppointmentIdFromUrl() ;
    return this.getAppointment(parseInt(appointmentID, 10));
  }

  getAppointmentIdFromUrl(): string {
    AppUtils.DEBUG && console.log(this.route.snapshot.paramMap, this.router.routerState.snapshot.url);
    const appointmentsRegex =  new RegExp(`/\/?${APP_ROUTES.dashboard}\/([0-9]+)`, 'i');
    const matches = appointmentsRegex.exec(this.router.routerState.snapshot.url);
    const appointmentID = matches && matches[1];
    AppUtils.DEBUG && console.log('appointmentID', appointmentID, matches);
    return appointmentID;
  }

  forgetAllAppointments(): void {
    this.store.dispatch(new ForgetAllAppointments());
  }

  openDialog(params: OpenDialogArguments): void {
    const dialog = params.matDialog.open(AppointmentSelectDialogComponent, {
        width: '400px',
        scrollStrategy: new NoopScrollStrategy(), // to avoid scrollbar when mat-dialog is displayed
        data: params.appointment,
      });
    dialog
      .afterClosed()
      .pipe(first())
      .subscribe((shouldAdvance) => {
        if (shouldAdvance) {
          if (shouldAdvance.noShowButtonEvent) {
            this.noShowConfirmationPopup(
              params.appointment.appointmentID,
              () => this.openDialog.apply(this, arguments as any),
              params.matDialog
            );
            return;
          }
          const routePath =
              SUB_PROCEDURE_MAPPING.has(params.appointment.procedureID)
              ? [APP_ROUTES.subProcedure, SUB_PROCEDURE_MAPPING.get(params.appointment.procedureID)]
              : [APP_ROUTES.ha];
          this.router.navigate([
          APP_ROUTES.dashboard,
          params.appointment?.appointmentID,
            ...routePath,
          ]);
          this.patientService.updatePatient(shouldAdvance);
        }
      });
  }

  noShowConfirmationPopup(appointmentID: number, cancelEvent, matDialog): void {
    const message =
      'Are you sure you want to mark this patient as a "No Show"? You cannot undo this action.';
    const title = ' Mark Patient as No Show';

    const dialogRef = matDialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        buttonText: {
          ok: 'Yes',
          cancel: 'Cancel',
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.store.dispatch(
          new SubmitNoShow({appointmentID: appointmentID})
        );
      } else {
        cancelEvent();
      }
    });
  }


}
