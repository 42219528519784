import { forkJoin, Observable, switchMap, take, timer } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MedicalReportObjectiveData, MedicalReportSection } from '@config/types';
import { HttpContextHelperService } from '@core/services/http-context-helper.service';
import {
    FormConfigContainer, FormConfigOption, FREQUENCIES_MAP, patientSnapshotConfig,
    PatientSnapshotData, peConfig, PeData
} from '@patient/pages/pe/pe-form.config';
import {
    NO_VALUE, YES_VALUE
} from '@shared/components/radio-button-set/radio-button-set.component';
import { AppUtils } from '@shared/utils/app.utils';

@Injectable()
export class PEService {
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private httpContextHelperService: HttpContextHelperService,
  ) { }

  mapSnapshotDataOut(
    input: PatientSnapshotData,
    sectionID: number,
  ): MedicalReportObjectiveData[] {
    const ethnicitySelection: FormConfigOption = patientSnapshotConfig.ethnicBackground.options.find(
      (option) => option.id === input.ethnicBackground
    );
    const {
      hbp,
      onHbpMeds,
      onBetaBlocker,
      highCholesterol,
      onStatins,
      diabetes,
      onDiabetesMeds,
      noConditions,
    } = patientSnapshotConfig.medicalConditions.questions;

    let medicalConditionFields = [
      input.medicalConditions.hbp && {
        fieldID: hbp.id,
        fieldName: hbp.name,
        fieldValue: 'true',
      },
      input.medicalConditions.onHbpMeds && {
        fieldID: onHbpMeds.id,
        fieldName: onHbpMeds.name,
        fieldValue: 'true',
      },
      input.medicalConditions.onBetaBlocker && {
        fieldID: onBetaBlocker.id,
        fieldName: onBetaBlocker.name,
        fieldValue: 'true',
      },
      input.medicalConditions.highCholesterol && {
        fieldID: highCholesterol.id,
        fieldName: highCholesterol.name,
        fieldValue: 'true',
      },
      input.medicalConditions.onStatins && {
        fieldID: onStatins.id,
        fieldName: onStatins.name,
        fieldValue: 'true',
      },
      input.medicalConditions.diabetes && {
        fieldID: diabetes.id,
        fieldName: diabetes.name,
        fieldValue: 'true',
      },
      input.medicalConditions.onDiabetesMeds && {
        fieldID: onDiabetesMeds.id,
        fieldName: onDiabetesMeds.name,
        fieldValue: 'true',
      },
    ].filter((v) => v);
    if (medicalConditionFields.length === 0 && input.medicalConditions.noConditions) {
      medicalConditionFields = [
        {
          fieldID: noConditions.id,
          fieldName: noConditions.name,
          fieldValue: 'true',
        },
      ];
    }

    let abnormalLabsField;
    if (input.abnormalLabs.hasAbnormalLabs && input.abnormalLabs.abnormalLabsDescription) {
      abnormalLabsField = {
        fieldID: patientSnapshotConfig.abnormalLabs.questions.hasAbnormalLabs.id,
        fieldName: patientSnapshotConfig.abnormalLabs.questions.hasAbnormalLabs.name,
        fieldValue: 'true',
        fieldOtherValue: input.abnormalLabs.abnormalLabsDescription,
      };
    } else if (input.abnormalLabs.noAbnormalLabs) {
      // explicitly declare no abnormal labs
      abnormalLabsField = {
        fieldID: patientSnapshotConfig.abnormalLabs.questions.noAbnormalLabs.id,
        fieldName: patientSnapshotConfig.abnormalLabs.questions.noAbnormalLabs.name,
        fieldValue: 'true',
      };
    }

    return ([
      ethnicitySelection && {
        sectionID,
        subSectionID: patientSnapshotConfig.ethnicBackground.id,
        objectiveFields: [
          {
            fieldID: ethnicitySelection.id,
            fieldName: ethnicitySelection.name,
            fieldValue: 'true',
          },
        ],
      },
      {
        sectionID,
        subSectionID: patientSnapshotConfig.medicalConditions.id,
        objectiveFields: medicalConditionFields,
      },
      {
        sectionID,
        subSectionID: patientSnapshotConfig.allergies.id,
        objectiveFields: [
          input.allergies && {
            fieldID: patientSnapshotConfig.allergies.questions.medicalAllergies.id,
            fieldName: patientSnapshotConfig.allergies.questions.medicalAllergies.name,
            fieldValue: 'true',
            fieldOtherValue: input.allergies,
          },
        ],
      },
      {
        sectionID,
        subSectionID: patientSnapshotConfig.alcoholMisuse.id,
        objectiveFields: [
          input.alcoholMisuse === YES_VALUE && {
            fieldID: (patientSnapshotConfig.alcoholMisuse.questions.alcoholMisuse as FormConfigContainer).Yes.id,
            fieldName: (patientSnapshotConfig.alcoholMisuse.questions.alcoholMisuse as FormConfigContainer).Yes.name,
            fieldValue: 'true',
          },
          input.alcoholMisuse === NO_VALUE && {
            fieldID: (patientSnapshotConfig.alcoholMisuse.questions.alcoholMisuse as FormConfigContainer).No.id,
            fieldName: (patientSnapshotConfig.alcoholMisuse.questions.alcoholMisuse as FormConfigContainer).No.name,
            fieldValue: 'true',
          },
        ].filter((v) => v),
      },
      {
        sectionID,
        subSectionID: patientSnapshotConfig.currentSmoker.id,
        objectiveFields: [
          input.currentSmoker === YES_VALUE && {
            fieldID: (patientSnapshotConfig.currentSmoker.questions.currentSmoker as FormConfigContainer).Yes.id,
            fieldName: (patientSnapshotConfig.currentSmoker.questions.currentSmoker as FormConfigContainer).Yes.name,
            fieldValue: 'true',
          },
          input.currentSmoker === NO_VALUE && {
            fieldID: (patientSnapshotConfig.currentSmoker.questions.currentSmoker as FormConfigContainer).No.id,
            fieldName: (patientSnapshotConfig.currentSmoker.questions.currentSmoker as FormConfigContainer).No.name,
            fieldValue: 'true',
          },
        ].filter((v) => v),
      },
      {
        sectionID,
        subSectionID: patientSnapshotConfig.stds.id,
        objectiveFields: [
          input.stds.highRiskStds === YES_VALUE && {
            fieldID: (patientSnapshotConfig.stds.questions.highRiskStds as FormConfigContainer).Yes.id,
            fieldName: (patientSnapshotConfig.stds.questions.highRiskStds as FormConfigContainer).Yes.name,
            fieldValue: 'true',
          },
          input.stds.highRiskStds === NO_VALUE && {
            fieldID: (patientSnapshotConfig.stds.questions.highRiskStds as FormConfigContainer).No.id,
            fieldName: (patientSnapshotConfig.stds.questions.highRiskStds as FormConfigContainer).No.name,
            fieldValue: 'true',
          },
          input.stds.stdTestingDeclined && {
            fieldID: patientSnapshotConfig.stds.questions.stdTestingDeclined.id,
            fieldName: patientSnapshotConfig.stds.questions.stdTestingDeclined.name,
            fieldValue: 'true',
          },
        ].filter((v) => v),
      },
      {
        sectionID,
        subSectionID: patientSnapshotConfig.gad2phq2.id,
        objectiveFields: [
          input.gad2phq2.healthAssessmentAnswered && {
            fieldID: patientSnapshotConfig.gad2phq2.questions.healthAssessmentAnswered.id,
            fieldName: patientSnapshotConfig.gad2phq2.questions.healthAssessmentAnswered.name,
            fieldValue: 'true',
          },
          input.gad2phq2.anxious && {
            fieldID: input.gad2phq2.anxious,
            fieldName: FREQUENCIES_MAP.anxious.find((c) => c.id === parseInt(input.gad2phq2.anxious, 10)).name,
            fieldValue: 'true',
          },
          input.gad2phq2.worried && {
            fieldID: input.gad2phq2.worried,
            fieldName: FREQUENCIES_MAP.worried.find((c) => c.id === parseInt(input.gad2phq2.worried, 10)).name,
            fieldValue: 'true',
          },
          input.gad2phq2.interest && {
            fieldID: input.gad2phq2.interest,
            fieldName: FREQUENCIES_MAP.interest.find((c) => c.id === parseInt(input.gad2phq2.interest, 10)).name,
            fieldValue: 'true',
          },
          input.gad2phq2.depressed && {
            fieldID: input.gad2phq2.depressed,
            fieldName: FREQUENCIES_MAP.depressed.find((c) => c.id === parseInt(input.gad2phq2.depressed, 10)).name,
            fieldValue: 'true',
          },
        ].filter((v) => v),
      },
      {
        sectionID,
        subSectionID: patientSnapshotConfig.abnormalLabs.id,
        objectiveFields: [abnormalLabsField],
      },
    ] as MedicalReportObjectiveData[]).filter((v) => v && v.objectiveFields.length > 0 && v.objectiveFields[0]);
  }

  mapPeDataOut(
    input: PeData,
    sectionID: number,
  ): MedicalReportObjectiveData[] {
    const answers = [];

    Object.keys(peConfig).forEach((key) => {
      const selectedOption = peConfig[key].find((sect) => sect.id === input[key].determination);
      const noteData = peConfig[key].find((sectionName) => sectionName.displayName === 'additionalComment');
      const noteText = input[key].note;

      const objectiveFields = [{
        fieldID: input[key].determination,
        fieldName: selectedOption?.name,
        fieldValue: 'true',
        fieldOtherValue: null,
      }];

      // combine selected option and notes
      if (noteText) {
        const note = {
          fieldID: noteData.id,
          fieldName: noteData.name,
          fieldValue: 'true',
          fieldOtherValue: noteText
        };

        objectiveFields.push(note);
      }

      if (selectedOption) {
        // add data to radio options array
        answers.push({
          sectionID,
          subSectionID: peConfig[key][0].subSectionId,
          objectiveFields,
        });
      }
    });

    return answers;
  }

  saveFormSection(
    data: MedicalReportObjectiveData[],
    callback?: any,
  ): void {
    // save each supplied section
    forkJoin(
      data.reduce((z, sectionValues, i) => {
        return [
          ...z,
          timer(AppUtils.DELAY_CONSECUTIVE_REQUESTS * i).pipe(
            switchMap((_) =>
              this.http.post(
                this.config.ENDPOINTS.medicalReportSection,
                sectionValues,
                this.httpContextHelperService.setParamTokensInHttpContext({
                  body: ['reportID'],
                  queryParams: ['epmsPID', 'userID']
                })
              ).pipe(
                take(1)
              )
            )
          )
        ];
      }, [])
    ).subscribe(() => {
      if (typeof callback === 'function') {
        callback();
      }
    });
  }

  getFormSection(params: {
    sectionID: number | string;
    subSectionID: number | string;
  }): Observable<MedicalReportSection> {
    return this.http.get<MedicalReportSection>(this.config.ENDPOINTS.medicalReportSection, {
      params: {
        sectionID: `${params.sectionID}`,
        subSectionID: `${params.subSectionID}`,
      },
      ...this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    });
  }

}
