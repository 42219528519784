import { Observable, take } from 'rxjs';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Appointment } from '@config/types';
import { AppointmentsControllerService } from '@core/services/appointments-controller.service';

@Injectable({ providedIn: 'root' })
export class AppointmentResolver implements Resolve<Appointment> {
  constructor(
    private appointmentsControllerService: AppointmentsControllerService,
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any>|Promise<any>|any {
    // 1) getAllAppointmentsIfNecessary
    // 2) getReportTypeIdIfNecesseary
    // 3) createExamIfNecessary
    const appointmentID = parseInt(route.paramMap.get('appointmentID'), 10);

    return this.appointmentsControllerService
      .createAppointmentReportIfNecessary(appointmentID)
      .pipe(take(1));
  }
}
