


export interface LookupDTO {
  displayOrder: number;
  id: number;
  name: string;
}

export interface DocumentTypeDTO {
  description: string;
  id: number;
  multipleUploadsAllowed: boolean;
  name: string;
  shareWithPatient: boolean;
  signatureRequired: boolean;
}

export interface SectionLookupDTO {
  displayName: string;
  displayOrder: number;
  id: number;
  name: string;
  subSections: SubSectionLookupDTO[];
}

export interface SubSectionLookupDTO {
  displayName: string;
  displayOrder: number;
  id: number;
  name: string;
}

export interface FieldLookupDTO {
  additionalDisplayName: string;
  dataType: string;
  displayName: string;
  displayOrder: number;
  hasOtherText: boolean;
  id: number;
  isTextSelection: boolean;
  name: string;
  sectionId: number;
  subFields: FieldLookupDTO[];
  subSectionId: number;
}
