<div [formGroup]="parent" class="form-row custom-radio-button-set-wrapper">
  <div class="column {{align}} {{padding}}">
    <div class="toggle-select-text">
      <ng-content select=".section-question"></ng-content>
    </div>
    <div>
      <div content class="radio-btn-set">
        <div label *ngIf="labels" class="_label bold">
          <ng-content select=".left"></ng-content>
        </div>
        <mat-label class="bold" *ngIf="label">
          {{label}}
        </mat-label>
        <mat-radio-group class="radio-btn-set" [formControlName]="control">
          <mat-radio-button *ngFor="let option of options" [value]="output(option, 0)">
            {{ output(option, 1) }}
          </mat-radio-button>
          <mat-error *ngIf="(parent.status ==='INVALID' && parent.errors)">Please select any of the above option</mat-error>
        </mat-radio-group>
        <div label *ngIf="labels" class="_label bold">
          <ng-content select=".right"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
