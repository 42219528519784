import {
    concat, debounceTime, delay, distinctUntilChanged, EMPTY, filter, map, mapTo, merge, mergeMap,
    of, share, Subject, switchAll, take, takeUntil, tap
} from 'rxjs';

import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClinicalNotesResponse } from '@config/types';
import { ClinicalNotesService } from '@patient/services/clinical-notes.service';

export interface ClinicalNotesParams {
  userID: string;
  epmsPID: number;
  reportID: number;
}

@Component({
  selector: 'app-clinical-notes',
  templateUrl: 'clinical-notes.component.html',
})
export class ClinicalNotesComponent implements OnInit, OnDestroy, AfterViewInit {

  clinicalNotesForm: FormGroup;
  clinicalNotesParams;
  saveIndicator = '';
  showMark = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private ngZone: NgZone,
    private clinicalNotesService: ClinicalNotesService,
  ) {}

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  ngOnInit() {
    this.buildForm();
    this.fetchForm();
  }

  ngAfterViewInit() {
    const notesCtrl = this.clinicalNotesForm.get('notes');
    notesCtrl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe((response) => {
        console.log('ngAfterViewInit response', response);
        this.showMark = false;
        this.renderSaveIndicator();
        if (notesCtrl.dirty) {
          this.saveForm(response);
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm() {
    this.clinicalNotesForm = this.fb.group({
      id: null,
      medicalReportID: null,
      notes: '',
    });
  }

  fetchForm() {
    this.clinicalNotesService
      .getClinicalNotes()
      .subscribe((response: ClinicalNotesResponse) => {
        if (response.data) {
          this.clinicalNotesForm.patchValue(response.data);
        }
      });
  }

  saveForm(body) {
    this.clinicalNotesService.postClinicalNotes(body).subscribe(
      (value) => {
        if (value) {
          this.showMark = true;
        }
      },
      (error) => {
        (this.showMark = false), console.log('ERROR', error);
      }
    );
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  renderSaveIndicator() {
    this.showMark = false;
    let savesInProgress = 0;

    const saveChanges = (value) => {
      return of(value).pipe(delay(2000));
    };

    const inputChanges$ = this.clinicalNotesForm.get('notes').valueChanges;
    const inputToSave$ = inputChanges$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(200),
      map((e) => e.value),
      distinctUntilChanged(),
      share()
    );

    const savesInProgress$ = inputToSave$.pipe(
      takeUntil(this.unsubscribe$),
      mapTo(of('Saving...')),
      tap((_) => savesInProgress++),
      tap(() => {
        this.showMark = false;
      })
    );

    const savesCompleted$ = inputToSave$.pipe(
      takeUntil(this.unsubscribe$),
      mergeMap(saveChanges),
      tap((_) => savesInProgress--),
      filter((_) => !savesInProgress),
      mapTo(concat(of('Saved'), EMPTY.pipe(delay(1500)))),
      tap(() => {
        this.showMark = true;
      })
    );

    merge(savesInProgress$, savesCompleted$)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchAll(),
      )
      .subscribe((status) => {
        this.saveIndicator = status;
      });
  }
}
