import { HttpContextToken } from '@angular/common/http';

// ref: https://steveholgado.com/typescript-types-from-arrays/
export const CUSTOM_PARAM_TOKEN_KEYS = [
  'appointmentID',
  'apptID',
  'userID',
  'epmsPID',
  'reportID',
  'examID',
  'procedureId',
  'procedureID',
  'reportTypeId',
  'reportTypeID',
  'clientID',
  'facilityID',
  'currentReportID'
] as const;
export type CustomParameterProcessingKey = typeof CUSTOM_PARAM_TOKEN_KEYS[number];

export const CUSTOM_QUERY_PARAMS_CONTEXT_TOKEN = new HttpContextToken<CustomParameterProcessingKey[]>(() => []);
export const CUSTOM_BODY_CONTEXT_TOKEN = new HttpContextToken<CustomParameterProcessingKey[]>(() => []);
export interface SetParamTokensInHttpContextParams {
  body?: CustomParameterProcessingKey[];
  queryParams?: CustomParameterProcessingKey[];
}
