import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appInteger]'
})
export class IntegerDirective {
  @Input() positiveValueOnly: string;
  @Input() maxVal: number;
  @Input() minVal: number;

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    if (!event) {
      return;
    }

    let newVal = event.toString();

    // check if input allows negative numbers
    if (this.positiveValueOnly === 'true' && event[0] === '-') {
      newVal = '';
    } else {
      // removes non numeric characters
      newVal = newVal.replace(/[^0-9-]/g, '');
      // handles situations like 5-
      newVal = newVal.replace(/(?<=[0-9]+)-/g, '');
    }

    // if multiple dashes, make only 1 dash
    newVal = newVal.replace(/(?<=-.*)-/g, '');

    // trim numbers that exceed max number for the input
    newVal = this.trimInput(newVal, this.minVal, this.maxVal);

    // don't allow inputs like '000'
    if ((/-?\d+/).test(newVal)) {
      newVal = Number(newVal).toString();
    }

    this.ngControl.valueAccessor.writeValue(newVal);
  }

  trimInput(value, min, max) {
    if (Number(value) > Number(max) || Number(value) < Number(min)) {
      const shorter = Number(value) / 10;
      value = parseInt(shorter.toString(), 10).toString();
      return value;
    }

    return value;
  }
}
