
export interface User {
  adminid: number;
  username: string;
  firstName: string;
  lastName: string;
  workLocation?: number;
  npiNumber: string;
}


export enum AUTH_STATES {
  PREAUTH_START,          // logged out state
  AUTHORIZED              // login token issued
}

export interface AuthState {
  state: AUTH_STATES;
  username?: string;
  token?: AuthToken;
  tokenExpiration?: Date;
}

export interface LoginResponse {
  data: {
    token: AuthToken;
    user: User;
  };
}

export interface AuthToken {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  newDeviceMetadata?: any;
  refreshToken: string;
  expirationTimestamp?: number;
  tokenType: string;
}
