import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import { LogoutComponent } from '@core/components/logout/logout.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { LoginComponent } from '@core/login/login.component';
import { DashboardComponent } from '@dashboard/components/dashboard.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: APP_ROUTES.ha,
    loadChildren: () =>
      import('./health-assessment/health-assessment.module').then(
        (m) => m.HealthAssessmentModule
      ),
  },
  {
    path: APP_ROUTES.dashboard,
    canActivate: [AuthGuard],
    component: DashboardComponent,
    pathMatch: 'full'
  },
  {
    path: APP_ROUTES.dashboard,
    loadChildren: () =>
      import('./patient/patient.module').then(
        (m) => m.PatientModule
      ),
  },
  {
    path: APP_ROUTES.login,
    component: LoginComponent,
    pathMatch: 'full',
  },
  { path: `${APP_ROUTES.login}/:redirectUrl`, component: LoginComponent },
  {
    path: APP_ROUTES.logout,
    component: LogoutComponent,
    pathMatch: 'full',
  },

  // The wildcard path must be last one defined.
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
