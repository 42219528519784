<pre *ngIf="debug" style="float: right">
    {{peService.mapSnapshotDataOut(forms.patientSnapshot.value, 2) | json}}
    ------
    {{forms.patientSnapshot.value | json}}
    ------
    {{forms.pe.value | json}}
    ------
    {{peService.mapPeDataOut(forms.pe.value, 2) | json}}
</pre>
<div class="pe-wrapper">
    <form [formGroup]="forms.patientSnapshot" (ngSubmit)="saveForm('snapshot')">
        <h2>Patient Snapshot</h2>
        <div>
            <h3>Ethnic Background</h3>

            <div class="input-select-lg">
                <mat-form-field>
                    <mat-select formControlName="ethnicBackground">
                        <mat-option></mat-option>
                        <mat-option *ngFor="let ethnicity of patientSnapshotConfig?.ethnicBackground?.options"
                            [value]="ethnicity.id">
                            {{ethnicity.displayName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>
        <div class="form-group" formGroupName="medicalConditions">
            <h3>Medical Conditions</h3>
            <div>
                <mat-checkbox formControlName="hbp">
                    High Blood Pressure
                </mat-checkbox>
            </div>
            <div *ngIf="fields.hbp.value" class="indent">
                <div>
                    <mat-checkbox formControlName="onHbpMeds">
                        on blood pressure medication(s)
                    </mat-checkbox>
                </div>
                <div>
                    <mat-checkbox formControlName="onBetaBlocker">
                        on beta blocker
                    </mat-checkbox>
                </div>
            </div>
            <div>
                <mat-checkbox formControlName="highCholesterol">
                    High Cholesterol
                </mat-checkbox>
            </div>
            <div *ngIf="fields.highCholesterol.value" class="indent">
                <div>
                    <mat-checkbox formControlName="onStatins">
                        on statin(s)
                    </mat-checkbox>
                </div>
            </div>
            <div>
                <mat-checkbox formControlName="diabetes">
                    Diabetes
                </mat-checkbox>
            </div>
            <div *ngIf="fields.diabetes.value" class="indent">
                <div>
                    <mat-checkbox formControlName="onDiabetesMeds">
                        on diabetes medication(s)
                    </mat-checkbox>
                </div>
            </div>
            <div>
                <mat-checkbox (click)="setNoMedicalConditions()" formControlName="noConditions">
                    None of the above
                </mat-checkbox>
            </div>
        </div>

        <div>
            <div>
                <h3>Medication Allergies</h3>
                <mat-form-field>
                    <textarea matInput formControlName="allergies"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div>
            <h3>Alcohol Misuse</h3>
            <div>
                <app-ui-radio-button-set [parent]="forms.patientSnapshot" control="alcoholMisuse" type="yesno">
                </app-ui-radio-button-set>
            </div>
        </div>
        <div>
            <h3>Current Smoker</h3>
            <div>
                <app-ui-radio-button-set [parent]="forms.patientSnapshot" control="currentSmoker" type="yesno">
                </app-ui-radio-button-set>
            </div>
        </div>
        <div class="form-group" formGroupName="stds">
            <h3>High Risk for STDs</h3>
            <div>
                <app-ui-radio-button-set [parent]="sections.stds" control="highRiskStds" type="yesno">
                </app-ui-radio-button-set>
            </div>
            <div>
                <mat-checkbox formControlName="stdTestingDeclined">
                    STD testing declined
                </mat-checkbox>
            </div>
        </div>
        <div formGroupName="gad2phq2">
            <h3 class="form-heading">GAD2/PHQ2</h3>
            <div class="form-group">
                <mat-checkbox formControlName="healthAssessmentAnswered">
                    Patient answered all four questions on the health assessment (skip below)
                </mat-checkbox>
            </div>
            <div>
                <h3>Over the last two weeks, how often have you been bothered by the following
                    problems?</h3>
                <div>

                    <p>Feeling nervous, anxious, or on edge</p>
                    <div class="input-select-lg">
                        <mat-form-field>
                            <mat-select formControlName="anxious">
                                <mat-option *ngFor="let frequency of FREQUENCIES_MAP.anxious" [value]="frequency.id">
                                    {{frequency.displayName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <p>Not being able to stop or control worrying</p>
                    <div class="input-select-lg">
                        <mat-form-field>
                            <mat-select formControlName="worried">
                                <mat-option *ngFor="let frequency of FREQUENCIES_MAP.worried" [value]="frequency.id">
                                    {{frequency.displayName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <p>Little interest or pleasure in doing things</p>
                    <div class="input-select-lg">
                        <mat-form-field>
                            <mat-select formControlName="interest">
                                <mat-option *ngFor="let frequency of FREQUENCIES_MAP.interest" [value]="frequency.id">
                                    {{frequency.displayName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <p>Feeling down, depressed, or helpless</p>
                    <div class="input-select-lg">
                        <mat-form-field>
                            <mat-select formControlName="depressed">
                                <mat-option *ngFor="let frequency of FREQUENCIES_MAP.depressed" [value]="frequency.id">
                                    {{frequency.displayName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="forms.ekgRiskForm" (ngSubmit)="saveForm('ekgRisk')" class="ekg-risk-form">
        <div class="ekg-risk-section">
            <h3 class="form-heading">Risk Scores</h3>
            <div class="risk-wrapper">
                <p>ASCVD (Atherosclerotic Cardiovascular Disease) Risk</p>
                <div class="input-sm">
                    <mat-form-field>
                        <input matInput formControlName="ascvdRisk" appDecimal maxVal="999" decimalPlaces="1"
                            positiveValueOnly="true">
                    </mat-form-field>
                    <a href="https://tools.acc.org/ASCVD-Risk-Estimator-Plus/#!/calculate/estimate/" target="_blank"
                        class="custom-label">ASCVD Risk Calculator</a>
                </div>
            </div>
            <div class="risk-wrapper">
                <p>Breast Cancer Risk</p>
                <div class="input-sm">
                    <mat-form-field>
                        <input matInput formControlName="breastCancerRisk" appDecimal maxVal="999" decimalPlaces="1"
                            positiveValueOnly="true">
                    </mat-form-field>
                    <a href="https://bcrisktool.cancer.gov/calculator.html" target="_blank" class="custom-label">Breast
                        Cancer Risk
                        Assessment</a>
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="forms.pe" id="pe-section" (ngSubmit)="saveForm('pe')">
        <h3>Patient Exam</h3>
        <div *ngFor="let section of peConfigSections">
            <app-pe-form
                *ngIf="showSection(section)"
                [parent]="forms.pe.get(section)"
                [options]="peConfig[section]">
                <h3>{{section}}</h3>
            </app-pe-form>
        </div>
    </form>
    <button mat-flat-button class="primary save-button" (click)="saveAll()" type="submit">Save and Continue</button>
</div>
