  
  <button
    type="submit"
    mat-raised-button
    color="primary"
    [class.done]="complete"
    [disabled]="loading || disabled"
  >
    {{ text }}
    <span class="flex-spacer"></span>
    <mat-icon *ngIf="complete">check</mat-icon>
    <mat-icon class="loadingIcon" *ngIf="loading">autorenew</mat-icon>

  </button>
