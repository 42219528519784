<table mat-table
       [dataSource]="appointmentData"
       class="mat-elevation-z8">
  <!-- Patient Last Name Column -->
  <ng-container matColumnDef="lastName">
    <th mat-header-cell *matHeaderCellDef>Last Name</th>
    <td mat-cell *matCellDef="let element">{{element.patient.lastName}}</td>
  </ng-container>

  <!-- Patient First Name Column -->
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef>First Name</th>
    <td mat-cell *matCellDef="let element">{{element.patient.firstName}}</td>
  </ng-container>

  <!-- Patient First Name Column -->
  <ng-container matColumnDef="keycode">
    <th mat-header-cell *matHeaderCellDef>Keycode</th>
    <td mat-cell *matCellDef="let element">{{element.patient.patientID}}</td>
  </ng-container>

  <!-- Procedure Name Column -->
  <ng-container matColumnDef="procedureName">
    <th mat-header-cell *matHeaderCellDef>Procedure</th>
    <td mat-cell *matCellDef="let element">{{element.procedureName}}</td>
  </ng-container>

  <!-- Patient DOB Column -->
  <ng-container matColumnDef="dob">
    <th mat-header-cell *matHeaderCellDef>DOB</th>
    <td mat-cell *matCellDef="let element">{{element.patient.dob}}</td>
  </ng-container>

  <!-- Patient Gender Column -->
  <ng-container matColumnDef="gender">
    <th mat-header-cell *matHeaderCellDef>Gender</th>
    <td mat-cell *matCellDef="let element">{{element.patient.gender}}</td>
  </ng-container>

  <ng-container matColumnDef="noResults">
    <td mat-footer-cell *matFooterCellDef [colSpan]="appointmentData?.length">
      No results found...
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnKeys"></tr>
  <tr mat-row *matRowDef="let row; columns: columnKeys;" (click)="clicked(row)"></tr>

  <div *matNoDataRow class="noData">No results found</div>
</table>
