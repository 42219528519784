import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ServerErrorMessage } from '@config/types';
import { ErrorDialogComponent } from '@core/components/error-dialog/error-dialog.component';
import { AppUtils } from '@shared/utils/app.utils';

@Injectable()
export class ErrorDialogService {
  constructor(public dialog: MatDialog) {}

  openDialog(
    data: ServerErrorMessage,
    url: string,
    httpParams?: HttpParams
  ): void {
    // implement logging service to capture error data(console log/send it to api)
    AppUtils.DEBUG && console.log(
      `Error in api: ${
        httpParams ? url + '?' + httpParams.toString() : url
      }, reason: ${data.reason}, code: ${data.status}`
    );

    this.dialog.open(ErrorDialogComponent, {
      minWidth: '300px',
      maxWidth: '400px',
      data,
    });
  }
}
