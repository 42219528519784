import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-textarea-control',
  template: `
  <div *ngIf="conditionalDisplay">
  <mat-form-field [style.width]="width">
      <mat-label>{{ label }}</mat-label>
      <textarea
        [placeholder]="placeholder"
        matInput
        [formControl]="control"
        cdkTextareaAutosize
        [cdkAutosizeMinRows]="minRows"
        [cdkAutosizeMaxRows]="maxRows"
      ></textarea>
    </mat-form-field>
  </div>
  `,
})
export class TextareaControlComponent {
  @Input() control: FormControl;
  @Input() label?: string;
  @Input() placeholder = '';
  @Input() width?: string;
  @Input() minRows?: string;
  @Input() maxRows?: string;
  @Input() conditionalDisplay?: boolean;

  constructor(){
    this.conditionalDisplay = this.conditionalDisplay ?? true;
    this.minRows = this.minRows || '3';
    this.maxRows = this.minRows || '5';
    this.width = this.width || '55%';
  }

}
