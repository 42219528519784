import { Subject, takeUntil } from 'rxjs';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VisionResultsResponse } from '@config/types';
import { ScreeningsService } from '@patient/services/screenings.service';
import { AppUtils } from '@shared/utils/app.utils';

@Component({
    selector: 'app-vision-form',
    templateUrl: 'vision-form.component.html'
})
export class VisionFormComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;

    private unsubscribe$ = new Subject<void>();

    constructor(
        private screeningsService: ScreeningsService,
    ) { }

    ngOnInit(): void {
      this.fetchForm();
    }

    fetchForm(): void {
        this.screeningsService.getVision().pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe(
          (response: VisionResultsResponse) => {
            if (response.data) {
              this.form.patchValue(response.data);
            }
        });
    }

    saveForm(): Promise<boolean> {
      return new Promise<boolean>( resolve => {
        this.screeningsService.postVision(
          this.form.value,
        ).pipe(
          takeUntil(this.unsubscribe$)
        ).subscribe((response: VisionResultsResponse) => {
          if (response) {
            this.form.markAsPristine();
            resolve(true);
          } else {
            resolve(false);
          }
          AppUtils.DEBUG && console.log('Response', response);
        });
      });
    }

    ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
    }
}
