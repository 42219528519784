import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from '@shared/components/header/header.component';
import { MaterialModule } from '@shared/material.module';
import { CalculateAgePipe } from '@shared/pipes/calculate-age.pipe';
import { GenderStringPipe } from '@shared/pipes/gender-string.pipe';
import { PatientPhonePipe } from '@shared/pipes/patient-phone.pipe';

import { LoaderComponent } from './components/loader/loader.component';
import { PeFormComponent } from './components/pe-form/pe-form.component';
import { ProgressButtonComponent } from './components/progress-button/progress-button.component';
import { RadioButtonSetComponent } from './components/radio-button-set/radio-button-set.component';
import { RadioButtonSetService } from './components/radio-button-set/radio-button-set.service';
import { TextareaControlComponent } from './components/textarea-control/textarea-control.component';
import {
    VerificationModalComponent
} from './components/verification-modal/verification-modal.component';
import {
    VerificationModalService
} from './components/verification-modal/verification-modal.service';
import { DecimalDirective } from './directives/decimal.directive';
import { IntegerDirective } from './directives/integer.directive';
import { FormatNamePipe } from './pipes/format-name.pipe';
import { RepeatPipe } from './pipes/repeat.pipe';
import { SortDataPipe } from './pipes/sort-data.pipe';

@NgModule({
  declarations: [
    CalculateAgePipe,
    DecimalDirective,
    FormatNamePipe,
    GenderStringPipe,
    HeaderComponent,
    IntegerDirective,
    LoaderComponent,
    PatientPhonePipe,
    PeFormComponent,
    ProgressButtonComponent,
    RadioButtonSetComponent,
    RepeatPipe,
    SortDataPipe,
    TextareaControlComponent,
    VerificationModalComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
  ],
  exports: [
    CalculateAgePipe,
    DecimalDirective,
    FlexLayoutModule,
    FormatNamePipe,
    GenderStringPipe,
    HeaderComponent,
    IntegerDirective,
    LoaderComponent,
    MaterialModule,
    PatientPhonePipe,
    PeFormComponent,
    ProgressButtonComponent,
    RadioButtonSetComponent,
    RepeatPipe,
    SortDataPipe,
    TextareaControlComponent,
    VerificationModalComponent,
  ],
  providers: [
    RadioButtonSetService,
    VerificationModalService
  ],
})
export class SharedModule { }
