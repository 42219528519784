<div class="req-details-container column">
    <label class="section-header">Requisition Details</label>
    <div class="divider">
        <mat-divider></mat-divider>
    </div>

    <form class="reqdetail-form-wrapper column" [formGroup]="form">
        <shared-ui-input formFieldSize="small" [parent]="form" required inputType="datePicker" control="dateCollected"
            label="Date Collected"></shared-ui-input>
        <shared-ui-input formFieldSize="small" [parent]="form" required inputType="timePicker" control="timeCollected"
            label="Time Collected" placeholder="Please select a time..."></shared-ui-input>
        <app-ui-radio-button-set *ngIf="isLabReq" [parent]="form" required control="fasting" label="Fasting *" type="yesno">
        </app-ui-radio-button-set>
    </form>
</div>