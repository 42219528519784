export const specialtyList = [
    {
        id: 1,
        name: 'Allergy/Immunology',
        displayOrder: 0,
    },
    {
        id: 2,
        name: 'Audiology',
        displayOrder: 0,
    },
    {
        id: 3,
        name: 'Cardiology',
        displayOrder: 0,
    },
    {
        id: 4,
        name: 'Chiropractic',
        displayOrder: 0
    },
    {
        id: 5,
        name: 'Dentistry',
        displayOrder: 0
    },
    {
        id: 6,
        name: 'Dermatology',
        displayOrder: 0
    },
    {
        id: 7,
        name: 'Endocrinology/Diabetes and Metabolism',
        displayOrder: 0
    },
    {
        id: 8,
        name: 'Family Practitioner',
        displayOrder: 0
    },
    {
        id: 9,
        name: 'Gastroenterology',
        displayOrder: 0
    },
    {
        id: 10,
        name: 'Genetics',
        displayOrder: 0
    },
    {
        id: 11,
        name: 'Gynecology',
        displayOrder: 0
    },
    {
        id: 12,
        name: 'Hematology',
        displayOrder: 0
    },
    {
        id: 13,
        name: 'Hepatology',
        displayOrder: 0
    },
    {
        id: 14,
        name: 'Infectious Disease',
        displayOrder: 0
    },
    {
        id: 15,
        name: 'Internal Medicine/PCP',
        displayOrder: 0
    },
    {
        id: 16,
        name: 'Medical Weight Loss',
        displayOrder: 0
    },
    {
        id: 17,
        name: 'Nephrology',
        displayOrder: 0
    },
    {
        id: 18,
        name: 'Neurology',
        displayOrder: 0
    },
    {
        id: 19,
        name: 'Neurosurgery',
        displayOrder: 0
    },
    {
        id: 20,
        name: 'Nutrition',
        displayOrder: 0
    },
    {
        id: 21,
        name: 'Oncology',
        displayOrder: 0
    },
    {
        id: 22,
        name: 'Ophthalmology',
        displayOrder: 0
    },
    {
        id: 23,
        name: 'Optometry',
        displayOrder: 0
    },
    {
        id: 24,
        name: 'Orthopedic Surgery - Back',
        displayOrder: 0
    },
    {
        id: 25,
        name: 'Orthopedic Surgery - Hand/Wrist',
        displayOrder: 0
    },
    {
        id: 26,
        name: 'Orthopedic Surgery - Foot/Ankle',
        displayOrder: 0
    },
    {
        id: 27,
        name: 'Orthopedic Surgery - Spine',
        displayOrder: 0
    },
    {
        id: 28,
        name: 'Orthopedic Surgery - Shoulder/Elbow',
        displayOrder: 0
    },
    {
        id: 29,
        name: 'Orthopedic Surgery - Upper Extremity',
        displayOrder: 0
    },
    {
        id: 30,
        name: 'Orthopedic Surgery - General',
        displayOrder: 0
    },
    {
        id: 31,
        name: 'Orthopedic Surgery - Hip/Knee',
        displayOrder: 0
    },
    {
        id: 32,
        name: 'Otolaryngology/ENT',
        displayOrder: 0
    },
    {
        id: 33,
        name: 'Physiatry',
        displayOrder: 0
    },
    {
        id: 34,
        name: 'Physical Therapy',
        displayOrder: 0
    },
    {
        id: 35,
        name: 'Podiatry',
        displayOrder: 0
    },
    {
        id: 36,
        name: 'Psychiatry',
        displayOrder: 0
    },
    {
        id: 37,
        name: 'Psychology',
        displayOrder: 0
    },
    {
        id: 38,
        name: 'Pulmonary Medicine',
        displayOrder: 0
    },
    {
        id: 39,
        name: 'Reproductive Endocrinology and Infertility',
        displayOrder: 0
    },
    {
        id: 40,
        name: 'Rheumatology',
        displayOrder: 0
    },
    {
        id: 41,
        name: 'Sleep Medicine',
        displayOrder: 0
    },
    {
        id: 42,
        name: 'Sports Medicine',
        displayOrder: 0
    },
    {
        id: 43,
        name: 'Surgery - Bariatric',
        displayOrder: 0
    },
    {
        id: 44,
        name: 'Surgery - Breast',
        displayOrder: 0
    },
    {
        id: 70,
        name: 'Surgery - Colon and Rectal',
        displayOrder: 0
    },
    {
        id: 45,
        name: 'Surgery - General',
        displayOrder: 0
    },
    {
        id: 46,
        name: 'Surgery - Maxillofacial',
        displayOrder: 0
    },
    {
        id: 47,
        name: 'Surgery - Plastic',
        displayOrder: 0
    },
    {
        id: 48,
        name: 'Surgery - Vascular',
        displayOrder: 0
    },
    {
        id: 49,
        name: 'Travel Medicine',
        displayOrder: 0
    },
    {
        id: 50,
        name: 'Urgent Care',
        displayOrder: 0
    },
    {
        id: 51,
        name: 'Urogynecology',
        displayOrder: 0
    },
    {
        id: 52,
        name: 'Urology',
        displayOrder: 0
    },
    {
        id: 54,
        name: 'Surgery - Wound Care',
        displayOrder: 0
    },
    {
        id: 55,
        name: 'Surgery - Cardiothoracic',
        displayOrder: 0
    },
    {
        id: 56,
        name: 'Massage Therapy',
        displayOrder: 0
    },
    {
        id: 57,
        name: 'Periodontology',
        displayOrder: 0
    },
    {
        id: 58,
        name: 'Neuropsychology',
        displayOrder: 0
    },
    {
        id: 59,
        name: 'Breast Cancer Genetic Counselor',
        displayOrder: 0
    },
    {
        id: 60,
        name: 'EHE Covered Ancillaries',
        displayOrder: 0
    },
    {
        id: 61,
        name: 'EHE Screening Mammogram',
        displayOrder: 0
    },
    {
        id: 62,
        name: 'EHE Coronary Calcium CT',
        displayOrder: 0
    },
    {
        id: 63,
        name: 'EHE DEXA Scan',
        displayOrder: 0
    },
    {
        id: 64,
        name: 'EHE High Resolution Lung CT',
        displayOrder: 0
    },
    {
        id: 65,
        name: 'EHE Ankle Brachial Index (ABI)',
        displayOrder: 0
    },
    {
        id: 66,
        name: 'EHE Abdominal Aorta Ultrasound',
        displayOrder: 0
    },
    {
        id: 67,
        name: 'EHE Cardiac Stress Test',
        displayOrder: 0
    },
    {
        id: 68,
        name: 'EHE Colonoscopy',
        displayOrder: 0
    },
    {
        id: 69,
        name: 'EHE Breast Ultrasound (PWC & Hubbell)',
        displayOrder: 0
    },
    {
        id: 53,
        name: 'Other',
        displayOrder: 0
    },
];
