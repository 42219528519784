<div class="lab-order-container">
    <label class="section-header">Lab Order</label>
    <div class="divider">
        <mat-divider></mat-divider>
    </div>

    <form [formGroup]="form" class="column mt-1">
        <shared-ui-select label="" [parent]="form" control="papProcedure"
            [options]="configOptions">
        </shared-ui-select>
    </form>

</div>