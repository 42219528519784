import { Injectable } from '@angular/core';

/** Radio button set service */
@Injectable()
export class RadioButtonSetService {
  /**
   * Load data for button set. We are
   * able to define custom field types
   * such that
   */

  //  interface integration "key:type:string"
  load(type: string, locale = 'en') {
    switch (type) {
      case 'agreement':
        return [
          [1, 'Strongly Disagree'],
          [2, 'Disagree'],
          [3, 'Neutral'],
          [4, 'Agree'],
          [5, 'Strongly Agree'],
        ];
      case 'agreement-text':
        return [
          'Strongly Disagree',
          'Disagree',
          'Neutral',
          'Agree',
          'Strongly Agree',
        ];
      case 'range':
        return [
          [1, 'Excellent'],
          [2, 'Good'],
          [3, 'Fair'],
          [4, 'Poor'],
        ];
      case 'range-text':
        return ['Excellent', 'Good', 'Fair', 'Poor'];
      case 'agreement-reverse':
        return [
          [5, 'Strongly Disagree'],
          [4, 'Disagree'],
          [3, 'Neutral'],
          [2, 'Agree'],
          [1, 'Strongly Agree'],
        ];
      case 'likert-scale':
        return [1, 2, 3, 4, 5, 6, 7];
      case 'gender':
        return ['Male', 'Female'];
      case 'ten-scale':
        return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      case 'emotional':
        return [
          ['N/A', 'Patient not given mental wellbeing screening'],
          ['No', 'Not at risk'],
          ['Yes', 'At risk'],
        ];
      case 'yes-no-not':
        return ['Yes', 'No', 'Not Sure'];
      case 'normalcy':
        return ['Normal', 'Abnormal'];
      case 'test-result':
        return ['Positive', 'Negative', 'Inconclusive'];
      case 'sex-gender':
        return ['Men', 'Women', 'Both'];
      case 'frequency':
        return [
          'Not at all',
          'Several days',
          'More than half the days',
          'Nearly every day',
        ];
      case 'frequency-key-value':
        return [
          [1, 'Not at all'],
          [2, 'Several days'],
          [3, 'More than half of the days'],
          [4, 'Nearly everyday'],
        ];
      case 'true-false':
        return [
          [true, 'Yes'],
          [false, 'No'],
        ];
      case 'yes-no':
      default:
        return ['Yes', 'No'];
    }
  }
}
