import { Directive, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appDecimal]'
})
export class DecimalDirective {
  @Input() positiveValueOnly: string;
  @Input() maxVal: number;
  @Input() minVal: number;
  @Input() decimalPlaces: number;

  constructor(public ngControl: NgControl) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.onInputChange(event, false);
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.onInputChange(event.target.value, true);
  }

  onInputChange(event, backspace) {
    if (!event) {
      return;
    }

    let newVal = event.toString();

    if (this.positiveValueOnly === 'true' && newVal[0] === '-') {
      newVal = '';
    } else {
      // check for non-numeric characters
      newVal = newVal.toString().replace(/[^0-9-.]/g, '');
    }

    // if multiple dots, make only 1
    newVal = newVal.replace(/(?<=\..*)\./g, '');
    // if multiple dashes, make only 1
    newVal = newVal.replace(/(?<=-.*)-/g, '');

    // trim numbers that exceed max number for the input
    newVal = this.trimInput(newVal, this.minVal, this.maxVal);

    // limits number of decimals to match the decimal limit
    newVal = this.limitDecimalPlaces(newVal);

    this.ngControl.valueAccessor.writeValue(newVal);
  }

  trimInput(value, min, max) {
    if (Number(value) > Number(max) || Number(value) < Number(min)) {
      const shorter = Number(value) / 10;
      value = parseInt(shorter.toString(), 10).toString();
      return value;
    }

    return value;
  }

  limitDecimalPlaces(value) {
    const decimal = value.search(/(?<=\.)[0-9]+/g);
    if (this.decimalPlaces && decimal !== -1) {
      value = (Math.floor(Number(value) * Math.pow(10, this.decimalPlaces)) / Math.pow(10, this.decimalPlaces)).toString();
      return value;
    }

    return value;
  }
}
