import { tap } from 'rxjs';

import { Injectable } from '@angular/core';
import {
    AuthorizationDetail, AuthorizationResponse, AuthToken, Facility, FacilityWithRoleDTO,
    GetAllowedFacilitiesWithRolesResponse, User
} from '@config/types';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { User2Service } from '../services/user2.service';
import {
    ClearAppointmentSearchTerms, ClearHaOnly, GetFacilityPermissions, Login, Logout,
    RefreshLoginToken, SetAppointmentSearchTerms, SetHaOnly
} from './user.actions';

export interface FacilityWithRoleInternal {
  [id: number]: FacilityWithRoleDTO;
}

export interface AppointmentSearchTerms {
  facilityID: number;
  appointmentDate: string;
}
export interface HaOnlyData {
  appointmentID: number;
  epmsPID: number;
}

export interface UserStateModel {
  user: User | null;
  auth: AuthToken | null;
  facilityRoles: FacilityWithRoleInternal | null;
  appointmentSearchTerms: AppointmentSearchTerms | null;
  haOnly: HaOnlyData | null;
}

export const EmptyUserState: UserStateModel = {
  user: null,
  auth: null,
  facilityRoles: null,
  appointmentSearchTerms: null,
  haOnly: null,
};
@State<UserStateModel>({
  name: 'user',
  defaults: EmptyUserState
})
@Injectable()
export class UserState {
  @Selector([UserState])
  static getUser(state: UserStateModel): User | null {
    return state && state.user;
  }

  @Selector([UserState])
  static getAuth(state: UserStateModel): AuthToken | null {
    return state && state.auth;
  }

  @Selector([UserState.getAuth])
  static isLoggedIn(state: AuthToken): boolean | null {
    return !!state;
  }

  @Selector([UserState])
  static getHaOnly(state: UserStateModel): HaOnlyData | null {
    return state && state.haOnly;
  }

  @Selector([UserState.getHaOnly])
  static isHaOnly(state: HaOnlyData): boolean {
    return !!state;
  }

  @Selector([UserState.getAuth])
  static getToken(state: AuthToken): string | null {
    return state && state.idToken;
  }

  @Selector([UserState])
  static getFacilityPermissions(state: UserStateModel): FacilityWithRoleInternal | null {
    return state && state.facilityRoles;
  }

  @Selector([UserState.getFacilityPermissions])
  static getAssignedFacilitiesList(state: FacilityWithRoleInternal): Facility[] | null {
    return state && Object.values(state).map((o) => o.facility);
  }

  @Selector([UserState])
  static getAppointmentSearchTerms(state: UserStateModel): AppointmentSearchTerms | null {
    return state && state.appointmentSearchTerms;
  }

  @Selector([UserState])
  static getRolesForSelectedFacility(state: UserStateModel): FacilityWithRoleDTO {
    return (
      state &&
      state.appointmentSearchTerms &&
      state.appointmentSearchTerms.facilityID &&
      state.facilityRoles[state.appointmentSearchTerms.facilityID]
    );
  }

  @Selector([UserState.getAuth])
  static getExpiration(state: AuthToken): number {
    return state && !!state.refreshToken && state.expirationTimestamp;
  }

  // @Selector()
  // static refreshToken(state: UserStateModel): string | null {
  //   return state.auth && state.auth.refreshToken;
  // }

  // // to do: Implement selector for determining if the token is expired
  // // 1) on the way in, convert the state.token.expiresIn number to a
  // //    JavaScript date we can use for comparison below
  // // 2) if we can detect a token and are not yet beyond the expiry, let's return true
  // @Selector()
  // static isTokenValid(state: AuthStateModel): boolean {
  //   const tokenExpiry;
  // }

  constructor(private userService: User2Service, private store: Store) {}

  @Action(Login)
  login(ctx: StateContext<UserStateModel>, action: Login) {
    return this.userService.login(action.payload).pipe(
      tap((result: AuthorizationResponse) => {
        this.handleAuthorizationResponse(ctx, result);
      })
    );
  }

  @Action(RefreshLoginToken)
  refreshLoginToken(ctx: StateContext<UserStateModel>) {
    const state = ctx.getState();
    const { refreshToken } = state.auth;
    const { adminid } = state.user;
    return this.userService.refreshLoginToken(refreshToken, adminid).pipe(
      tap((result: AuthorizationResponse) => {
        this.handleAuthorizationResponse(ctx, result);
      })
    );
  }

  @Action(Logout)
  logout() {
    // to do: copy actions from AuthService#logout
    this.store.reset({ user: EmptyUserState, appointments: { todo: null, inProgress: null, needsReview: null } });
  }

  @Action(GetFacilityPermissions)
  getFacilityPermissions(ctx: StateContext<UserStateModel>, action: GetFacilityPermissions) {
    return this.userService.getFacilityPermissions(action.payload).pipe(
      tap((result: GetAllowedFacilitiesWithRolesResponse) => {
        const data: FacilityWithRoleDTO[] = result.data;
        ctx.patchState({
          facilityRoles: data.reduce((z, o) => ({ ...z, [o.facility.id]: o }), {})
        });
      })
    );
  }

  @Action(SetAppointmentSearchTerms)
  setAppointmentSearchTerms(ctx: StateContext<UserStateModel>, action: SetAppointmentSearchTerms) {
    return ctx.patchState({
      appointmentSearchTerms: action.payload
    });
  }

  @Action(ClearAppointmentSearchTerms)
  clearAppointmentSearchTerms(ctx: StateContext<UserStateModel>) {
    return ctx.patchState({
      appointmentSearchTerms: null
    });
  }

  @Action(SetHaOnly)
  setHaOnly(ctx: StateContext<UserStateModel>, action: SetHaOnly) {
    return ctx.patchState({
      haOnly: action.payload
    });
  }

  @Action(ClearHaOnly)
  clearHaOnly(ctx: StateContext<UserStateModel>) {
    return ctx.patchState({
      haOnly: null
    });
  }

  private handleAuthorizationResponse(ctx: StateContext<UserStateModel>, result: AuthorizationResponse): void {
    const data: AuthorizationDetail = result.data;
    const authData: AuthToken = {
      ...data.token,
      expirationTimestamp: Date.now() + data.token.expiresIn * 1000 // we refer to expirationTimestamp to know when to restore the token
    };
    ctx.patchState({
      auth: authData, // map token [external] -> auth [internal]
      user: data.user
    });
  }
}
