import { IWithMessage, Patient } from '@config/types';

export interface HealthAssessment {
  apptID: number;
  epmsPID: number;
  id: number;
  isCompleted: boolean;
  isReviewed: boolean;
  lastSavedSubSection: number;
  patient: Patient;
  patientHAStatus: number;
}

export interface HealthAssessmentResponse extends IWithMessage {
  data: HealthAssessment;
}
