import { map } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Specialty, SpecialtyInternal, SpecialtyLookup } from '@config/types';
import { HttpContextHelperService } from '@core/services/http-context-helper.service';
import { SpecialtyObject } from '@patient/pages/create/create/create.component';

import { specialtyList } from './specialty-config';

@Injectable()
export class SpecialtyService {

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private config: AppConfigService,
    private httpContextHelperService: HttpContextHelperService
  ) { }

  specialtyOptions$ = this.http.get<SpecialtyLookup[]>(this.config.ENDPOINTS.providerLookupReferralSpecialities, {})
    .pipe(
      map(obj => obj['data']), // Remove outer 'data property'
    );


  getEAPSpecialties() {
    return this.http.get<SpecialtyObject[]>(
      this.config.ENDPOINTS.providerLookupEAPSpecialities,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['clientID']
      })
    );
  }

  generateFormGroup(specialty?: Specialty): FormGroup {
    const name = specialty ? specialty.name : null;
    const isStat = specialty ? specialty.isStat : false;
    const otherSpeciality = specialty ? specialty.otherSpeciality : '';

    return this.fb.group({
      name,
      isStat,
      otherSpeciality,
      reason: '',
    });
  }

  addSpecialty(formArray: FormArray, specialty?): void {
    formArray.push(this.generateFormGroup(specialty));
  }

  removeSpecialty(formArray: FormArray, index: number): void {
    formArray.removeAt(index);
    formArray.markAsDirty();
  }

  importGeneralSpecialtyData(formArray: FormArray, externalData: SpecialtyObject[]): void {
    if (!externalData || !externalData.length) {
      return;
    }

    externalData?.forEach(o => {
      const internalSpecialty: SpecialtyInternal = {
        name: this.findSpecialtyName(o.specialtyID),
        otherSpeciality: o.otherSpeciality,
        isStat: o.isStat
      };
      this.addSpecialty(formArray, internalSpecialty);
    });
  }

  async importEAPSpecialtyData(formArray: FormArray, externalData: SpecialtyObject[]) {
    if (!externalData || !externalData.length) {
      return;
    }

    let eapSpecialties = [];
    const filteredData = [];

    return new Promise((resolve) => {
      this.getEAPSpecialties().subscribe((response: any) => {
        if (response.data) {
          eapSpecialties = response.data;

          externalData.forEach(o => {
            const internalSpecialty: SpecialtyInternal = {
              name: this.findEAPSpecialtyName(o.specialtyID, eapSpecialties),
              otherSpeciality: o.otherSpeciality,
              isStat: o.isStat
            };
            this.addSpecialty(formArray, internalSpecialty);
          });

          resolve(filteredData);
        }
      });
    });
  }

  async exportEAPSpecialties(internalData: SpecialtyInternal[]) {
    let eapSpecialties = [];
    const filteredData = [];

    return new Promise((resolve) => {
      this.getEAPSpecialties().subscribe((response: any) => {
        if (response.data) {
          eapSpecialties = response.data;

          // filter out empty referrals (no name or id)
          internalData.forEach(i => {
            const currentID = this.findEAPSpecialtyId(i.name, eapSpecialties);
            if (currentID && i.name) {
              filteredData.push({
                name: i.name,
                specialtyID: currentID,
                reason: '',
                otherSpeciality: null,
                isStat: i.isStat
              } as SpecialtyObject);
            }
          });

          resolve(filteredData);
        }
      });
    });
  }

  exportGeneralSpecialtyData(internalData: SpecialtyInternal[]): SpecialtyObject[] {
    const filteredData = [];

    // filter out empty referrals (no name or id)
    internalData.forEach(i => {
      const currentID = this.findGeneralSpecialtyId(i.name);
      if (currentID && i.name) {
        filteredData.push({
          name: i.name,
          specialtyID: currentID,
          reason: '',
          otherSpeciality: i.otherSpeciality,
          isStat: i.isStat
        } as SpecialtyObject);
      }
    });

    return filteredData;
  }

  findEAPSpecialtyId(specialty: string, eapSpecialties): number | null {
    if (!specialty) {
      return;
    }

    const foundSpecialty = eapSpecialties.find(o => o.name.toLowerCase() === specialty.toLowerCase());
    if (!foundSpecialty) {
      return null;
    }
    return foundSpecialty.id;

  }

  findGeneralSpecialtyId(specialty: string): number | null {
    if (!specialty) {
      return;
    }

    const foundSpecialty = specialtyList.find(o => o.name.toLowerCase() === specialty.toLowerCase());
    if (!foundSpecialty) {
      return null;
    }
    return foundSpecialty.id;
  }

  findEAPSpecialtyName(specialtyId: number, eapSpecialties): string | null {
    const foundSpecialty = eapSpecialties.find(o => o.id === specialtyId);
    if (!foundSpecialty) {
      return null;
    }
    return foundSpecialty.name;
  }

  findSpecialtyName(specialtyId: number): string | null {
    const foundSpecialty = specialtyList.find(o => o.id === specialtyId);
    if (!foundSpecialty) {
      return null;
    }
    return foundSpecialty.name;
  }

}
