<div class="lab-request column">
    <mat-toolbar class="header" >
        <mat-toolbar-row>
            <span >Lab Requisition</span> 
            <span class="flex-spacer"></span>
            <button  class="backbtn" mat-raised-button (click)="navigateToRequests()">Back</button>  
        </mat-toolbar-row>
    </mat-toolbar>
    <app-exam-info class="padding-vertical"></app-exam-info>
    <app-req-details [isLabReq]="true" class="padding-vertical"></app-req-details>
    <app-lab-order class="padding-vertical"></app-lab-order>
   
    <button [disabled]="isInvalidForm" mat-raised-button color="primary" (click)="createLabOrder()">Save Order</button>
    <mat-divider></mat-divider>
</div>