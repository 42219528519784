import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';

import { SharedUiInputComponent } from './ui-input-field/ui-input-field.component';
import { SharedUiSelectComponent } from './ui-select-field/ui-select-field.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  exports: [
    SharedUiInputComponent,
    SharedUiSelectComponent
  ],
  declarations: [
    SharedUiInputComponent,
    SharedUiSelectComponent
  ],
  providers: [],
})
export class DynamicFormModule {}
