import { finalize, Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
   endPtswithLoader: string[] = [
    this.config.ENDPOINTS.login,
    this.config.ENDPOINTS.loginWithAuth0,
    this.config.ENDPOINTS.allowedFacilitiesAndRoles,
    this.config.ENDPOINTS.appointmentsToDo,
    this.config.ENDPOINTS.appointmentsInProgress,
    this.config.ENDPOINTS.appointmentsNeedsReview
  ];

  private totalRequests = 0;

  constructor(private injector: Injector, private config: AppConfigService, private loaderService: LoaderService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // If we want multiple http requests, we can have a counter for requests (+1) and responses(-1)

      if (this.endPtswithLoader.includes(req.url)) {
        this.loaderService.show();
        this.totalRequests++;
        return next.handle(req).pipe(
          finalize(() => {
            this.totalRequests--;
            console.log('finalize triggered', this.totalRequests);
            if (this.totalRequests === 0){
              console.log('hiding via loaderService!');
              this.loaderService.hide();
            }
          })
        );
      }else{
        return next.handle(req);
      }
  }
}
