import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppointmentCardComponent } from './components/appointment-card/appointment-card.component';
import {
    NewDashDesignComponent
} from './components/appointment-layout/appointment-layout.component';
import {
    AppointmentSelectDialogComponent
} from './components/appointment-select-dialog/appointment-select-dialog.component';
import { AppointmentToDoOnlyLayout } from './components/appointment-todo-only-layout/appointment-todo-only-layout.component';
import { DashboardComponent } from './components/dashboard.component';
import { StateComponent } from './components/state/state.component';
import { SkeletonCardComponent } from './skeleton-card/skeleton-card.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'state',
    component: StateComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DashboardRoutingModule {
  static components = [
    AppointmentCardComponent,
    AppointmentToDoOnlyLayout,
    AppointmentSelectDialogComponent,
    DashboardComponent,
    NewDashDesignComponent,
    SkeletonCardComponent,
    StateComponent,
  ];
}
