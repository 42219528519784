import {
    BehaviorSubject, filter, first, Observable, share, skip, skipWhile, Subject, takeUntil, tap
} from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AppointmentDetail, ExamStatic, MedicalReportObjectiveData, ObjectiveField
} from '@config/types';
import { EheFormComponent, EheFormComponentTemplate } from '@core/guards/dirty-check.guard';
import { AppointmentsControllerService } from '@core/services/appointments-controller.service';
import { NavigationService } from '@core/services/navigation.service';
import { PEService } from '@patient/services/pe.service';
import { ScreeningsService } from '@patient/services/screenings.service';
import {
    NO_VALUE, YES_VALUE
} from '@shared/components/radio-button-set/radio-button-set.component';
import { AppUtils } from '@shared/utils/app.utils';

import {
    FREQUENCIES_MAP, patientSnapshotConfig, PE_SECTIONS, PeConfig, peConfig, SnapshotFormConfig
} from './pe-form.config';

@Component({
  selector: 'app-pe',
  templateUrl: './pe.component.html',
  styleUrls: ['./pe.component.scss']
})
export class PEComponent extends EheFormComponentTemplate implements OnInit, OnDestroy, EheFormComponent {

  patientSnapshotConfig: SnapshotFormConfig = patientSnapshotConfig;
  peConfig: PeConfig = peConfig;
  peConfigSections: string[] = Object.keys(peConfig);

  formSectionStds: FormGroup;
  fields: { [key: string]: FormControl };
  sections: { [key: string]: FormGroup };

  // 1) observable tracking pending save requests
  // snapshot, pe, ekgRisk
  pendingSaveRequests$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  private readonly findingKeys = [
    'hbp',
    'onHbpMeds',
    'onBetaBlocker',
    'highCholesterol',
    'onStatins',
    'diabetes',
    'onDiabetesMeds',
  ];

  YES_VALUE = YES_VALUE;
  FREQUENCIES_MAP = FREQUENCIES_MAP;
  debug = AppUtils.DEBUG;

  private unsubscribe$ = new Subject<void>();

  appointment$: Observable<AppointmentDetail>;
  appointmentID: number;
  // // snapshot ID
  exam: ExamStatic;
  gender = '';

  constructor(
    public peService: PEService,
    private screeningsService: ScreeningsService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private appointmentsControllerSerivce: AppointmentsControllerService,
  ) {
    super();
    this.buildForm();
    this.route.parent.data.pipe(takeUntil(this.unsubscribe$)).subscribe(
      ({appointment}) => {
        this.appointmentID = appointment.appointmentID;
        this.gender = appointment.patient.gender;
        console.log('exam objectives', appointment.exam.objectives);
        this.updateFormValues(appointment.exam.objectives);
      }
    );
  }

  ngOnInit(): void {

    this.prefillPeRisks();

    this.pendingSaveRequests$.pipe(
      skip(1),
      filter(arr => arr.length === 0),
      first(),
      share()
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveForm(section: string): void {

    let data: MedicalReportObjectiveData[];

    // save Cardiovascular and Breast Cancer Risk sections
    if (section === 'ekgRisk') {
      this.saveRiskData();
      return;
    }

    // 2) Track save request as initiated
    this.pendingSaveRequests$.next([...this.pendingSaveRequests$.value, section]);

    if (section === 'snapshot') {
      data = this.peService.mapSnapshotDataOut(this.forms.patientSnapshot.value, 2);
    } else if (section === 'pe') {
      data = this.peService.mapPeDataOut(this.forms.pe.value, 3);
    }

    this.peService.saveFormSection(data, ((self) => {
      return () => {
        // reset form to pristine state after save
        setTimeout(() => {
          console.log('in callback from saveFormSection', section);
          self.markAsPristine();
          // 3) mark save as complete
          const foundIndex = this.pendingSaveRequests$.value.indexOf(section);
          this.pendingSaveRequests$.next(
            [
              ...(this.pendingSaveRequests$.value.slice(0, foundIndex)),
              ...(this.pendingSaveRequests$.value.slice(foundIndex + 1))
            ]
          );
        }, AppUtils.DELAY_CONSECUTIVE_REQUESTS);
      };
    })(this));
  }

  saveAll() {
    this.saveForm('snapshot');
    this.saveForm('pe');
    this.saveForm('ekgRisk');

    this.pendingSaveRequests$.pipe(
      takeUntil(this.unsubscribe$),
      skipWhile((v) => v.length > 0),
    ).subscribe((value) => {
      this.goToNextSection();
    });

  }

  goToNextSection(): void {
    this.appointmentsControllerSerivce.refreshAppointment(this.appointmentID);
    const nextSection = this.navigationService.navigateNext('PE', true);
    this.router.navigate([nextSection.path], { relativeTo: this.route.parent });
  }

  saveRiskData(): void {
    this.screeningsService.postRisk({
      ...this.forms.ekgRiskForm.value,
      ekgOption: undefined,
    }).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((response) => {
      this.forms.ekgRiskForm.markAsPristine();
    });
  }

  private updateFormValues(objectivesPrefill: ObjectiveField[]) {
    const peSection = PE_SECTIONS.reduce(
      (z, section) => ({
        ...z,
        [section]: this.prefill(section, objectivesPrefill),
      }),
      {}
    );

    this.debug && console.log('patch pe section: ', peSection);

    this.forms.patientSnapshot.patchValue({
      ethnicBackground: this.prefill('ethnicBackground', objectivesPrefill),
      medicalConditions: {
        hbp: this.prefill('hbp', objectivesPrefill),
        onHbpMeds: this.prefill('onHbpMeds', objectivesPrefill),
        onBetaBlocker: this.prefill('onBetaBlocker', objectivesPrefill),
        highCholesterol: this.prefill('highCholesterol', objectivesPrefill),
        onStatins: this.prefill('onStatins', objectivesPrefill),
        diabetes: this.prefill('diabetes', objectivesPrefill),
        onDiabetesMeds: this.prefill('onDiabetesMeds', objectivesPrefill),
        noConditions: this.prefill('noConditions', objectivesPrefill),
      },
      allergies: this.prefill('medicalAllergies', objectivesPrefill),
      alcoholMisuse: this.prefill('alcoholMisuse', objectivesPrefill),
      currentSmoker: this.prefill('currentSmoker', objectivesPrefill),
      stds: {
        highRiskStds: this.prefill('highRiskStds', objectivesPrefill),
        stdTestingDeclined: this.prefill('stdTestingDeclined', objectivesPrefill),
      },
      gad2phq2: {
        healthAssessmentAnswered: this.prefill('healthAssessmentAnswered', objectivesPrefill),
        anxious: this.prefill('anxious', objectivesPrefill),
        worried: this.prefill('worried', objectivesPrefill),
        interest: this.prefill('interest', objectivesPrefill),
        depressed: this.prefill('depressed', objectivesPrefill),
      },
    });
    this.forms.pe.patchValue(peSection);
  }

  prefillPeRisks() {
    this.screeningsService.getRisk().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe( riskResponse => {
      if (riskResponse.data) {
        const riskScoresData = riskResponse.data;
        const ekgOption: string | null = null;

        const peRiskFormBody = {
          ...riskScoresData,
          ekgOption,
        };

        this.forms.ekgRiskForm.patchValue(peRiskFormBody);
      }
    });
  }

  // prefill Snapshot and PE sections
  setNoMedicalConditions(): void {
    this.findingKeys.forEach(key => {
      this.sections.medicalConditions.controls[key].reset();
    });
  }

  private prefill(question: string, objectivesPrefill: ObjectiveField[]) {
    if (!objectivesPrefill) {
      return null;
    }
    let section = question;
    let objective;
    switch (question) {
      case 'ethnicBackground':
        return ((needle) => (needle ? needle.fieldID : null))(
          objectivesPrefill.find((c) => patientSnapshotConfig[question].options.some((o) => o.id === c.fieldID))
        );
      case 'hbp':
      case 'onHbpMeds':
      case 'onBetaBlocker':
      case 'highCholesterol':
      case 'onStatins':
      case 'diabetes':
      case 'onDiabetesMeds':
      case 'noConditions':
        section = 'medicalConditions';
        break;
      case 'medicalAllergies':
        section = 'allergies';
        objective = objectivesPrefill.find((c) => patientSnapshotConfig[section].questions[question].id === c.fieldID);
        return objective ? objective.fieldOtherValue : null;
      case 'highRiskStds':
        section = 'stds';
      case 'alcoholMisuse':
      case 'currentSmoker':
        return ((o) => {
          if (objectivesPrefill.find((c) => o.Yes.id === c.fieldID)) {
            return YES_VALUE;
          } else if (objectivesPrefill.find((c) => o.No.id === c.fieldID)) {
            return NO_VALUE;
          } else {
            return null;
          }
        })(patientSnapshotConfig[section].questions[question]);
      case 'stdTestingDeclined':
        section = 'stds';
        break;
      case 'healthAssessmentAnswered':
        section = 'gad2phq2';
        break;
      case 'anxious':
      case 'worried':
      case 'interest':
      case 'depressed':
        section = 'gad2phq2';
        return this.frequencyFiller(question, section, objectivesPrefill);
      case 'hasAbnormalLabs':
      case 'noAbnormalLabs':
        section = 'abnormalLabs';
        break;
      case 'abnormalLabsDescription':
        question = 'hasAbnormalLabs';
        section = 'abnormalLabs';
        objective = objectivesPrefill.find((o) => patientSnapshotConfig[section].questions[question].id === o.fieldID);
        return objective ? objective.fieldOtherValue : null;
      default: {
        // PE prefills
        this.debug && console.log('PE prefill detected...', question);

        // returns object with radio button data
        const peAnswer = objectivesPrefill.find((o) => peConfig[question].some((c) => c.id === o.fieldID));

        const notesId = peConfig[question].find((sectionName) => sectionName.displayName === 'additionalComment').id;
        const note = objectivesPrefill.find(o => o.fieldID === notesId);

        this.debug && console.log(question, peConfig[question], peAnswer);

        return {
          determination: peAnswer ? peAnswer.fieldID : null,
          note: note ? note.fieldOtherValue : null
        };
      }
    }

    this.debug &&
      console.log('question/section', question, section, patientSnapshotConfig, patientSnapshotConfig[section]);
    return objectivesPrefill.some((o) => patientSnapshotConfig[section].questions[question].id === o.fieldID);
  }

  private frequencyFiller(question: string, section: string, objectives: ObjectiveField[]): null | number {
    const config = patientSnapshotConfig[section].questions[question];
    // const frequencies = FREQUENCIES.map(freq => config[freq]);
    const foundFreq = objectives.find((o) => FREQUENCIES_MAP[question].some((f) => f.id === o.fieldID));
    this.debug && console.log('config', config);
    this.debug && console.log('FREQUENCIES_MAP[question]', FREQUENCIES_MAP[question]);
    this.debug && console.log('foundFreq', foundFreq);
    return foundFreq ? foundFreq.fieldID : null;
  }

  private buildForm(): void {
    // Direct reference to certain fields and sections made available within our template
    this.fields = {
      hbp: this.fb.control(null),
      highCholesterol: this.fb.control(null),
      diabetes: this.fb.control(null),
      highRiskStds: this.fb.control(null),
    };

    this.sections = {
      stds: this.fb.group({
        highRiskStds: this.fields.highRiskStds,
        stdTestingDeclined: null,
      }),
      gad2phq2: this.fb.group({
        healthAssessmentAnswered: null,
        anxious: null,
        worried: null,
        interest: null,
        depressed: null
      }),
      medicalConditions: this.fb.group({
        hbp: this.fields.hbp,
        onHbpMeds: null,
        onBetaBlocker: null,
        highCholesterol: this.fields.highCholesterol,
        onStatins: null,
        diabetes: this.fields.diabetes,
        onDiabetesMeds: null,
        noConditions: null,
      })
    };

    const peSection = Object.keys(peConfig).reduce((z, key) => ({
      ...z,
      [key]: this.fb.group({
        determination: null,
        note: null
      })
    }), {});

    this.forms = {
      patientSnapshot: this.fb.group({
        ethnicBackground: null,
        medicalConditions: this.sections.medicalConditions,
        allergies: null,
        alcoholMisuse: null,
        currentSmoker: null,
        stds: this.sections.stds,
        gad2phq2: this.sections.gad2phq2,
        abnormalLabs: this.fb.group({
          hasAbnormalLabs: this.fields.hasAbnormalLabs,
          abnormalLabsDescription: this.fields.abnormalLabsDescription,
          noAbnormalLabs: this.fields.noAbnormalLabs,
        })
      }),
      //
      // EKG Risk Form
      //
      ekgRiskForm: this.fb.group({
        id: null,
        ekgOption: new FormControl(null),
        ascvdRisk: null,
        breastCancerRisk: null
      }),
      //
      // PE Form
      //
      pe: this.fb.group(peSection)
    };
  }

  showSection(currentSection) {
    const userGender = this.gender;
    const femaleSpecific = ['Female - Breasts', 'Female - Pelvis'];
    const maleSpecific = ['Male - Testes', 'Male - Rectum'];

    if (userGender === 'Female' && femaleSpecific.includes(currentSection)) {
      return true;
    } else if (userGender === 'Male' && maleSpecific.includes(currentSection)) {
      return true;
    } else if (!femaleSpecific.includes(currentSection) && !maleSpecific.includes(currentSection)) {
      // section is not gender specific
      return true;
    }
    return false;
  }
}
