import { Observable } from 'rxjs';

import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { APP_ROUTES } from '@config/application-routes';
import { User } from '@config/types';
import { Select } from '@ngxs/store';

import { LoaderService } from '../services/loader.service';
import { UserControllerService } from '../services/user-controller.service';
import { FacilityWithRoleInternal, UserState } from '../state/user.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public loginInfoPerc = false;

  passwordType = 'password';

  loginForm = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  user: User;

  @Select(UserState.getUser) user$: Observable<User>;
  @Select(UserState.getToken) token$: Observable<string>;
  @Select(UserState.getFacilityPermissions)
  facilityRoles$: Observable<FacilityWithRoleInternal>;
  @Select(UserState.isHaOnly) isHaOnly$: Observable<boolean>;

  constructor(
    public loaderService: LoaderService,
    public userControllerService: UserControllerService,
  ) {
    this.isHaOnly$.subscribe((isHaOnly) => {
      if (isHaOnly) {
        this.loginInfoPerc = true;
      }
    });
    this.user$.subscribe((user: User) => {
      this.user = user;
    });
  }

  togglePasswordVisiblity() {
    if (this.passwordType === 'password') {
      this.passwordType = 'text';
    } else {
      this.passwordType = 'password';
    }
  }

  login() {
    const route = this.loginInfoPerc ? APP_ROUTES.ha : APP_ROUTES.dashboard
    this.userControllerService.login(
      {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
      },
      route
    );
  }
}
