import { Observable, switchMap, withLatestFrom } from 'rxjs';

import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES } from '@config/application-routes';
import {
    AppointmentDetail, CUSTOM_BODY_CONTEXT_TOKEN, CUSTOM_QUERY_PARAMS_CONTEXT_TOKEN, User
} from '@config/types';
import { AppointmentsControllerService } from '@core/services/appointments-controller.service';
import { UserState } from '@core/state/user.state';
import { Select } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

export interface CustomParameterTranslationFunctionParams {
  appointment?: AppointmentDetail;
  user?: User;
}

const appointmentIdTranslationFunction = (params: CustomParameterTranslationFunctionParams) => params.appointment?.appointmentID;
const examIdTranslationFunction = (params: CustomParameterTranslationFunctionParams) => params.appointment?.exam.id;
export const CustomParameterTranslationObject = {
  appointmentID: appointmentIdTranslationFunction,
  apptID: appointmentIdTranslationFunction,
  userID: (params: CustomParameterTranslationFunctionParams) => params.user?.adminid,
  epmsPID: (params: CustomParameterTranslationFunctionParams) => params.appointment?.patient?.epmsPID,
  reportID: examIdTranslationFunction,
  currentReportID: examIdTranslationFunction,
  examID: examIdTranslationFunction,
  procedureId: (params: CustomParameterTranslationFunctionParams) => params.appointment?.procedureID,
  procedureID: (params: CustomParameterTranslationFunctionParams) => params.appointment?.procedureID,
  reportTypeId: (params: CustomParameterTranslationFunctionParams) => params.appointment?.exam?.reportTypeId,
  reportTypeID: (params: CustomParameterTranslationFunctionParams) => params.appointment?.exam?.reportTypeId,
  clientID: (params: CustomParameterTranslationFunctionParams) => params.appointment?.patient?.clientNumber,
  facilityID: (params: CustomParameterTranslationFunctionParams) => params.appointment?.exam?.facility
};


@Injectable()
export class HttpParamInterceptor implements HttpInterceptor {
  @Select(UserState.getUser) user$: Observable<User>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appointmentsControllerService: AppointmentsControllerService,
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (!request.context) {
      return next.handle(request);
    }
    AppUtils.DEBUG && console.log('http param interceptor request', request);
    AppUtils.DEBUG && console.log(this.route.snapshot.paramMap, this.router.routerState.snapshot.url);
    const appointmentsRegex =  new RegExp(`/\/?${APP_ROUTES.dashboard}\/([0-9]+)`, 'i');
    // only act upon children of /appointments/:appointmentID
    if (!appointmentsRegex.test(this.router.routerState.snapshot.url)) {
      return next.handle(request);
    }
    const matches = appointmentsRegex.exec(this.router.routerState.snapshot.url);
    const appointmentID = matches && matches[1];
    AppUtils.DEBUG && console.log('appointmentID', appointmentID, matches);
    const appointment = this.appointmentsControllerService.getAppointment(parseInt(appointmentID, 10));

    return this.user$.pipe(
      withLatestFrom(appointment),
      switchMap(([user, _appointment]) => {
        return next.handle(
          this.processContextTokens(request, user, _appointment)
        );
      })
    );
  }

  processContextTokens(request: HttpRequest<any>, user: User, appointment?: AppointmentDetail): HttpRequest<any> {
    AppUtils.DEBUG && console.log('processConextTokens', request, user, appointment);
    const options: any = {};
    const bodyParamTokens = request.context.get(CUSTOM_BODY_CONTEXT_TOKEN);
    if (Array.isArray(bodyParamTokens) && bodyParamTokens.length > 0) {
      options.body = {};
      bodyParamTokens.forEach(token => {
        if (token in CustomParameterTranslationObject) {
          options.body[token] = CustomParameterTranslationObject[token].call(this, {appointment, user});
        } else {
          throw new Error(`Unknown query param token encountered in HttpParamInterceptor ${token}`);
        }
      });
    }
    // https://github.com/angular/angular/issues/18812#issuecomment-336301386
    let newParams = new HttpParams({fromString: request.params.toString()});
    const queryParamTokens = request.context.get(CUSTOM_QUERY_PARAMS_CONTEXT_TOKEN);
    if (Array.isArray(queryParamTokens) && queryParamTokens.length > 0) {
      queryParamTokens.forEach(token => {
        if (token in CustomParameterTranslationObject) {
          newParams = newParams.append(token, CustomParameterTranslationObject[token].call(this, {appointment, user}));
        } else {
          throw new Error(`Unknown body token encountered in HttpParamInterceptor ${token}`);
        }
      });
    }

    // must not expand text/plain type as this splits apart a string and garbles the response
    if (options.body || (request.body && request.headers.get('Content-Type') !== 'text/plain')) {
      options.body = {
        ...request.body,
        ...options.body
      };
    }
    AppUtils.DEBUG && console.log('options', options);
    const newRequest = request.clone({
      params: newParams,
      ...options
    });
    AppUtils.DEBUG && console.log('new Request', newRequest);
    return newRequest;
  }
}
