import { tap } from 'rxjs';

import { Injectable } from '@angular/core';
import { LookupOption } from '@config/types';
import { CoreLookupService } from '@core/services/core-lookup.service';
import { Action, Selector, State, StateContext } from '@ngxs/store';

import { GetReportTypeId } from './lookup.actions';
import { AppUtils } from '@shared/utils/app.utils';

export interface ProcedureLookupObject {
  [procedureId: number]: number; // reportTypeID
}

export interface LookupStateModel {
  medicalReportType: ProcedureLookupObject;
}

export const EmptyLookupStateModel = {
  medicalReportType: {
    1: 1,
    32: 3
  }
};

@State<LookupStateModel>({
  name: 'lookup',
  defaults: EmptyLookupStateModel,
})
@Injectable()
export class LookupState {
  @Selector([LookupState])
  static getReportTypeIdDictionary(state: LookupStateModel): ProcedureLookupObject {
    return state && state.medicalReportType;
  }

  constructor(
    private coreLookupService: CoreLookupService
  ) {}


// if - we have the reportTypeId for a procedure ID, let's that that
// else -- let's fetch the reportTypeId from the API and use that

//   controller service -> state action -> service


  @Action(GetReportTypeId)
  getReportTypeId(
    ctx: StateContext<LookupStateModel>,
    action: GetReportTypeId,
  ) {
    return this.coreLookupService.getMedicalReportTypeId(action.payload).pipe(
      tap((result: [LookupOption]) => {
        AppUtils.DEBUG && console.log('incoming result is', result, result[0]?.id);
        const lookupValue = result[0]?.id;
        const medicalReportState = ctx.getState().medicalReportType;
        AppUtils.DEBUG && console.log('desired patch is', {
          medicalReportType: {
            ...medicalReportState,
            [action.payload.procedureId]: lookupValue,
          }
        });
        ctx.patchState({
          medicalReportType: {
            ...medicalReportState,
            [action.payload.procedureId]: lookupValue,
          }
        });
      })
    );
  }
}
