import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizationResponse, GetAllowedFacilitiesWithRolesResponse } from '@config/types';

// User2Service is intended to eventually replace AuthService / UserService
// implementing ngxs state & action dispatches
@Injectable({
  providedIn: 'root'
})
export class User2Service {
  constructor(
    private http: HttpClient,
    private config: AppConfigService,
  ) {}

  login(params: { username: string; password: string }): Observable<AuthorizationResponse> {
    const { username, password } = params;
    return this.http.post<AuthorizationResponse>(this.config.ENDPOINTS.login, {
      username,
      password
    });
  }

  refreshLoginToken(token: string, userID: number): Observable<AuthorizationResponse> {
    return this.http.post<AuthorizationResponse>(
      this.config.ENDPOINTS.refreshToken,
      {
        refreshToken: token,
        userID
      },
    );
  }

  getFacilityPermissions(params: { userId: number }): Observable<GetAllowedFacilitiesWithRolesResponse> {
    const { userId } = params;
    return this.http.get<GetAllowedFacilitiesWithRolesResponse>(this.config.ENDPOINTS.allowedFacilitiesAndRoles, {
      params: { userID: userId.toString() }
    });
  }
}
