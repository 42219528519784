import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LookupOption, BadgeConfig } from '@config/types';
import { SortOrder } from '@shared/pipes/sort-data.pipe';

@Component({
  selector: 'shared-ui-select',
  templateUrl: './ui-select-field.component.html',
  styleUrls: ['./ui-select-field.component.scss']
})
export class SharedUiSelectComponent implements OnChanges {
  @Input() control: string;
  @Input() formFieldSize?: string;
  @Input() fxFlex?: string;
  @Input() label?: string;
  @Input() multipleSelect?: boolean;
  @Input() options: LookupOption[];
  @Input() parent?: FormGroup;
  @Input() path?: string[];
  @Input() placeholder?: string;
  @Input() prompt?: string;
  @Input() required?: boolean;
  @Input() sort? = false;
  @Input() typeDropDown?: boolean;
  @Input() optionBadges?: BadgeConfig[][];

  /**
   * Override from default ASC order. Can
   */
  @Input() order?: number;

  hasMultipleOptions: boolean;

  constructor() {
    // Other lookups may have 'displayName', e.g. PE and Create section
    this.path = this.path ?? ['name'];
    this.order = this.order ?? SortOrder.ASC;
    this.required = this.required ?? false;
    this.formFieldSize = this.fxFlex ? '' : this.formFieldSize ?? 'medium';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options.currentValue !== null) {
      this.hasMultipleOptions = this.typeDropDown ?? this.hasMoreThanFive();
    }
  }

  private hasMoreThanFive(): boolean {
    const options = this.options?.length;
    return options > 6;
  }

  getSelectedOptionName(id): string {
    const foundOption = this.options.find((o) => o.id === id);
    return foundOption ? foundOption.name : this.placeholder;
  }
}
