<ng-container *ngIf="!(type === 'eapDropdown' && !specialtyEAPOptions.length); else noEapReferrals">
  <ng-container [formGroup]="formGroup" *ngIf="formArray">
    <ng-content select="[header]"></ng-content>
    <div
      *ngFor="let formGroupData of formArray.controls; let i = index"
      [formArrayName]="formArrayName"
      class="autocomplete-wrapper"
    >
      <div [formGroupName]="i">
        <div class="dropdown-autocomplete-item-wrapper">
          <app-add-specialty-item
            [specialtyOptions]="type === 'eapDropdown' ? specialtyEAPOptions : specialtyOptions"
            [specialty]="formGroupData"
            [type]="type"
          >
          </app-add-specialty-item>
          <button
            mat-icon-button
            (click)="removeSpecialty(i)"
            type="button"
            class="autocomplete-remove-btn"
            aria-label="Remove specialty"
            title="Remove"
          >
            <mat-icon>close</mat-icon>
          </button>
          <section>
            <mat-checkbox
              (change)="openStatDialog( formGroupData ,'isStat')"
              formControlName="isStat"
              value="formGroupData.isStat"
              >STAT</mat-checkbox
            >
          </section>
        </div>
        <div class="autocomplete-other" *ngIf="formGroupData.value.name === 'Other'">
          <app-textarea-control
            label="Please Explain Other Referral"
            [control]="formGroupData.get('otherSpeciality')"
          ></app-textarea-control>
        </div>
      </div>
    </div>
    <button mat-button (click)="addSpecialty()" class="btn-clear">
      <u *ngIf="type === 'eapDropdown'; else newSpecialistReferral">+ Add another EAP Referral</u>
      <ng-template #newSpecialistReferral><u>+ Add another Specialist Referral</u></ng-template>
    </button>
  </ng-container>
</ng-container>

<ng-template #noEapReferrals>
  <h2>No EAP Referrals are configured for this client.</h2>
  <h4>Please contact the data team to add EAP referrals</h4>
</ng-template>
