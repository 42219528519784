import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LabOrderState, LookupOption } from '@config/types';

@Component({
  selector: 'app-pap-order',
  templateUrl: './pap-order.component.html',
  styleUrls: ['./pap-order.component.scss']
})
export class PapOrderComponent implements OnInit {

  labOrderState: LabOrderState;
  configOptions: LookupOption[];

  form: FormGroup = this.fb.group({
    papProcedure: ''
  });
  constructor(private fb: FormBuilder, private router: Router) {
    this.labOrderState = <LabOrderState>history.state ?? <LabOrderState>(
      this.router.getCurrentNavigation().extras.state
    );
    this.configOptions = this.labOrderState.labOrderOptions;
  }

  ngOnInit(): void {
    if (this.labOrderState) {
      this.form.patchValue({
        papProcedure: this.labOrderState.cytologyPanels.length > 0 ? this.labOrderState.cytologyPanels: null,
      });
    }
  }

}
