import { Component } from '@angular/core';
import { LoaderService } from '@core/services/loader.service';

@Component({
  selector: 'app-loader',
  template: ` <div *ngIf="loaderService.isLoading$ | async" class="loader-overlay">
  <div class="loader"></div>
</div>`,
})
export class LoaderComponent {

  constructor(public loaderService: LoaderService) {}
}
