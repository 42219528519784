import { Pipe, PipeTransform } from '@angular/core';
import { Patient } from '@config/types';

export interface FormatNameEnabledObject {
  firstName: string;
  lastName: string;
}

/*
 * Formats name from an object with firstName and lastName attributes
 * Takes boolean formatting flag that defaults to undefined
 * Usage:
 *   value | formatName:inFirstLastOrder
 * Example:
 *   {{ {firstName: 'Joe', lastName: 'Gibbs'} | formatName:true }}
 *   formats to: 'Joe Gibbs'
*/
@Pipe({
  name: 'formatName',
})
export class FormatNamePipe implements PipeTransform {
  transform(object: FormatNameEnabledObject, inFirstLastOrder?: boolean): string {
    if (!object || !object.lastName || !object.firstName) {
      return 'N/A';
    }
    if (inFirstLastOrder) {
      return `${object.firstName} ${object.lastName}`;
    } else {
      return `${object.lastName}, ${object.firstName}`;
    }
  }
}
