<h2 mat-dialog-title align="left">{{title}}</h2>

<mat-dialog-content align="left" class="dialog-patient-data">
    <div class="dialog-patient-row">
        <p class="custom-label">Full name:</p>
        <p>{{patient | formatName}}</p>
    </div>
    <div class="dialog-patient-row">
        <p class="custom-label">Keycode:</p>
        <p>{{patient.patientID}}</p>
    </div>
    <div class="dialog-patient-row">
        <p class="custom-label">Sex:</p>
        <p>{{patient.gender | gender}}</p>
    </div>
    <div class="dialog-patient-row">
        <p class="custom-label">Date of Birth:</p>
        <p>{{patient.dob}}</p>
    </div>
    <div class="dialog-patient-row">
        <p class="custom-label">Address:</p>
        <p>{{patient.address}}</p>
        <p>{{patient.address2}}</p>
        <p>{{patient.city}}, {{patient.state}} {{patient.zip}}</p>
    </div>
    <div class="dialog-patient-row">
        <p class="custom-label">Phone Number: </p>
        <p>{{patient | patientPhone}}</p>
    </div>
</mat-dialog-content>

<mat-dialog-actions class="btn-container justify-content__between">
    <div class="btn-row">
        <button *ngIf="isNoShowEnabled" mat-raised-button [mat-dialog-close]="{noShowButtonEvent: true}" class="no-show-btn">No Show</button>
    </div>
    <div class="btn-row">
        <button mat-raised-button mat-dialog-close>Cancel</button>
        <button mat-raised-button [mat-dialog-close]="patient" color="primary">Confirm</button>
    </div>
</mat-dialog-actions>
