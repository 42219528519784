import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
    ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree
} from '@angular/router';
import {
    ConfirmationDialogComponent
} from '@core/components/confirmation-dialog/confirmation-dialog.component';

@Injectable({
    providedIn: 'root'
})
export class DirtyCheckGuard implements CanDeactivate<EheFormComponent> {
    confirmDialog: MatDialogRef<ConfirmationDialogComponent>;

    constructor(
        protected dialog: MatDialog,
    ) { }

    canDeactivate(
        component: EheFormComponent,
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return !component.isDirty() || this.openDialog();
    }

    openDialog(): Promise<boolean> {
        const message = 'Have you saved all changes?';

        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                message,
                buttonText: {
                    ok: 'Yes, navigate away',
                    cancel: 'Stay on page'
                },
            }
        });

        return new Promise<boolean>((resolve, reject) => {
             dialogRef.afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }
}

export interface EheFormComponent {
    forms: { [key: string]: FormGroup };
    isDirty: () => boolean;
    markAsPristine: () => void;
    reset: () => void;
}
export class EheFormComponentTemplate {

    forms: {
        [label: string]: FormGroup;
    };

    isDirty(): boolean {
        return Object.keys(this.forms).some(key => this.forms[key].dirty);
    }

    markAsPristine(): void {
        Object.keys(this.forms).some(key => this.forms[key].markAsPristine());
    }

    reset(): void {
        Object.keys(this.forms).forEach(key => this.forms[key].reset());
    }
}
