import { map, Observable } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    CreateReferralsResponse, ExternalImmunizationLogs, ImmunizationLogsResponse,
    ImmunizationStatuses, InternalImmunizationLogs, LookupOption, LookupOptionResponse,
    SubProcedurePostData, User
} from '@config/types';
import { HttpContextHelperService } from '@core/services/http-context-helper.service';
import { UserState } from '@core/state/user.state';
import { Select } from '@ngxs/store';
import { AppUtils } from '@shared/utils/app.utils';

@Injectable()
export class CreateService {
  @Select(UserState.getUser) user$: Observable<User>;

  constructor(
    private http: HttpClient,
    private config: AppConfigService,
    private httpContextHelperService: HttpContextHelperService,
  ) {}

  postSection(body): Observable<SubProcedurePostData> {
    return this.http.post<SubProcedurePostData>(
      this.config.ENDPOINTS.createSections,
      body,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID'],
        body: ['reportID']
      })
    );
  }

  getSpecialities(): Observable<LookupOptionResponse> {
    return this.http.get<LookupOptionResponse>(
      this.config.ENDPOINTS.providerLookupReferralSpecialities,
      {}
    );
  }

  saveImmunizationLogs(body): Observable<ImmunizationLogsResponse> {
    console.log(
      'save immunization Logs entered',
      body,
    );
    return this.http.post<ImmunizationLogsResponse>(
      this.config.ENDPOINTS.immunizations,
      {
        immunizationLogs: this.mapImmunizationLogsToExternal(body)
      },
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID'],
        body: ['reportID']
      })
    );
  }

  getImmunizationLogs(): Observable<ImmunizationLogsResponse> {
    return this.http.get<ImmunizationLogsResponse>(
      this.config.ENDPOINTS.immunizations,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  // Omitting the reportID from the body to allow this to be handled by context
  // body: reportID
  mapImmunizationLogsToExternal(
    data: InternalImmunizationLogs,
    reportID?: number
  ): ExternalImmunizationLogs {
    return {
      immunizationLogs: data.data
        .filter((value) => !!value.immunizationLogID)
        .map(({ immunizationLogID, comments, dateGiven, dateUnknown, administeredOrReported, otherValue }) => ({
          administered: administeredOrReported === ImmunizationStatuses.administered,
          comments,
          dateGivenStr: dateGiven ? AppUtils.getFormattedDate(dateGiven.toString()) : null,
          dateUnknown,
          immunizationLogID,
          otherValue,
          reported: administeredOrReported === ImmunizationStatuses.reported,
        })),
      reportID,
    };
  }

  mapImmunizationLogsToInternal(
    data: ExternalImmunizationLogs
  ): InternalImmunizationLogs {
    if (!data) {
      console.log('no data detectedin mapImmunizationLogsToInternal');
      return {
        data: [],
      };
    }
    return {
      data: data.immunizationLogs.map(
        ({ administered, comments, dateGivenStr, dateUnknown, immunizationLogID, otherValue, reported }) => ({
          immunizationLogID,
          comments,
          dateGiven: dateGivenStr ? new Date(dateGivenStr) : null,
          dateUnknown: !dateUnknown && immunizationLogID && !dateGivenStr ? true : dateUnknown,
          administeredOrReported:
            (administered && 'administered') ||
            (reported && 'reported') ||
            null,
            otherValue
        })
      ),
    };
  }

  lookupImmunizations(): Observable<LookupOptionResponse> {
    return this.http.get<LookupOptionResponse>(
      this.config.ENDPOINTS.providerLookupImmunizations
    );
  }

  lookupCardiorespiratoryFitnessLevel(): Observable<LookupOption[]> {
    const sectionID = '2';
    const cardiorespiratoryFitnessLevelIDs = [181, 176, 34, 217, 218, 219];
    return this.http.get<LookupOptionResponse>(this.config.ENDPOINTS.providerLookupAssessmentFields, {
      params: {
          sectionId: sectionID
      }
    }
    ).pipe(map((response) => {
        return response.data.filter((arr) => cardiorespiratoryFitnessLevelIDs.includes(arr.id));
    }));
  }

  getReferrals(): Observable<CreateReferralsResponse> {
    return this.http.get<CreateReferralsResponse>(
      this.config.ENDPOINTS.referralSection,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID', 'reportID']
      })
    );
  }

  postReferrals(body): Observable<CreateReferralsResponse> {
    return this.http.post<CreateReferralsResponse>(
      this.config.ENDPOINTS.referralSection,
      body,
      this.httpContextHelperService.setParamTokensInHttpContext({
        queryParams: ['userID', 'epmsPID']
      })
    );
  }
}

export interface SaveImmunizationLogsParams {
  body: any;
  reportID: number;
}
