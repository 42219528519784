<h2 class="form-heading">Hearing</h2>
<form [formGroup]="form" class="hearing-form" (ngSubmit)="saveForm()">
    <p class="form-subheading">Right Ear</p>
    <mat-divider></mat-divider>
    <div class="inputs-inline">
        <div class="input-xs">
            <mat-form-field>
                <mat-label>250 Hz</mat-label>
                <input matInput formControlName="right250hz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>500 Hz</mat-label>
                <input matInput formControlName="right500hz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>1 kHz</mat-label>
                <input matInput formControlName="right1khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>2 kHz</mat-label>
                <input matInput formControlName="right2khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
    </div>
    <div class="inputs-inline">
        <div class="input-xs">
            <mat-form-field>
                <mat-label>3 kHz</mat-label>
                <input matInput formControlName="right3khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>4 kHz</mat-label>
                <input matInput formControlName="right4khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>6 kHz</mat-label>
                <input matInput formControlName="right6khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>8 kHz</mat-label>
                <input matInput formControlName="right8khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
    </div>

    <p class="form-subheading">Left Ear</p>
    <mat-divider></mat-divider>
    <div class="inputs-inline">
        <div class="input-xs">
            <mat-form-field>
                <mat-label>250 Hz</mat-label>
                <input matInput formControlName="left250hz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>500 Hz</mat-label>
                <input matInput formControlName="left500hz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>1 kHz</mat-label>
                <input matInput formControlName="left1khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>2 kHz</mat-label>
                <input matInput formControlName="left2khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
    </div>

    <div class="inputs-inline">
        <div class="input-xs">
            <mat-form-field>
                <mat-label>3 kHz</mat-label>
                <input matInput formControlName="left3khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>4 kHz</mat-label>
                <input matInput formControlName="left4khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>6 kHz</mat-label>
                <input matInput formControlName="left6khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
        <div class="input-xs">
            <mat-form-field>
                <mat-label>8 kHz</mat-label>
                <input matInput formControlName="left8khz" appInteger minVal="-9" maxVal="99">
            </mat-form-field>
        </div>
    </div>
    <mat-radio-group aria-label="Select an option" formControlName="hearingOption" color="primary">
        <mat-radio-button value="hearingUploaded">Hearing Done / Reviewed</mat-radio-button>
        <mat-radio-button value="hearingDeclined">Hearing Not Done / Declined</mat-radio-button>
    </mat-radio-group>
</form>
