import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINTS } from '@config/endpoints';

export class EnvironmentConfig {
  ppServer: string;
  ppSecretKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private _ENVIRONMENT: EnvironmentConfig;
  private _ENDPOINTS: any;

  get environment() {
    return this._ENVIRONMENT;
  }

  get ENDPOINTS() {
    return this._ENDPOINTS;
  }

  constructor(handler: HttpBackend, private http: HttpClient) {
    this.http = new HttpClient(handler);
  }

  // app module calls getEnvironmentConfig as "Factory" to populate environment & ENDPOINTS
  getEnvironmentConfig(): object {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get('/assets/environment.json')
        .toPromise()
        .then((config: EnvironmentConfig) => {
          this._ENVIRONMENT = config;
          this._ENDPOINTS = Object.keys(ENDPOINTS).reduce((z1, sectionKey) => ({
            ...z1,
              ...Object.keys(ENDPOINTS[sectionKey].routes).reduce(
                (z2, k) => ({
                    ...z2,
                      [k]: `https://${this.environment.ppServer}${ENDPOINTS[sectionKey].prefix}${ENDPOINTS[sectionKey].routes[k]}`,
                }),
                {}
            ),
        }), {}),
          resolve();
        });
    });
  }
}
