import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
    ConfirmationDialogComponent
} from '@core/components/confirmation-dialog/confirmation-dialog.component';
import { ErrorDialogComponent } from '@core/components/error-dialog/error-dialog.component';
import { LogoutComponent } from '@core/components/logout/logout.component';
import { EnsureModuleLoadedOnceGuard } from '@core/guards/ensure-module-loaded-once.guard';
import { HttpConfigInterceptor } from '@core/interceptors/http-config.interceptor';
import { HttpParamInterceptor } from '@core/interceptors/http-param.interceptor';
import { LoginComponent } from '@core/login/login.component';
import { TimeoutComponent } from '@core/components/timeout/timeout.component';
import { AppointmentsControllerService } from '@core/services/appointments-controller.service';
import { AuthService } from '@core/services/auth.service';
import { HeaderComponent } from '@shared/components/header/header.component';
import { DynamicFormModule } from '@shared/dynamic-form/dynamic-form.module';
import { SharedModule } from '@shared/shared.module';

import { StatusDialogComponent } from './components/status-dialog/status-dialog.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { CoreLookupControllerService } from './services/core-lookup-controller.service';
import { CoreLookupService } from './services/core-lookup.service';
import { ErrorDialogService } from './services/error-dialog.service';
import { HttpContextHelperService } from './services/http-context-helper.service';
import { StorageService } from './services/storage.service';
import { UserService } from './services/user.service';

// ToDo: Add Material imports just for header

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DynamicFormModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
    ],
    exports: [
        ConfirmationDialogComponent,
        HeaderComponent,
        StatusDialogComponent,
        TimeoutComponent,
    ],
    declarations: [
        LoginComponent,
        LogoutComponent,
        ErrorDialogComponent,
        ConfirmationDialogComponent,
        StatusDialogComponent,
        TimeoutComponent,
    ],
    providers: [
        AuthService,
        ErrorDialogService,
        StorageService,
        UserService,
        CoreLookupControllerService,
        CoreLookupService,
        AppointmentsControllerService,
        HttpContextHelperService,
        { provide: HTTP_INTERCEPTORS, useClass: HttpParamInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    ]
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
