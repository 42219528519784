<div class="column flex-center">
  <h2>EHE Connect</h2>
  <h3 *ngIf="loginInfoPerc">
    Log in to review and update this patient's health assessment. You will use the
    same login credentials as you do for EPMS.
  </h3>
  <form autocomplete="off" [formGroup]="loginForm" (ngSubmit)="login()">
    <shared-ui-input
      [parent]="loginForm"
      prompt="User ID"
      control="username"
    ></shared-ui-input>
    <p class="bold">Password</p>
    <mat-form-field class="small">
      <input formControlName="password" autocomplete="off" [type]="passwordType" matInput />
      <button
        type="button"
        matSuffix
        (click)="togglePasswordVisiblity()"
        mat-icon-button
      >
        <mat-icon>visibility</mat-icon>
      </button>
    </mat-form-field>
    <div class="row flex-center">
      <button color="primary" mat-flat-button type="submit">Login</button>
    </div>
  </form>
</div>
<app-loader></app-loader>
