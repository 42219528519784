import {
    GetCommonAppointmentsParams, GetToDoAppointmentsParams
} from '@core/services/appointments.service';

import { UpdateAppointmentExamParams } from './appointments.state';

export class GetToDoAppointments {
  static readonly type = '[Appointments] Get ToDo';
  constructor(public payload: GetToDoAppointmentsParams) {}
}

export class GetInProgressAppointments {
  static readonly type = '[Appointments] Get In Progress';
  constructor(public payload: GetCommonAppointmentsParams) {}
}

export class GetNeedsReviewAppointments {
  static readonly type = '[Appointments] Get Needs Review';
  constructor(public payload: GetCommonAppointmentsParams) {}
}

export class GetAllAppointments {
  static readonly type = '[Appointments] Get all';
  constructor(public payload: GetToDoAppointmentsParams) {}
}

export class ForgetAllAppointments {
  static readonly type = '[Appointments] Forget all';
}

// Update Appointment Exam updates the Report details within an appointment
export class UpdateAppointmentExam {
  static readonly type = '[Appointments] Update Appointment Exam';
  constructor(public payload: UpdateAppointmentExamParams) {}
}

// Refresh Selected Appointment will update an entire appointment
export class RefreshSelectedAppointment {
  static readonly type = '[Appointments] Refresh Selected Appointment';
  constructor(public payload: UpdateAppointmentExamParams) {}
}

export class GetAppointment {
  static readonly type = '[Appointments] Get Appointment By ID';
  constructor(public payload: {appointmentID: number}) {}
}

export class SubmitNoShow {
  static readonly type = '[Appointments] Submit No Show';
  constructor(public payload: {appointmentID: number}) {}
}
