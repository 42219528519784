// API Interface formatting Structure
//
// [method][nameOfApiCall][Type of request/response]
// Ex: getEkgResultsRequest
//
// Types of request/response:
// * RequestParams (queryString params)
// * ReuqestBody (POST contents)
// * Response (JSON structure)

export interface BooleanResponse extends IWithMessage {
  data: boolean;
}

export interface IWithMessage {
  message: string;
}

export interface IEmpty { }

export interface PatientKeys {
  appointmentID: number;
  epmsPID: number;
  reportID: number;
  examID: number;
  procedureID: number;
  reportTypeID: number;
  healthAssessmentID: number;
}

export const EmptyPatientKeys = {
  appointmentID: null,
  epmsPID: null,
  reportID: null,
  examID: null,
  procedureID: null,
  reportTypeID: null,
  healthAssessmentID: null
};
export interface IParamsUserAndEpms {
  userID: string;
  epmsPID: string;
}

// Params ReportID, UserID, EpmsPID
export interface IParamsRUE extends IParamsUserAndEpms {
  reportID: number;
}

export interface IParamsEpmsAndReport {
  epmsPID: number;
  reportID: number;
}

export interface BadgeConfig {
  text: string;
  color: string;
}
