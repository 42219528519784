import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AppointmentDetail } from "@config/types";
import {MatTable} from '@angular/material/table';

interface ToDoOnlyDataFormat {
  firstName: string;
  lastName: string;
  keycode: string;
  procedureName: string;
  dob: string;
  gender: string;
}

@Component({
  selector: 'app-appointments-todo-only',
  templateUrl: './appointment-todo-only-layout.component.html',
  styles: [
    `
      table {
        width: 100%;
      }
      .noData {
        padding: 25px;
      }
      .mat-row:nth-child(2n) {
        background-color: #f6f6ff;
      }
      .mat-row:hover {
        background-color: #c0c0ff;
        transition: all 0.5s;
        cursor: pointer;
      }
    `
  ]
})
export class AppointmentToDoOnlyLayout {
  @ViewChild(MatTable) table: MatTable<ToDoOnlyDataFormat>;
  @Input() appointmentData: AppointmentDetail[];
  @Output() appointmentSelected = new EventEmitter<AppointmentDetail>();

  columnKeys: string[] = ['lastName', 'firstName', 'keycode', 'procedureName', 'dob', 'gender'];

  clicked(appointmentDetail): void {
    console.log('clicked', appointmentDetail);
    this.appointmentSelected.emit(appointmentDetail);
  }
}
