import { map, Observable, Subject, takeUntil, withLatestFrom } from 'rxjs';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
    AppointmentDetail, ExamStatic, FacilityWithRoleDTO, LatestReportStatus, PatientPlan,
    PatientPlanResponse, SignOffResponse, User
} from '@config/types';
import {
    ConfirmationDialogComponent
} from '@core/components/confirmation-dialog/confirmation-dialog.component';
import { NavigationService } from '@core/services/navigation.service';
import { UserRole, userRole } from '@core/services/user.service';
import { UserState } from '@core/state/user.state';
import { Select } from '@ngxs/store';
import { ReviewService } from '@patient/services/review.service';
import { AppUtils } from '@shared/utils/app.utils';

import { MacroDialogComponent } from './macro-dialog.component';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit, OnDestroy {
  @Select(UserState.getUser) user$: Observable<User>;
  @Select(UserState.getRolesForSelectedFacility) roles$: Observable<FacilityWithRoleDTO>;

  rolesForSelectedFacility: FacilityWithRoleDTO;
  showEdit = null;
  followupSectionId = 3;
  loading = true;
  showField: number;

  appointment$: Observable<AppointmentDetail>;
  medicalReport: PatientPlan[] = [];
  status: LatestReportStatus;
  exam: ExamStatic;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private reviewService: ReviewService,
    private route: ActivatedRoute,
    private navigationService: NavigationService
  ) {
    this.appointment$ = this.route.parent.data.pipe(map(data => data.appointment));
  }

  ngOnInit(): void {
    this.appointment$.pipe(
      takeUntil(this.unsubscribe$),
      withLatestFrom(this.roles$)
    ).subscribe( ([appointment, roles]) => {
      this.status = appointment.exam.latestReportStatus;
      this.exam = appointment.exam;
      this.fetchMedicalReport();
      this.rolesForSelectedFacility = roles;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchMedicalReport(): void {
    this.reviewService
      .getMedicalReport()
      .subscribe(
        (response: PatientPlanResponse) => {
          if (response.data) {
            this.medicalReport = response.data;
            AppUtils.DEBUG && console.log(this.medicalReport);
          }
          this.loading = false;
        },
        () => (this.loading = false)
      );
  }

  openDialog(role: userRole, action?: string) {
    let message =
      role === UserRole.Provider
        ? `You will not be able to reopen the patient record after this sign off.
      If you are not ready to sign off, you can leave this record and return to it later - all inputs have been saved.
      To sign off and lock this record, please select Confirm Signoff below.`
        : `Please confirm that the Patient Plan is complete and ready to be shared with the patient.`;
    let okayButton = 'Confirm Signoff';
    if (action === 'needsReview') {
      message = 'Would you like to send this report back to a physician for review?';
      okayButton = 'Confirm';
    }
    const title =
      role === UserRole.Provider
        ? 'Are you sure you are completely finished with this patient\'s record and plan?'
        : null;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        message,
        role,
        buttonText: {
          ok: okayButton,
          cancel: 'Cancel',
        },
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe((confirmed) => {
        let methodName;
        if (confirmed) {
          if (action === 'needsReview') {
            methodName = 'needsReview';
          } else if (role === UserRole.ClinicalReview) {
            methodName = 'crSignoff';
          } else if (role === UserRole.Provider) {
            methodName = 'mdSignOff';
          }
          this.reviewService[methodName]().pipe(takeUntil(this.unsubscribe$)).subscribe((response: SignOffResponse) => {
            if (response.data) {
              this.status = response.data; // update status
              this.navigationService.goToDashboard();
            }
          });
        }
      });
  }

  openMacroDialog(field) {
    const dialogRef = this.dialog.open( MacroDialogComponent, {
      width: '50%',
       data: {
        fieldID: field.fieldID,
        overrideMacroNotes: field.notes,
        reportID: this.exam.id,
       }
    });

    dialogRef.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
      if (result) {
        this.reviewService.updateMedicalReport(result).subscribe(
          () =>  {
            field.notes = result.overrideMacroNotes;
            AppUtils.DEBUG && console.log(`${field.fieldName} Saved!`);
          }
        );
      }
    });
  }

  mouseEnter(data: any) {
    this.showEdit = data.showEdit;
    this.showField = data.showField;
  }

  mouseLeave() {
    this.showEdit = false;
    this.showField = null;
  }
}

