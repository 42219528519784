import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-button',
  templateUrl: './progress-button.component.html',
  styleUrls: ['./progress-button.component.scss'],
})
export class ProgressButtonComponent {
  @Input() loading: boolean;
  @Input() complete: boolean;
  @Input() text: string;
  @Input() disabled: boolean; // setting disabled state without loader icon in case of MarkAsReviewed

  renderIcon = false;

  renderCheck() {
    if (this.complete && !this.loading) {
      this.renderIcon = !this.renderIcon;
    }
    return;
  }

  action(): void {
    this.loading = true;
    this.text = 'Saving';
    setTimeout(() => {
      this.loading = !this.loading;
      this.text = 'Done';
      this.renderCheck();
    }, 2000);
    this.complete = true;
  }
}
